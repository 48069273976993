export interface LocalOptions {
    includeCurrencySymbol?: boolean;
    includeDecimal?: boolean;
    currency?: string;
    useGrouping?: boolean;
    locales?: string | string[] | undefined;
}

export const toLocaleNumber = (amount?: number | string, options?: LocalOptions): string => {
    const {
        includeCurrencySymbol = true,
        includeDecimal = false,
        currency = "INR",
        useGrouping = false,
        locales = "en-US",
    } = options ?? {};
    let num = amount ?? 0;
    if (typeof num === "string") {
        num = Number(num);
        if (isNaN(num)) num = 0;
    }
    return num.toLocaleString(locales, {
        ...(includeCurrencySymbol ? { style: "currency", currency } : {}),
        minimumFractionDigits: includeDecimal ? 2 : 0,
        maximumFractionDigits: includeDecimal ? 2 : 0,
        useGrouping,
    });
};

interface FormatCurrencyProps extends Intl.NumberFormatOptions {
    currency: string;
    value: number;
    includeDecimal: boolean;
}
export const formatCurrency = (props: FormatCurrencyProps) => {
    const { currency, value, includeDecimal = false, ...options } = props;

    const locales = currency == "INR" ? "en-IN" : "en-US";

    return new Intl.NumberFormat(locales, {
        style: "currency",
        currency,
        ...(options ?? {}),
        minimumFractionDigits: includeDecimal ? 2 : 0,
        maximumFractionDigits: includeDecimal ? 2 : 0,
    }).format(value);
};

const a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
];
const b = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/;

const getLT20 = (n: string) => a[Number(n)];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const getGT20 = (n: string) => b[n[0]] + " " + a[n[1]];

export const numWords = (input: string | number): string => {
    const num = Number(input);
    if (isNaN(num)) return "";
    if (num === 0) return "zero";

    const numStr = num.toString();
    if (numStr.length > 9) {
        throw new Error("overflow"); // Does not support converting more than 9 digits yet
    }

    const [, n1, n2, n3, n4, n5] = ("000000000" + numStr).substr(-9).match(regex) as string[]; // left pad zeros

    let str = "";
    str += Number(n1) != 0 ? (getLT20(n1) || getGT20(n1)) + "crore " : "";
    str += Number(n2) != 0 ? (getLT20(n2) || getGT20(n2)) + "lakh " : "";
    str += Number(n3) != 0 ? (getLT20(n3) || getGT20(n3)) + "thousand " : "";
    str += Number(n4) != 0 ? getLT20(n4) + "hundred " : "";
    str += Number(n5) != 0 && str != "" ? "and " : "";
    str += Number(n5) != 0 ? getLT20(n5) || getGT20(n5) : "";

    return str.trim();
};
