import { ChakraProps, HStack, StackProps, SystemStyleObject, Text, TextProps } from "@chakra-ui/react";
import startCase from "lodash/startCase";
import React from "react";

type Modules =
    | "bot-flow-table"
    | "bot"
    | "bot-session-logs"
    | "order"
    | "knowledge-base"
    | "contact-import-history"
    | "whatsapp-flows"
    | "message";

interface ChipColor {
    bgColor: SystemStyleObject["bgColor"];
    color: SystemStyleObject["color"];
    fontWeight?: SystemStyleObject["fontWeight"];
    fontSize?: SystemStyleObject["fontSize"];
    rounded?: SystemStyleObject["rounded"];
    px?: SystemStyleObject["px"];
}

const green: ChipColor = {
    bgColor: "green.100",
    color: "green.600",
};
const red: ChipColor = {
    bgColor: "red.100",
    color: "red.600",
};
const blue: ChipColor = {
    bgColor: "blue.100",
    color: "blue.600",
};
const yellow: ChipColor = {
    bgColor: "yellow.100",
    color: "yellow.600",
};
const gray: ChipColor = {
    bgColor: "gray.200",
    color: "gray.600",
};
const greenBadge: ChipColor = {
    bgColor: "green.500",
    color: "white",
    fontWeight: "normal",
    fontSize: "0.625rem",
    rounded: "sm",
};
const yellowBadge: ChipColor = {
    bgColor: "yellow.500",
    color: "white",
    fontWeight: "normal",
    fontSize: "0.625rem",
    rounded: "sm",
};
const tealBadge: ChipColor = {
    bgColor: "teal.100",
    color: "teal.800",
    fontWeight: "normal",
    fontSize: "0.625rem",
    rounded: "sm",
    px: 1,
};

const orange = {
    bgColor: "orange.50",
    color: "orange.600",
};
const styles: Record<string, ChipColor> = {
    open: green,
    close: blue,
    draft: blue,
    published: green,
    inactive: red,
    offline: red,
    live: green,
    success: green,
    failed: red,
    connected: green,
    sandbox: blue,
    disabled: red,
    pending_deletion: red,
    unregistered: blue,
    approved: green,
    pending: yellow,
    delivered: yellow,
    submitted: blue,
    received: blue,
    rejected: red,
    error: red,
    active: blue,
    dropped: gray,
    deleted: red,
    errored: red,
    removed: red,
    ended: green,
    succeeded: green,
    completed: green,
    read: green,
    sent: green,
    unknown: gray,
    /** Payments start */
    paid: green,
    created: blue,
    cancelled: red,
    enabling: yellow,
    enabled: green,
    /** Payments start */

    paused: yellow,
    warning: yellow,
    inprogress: yellow,
    accepted: green,
    revoked: blue,
    expired: red,
    new: greenBadge,
    beta: yellowBadge,
    /** Orders status start */
    "Order pending": yellow,
    "Order processing": yellow,
    "Order partially-shipped": blue,
    "Order shipped": blue,
    "Order completed": green,
    "Order cancelled": red,
    /** Orders status end */
    upgrade: blue,
    readonly: gray,
    "read-only": gray,
    optional: gray,
    "coming soon": gray,
};

const stylesWithModule: Record<Modules, Record<string, ChipColor>> = {
    "bot-flow-table": {
        published: green,
        in_draft: blue,
    },
    bot: {
        "default bot": tealBadge,
        v1: tealBadge,
    },
    "bot-session-logs": {
        active: yellow,
        ended: green,
        dropped: gray,
        "flow-dropped": gray,
        errored: red,
        unanswered: gray,
        "re-answered": blue,
        succeeded: green,
        failed: red,
    },
    order: {
        new: green,
        pending: yellow,
        processing: yellow,
        "partially-shipped": blue,
        shipped: blue,
        canceled: red,
        completed: green,
        paid: green,
        failed: red,
    },
    "whatsapp-flows": {
        draft: blue,
        published: green,
        deprecated: red,
        blocked: red,
        throttled: blue,
    },
    "knowledge-base": {
        "not-started": gray,
        failed: red,
        synced: green,
        processing: blue,
        "validation error": red,
        completed: green,
        cancelled: red,
    },
    "contact-import-history": {
        parsing: yellow,
        "parsing error": red,
        "ready for mapping": blue,
        processing: yellow,
        "validation error": red,
        completed: green,
        failed: red,
        cancelled: red,
    },
    message: {
        unknown: gray,
        queued: orange,
        sent: green,
        received: green,
        delivered: gray,
        read: blue,
        warning: orange,
        failed: red,
        deleted: red,
        error: red,
        //** Payment Starting */
        success: green,
        canceled: red,
        pending: orange,
        captured: gray,
        new: gray,
    },
};

export const WHATSAPP_MESSAGE_STATUS_TYPES = [
    "unknown",
    "queued",
    "sent",
    "received",
    "delivered",
    "read",
    "warning",
    "failed",
    "deleted",
    "error",
    //** Payment Starting */
    "success",
    "canceled",
    "failed",
    "pending",
    "captured",
    "new",
    // ** Payment Ending */
] as const;
export const getStatusColor = (value: string, module: Modules | undefined = undefined): ChipColor => {
    if (module) {
        return stylesWithModule[module]?.[value.toLowerCase()];
    }
    return styles[value.toLowerCase()];
};

interface StatusLabelProps extends Omit<StackProps, "bgColor" | "fontWeight" | "fontSize"> {
    module?: Modules;
    status: string;
    size?: ChakraProps["fontSize"];
    textProps?: Partial<TextProps>;
}

const StatusLabel: React.FC<StatusLabelProps> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status, module, size = "xs", textProps = {}, ...restProps } = props;
    return (
        <HStack
            sx={{
                fontSize: "xs",
                fontWeight: "medium",
                py: "0.5",
                px: "2.5",
                rounded: "md",
                ...restProps,
                ...(getStatusColor(status, module) ?? blue),
            }}
            justify="center"
            w="max-content"
        >
            <Text {...textProps}>{startCase(status.toLowerCase())}</Text>
        </HStack>
    );
};
export default StatusLabel;
