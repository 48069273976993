import { useToast } from "@chakra-ui/react";
import {
    CheckPermissionResult,
    Catalog,
    FBCatalogProduct,
    FBCatalogProductSet,
    FBCatalogResponse,
    ProductMessage,
} from "app/types/catalog-v2";
import { QueryKey, TQueryKey } from "app/types/common";
import { fetcher, getJSON, mapQueryParams, postJSON } from "app/utils/fetchUtils";
import {
    MutationFunction,
    QueryFunction,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from "react-query";

type FBCatalogListResponse = FBCatalogResponse<Catalog>;

interface UseCatalogListProps
    extends UseInfiniteQueryOptions<
        FBCatalogListResponse,
        Error,
        FBCatalogListResponse,
        FBCatalogListResponse,
        TQueryKey
    > {
    accountId: string;
    channelId: string;
    search?: string;
    status?: Catalog["status"] | "all";
    limit?: number;
}

export const useCatalogList = (props: UseCatalogListProps): UseInfiniteQueryResult<FBCatalogListResponse, Error> => {
    const { accountId, channelId, search, status = "connected", limit, ...options } = props;

    const PAGE_SIZE = 25;

    const queryResult = useInfiniteQuery<FBCatalogListResponse, Error, FBCatalogListResponse, TQueryKey>(
        [QueryKey.CatalogsV2, { accountId, channelId, status }],
        ({ queryKey, pageParam }) => {
            const [, { accountId, channelId, status }] = queryKey;
            const queryParams = mapQueryParams({ after: pageParam, limit: limit || PAGE_SIZE, name: search, status });
            const url = `/api/accounts/${accountId}/channels/${channelId}/fb-catalogs-v2?${queryParams}`;
            return getJSON(url);
        },
        {
            staleTime: 900000, // 15 mins
            ...options,
            getNextPageParam: (lastPage) =>
                lastPage.data.length >= PAGE_SIZE ? lastPage?.paging?.cursors?.after : undefined,
        }
    );
    return queryResult;
};

interface UseProductListProps
    extends UseInfiniteQueryOptions<
        FBCatalogResponse<FBCatalogProduct>,
        Error,
        FBCatalogResponse<FBCatalogProduct>,
        FBCatalogResponse<FBCatalogProduct>,
        TQueryKey
    > {
    accountId: string;
    channelId: string;
    catalogId: string;
    search?: string;
    searchBy?: "name" | "retailerId";
}

export const useProductList = (
    props: UseProductListProps
): UseInfiniteQueryResult<FBCatalogResponse<FBCatalogProduct>, Error> => {
    const { accountId, channelId, catalogId, search, searchBy, ...options } = props;

    const PAGE_SIZE = 25;

    const queryResult = useInfiniteQuery<
        FBCatalogResponse<FBCatalogProduct>,
        Error,
        FBCatalogResponse<FBCatalogProduct>,
        TQueryKey
    >(
        [QueryKey.ProductList, { accountId, channelId, catalogId, search, searchBy }],
        ({ queryKey, pageParam }) => {
            const [, { accountId, channelId, catalogId }] = queryKey;
            const queryParams = mapQueryParams({
                after: pageParam,
                limit: PAGE_SIZE,
                ...(searchBy === "retailerId" ? { retailerId: [search] } : { name: search }),
            });
            const url = `/api/accounts/${accountId}/channels/${channelId}/fb-catalogs-v2/${catalogId}/products?${queryParams}`;
            return fetcher(url);
        },
        {
            ...options,
            getNextPageParam: (lastPage) =>
                lastPage.data.length >= PAGE_SIZE ? lastPage?.paging?.cursors?.after : undefined,
            staleTime: 900000, // 15 mins
        }
    );
    return queryResult;
};

interface UseProductSetListProps
    extends UseInfiniteQueryOptions<
        FBCatalogResponse<FBCatalogProductSet>,
        Error,
        FBCatalogResponse<FBCatalogProductSet>,
        FBCatalogResponse<FBCatalogProductSet>,
        TQueryKey
    > {
    accountId: string;
    channelId?: string;
    catalogId?: string;
    search?: string;
    compact?: boolean;
}

export const useProductSetList = (
    props: UseProductSetListProps
): UseInfiniteQueryResult<FBCatalogResponse<FBCatalogProductSet>, Error> => {
    const { accountId, channelId = "all", compact, catalogId, ...options } = props;

    const PAGE_SIZE = 100;

    const queryResult = useInfiniteQuery<
        FBCatalogResponse<FBCatalogProductSet>,
        Error,
        FBCatalogResponse<FBCatalogProductSet>,
        TQueryKey
    >(
        [QueryKey.ProductSetList, { accountId, channelId, catalogId }],
        ({ queryKey, pageParam }) => {
            const [, { accountId, channelId, catalogId }] = queryKey;
            const queryParams = mapQueryParams({
                after: pageParam,
                limit: PAGE_SIZE,
                fields: compact ? "name,id,product_count" : undefined,
            });
            const url = `/api/accounts/${accountId}/channels/${channelId}/fb-catalogs-v2/${catalogId}/product-sets?${queryParams}`;
            return fetcher(url);
        },
        {
            ...options,
            getNextPageParam: (lastPage) =>
                lastPage.data.length >= PAGE_SIZE ? lastPage?.paging?.cursors?.after : undefined,
            staleTime: 900000, // 15 mins
        }
    );
    return queryResult;
};

interface WAProductMessagesListProps
    extends UseInfiniteQueryOptions<
        FBCatalogResponse<ProductMessage>,
        Error,
        FBCatalogResponse<ProductMessage>,
        FBCatalogResponse<ProductMessage>,
        TQueryKey
    > {
    accountId: string;
    channelId: string;
    search?: string | null;
    searchBy?: "name" | "retailerId" | null;
}

type UseWAProductMessagesResult = UseInfiniteQueryResult<FBCatalogResponse<ProductMessage>, Error>;

export const useWAProductMessagesList = (props: WAProductMessagesListProps): UseWAProductMessagesResult => {
    const PAGE_SIZE = 4;

    const { accountId, channelId, search, searchBy, ...options } = props;

    const fetchWACatalogs: QueryFunction<FBCatalogResponse<ProductMessage>, TQueryKey> = ({ pageParam, queryKey }) => {
        const [, { accountId, channelId, search }] = queryKey;
        const queryParams = mapQueryParams({
            after: pageParam,
            limit: PAGE_SIZE,
            ...(searchBy === "retailerId" ? { retailerId: [search] } : { name: search }),
        });
        return fetcher(
            `/api/accounts/${accountId}/channels/${channelId}/fb-catalogs-v2/productMessages?${queryParams}`
        );
    };

    const infiniteQueryResult = useInfiniteQuery<
        FBCatalogResponse<ProductMessage>,
        Error,
        FBCatalogResponse<ProductMessage>,
        TQueryKey
    >([QueryKey.WACatalogMessageList, { accountId, channelId, search, searchBy }], fetchWACatalogs, {
        ...options,
        getNextPageParam: (lastPage) =>
            lastPage.data.length >= PAGE_SIZE ? lastPage?.paging?.cursors?.after : undefined,
        staleTime: 900000, // 15 mins
    });

    return infiniteQueryResult;
};

export const useWAProductSetMessagesList = (props: WAProductMessagesListProps): UseWAProductMessagesResult => {
    const PAGE_SIZE = 100;

    const { accountId, channelId, search, ...options } = props;

    const fetchWACatalogs: QueryFunction<FBCatalogResponse<ProductMessage>, TQueryKey> = ({ pageParam, queryKey }) => {
        const [, { accountId, channelId, search }] = queryKey;
        const queryParams = mapQueryParams({ after: pageParam, limit: PAGE_SIZE, name: search });
        return fetcher(
            `/api/accounts/${accountId}/channels/${channelId}/fb-catalogs-v2/productSetMessages?${queryParams}`
        );
    };

    const infiniteQueryResult = useInfiniteQuery<
        FBCatalogResponse<ProductMessage>,
        Error,
        FBCatalogResponse<ProductMessage>,
        TQueryKey
    >([QueryKey.WACatalogSetList, { accountId, channelId, search }], fetchWACatalogs, {
        ...options,
        getNextPageParam: (lastPage) =>
            lastPage.data.length >= PAGE_SIZE ? lastPage?.paging?.cursors?.after : undefined,
        staleTime: 900000, // 15 mins
    });

    return infiniteQueryResult;
};

interface UseCheckPermissionsProps
    extends UseQueryOptions<CheckPermissionResult, Error, CheckPermissionResult, TQueryKey> {
    accountId: string;
}

export const useCheckPermissions = (props: UseCheckPermissionsProps): UseQueryResult<CheckPermissionResult, Error> => {
    const { accountId, ...options } = props;

    const queryResult = useQuery<CheckPermissionResult, Error, CheckPermissionResult, TQueryKey>(
        [QueryKey.FBPermissions, { accountId }],
        ({ queryKey }) => {
            const [, { accountId }] = queryKey;
            const url = `/api/accounts/${accountId}/channels/all/fb-catalogs-v2/check-permissions`;
            return fetcher(url);
        },
        { ...options, staleTime: 900000 } // 15 mins
    );
    return queryResult;
};

interface SyncCatalogsResponse {
    status: string;
}

interface UseSyncCatalogsProps extends UseMutationOptions<SyncCatalogsResponse, Error, void> {
    accountId: string;
    channelId?: string;
}

export const useSyncCatalogs = (props: UseSyncCatalogsProps): UseMutationResult<SyncCatalogsResponse, Error, void> => {
    const { accountId, channelId, ...options } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const syncProducts: MutationFunction<SyncCatalogsResponse, void> = () => {
        return postJSON(
            `/api/accounts/${accountId}/channels/${channelId ? channelId : "all"}/fb-catalogs-v2/sync?immediate=true`,
            {}
        );
    };

    const mutationResult = useMutation<SyncCatalogsResponse, Error, void>(syncProducts, {
        onError: () => {
            toast({ title: "Error while syncing catalogues!", status: "error" });
        },
        onSuccess: () => {
            toast({ title: "Catalogue synced successfully", status: "success" });
            queryClient.invalidateQueries([QueryKey.CatalogsV2, { accountId, channelId }]);
        },
        ...options,
    });

    return mutationResult;
};
