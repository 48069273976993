import { HStack, Icon, Image, Text } from "@chakra-ui/react";
import React from "react";
import { BiHash } from "react-icons/bi";
import { components, OptionProps } from "react-select";
import { Option } from "./types";

export const CustomOption: React.ComponentType<OptionProps<Option, false>> = (innerProps) => {
    const { Icon: LogoIcon, IconImage, subTitle } = innerProps.data;
    const renderIcon = () => {
        if (!innerProps.data.IconImage && !innerProps.data.Icon) {
            return <Icon as={BiHash} ml="1" boxSize="5" color="#gray.600" />;
        }
        if (innerProps.data.IconImage) {
            return <Image src={IconImage} w="5" h="5" ml="1" />;
        }
        return <Icon as={LogoIcon} ml="1" boxSize="5" textColor="gray.600" />;
    };
    return (
        <components.Option {...innerProps}>
            <HStack
                padding="6px 6px 6px 0px"
                backgroundColor={innerProps.isFocused ? "gray.100" : "white"}
                _hover={{
                    backgroundColor: "gray.100",
                }}
                _focus={{
                    backgroundColor: "gray.100",
                }}
                ref={innerProps.innerRef}
                justifyContent="space-between"
            >
                <HStack>
                    {renderIcon()}
                    <Text color="gray.600">{innerProps.label}</Text>
                </HStack>
                {!subTitle && (
                    <Text fontWeight="semibold" color="gray.100">
                        {subTitle}
                    </Text>
                )}
            </HStack>
        </components.Option>
    );
};
