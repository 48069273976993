import { Badge, Box, HStack, Icon, Image, Skeleton, Spacer, Text, VStack } from "@chakra-ui/react";
import MarkdownRender from "app/screens/ConversationV2/Messages/Message/MarkdownRender";
import { getMapImageAndLink, mustacheRender } from "app/utils/react-helpers";
import React from "react";
import { IconType } from "react-icons";
import { FaListUl } from "react-icons/fa";
import { MdList } from "react-icons/md";
import { RiExternalLinkFill, RiPhoneFill, RiReplyFill } from "react-icons/ri";
import FilePreview from "../FilePreview";
import GBTooltip from "../GBTooltip";
import { msgContainerProps, styles } from "./styles";
import { TemplatePreviewComponentProps } from "./types";

export const BUTTON_ICONS: Record<string, IconType> = {
    PHONE_NUMBER: RiPhoneFill,
    QUICK_REPLY: RiReplyFill,
    URL: RiExternalLinkFill,
    STATIC_URL: RiExternalLinkFill,
    DYNAMIC_URL: RiExternalLinkFill,
    EXPLORE: FaListUl,
    LIST: MdList,
};

const mapVariablesWithBodyText = (
    bodyText: string | undefined,
    sampleValuesObj?: Record<string, any>
): React.JSX.Element | React.JSX.Element[] => {
    if (!bodyText) return <></>;
    const variablePattern = /{{(\s*[\w.]+\s*)}}/g;
    const textSplit = bodyText.split(" ");

    const arr = textSplit?.map((a, aI) => {
        const [match, variableName] = variablePattern.exec(a) ?? [];
        variablePattern.lastIndex = 0;
        const isVariable = variableName != null;
        if (isVariable) {
            const prefix = match && a.substring(0, a.indexOf(match));
            const suffix = match && a.substring(a.indexOf(match) + match.length);

            return (
                <React.Fragment key={`bodyVariable-${aI}`}>
                    {prefix && <Text as="span">{prefix}</Text>}
                    <GBTooltip hasArrow label={variableName}>
                        <Badge
                            overflow="hidden"
                            maxWidth="full"
                            color="green.500"
                            whiteSpace="pre-line"
                            overflowWrap="anywhere"
                        >
                            {sampleValuesObj?.[variableName] || variableName}
                        </Badge>
                    </GBTooltip>
                    {suffix && <Text as="span">{suffix}</Text>}
                </React.Fragment>
            );
        }
        const prefix = aI === 0 ? "" : " ";
        const suffix = aI === textSplit.length - 1 ? "" : " ";
        return (
            <React.Fragment key={`bodyVariable-${aI}`}>
                {prefix && <Text as="span">{prefix}</Text>}
                <Text as="span">{a}</Text>
                {suffix && <Text as="span">{suffix}</Text>}
            </React.Fragment>
        );
    });
    return arr;
};

const TemplateMsgComponent: React.FC<TemplatePreviewComponentProps> = (props) => {
    const { templateObj, showBodyVariable = false, isRTL, ...stackProps } = props;

    const rightToLeftStyle = React.useMemo(() => {
        return isRTL ? { direction: "rtl" } : {};
    }, [isRTL]);

    const locationMapImage = React.useMemo(() => {
        const { latitude, longitude, name, address } = templateObj?.location ?? {};
        if (!latitude || !longitude) return null;

        const { image } = getMapImageAndLink(latitude, longitude, { height: 216, width: 216 });
        return (
            <VStack spacing={1} w="full" alignItems="flex-start" bg="gray.200" borderRadius={"4px"} overflow="hidden">
                <Image src={image} w="full" />

                {name && (
                    <Text ml={1} fontSize="xs" fontWeight="semibold" sx={rightToLeftStyle} color={"gray.800"}>
                        {name}
                    </Text>
                )}

                {address && (
                    <Text ml={1} fontSize="xs" sx={rightToLeftStyle} color={"gray.600"}>
                        {address}
                    </Text>
                )}
            </VStack>
        );
    }, [rightToLeftStyle, templateObj?.location]);

    const headerText = React.useMemo(() => {
        if (!templateObj?.headerText || templateObj.productTemplate) return null;
        return (
            <Box sx={{ ...rightToLeftStyle, ...styles.headerText }}>
                <MarkdownRender source={`${templateObj?.headerText}`} />
            </Box>
        );
    }, [templateObj?.headerText, templateObj?.productTemplate, rightToLeftStyle]);

    const headerMedia = React.useMemo(() => {
        if (!templateObj?.headerMedia?.mediaUrl) return null;
        return (
            <Box sx={{ ...styles.mediaContainerStyle }}>
                <FilePreview
                    source={templateObj?.headerMedia.mediaUrl}
                    contentType={templateObj?.headerMedia.type}
                    contentFormat={templateObj?.headerMedia?.format}
                />
            </Box>
        );
    }, [templateObj?.headerMedia]);

    const headerProduct = React.useMemo(() => {
        if (!templateObj?.productTemplate || !templateObj?.productTemplate?.title) return null;
        const { description, itemCount, thumbnailUrl, title, type } = templateObj?.productTemplate;

        return (
            <HStack bgColor="gray.100" marginBottom={2} borderRadius={10} overflow="hidden">
                <Image fallback={<Skeleton height="30px" />} boxSize="75px" objectFit="cover" src={thumbnailUrl} />
                <VStack w="full" alignItems="flex-start" spacing={0}>
                    <Text fontSize="xs" fontWeight="semibold" sx={rightToLeftStyle} noOfLines={2}>
                        {title}
                    </Text>
                    <Text fontSize="xs" sx={rightToLeftStyle} noOfLines={2}>
                        {type === "catalog" ? description : `${itemCount} ${itemCount ?? 0 > 1 ? "items" : "item"}`}
                    </Text>
                </VStack>
            </HStack>
        );
    }, [rightToLeftStyle, templateObj?.productTemplate]);

    const bodyText = React.useMemo(() => {
        if (!templateObj?.bodyText) return null;
        return (
            <Box sx={{ ...rightToLeftStyle, ...styles.bodyText }}>
                {showBodyVariable ? (
                    mapVariablesWithBodyText(templateObj?.bodyText, templateObj?.bodyTextSampleVarValues)
                ) : (
                    <MarkdownRender
                        source={
                            templateObj?.bodyTextSampleVarValues
                                ? mustacheRender(`${templateObj?.bodyText}`, templateObj?.bodyTextSampleVarValues)
                                : `${templateObj?.bodyText}`
                        }
                    />
                )}
            </Box>
        );
    }, [templateObj?.bodyText, templateObj?.bodyTextSampleVarValues, rightToLeftStyle, showBodyVariable]);

    const footerText = React.useMemo(() => {
        if (!templateObj?.footerText) return null;
        return (
            <Box sx={{ ...rightToLeftStyle, ...styles.footerText }}>
                <MarkdownRender source={`${templateObj?.footerText}`} />
            </Box>
        );
    }, [templateObj?.footerText, rightToLeftStyle]);

    const buttons = React.useMemo(() => {
        if (!templateObj?.buttons || !Boolean(templateObj?.buttons.length)) return null;

        if (templateObj?.buttons.length > 3) {
            return (
                <>
                    {templateObj?.buttons.slice(0, 2).map((button, index) => {
                        const icon = button.type ? BUTTON_ICONS[button.type] : null;
                        if (!button.text) return null;
                        return (
                            <HStack key={index} sx={{ ...styles.buttons }}>
                                {icon && <Icon as={icon} />}
                                <Text title={button?.url ?? button?.phone_number ?? button?.text}>{button.text}</Text>
                            </HStack>
                        );
                    })}
                    <HStack key={"explore_btn"} sx={{ ...styles.buttons }}>
                        <Icon as={BUTTON_ICONS["EXPLORE"]} />
                        <Text
                            title={templateObj?.buttons
                                .slice(2)
                                .map((d) => d.text)
                                .join(", ")}
                        >
                            Explore more
                        </Text>
                    </HStack>
                </>
            );
        } else
            return templateObj?.buttons.map((button, index) => {
                const icon = button.type ? BUTTON_ICONS[button.type] : null;
                if (!button.text) return null;
                return (
                    <HStack key={index} sx={{ ...styles.buttons }}>
                        {icon && <Icon as={icon} />}
                        <Text title={button?.url ?? button?.phone_number ?? button?.text}>{button.text}</Text>
                    </HStack>
                );
            });
    }, [templateObj?.buttons]);

    const cards = React.useMemo(() => {
        if (!templateObj?.cards || !Boolean(templateObj?.cards.length)) return;

        return templateObj?.cards.map((card, cardIndex) => {
            const { bodyText, bodyTextSampleVarValues, buttons, headerMedia } = card;
            return (
                <Box
                    key={`card-${cardIndex}`}
                    {...msgContainerProps}
                    {...stackProps}
                    flexShrink={0}
                    scrollSnapAlign="start"
                    scrollBehavior="smooth"
                    width={(templateObj?.cards?.length || 0) > 1 ? "95%" : "100%"}
                >
                    {headerMedia?.mediaUrl && (
                        <Box sx={{ ...styles.mediaContainerStyle }}>
                            <FilePreview
                                source={headerMedia.mediaUrl}
                                contentType={headerMedia.type}
                                contentFormat={headerMedia?.format}
                            />
                        </Box>
                    )}
                    {bodyText && (
                        <Box sx={{ ...rightToLeftStyle, ...styles.bodyText }} noOfLines={8} title={`${bodyText}`}>
                            {showBodyVariable ? (
                                mapVariablesWithBodyText(bodyText, bodyTextSampleVarValues)
                            ) : (
                                <MarkdownRender
                                    source={
                                        bodyTextSampleVarValues
                                            ? mustacheRender(`${bodyText}`, bodyTextSampleVarValues)
                                            : `${bodyText}`
                                    }
                                />
                            )}
                        </Box>
                    )}
                    <Spacer />
                    {buttons &&
                        buttons.map((button, index) => {
                            const icon = button.type ? BUTTON_ICONS[button.type] : null;
                            if (!button.text) return null;
                            return (
                                <HStack key={index} sx={{ ...styles.buttons }}>
                                    {icon && <Icon as={icon} />}
                                    <Text title={button?.url ?? button?.phone_number ?? button?.text}>
                                        {button.text}
                                    </Text>
                                </HStack>
                            );
                        })}
                </Box>
            );
        });
    }, [templateObj?.cards, stackProps, rightToLeftStyle, showBodyVariable]);

    return (
        <VStack minW="192px" maxW="232px" width="full" p={0} spacing={1}>
            <Box
                {...msgContainerProps}
                {...stackProps}
                borderTopLeftRadius={0}
                position="relative"
                zIndex={0}
                _after={{
                    // eslint-disable-next-line quotes
                    content: '""',
                    position: "absolute",
                    width: 0,
                    height: 0,
                    left: "-16px",
                    right: "auto",
                    top: 0,
                    bottom: "auto",
                    border: "16px solid",
                    borderColor: "white transparent transparent transparent",
                    zIndex: -1,
                    borderRadius: "6px",
                }}
            >
                {headerText}
                {headerMedia}
                {headerProduct}
                {locationMapImage}
                {bodyText}
                {footerText}
                {buttons}
            </Box>
            <HStack
                alignItems="stretch"
                width="full"
                padding={0}
                spacing={1}
                style={{ overflowX: "auto", scrollbarWidth: "thin", msOverflowStyle: "none" }}
            >
                {cards}
            </HStack>
        </VStack>
    );
};

export default TemplateMsgComponent;
