import { SAccountEntitlement, FeatureName, FeatureEntitlement, FeatureCategoryType } from "app/types";
import cloneDeep from "lodash/cloneDeep";
import { AccountEntitlement, UpdateAccountEntitlementUsageArgs } from "./type";

export const sanitizeAccountEntitlement = (accountEntitlement: SAccountEntitlement): AccountEntitlement => {
    const entitlementByFeature: Partial<AccountEntitlement["entitlementByFeature"]> = {};
    const { addOns, exceptions, entitlement, usage: allUsages } = accountEntitlement;

    const excp = exceptions ?? [];
    const entitledFeatures: FeatureEntitlement["features"] = entitlement?.features ?? [];

    const features: FeatureEntitlement["features"] = excp.reduce((acc, curr) => {
        const featureIndex = acc.findIndex((f) => f.name === curr.name);
        if (featureIndex > -1) {
            acc[featureIndex] = {
                ...acc[featureIndex],
                ...(curr.type === "category" || curr.type === "boolean"
                    ? { category: curr.category }
                    : { limit: curr.limit }),
            };
        } else {
            acc.push(curr);
        }
        return acc;
    }, entitledFeatures);

    let featuresWithAddon: FeatureEntitlement["features"] = features;

    if (addOns && Object.keys(addOns).length > 0) {
        featuresWithAddon = features.map((feature) => {
            if (!accountEntitlement?.addOns) return feature;
            const featureAddOn = accountEntitlement.addOns?.[feature.name];
            if (!featureAddOn) return feature;
            if (feature.type === "category") {
                return {
                    ...feature,
                    category: featureAddOn,
                };
            }
            if (feature.type === "limit" && typeof feature["limit"] == "number") {
                return {
                    ...feature,
                    limit: featureAddOn + feature.limit,
                };
            }
            return feature;
        }) as FeatureEntitlement["features"];
    }

    featuresWithAddon?.forEach((feature) => {
        if (feature.type === "category") {
            entitlementByFeature[feature.name] = {
                limitExceeds: false,
                pageAccess: true,
                category: feature.category as FeatureCategoryType,
                restrictPage: false,
                overUsage: false,
                type: feature.type,
                usage: null,
                limit: null,
            };
        } else if (feature.type === "limit" && typeof feature["limit"] == "number") {
            let limitExceeds = false;
            let pageAccess = true;
            let overUsage = false;

            const usage = allUsages[feature.name];
            const limit = feature.limit;
            if (limit < 1) {
                pageAccess = false;
                limitExceeds = true;
                overUsage = false;
            } else {
                limitExceeds = usage >= limit;
                overUsage = usage > limit;
                pageAccess = true;
            }

            entitlementByFeature[feature.name] = {
                limitExceeds,
                pageAccess,
                restrictPage: !pageAccess,
                overUsage,
                type: feature.type,
                usage,
                limit,
            };
        }
    });

    return {
        ...accountEntitlement,
        entitlementByFeature: entitlementByFeature as AccountEntitlement["entitlementByFeature"],
    };
};

export const updateAccountEntitlement = (data: UpdateAccountEntitlementUsageArgs): SAccountEntitlement => {
    const { accountEntlmt, type, updatedData } = data;
    const { feature, updateValue } = updatedData;
    const accountEntitlement = cloneDeep(accountEntlmt);

    const updateValueFn = (featureName: FeatureName) => {
        // Get previous usage/addOn value to update
        const prevValue = accountEntitlement[type]?.[featureName];

        // while addOns updating, we will get new addOn value, need to replace it with old one.
        if (type === "addOns" || typeof prevValue === "undefined") {
            // if feature not already in usage/addOn , adding the usage/addOn with updateValue
            (accountEntitlement[type] as Record<Partial<FeatureName>, number>)[featureName] = updateValue;
        } else if (typeof updateValue === "number" && accountEntitlement[type]) {
            // if feature in usage/addOn , updating previous value with current value
            (accountEntitlement[type] as Record<Partial<FeatureName>, number>)[featureName] = prevValue + updateValue;
        }
    };

    if (Array.isArray(feature)) {
        accountEntlmt.entitlement?.features
            .filter((f) => feature.includes(f.name) && f.type === "limit")
            .forEach((f) => updateValueFn(f.name));
    } else {
        updateValueFn(feature);
    }

    return accountEntitlement;
};
