import {
    CircularProgress,
    CircularProgressProps,
    ColorProps,
    Image,
    Text,
    TypographyProps,
    VStack,
} from "@chakra-ui/react";
import config from "app/app.config";
import gbloader from "assets/GB_loading_animation.gif";
import React from "react";

export interface LoadingStateProps {
    title?: string;
    spinnerSize?: number;
    textSize?: TypographyProps["fontSize"];
    textColor?: ColorProps["color"];
    spinnerColor?: CircularProgressProps["color"];
}

const AnimatedLoadingState: React.FC<LoadingStateProps> = ({
    title,
    spinnerSize = 20,
    spinnerColor = "brand.500",
    textSize = "xs",
    textColor = "gray.600",
}) => {
    return (
        <VStack justify="center">
            {config.product_name === "Gallabox" ? (
                <Image src={gbloader} boxSize={`${spinnerSize}px`} />
            ) : (
                <CircularProgress isIndeterminate capIsRound color={spinnerColor} size={`${spinnerSize}px`} />
            )}
            {title ? (
                <Text color={textColor} fontSize={textSize}>
                    {title}
                </Text>
            ) : null}
        </VStack>
    );
};

export default AnimatedLoadingState;
