import {
    Button,
    ButtonProps,
    HStack,
    Icon,
    IconButton,
    Skeleton,
    Stack,
    StackProps,
    Tag,
    Text,
    VStack,
} from "@chakra-ui/react";
import { templateTagColor } from "app/screens/Account/WATemplatesV2/utilities/constants";
import { StylesObject, WATemplateMessageCategoryType, WATemplateStatus } from "app/types";
import { formatDateGroup, formatDateGroupNew } from "app/utils/date";
import delay from "lodash/delay";
import startCase from "lodash/startCase";
import React, { PropsWithChildren } from "react";
import { RiCheckboxCircleFill } from "react-icons/ri";
import GBTooltip from "../GBTooltip";

const styles: StylesObject = {
    msgContainerStyles: {
        alignItems: "flex-start",
        w: "full",
        borderRadius: "6px",
        spacing: "0px",
        overflow: "hidden",
        height: "360px",
        borderWidth: "2px",
        borderColor: "gray.200",
        bg: "gray.100",
        maxW: "320px",
    },
    msgHeaderTextStyles: {
        textAlign: "left",
        noOfLines: 1,
        maxW: "18ch",
        fontSize: "sm",
        fontWeight: "medium",
        color: "gray.800",
    },
    msgChildrenContainerStyles: {
        py: 3,
        pl: 6,
        w: "full",
        overflowY: "scroll",
        position: "relative",
        alignItems: "flex-start",
        height: "full",
    },
    msgHeaderContainerStyles: {
        justifyContent: "space-between",
        bg: "gray.200",
        w: "full",
        py: 2,
        px: 2,
    },
};

interface GBPreviewWaTemplateMsgWrapProps extends StackProps {
    templateName: string;
    action?: JSX.Element;
    actionProps?: ButtonProps;
    actionText?: string;
    secondaryInfo?: JSX.Element;
    footer?: JSX.Element;
    disableAction?: boolean;
    childrenContainerProps?: StackProps;
}

export const GBPreviewWaTemplateMsgWrap: React.FC<PropsWithChildren<GBPreviewWaTemplateMsgWrapProps>> = ({
    templateName,
    action,
    actionProps,
    actionText,
    secondaryInfo,
    footer,
    children,
    disableAction = false,
    childrenContainerProps,
    className,
    ...stackProps
}) => {
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => setIsVisible(true), 1000);
    }, []);

    return (
        <VStack sx={{ ...styles.msgContainerStyles, ...stackProps }} className={className}>
            <HStack sx={{ ...styles.msgHeaderContainerStyles }}>
                <VStack alignItems="flex-start">
                    <Text
                        sx={{ ...styles.msgHeaderTextStyles, maxW: disableAction ? "30ch" : "18ch" }}
                        title={templateName}
                    >
                        {templateName}
                    </Text>
                    {secondaryInfo}
                </VStack>

                {disableAction ? null : action ? (
                    action
                ) : (
                    <Button variant="primary" size="medium" px={3} {...actionProps}>
                        {actionText ?? "Select"}
                    </Button>
                )}
            </HStack>
            {children && (
                <VStack sx={{ ...styles.msgChildrenContainerStyles, ...childrenContainerProps }}>
                    {isVisible ? (
                        children
                    ) : (
                        <Stack w="full">
                            <Skeleton height="20px" />
                            <Skeleton height="60px" ml={3} />
                            <Skeleton height="20px" ml={3} />
                        </Stack>
                    )}
                </VStack>
            )}
            {footer}
        </VStack>
    );
};

interface GBPickWaTemplateMsgWrapProps extends StackProps {
    templateName: string;
    childrenContainerProps?: StackProps;
    language?: string;
    createdAt?: string;
    status?: WATemplateStatus;
    category?: WATemplateMessageCategoryType;
    onSelect: () => void;
}

export const GBPickWaTemplateMsgWrap: React.FC<PropsWithChildren<GBPickWaTemplateMsgWrapProps>> = ({
    templateName,
    children,
    childrenContainerProps,
    createdAt,
    language,
    status,
    category,
    onSelect,
    ...stackProps
}) => {
    const [selected, setSelected] = React.useState<boolean>(false);
    const [isVisible, setIsVisible] = React.useState(false);
    const tagColor = templateTagColor.get(status ?? "pending");
    const recentlyApprovedInfo =
        status === "recently_approved"
            ? "To avoid message failure, templates can be used only 15 minutes after approval."
            : undefined;

    React.useEffect(() => {
        setTimeout(() => setIsVisible(true), 1000);
    }, []);

    return (
        <VStack
            sx={{ ...styles.msgContainerStyles, ...stackProps }}
            cursor={status === "recently_approved" ? "not-allowed" : "pointer"}
            _hover={{
                borderRadius: "6px",
                borderWidth: "2px",
                borderColor: "blue.400",
                transition: "all 0.1s ease-in",
            }}
            onClick={() => {
                if (status === "recently_approved") return;
                setSelected(true);
                delay(onSelect, 200);
            }}
            role="group"
            title={recentlyApprovedInfo}
        >
            <HStack sx={{ ...styles.msgHeaderContainerStyles }}>
                <VStack w="full" alignItems="flex-start">
                    <Text sx={{ ...styles.msgHeaderTextStyles }} title={templateName}>
                        {templateName}
                    </Text>
                    {(status || category) && (
                        <HStack w="full">
                            {status && (
                                <Tag
                                    backgroundColor={tagColor?.bgColor}
                                    color={tagColor?.fontColor}
                                    size="sm"
                                    borderRadius="4px"
                                >
                                    <Text isTruncated title={startCase(status)}>
                                        {startCase(status)}
                                    </Text>
                                </Tag>
                            )}
                            <Text
                                fontSize="11px"
                                maxWidth="15ch"
                                noOfLines={1}
                                color="gray.500"
                                fontStyle="normal"
                                title={category}
                            >
                                {category}
                            </Text>
                        </HStack>
                    )}
                </VStack>

                <GBTooltip label={recentlyApprovedInfo}>
                    <IconButton
                        variant="ghost"
                        rounded="full"
                        aria-label="Select template"
                        size="sm"
                        icon={
                            <Icon
                                as={RiCheckboxCircleFill}
                                boxSize="7"
                                opacity={selected ? "1" : "0"}
                                textColor={selected ? "blue.500" : "blue.100"}
                                _groupHover={{
                                    opacity: "1",
                                    textColor: selected ? "blue.500" : "blue.200",
                                    transition: "all 0.2s ease-in-out",
                                }}
                            />
                        }
                        cursor={status === "recently_approved" ? "not-allowed" : "pointer"}
                    />
                </GBTooltip>
            </HStack>
            {children && (
                <VStack sx={{ ...styles.msgChildrenContainerStyles, ...childrenContainerProps }}>
                    {isVisible ? (
                        children
                    ) : (
                        <Stack w="full">
                            <Skeleton height="20px" />
                            <Skeleton height="60px" ml={3} />
                            <Skeleton height="20px" ml={3} />
                        </Stack>
                    )}
                </VStack>
            )}

            {(language || createdAt) && (
                <HStack
                    shadow="base"
                    borderColor="gray.500"
                    px="10px"
                    justifyContent="space-between"
                    bg="gray.200"
                    w="full"
                    p={2}
                >
                    <Tag bgColor="gray.100" size="sm" color="gray.600" fontSize="xs">
                        {language}
                    </Tag>
                    {createdAt && (
                        <GBTooltip label={formatDateGroupNew(createdAt)}>
                            <Text fontSize="xs" fontWeight="normal" color="gray.500">
                                {formatDateGroup(createdAt)}
                            </Text>
                        </GBTooltip>
                    )}
                </HStack>
            )}
        </VStack>
    );
};
