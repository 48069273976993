import { createContext } from "react";
import { AccountEntitlementContextType } from "./type";

export const AccountEntitlementContext = createContext<AccountEntitlementContextType>({
    isLoading: false,
    isFetched: false,
    setAccountEntitlement: () => {
        throw Error("Account Entitlement not set yet::setAccountEntitlement");
    },
    updateEntitlement: () => {
        throw Error("Account Entitlement not set yet::updateEntitlement");
    },
});
