import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Checkbox as ChakraCheckbox,
    CheckboxProps as ChakraCheckboxProps,
    Editable as ChakraEditable,
    EditableInput,
    EditablePreview,
    EditableProps as ChakraEditableProps,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabelProps,
    HStack,
    IconButton,
    Input as ChakraInput,
    InputGroup,
    InputGroupProps,
    InputLeftAddon,
    InputProps as ChakraInputProps,
    InputRightElement,
    RadioGroup as ChakraRadioGroup,
    RadioGroupProps as ChakraRadioGroupProps,
    Select as ChakraSelect,
    SelectProps as ChakraSelectProps,
    Spacer,
    Switch as ChakraSwitch,
    SwitchProps as ChakraSwitchProps,
    SystemStyleObject,
    Text,
    Textarea as ChakraTextarea,
    TextareaProps as ChakraTextareaProps,
    VStack,
} from "@chakra-ui/react";
import AsyncSelectStyled, { AsyncSelectStyledProps } from "app/components/CustomizedReactSelect/AsyncSelectStyled";
import CreatableReactSelectStyled, {
    CreatableReactSelectStyledProps,
} from "app/components/CustomizedReactSelect/CreatableReaceSelectStyled";
import ReactSelectStyled, { ReactSelectStyledProps } from "app/components/CustomizedReactSelect/ReactSelectStyled";
import {
    CreatableSelectRefType,
    ReactAsyncSelectRefType,
    ReactSelectRefType,
} from "app/components/CustomizedReactSelect/type";
import { booleanOptions, getBooleanValue } from "app/components/Fields/FieldUtils";
import FloatInput, { FloatInputProps } from "app/components/Input/FloatInput";
import PhoneNumberInput, { PhoneNumberFormInput } from "app/components/PhoneNumberInput";
import CurrencyInput, { CurrencyInputProps } from "app/gbComponents/Input/CurrencyInput";
import FormControlWrapper, { FormControlWrapperProps } from "app/gbComponents/Misc/FormControlWrapper";
import StarRating, { StarRatingProps } from "app/gbComponents/Misc/StarRating";
import ContactSearchSelect, { ContactSearchSelectProps } from "app/gbComponents/ReactSelect/ContactSearchSelect";
import CurrencySelect, { CurrencySelectProps } from "app/gbComponents/ReactSelect/CurrencySelect";
import { LabelValue } from "app/types";
import startCase from "lodash/startCase";
import * as React from "react";
import {
    Controller,
    FieldError,
    FieldValues,
    RegisterOptions,
    SubmitHandler,
    useFieldArray,
    useForm,
    UseFormMethods,
    UseFormOptions,
} from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { HiOutlinePlus, HiOutlineX, HiPlus } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Props as PhoneNumberInputProps } from "react-phone-number-input";
import {
    default as ReactSelectInput,
    OptionTypeBase,
    Props as ReactSelectInputProps,
    StylesConfig,
} from "react-select";
import { default as AsyncSelect, Props as ReactAsyncSelectInputProps } from "react-select/async";
import { callFnsInSequence, CountryAndCurrencyType, isJSXElement } from "./common";

export const spiltFormControlWrapperProps = <T extends FormControlWrapperProps>(
    props: T
): { formControlWrapperProps: FormControlWrapperProps; restProps: Omit<T, keyof FormControlWrapperProps> } => {
    const {
        label,
        labelProps = {},
        labelContainerProps = {},
        fieldContainerProps = {},
        formControlProps = {},
        helpText,
        required = false,
        isOptional = false,
        labelFieldOrientation,
        hidelabel = false,
        tooltipText,
        toolTipProps,
        errorMessageProps,
        helperTextProps,
        error,
        infoLink,
        infoLinkLabel,
        labelExtraComponent,
        ...restProps
    } = props;

    return {
        formControlWrapperProps: {
            label,
            labelProps,
            labelContainerProps,
            fieldContainerProps,
            formControlProps,
            helpText,
            required,
            isOptional,
            labelFieldOrientation,
            hidelabel,
            tooltipText,
            toolTipProps,
            errorMessageProps,
            helperTextProps,
            infoLink,
            infoLinkLabel,
            error,
            labelExtraComponent,
        },
        restProps,
    };
};

interface FormProps<FormValues extends FieldValues = FieldValues> extends UseFormOptions<FormValues> {
    onSubmit: SubmitHandler<FormValues>;
    children: (methods: UseFormMethods<FormValues>) => React.ReactNode;
    isFullWidth?: boolean;
    formProps?: Omit<React.FormHTMLAttributes<HTMLFormElement>, "aria-relevant" | "dangerouslySetInnerHTML">;
    formId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Form = <FormValues extends FieldValues = FieldValues>(hooksFormProps: FormProps<FormValues>) => {
    const { onSubmit, children, isFullWidth = false, formId, formProps, ...formOptions } = hooksFormProps;
    const { id: formPropsId, ...restFormProps } = formProps ?? {};
    const methods = useForm<FormValues>(formOptions);
    const styles = isFullWidth ? { width: "100%" } : {};
    return (
        <form style={styles} {...restFormProps} onSubmit={methods.handleSubmit(onSubmit)} id={formId || formPropsId}>
            {children(methods)}
        </form>
    );
};

export interface FormInputProps extends ChakraInputProps, FormControlWrapperProps {
    inputGroupProps?: Partial<InputGroupProps>;
    name: string;
    showPassword?: boolean;
    isV2?: boolean;
    register: ReturnType<typeof useForm>["register"];
    registerProps?: RegisterOptions;
    inputLeftElement?: React.ReactElement;
    inputRightElement?: React.ReactElement;
    spacingY?: string;
    description?: string;
    isFloatingLabel?: boolean;
}

export const Input: React.FC<FormInputProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<FormInputProps>(props);
    const {
        name,
        showPassword = false,
        type,
        borderColor = "gray.200",
        registerProps = {},
        register,
        description,
        inputLeftElement,
        inputRightElement,
        inputGroupProps,
        ...otherProps
    } = restProps;

    const { formControlProps, tooltipText, required, ...restFormControlWrapperProps } = formControlWrapperProps;
    //#region ShowPassword as Text
    const [showPasswordAsText, setShowPasswordAsText] = React.useState<boolean>(false);
    const [canShowPassword] = React.useState<boolean>(type === "password" && showPassword);
    const toggleShowPasswordAsText = () => setShowPasswordAsText(!showPasswordAsText);
    //#endregion

    return (
        <FormControlWrapper
            formControlProps={{ id: name, ...formControlProps }}
            tooltipText={description ?? tooltipText}
            required={required}
            {...restFormControlWrapperProps}
        >
            <InputGroup {...inputGroupProps}>
                {inputLeftElement}
                <ChakraInput
                    ref={register({ required, ...registerProps })}
                    borderColor={borderColor}
                    name={name}
                    errorBorderColor="red.300"
                    {...otherProps}
                    type={canShowPassword ? (showPasswordAsText ? "text" : "password") : type}
                />
                {canShowPassword ? (
                    <InputRightElement pr="1" height="full">
                        <Button h="1.3rem" size="sm" onClick={toggleShowPasswordAsText}>
                            {showPasswordAsText ? <ViewIcon fontSize="1rem" /> : <ViewOffIcon fontSize="1rem" />}
                        </Button>
                    </InputRightElement>
                ) : (
                    inputRightElement
                )}
            </InputGroup>
        </FormControlWrapper>
    );
};

export const InputV2: React.FC<FormInputProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<FormInputProps>(props);
    const {
        name,
        showPassword = false,
        type,
        isV2 = false,
        registerProps = {},
        register,
        inputGroupProps = {},
        inputLeftElement,
        inputRightElement,
        ...otherProps
    } = restProps;

    //#region ShowPassword as Text
    const [showPasswordAsText, setShowPasswordAsText] = React.useState<boolean>(false);
    const [canShowPassword] = React.useState<boolean>(type === "password" && showPassword);
    const toggleShowPasswordAsText = () => setShowPasswordAsText(!showPasswordAsText);

    //#endregion
    const {
        formControlProps,
        // tooltipText,
        // helpText,
        required,
        ...restFormControlWrapperProps
    } = formControlWrapperProps;

    return (
        <FormControlWrapper
            formControlProps={{ id: name, ...formControlProps }}
            // tooltipText={tooltipText ?? helpText}
            // helpText={tooltipText ? helpText : undefined}
            required={required}
            {...restFormControlWrapperProps}
        >
            <InputGroup {...inputGroupProps}>
                {inputLeftElement}
                <ChakraInput
                    ref={register({ required, ...registerProps })}
                    bgColor={"white"}
                    borderRadius={"6px"}
                    borderColor="gray.200"
                    name={name}
                    size={isV2 ? "sm" : "md"}
                    errorBorderColor="red.300"
                    {...otherProps}
                    type={canShowPassword ? (showPasswordAsText ? "text" : "password") : type}
                />
                {canShowPassword ? (
                    <InputRightElement>
                        <Button h="1.75rem" size="sm" onClick={toggleShowPasswordAsText}>
                            {showPasswordAsText ? <ViewIcon fontSize="1.2rem" /> : <ViewOffIcon fontSize="1.2rem" />}
                        </Button>
                    </InputRightElement>
                ) : (
                    inputRightElement
                )}
            </InputGroup>
        </FormControlWrapper>
    );
};

export interface FormDateInputProps extends ChakraInputProps, FormControlWrapperProps {
    name: string;
    register: ReturnType<typeof useForm>["register"];
    isV2?: boolean;
}

export const FormDateInput: React.FC<FormDateInputProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<FormDateInputProps>(props);
    const { name, register, isV2, ...otherProps } = restProps;

    const {
        formControlProps,
        // tooltipText,
        // helpText,
        required,
        error,
        ...restFormControlWrapperProps
    } = formControlWrapperProps;

    return (
        <FormControlWrapper
            // helpText={tooltipText ? helpText : undefined}
            // tooltipText={tooltipText ?? helpText}
            formControlProps={{ id: name, ...formControlProps }}
            required={required}
            error={error}
            {...restFormControlWrapperProps}
        >
            <ChakraInput
                isInvalid={Boolean(error)}
                errorBorderColor="red.300"
                ref={register({ required })}
                name={name}
                size={isV2 ? "sm" : "md"}
                {...otherProps}
                type="date"
            />
        </FormControlWrapper>
    );
};

interface FormFloatInputProps
    extends Omit<FloatInputProps, "formControlProps" | "name">,
        Omit<FormInputProps, "label" | "labelProps" | "hidelabel" | "isV2" | "description" | "isFloatingLabel"> {}

export const FormFloatInput: React.FC<FormFloatInputProps> = ({
    name,
    label,
    helpText,
    showPassword = false,
    type,
    error,
    borderColor = "gray.200",
    required = false,
    registerProps = {},
    register,
    spacingY,
    inputLeftElement,
    inputRightElement,
    placeholder,
    formControlProps = {},
    inputGroupProps = {},
    errorMessageProps = {},
    ...otherProps
}) => {
    //#region ShowPassword as Text
    const [showPasswordAsText, setShowPasswordAsText] = React.useState<boolean>(false);
    const [canShowPassword] = React.useState<boolean>(type === "password" && showPassword);
    const toggleShowPasswordAsText = () => setShowPasswordAsText(!showPasswordAsText);
    //#endregion

    return (
        <FormControl
            isInvalid={Boolean(error)}
            id={name}
            py={spacingY ?? "15px"}
            isRequired={Boolean(required)}
            {...formControlProps}
        >
            <InputGroup {...inputGroupProps}>
                {inputLeftElement}
                <FloatInput
                    label={label}
                    formControlProps={{
                        isRequired: Boolean(required),
                    }}
                    ref={register({ required, ...registerProps })}
                    isInvalid={error != null}
                    errorBorderColor="red.300"
                    borderColor={borderColor}
                    name={name}
                    {...otherProps}
                    _autofill={{ boxShadow: "0 0 0 50px white inset" }}
                    type={canShowPassword ? (showPasswordAsText ? "text" : "password") : type}
                />
                {canShowPassword ? (
                    <InputRightElement>
                        <Button h="1.75rem" size="sm" onClick={toggleShowPasswordAsText}>
                            {showPasswordAsText ? <ViewIcon fontSize="1.2rem" /> : <ViewOffIcon fontSize="1.2rem" />}
                        </Button>
                    </InputRightElement>
                ) : (
                    inputRightElement
                )}
            </InputGroup>
            {helpText ? <FormHelperText mt={1}>{helpText}</FormHelperText> : null}
            {error ? <FormErrorMessage {...errorMessageProps}>{error}</FormErrorMessage> : null}
        </FormControl>
    );
};

export interface FormTextareaProps extends ChakraTextareaProps, FormControlWrapperProps {
    name: string;
    register: ReturnType<typeof useForm>["register"];
    registerProps?: RegisterOptions;
}

export const Textarea: React.FC<FormTextareaProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<FormTextareaProps>(props);

    const { name, borderColor = "gray.200", registerProps = {}, register, ...otherProps } = restProps;
    const { formControlProps, required, ...restFormControlWrapperProps } = formControlWrapperProps;
    return (
        <FormControlWrapper
            formControlProps={{ id: name, ...formControlProps }}
            required={required}
            {...restFormControlWrapperProps}
        >
            <ChakraTextarea
                ref={register({ required, ...registerProps })}
                borderColor={borderColor}
                name={name}
                errorBorderColor="red.100"
                {...otherProps}
            />
        </FormControlWrapper>
    );
};

//Text areaV2
export const TextareaV2: React.FC<FormTextareaProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<FormTextareaProps>(props);

    const { name, borderColor = "gray.200", registerProps = {}, register, ...otherProps } = restProps;
    const {
        formControlProps,
        // tooltipText,
        // helpText,
        required,
        ...restFormControlWrapperProps
    } = formControlWrapperProps;
    return (
        <FormControlWrapper
            formControlProps={{ id: name, ...formControlProps }}
            // tooltipText={tooltipText ?? helpText}
            // helpText={tooltipText ? helpText : undefined}
            required={required}
            {...restFormControlWrapperProps}
        >
            <ChakraTextarea
                ref={register({ required, ...registerProps })}
                borderColor={borderColor}
                name={name}
                {...otherProps}
            />
        </FormControlWrapper>
    );
};

interface FormCheckboxProps
    extends ChakraCheckboxProps,
        Omit<
            FormControlWrapperProps,
            | "label"
            | "labelProps"
            | "labelContainerProps"
            | "isOptional"
            | "labelFieldOrientation"
            | "tooltipText"
            | "toolTipProps"
        > {
    label?: string;
    name: string;
    required?: boolean;
    control: ReturnType<typeof useForm>["control"];
}

export const Checkbox: React.FC<FormCheckboxProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<FormCheckboxProps>(props);

    const { name, control, children, onChange: propOnChange, ...otherProps } = restProps;

    const { formControlProps, required, label, ...restFormControlWrapperProps } = {
        ...formControlWrapperProps,
        hidelabel: true,
    };

    return (
        <FormControlWrapper
            formControlProps={{ id: name, ...formControlProps }}
            required={required}
            {...restFormControlWrapperProps}
        >
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <Controller
                control={control}
                name={name}
                rules={{ required }}
                defaultValue={otherProps.defaultChecked}
                render={({ onChange, value, ...otherControllerProps }) => {
                    return (
                        <ChakraCheckbox
                            onChange={(e) => {
                                onChange(e.target.checked);
                                propOnChange?.(e);
                            }}
                            isChecked={value}
                            {...otherControllerProps}
                            {...otherProps}
                        >
                            {label ?? children}
                        </ChakraCheckbox>
                    );
                }}
            />
        </FormControlWrapper>
    );
};

interface FormSwitchProps extends ChakraSwitchProps, FormControlWrapperProps {
    name: string;
    control: ReturnType<typeof useForm>["control"];
    inline?: boolean;
    isV2?: boolean;
    [key: string]: unknown;
}

export const Switch: React.FC<FormSwitchProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<FormSwitchProps>(props);

    const { name, control, inline = false, onChange: onC, defaultValue, ...otherProps } = restProps as FormSwitchProps;
    const {
        formControlProps,
        // tooltipText,
        // helpText,
        required,
        label,
        labelFieldOrientation,
        ...restFormControlWrapperProps
    } = formControlWrapperProps;
    return (
        <FormControlWrapper
            labelFieldOrientation={inline ? "horizontal" : labelFieldOrientation}
            formControlProps={{
                id: name,
                ...formControlProps,
            }}
            // helpText={tooltipText ? helpText : undefined}
            // tooltipText={tooltipText ?? helpText}
            required={required}
            label={label}
            {...restFormControlWrapperProps}
        >
            <Controller
                defaultValue={defaultValue}
                render={({ onChange, value, ...restProps }) => (
                    <ChakraSwitch
                        onChange={(e) => {
                            onC?.(e);
                            onChange(e.target.checked);
                        }}
                        isChecked={value}
                        {...restProps}
                        {...otherProps}
                    />
                )}
                name={name}
                control={control}
                rules={{
                    validate: (data) => {
                        if (!required) return true;
                        return data != null ? true : `${label} is required`;
                    },
                }}
            />
        </FormControlWrapper>
    );
};

interface Option {
    label: React.ReactNode;
    value: string | number;
}

interface SelectProps extends ChakraSelectProps, FormControlWrapperProps {
    name: string;
    options: Option[];
    register: ReturnType<typeof useForm>["register"];
    registerProps?: RegisterOptions;
}

export const Select: React.FC<SelectProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<SelectProps>(props);

    const { options = [], name, register, registerProps = {}, borderColor = "gray.200", ...otherProps } = restProps;

    const { formControlProps, required, ...restFormControlWrapperProps } = formControlWrapperProps;
    return (
        <FormControlWrapper
            required={required}
            formControlProps={{ id: name, ...formControlProps }}
            {...restFormControlWrapperProps}
        >
            <ChakraSelect
                ref={register({ required, ...registerProps })}
                borderColor={borderColor}
                name={name}
                {...otherProps}
            >
                {options.map(({ label, value }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </ChakraSelect>
        </FormControlWrapper>
    );
};

export interface ReactSelectProps<isMulti extends boolean>
    extends ReactSelectInputProps<OptionTypeBase, isMulti>,
        FormControlWrapperProps {
    name: string;
    mb?: number | string;
    control: ReturnType<typeof useForm>["control"];
    "data-cy"?: string;
    [key: string]: unknown;
    isV2?: boolean;
}

export const ReactSelect = <isMulti extends boolean = false>(props: ReactSelectProps<isMulti>) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<ReactSelectProps<isMulti>>(props);
    const {
        name,
        control,
        mb,
        styles,
        isV2 = false,
        defaultValue,
        ...otherProps
    } = restProps as ReactSelectProps<isMulti>;

    const {
        formControlProps,
        // tooltipText,
        // helpText,
        required,
        error,
        ...restFormControlWrapperProps
    } = formControlWrapperProps;

    const selectI = React.useRef<ReactSelectInput<OptionTypeBase, isMulti>>(null);

    const overriddenStyles: StylesConfig<OptionTypeBase, isMulti> = React.useMemo(() => {
        return {
            control: (baseStyles, restProps) => ({
                ...baseStyles,
                minHeight: isV2 ? "32px" : "36px",
                borderColor: error ? "#E53E3E" : "gray.200",
                boxShadow: error ? "0 0 0 1px #e53e3e" : baseStyles.boxShadow,
                "&:hover": {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    borderColor: error ? "#E53E3E" : (baseStyles as any)["&:hover"].borderColor,
                },
                ...(styles?.control?.(baseStyles, restProps) ?? {}),
            }),
            ...(styles ?? {}),
        };
    }, [styles, isV2, error]);

    return (
        <FormControlWrapper
            // helpText={tooltipText ? helpText : undefined}
            // tooltipText={tooltipText ?? helpText}
            formControlProps={{ id: name, mb: mb ?? 5, ...formControlProps }}
            required={required}
            error={error}
            {...restFormControlWrapperProps}
        >
            <Controller
                defaultValue={defaultValue}
                render={(controllerProps) => (
                    <ReactSelectInput
                        {...otherProps}
                        {...controllerProps}
                        onChange={(selected, action) => {
                            controllerProps.onChange(selected);
                            otherProps?.onChange?.(selected, action);
                        }}
                        styles={overriddenStyles}
                        ref={selectI}
                        data-cy={otherProps["data-cy"] ?? "default-react-select-component"}
                    />
                )}
                name={name}
                control={control}
                rules={{ required }}
                onFocus={() => selectI.current?.focus()}
            />
        </FormControlWrapper>
    );
};

export interface BooleanReactSelectProps
    extends Omit<ReactSelectStyledProps<LabelValue<boolean>>, "onChange" | "value" | "options">,
        FormControlWrapperProps {
    name: string;
    mb?: number | string;
    control: ReturnType<typeof useForm>["control"];
    "data-cy"?: string;
    [key: string]: unknown;
    isV2?: boolean;
    onChange?: (value: boolean) => void;
}

export const BooleanReactSelect: React.FC<BooleanReactSelectProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<BooleanReactSelectProps>(props);
    const { name, mb, isV2 = false, defaultValue, control, ...otherProps } = restProps as BooleanReactSelectProps;

    const {
        formControlProps,
        // tooltipText,
        // helpText,
        required,
        error,
        label,
        ...restFormControlWrapperProps
    } = formControlWrapperProps;

    const selectI = React.useRef<ReactSelectInput<OptionTypeBase>>(null);

    return (
        <FormControlWrapper
            // helpText={tooltipText ? helpText : undefined}
            // tooltipText={tooltipText ?? helpText}
            formControlProps={{ id: name, mb: mb ?? 5, ...formControlProps }}
            required={required}
            error={error}
            label={label}
            {...restFormControlWrapperProps}
        >
            <Controller
                defaultValue={defaultValue}
                render={(props) => {
                    return (
                        <ReactSelectStyled
                            isClearable
                            {...otherProps}
                            options={booleanOptions}
                            value={getBooleanValue(props.value)}
                            onChange={(selected) => {
                                const selectedOption = selected as LabelValue<boolean>;
                                props.onChange(selectedOption?.value);
                                otherProps?.onChange?.(selectedOption?.value);
                            }}
                            ref={selectI}
                            data-cy={otherProps["data-cy"] ?? "default-react-select-component"}
                            key={JSON.stringify(props.value)}
                        />
                    );
                }}
                name={name}
                control={control}
                rules={{
                    validate: (data) => {
                        if (!required) return true;
                        return data != null ? true : typeof required === "string" ? required : `${label} is required`;
                    },
                }}
                onFocus={() => selectI.current?.focus()}
            />
        </FormControlWrapper>
    );
};

interface ReactAsyncSelectProps extends ReactAsyncSelectInputProps<OptionTypeBase, false>, FormControlWrapperProps {
    name: string;
    control: ReturnType<typeof useForm>["control"];
    onSelectionChange?: (value: LabelValue) => void;
}

export const ReactAsyncSelect: React.FC<ReactAsyncSelectProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<ReactAsyncSelectProps>(props);

    const { name, control, styles, onSelectionChange, ...otherProps } = restProps as ReactAsyncSelectProps;

    const { formControlProps, required, error, ...restFormControlWrapperProps } = formControlWrapperProps;

    const selectI = React.useRef<AsyncSelect<OptionTypeBase, false>>(null);

    const overriddenStyles: StylesConfig<OptionTypeBase, false> = React.useMemo(() => {
        return {
            ...(styles ?? {}),
            control: (baseStyles, restProps) => ({
                ...baseStyles,
                borderColor: error ? "#E53E3E" : baseStyles.borderColor,
                boxShadow: error ? "0 0 0 1px #e53e3e" : baseStyles.boxShadow,
                "&:hover": {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    borderColor: error ? "#E53E3E" : (baseStyles as any)["&:hover"].borderColor,
                },
                ...(styles?.control?.(baseStyles, restProps) ?? {}),
            }),
        };
    }, [styles, error]);

    return (
        <FormControlWrapper
            formControlProps={{ id: name, ...formControlProps }}
            required={required}
            error={error}
            {...restFormControlWrapperProps}
        >
            <Controller
                render={(props) => (
                    <AsyncSelect
                        {...otherProps}
                        {...props}
                        onChange={(value) => {
                            props.onChange(value);
                            onSelectionChange?.(value as unknown as LabelValue);
                        }}
                        styles={overriddenStyles}
                        ref={selectI}
                    />
                )}
                name={name}
                control={control}
                rules={{ required }}
                onFocus={() => selectI.current?.focus()}
            />
        </FormControlWrapper>
    );
};

interface RadioGroupProps extends ChakraRadioGroupProps, FormControlWrapperProps {
    name: string;
    label?: string;
    helpText?: string;
    required?: boolean;
    control: ReturnType<typeof useForm>["control"];
    inline?: boolean;
    error?: string;
    labelProps?: Partial<FormLabelProps>;
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<RadioGroupProps>(props);

    const { name, control, inline, onChange: onSelectionChange, ...otherProps } = restProps;

    const { formControlProps, required, error, label, ...restFormControlWrapperProps } = formControlWrapperProps;

    return (
        <FormControlWrapper
            formControlProps={{ id: name, w: inline ? "fit-content" : "full", ...formControlProps }}
            required={required}
            error={error}
            label={label}
            {...restFormControlWrapperProps}
        >
            <Controller
                render={({ onChange, ...props }) => (
                    <ChakraRadioGroup
                        aria-label={isJSXElement(label) ? "radio-group" : label}
                        onChange={callFnsInSequence(onChange, onSelectionChange)}
                        {...otherProps}
                        {...props}
                        name={name}
                    >
                        {otherProps.children}
                    </ChakraRadioGroup>
                )}
                name={name}
                control={control}
                rules={{ required }}
            />
        </FormControlWrapper>
    );
};

export interface ControlledElementProps extends FormControlWrapperProps {
    name: string;
    isV2?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element: React.FC<any>;
    control: ReturnType<typeof useForm>["control"];
    [key: string]: unknown;
    rules?: any;
}

export const ControlledElement: React.FC<ControlledElementProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<ControlledElementProps>(props);

    const { name, element: Element, control, isV2 = false, rules, ...otherProps } = restProps as ControlledElementProps;

    const { formControlProps, ...restFormControlWrapperProps } = formControlWrapperProps;

    return (
        <FormControlWrapper
            // helpText={tooltipText ? helpText : undefined}
            // tooltipText={tooltipText ?? helpText}
            formControlProps={{ id: name, ...formControlProps }}
            {...restFormControlWrapperProps}
        >
            <Controller
                name={name}
                rules={rules}
                render={(props) => <Element {...otherProps} {...props} />}
                control={control}
            />
        </FormControlWrapper>
    );
};

interface FormInputArrayProps extends FormInputProps {
    errors?:
        | (
              | DeepMap<
                    {
                        value: string;
                    },
                    FieldError
                >
              | undefined
          )[]
        | undefined;
    control: ReturnType<typeof useForm>["control"];
    setValue?: ReturnType<typeof useForm>["setValue"];
    inputType?: "text" | "phone";
    minLength?: number;
}

export const InputArray: React.FC<FormInputArrayProps> = ({
    name,
    register,
    required = false,
    control,
    errors,
    label,
    hidelabel = false,
    inputType = "text",
    minLength,
    setValue,
    error,
    ...otherProps
}) => {
    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name, // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    });
    const { isDisabled } = otherProps;
    return (
        <Flex direction="column" alignItems="flex-end" w="100%">
            {(fields as ReturnType<typeof useFieldArray>["fields"]).map((field, index) => {
                return (
                    <Flex key={field.id} width="100%" alignItems="flex-end">
                        {inputType === "phone" ? (
                            <Controller
                                key={field.id}
                                label={index === 0 && label ? label : " "}
                                name={`${name}[${index}].value`}
                                control={control}
                                defaultValue={field.value}
                                autoComplete="off"
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                render={({ onChange, ...props }) => (
                                    <PhoneNumberFormInput
                                        international
                                        countryCallingCodeEditable={false}
                                        defaultCountry="IN"
                                        label={index === 0 && typeof label === "string" ? label : " "}
                                        onChange={(v: string) => {
                                            setValue?.(`${name}[${index}].value`, v, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }}
                                        data-cy={`create-contact-${inputType}-${index}`}
                                        {...props}
                                    />
                                )}
                            />
                        ) : (
                            <Input
                                label={index === 0 && label ? label : " "}
                                name={`${name}[${index}].value`}
                                register={register}
                                error={errors?.[index]?.value?.message}
                                isInvalid={errors?.[index]?.value?.message != null}
                                errorBorderColor="red.300"
                                defaultValue={field.value}
                                autoComplete="off"
                                registerProps={{ required, min: required ? 1 : undefined }}
                                data-cy={`create-contact-${inputType}-${index}`}
                                {...otherProps}
                            />
                        )}
                        <IconButton
                            aria-label="Remove"
                            fontSize="20px"
                            border="none"
                            height="40px"
                            ml="2"
                            variant="link"
                            isDisabled={minLength ? (index < minLength ? true : false) : isDisabled}
                            icon={<HiOutlineX />}
                            onClick={() => remove(index)}
                            data-cy="create-contact-remove-input-button"
                        />
                    </Flex>
                );
            })}
            <Button
                colorScheme="brand"
                variant="link"
                my="1"
                fontWeight="normal"
                isDisabled={isDisabled}
                onClick={() => append({ value: "" })}
                data-cy="create-contact-add-input-button"
            >
                {`Add ${label}`}
            </Button>
            <Text w="full" fontSize="sm" color="red.500">
                {error}
            </Text>
        </Flex>
    );
};

export const InputArrayV2: React.FC<FormInputArrayProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<FormInputArrayProps>(props);

    const {
        name,
        register,
        minLength,
        errors,
        inputType = "text",
        setValue,
        control,
        ...otherProps
    } = restProps as FormInputArrayProps;

    const {
        formControlProps,
        label,
        // tooltipText,
        // helpText,
        required,
        ...restFormControlWrapperProps
    } = formControlWrapperProps;

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name, // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    });
    const { isDisabled } = otherProps;
    return (
        <FormControlWrapper
            formControlProps={{ id: name, ...formControlProps }}
            label={label ?? startCase(name)}
            // tooltipText={tooltipText ?? helpText}
            required={required}
            {...restFormControlWrapperProps}
            labelExtraComponent={
                fields ? (
                    <>
                        <Spacer />
                        <IconButton
                            aria-label="Add"
                            variant="ghost"
                            my="1"
                            isRound
                            size="xs"
                            fontWeight="normal"
                            icon={<HiPlus size={16} />}
                            isDisabled={isDisabled}
                            onClick={() => append({ value: "" })}
                            data-cy="create-contact-add-input-button"
                        />
                    </>
                ) : null
            }
        >
            <VStack spacing={2} w="full">
                {(fields as ReturnType<typeof useFieldArray>["fields"]).map((field, index) => {
                    return (
                        <HStack key={field.id} width="full" justify="space-between">
                            {inputType === "phone" ? (
                                <Controller
                                    key={field.id}
                                    label={index === 0 && label ? label : " "}
                                    name={`${name}[${index}].value`}
                                    control={control}
                                    defaultValue={field.value}
                                    autoComplete="off"
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    render={({ onChange, ...props }) => (
                                        <PhoneNumberFormInput
                                            international
                                            countryCallingCodeEditable={false}
                                            defaultCountry="IN"
                                            label={index === 0 && typeof label === "string" ? label : " "}
                                            onChange={(v: string) => {
                                                setValue?.(`${name}[${index}].value`, v, {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                });
                                            }}
                                            data-cy={`create-contact-${inputType}-${index}`}
                                            {...props}
                                        />
                                    )}
                                />
                            ) : (
                                <InputV2
                                    // label={hidelabel ? " " : index === 0 && label ? label : " "}
                                    name={`${name}[${index}].value`}
                                    register={register}
                                    width="100%"
                                    error={errors?.[index]?.value?.message}
                                    isInvalid={errors?.[index]?.value?.message != null}
                                    errorBorderColor="red.300"
                                    defaultValue={field.value}
                                    autoComplete="off"
                                    registerProps={{ required, min: required ? 1 : undefined }}
                                    data-cy={`create-contact-${inputType}-${index}`}
                                    {...otherProps}
                                />
                            )}
                            <Box>
                                <IconButton
                                    aria-label="Remove"
                                    height="25px"
                                    isRound
                                    size="xs"
                                    variant="ghost"
                                    isDisabled={minLength ? (index < minLength ? true : false) : isDisabled}
                                    icon={<RiDeleteBinLine size={16} />}
                                    onClick={() => remove(index)}
                                    data-cy="create-contact-remove-input-button"
                                />
                            </Box>
                        </HStack>
                    );
                })}
            </VStack>
        </FormControlWrapper>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SortArray: React.FC<FormInputArrayProps> = ({ name, register, control, errors, label, ...otherProps }) => {
    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name, // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    });

    return (
        <Flex direction="column" alignItems="flex-start">
            {(fields as ReturnType<typeof useFieldArray>["fields"]).map((field, index) => {
                return (
                    <HStack key={field.id} width="100%" alignItems="center" my={2}>
                        <SortArrayComp index={index} register={register} field={field} name={name} />
                        <IconButton
                            aria-label="Remove"
                            fontSize="20px"
                            border="none"
                            height="40px"
                            variant="link"
                            icon={<HiOutlineX />}
                            onClick={() => remove(index)}
                        />
                    </HStack>
                );
            })}
            <Button
                colorScheme="brand.500"
                variant="link"
                my="1"
                fontWeight="normal"
                onClick={() => append({})}
                leftIcon={<HiOutlinePlus />}
            >
                {`Add ${name}`}
            </Button>
        </Flex>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SortArrayComp: React.FC<any> = ({ index, register, field, name }) => {
    const SortOptions = [
        { label: "Created Date", value: "createdAt" },
        { label: "Recent Edit", value: "updatedAt" },
        { label: "Title", value: "title" },
    ];
    const SortValue = [
        { label: "Newest", value: "-" },
        { label: "Oldest", value: "" },
    ];
    const SortValueTitle = [
        { label: "A-Z", value: "" },
        { label: "Z-A", value: "-" },
    ];
    const [options, setOptions] = React.useState(field.sortColumn);
    return (
        <>
            <Select
                options={SortOptions}
                name={`${name}[${index}].sortColumn`}
                register={register}
                defaultValue={field.sortColumn}
                onChange={(e) => setOptions(e.target.value)}
            />

            <Select
                options={options === "title" ? SortValueTitle : SortValue}
                name={`${name}[${index}].sortOrder`}
                register={register}
                defaultValue={field.sortOrder}
            />
        </>
    );
};

interface EditableProps extends ChakraEditableProps {
    name?: string;
    previewStyleProps?: SystemStyleObject;
    inputStyleProps?: SystemStyleObject;
}

export const Editable: React.FC<EditableProps> = ({
    defaultValue,
    value,
    onChange,
    previewStyleProps,
    inputStyleProps,
    ...props
}) => {
    const [controlledValue, setControlledValue] = React.useState<string>(value ?? defaultValue ?? "");

    React.useEffect(() => {
        setControlledValue(value ?? defaultValue ?? "");
    }, [value, defaultValue]);

    return (
        <ChakraEditable value={controlledValue} onChange={setControlledValue} onSubmit={onChange} {...props}>
            <EditablePreview sx={previewStyleProps} />
            <EditableInput sx={inputStyleProps} />
        </ChakraEditable>
    );
};

export const StyledInput = React.forwardRef<HTMLInputElement, ChakraInputProps>(function StyledInput(
    { ...props },
    ref
) {
    return (
        <ChakraInput
            ref={ref}
            sx={{ backgroundClip: "padding-box", borderColor: "rgba(0,0,0,0)" }}
            _focus={{
                borderColor: "rgba(0,0,0,0.2)",
            }}
            px={1}
            {...props}
        />
    );
});

export interface ReactSelectV2Props<T extends OptionTypeBase, isMulti extends boolean>
    extends ReactSelectStyledProps<T, isMulti>,
        FormControlWrapperProps {
    name: string;
    mb?: string | number;
    control: ReturnType<typeof useForm>["control"];
    [key: string]: unknown;
}

export const ReactSelectV2 = <T extends OptionTypeBase = OptionTypeBase, isMulti extends boolean = false>(
    props: ReactSelectV2Props<T, isMulti>
): JSX.Element => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<ReactSelectV2Props<T, isMulti>>(props);

    const { name, control, mb, defaultValue, ...otherProps } = restProps as ReactSelectV2Props<T, isMulti>;

    const selectI = React.useRef<ReactSelectRefType<T, isMulti>>(null);

    const {
        formControlProps,
        // tooltipText,
        // helpText,
        required,
        label,
        hidelabel,
        error,
        ...restFormControlWrapperProps
    } = formControlWrapperProps;

    return (
        <FormControlWrapper
            // helpText={tooltipText ? helpText : undefined}
            // tooltipText={tooltipText ?? helpText}
            formControlProps={{ id: name, mb: mb ?? 5, ...formControlProps }}
            label={label}
            required={required}
            error={error}
            {...restFormControlWrapperProps}
            hidelabel={otherProps?.isFloatingLabel || hidelabel}
        >
            <Controller
                defaultValue={defaultValue}
                render={(props) => (
                    <ReactSelectStyled<T, isMulti>
                        menuPlacement="auto"
                        {...otherProps}
                        {...props}
                        isError={Boolean(error)}
                        placeholder={otherProps?.isFloatingLabel ? label : otherProps.placeholder}
                        value={props.value == null ? null : props.value}
                        onChange={(...args) => {
                            props.onChange(args[0]);
                            otherProps?.onChange?.(...args);
                        }}
                        ref={selectI}
                        key={JSON.stringify(props.value)}
                    />
                )}
                name={name}
                control={control}
                rules={{ required }}
                onFocus={() => selectI.current?.focus()}
            />
        </FormControlWrapper>
    );
};
export interface CreatableReactSelectV2Props<T extends OptionTypeBase, isMulti extends boolean>
    extends CreatableReactSelectStyledProps<T, isMulti>,
        FormControlWrapperProps {
    name: string;
    mb?: string | number;
    control: ReturnType<typeof useForm>["control"];
    [key: string]: unknown;
}

export const CreatableReactSelectV2 = <T extends OptionTypeBase = OptionTypeBase, isMulti extends boolean = false>(
    props: CreatableReactSelectV2Props<T, isMulti>
): JSX.Element => {
    const { formControlWrapperProps, restProps } =
        spiltFormControlWrapperProps<CreatableReactSelectV2Props<T, isMulti>>(props);

    const { name, control, mb, defaultValue, ...otherProps } = restProps as CreatableReactSelectV2Props<T, isMulti>;

    const {
        formControlProps,
        // tooltipText,
        // helpText,
        required,
        label,
        hidelabel,
        error,
        ...restFormControlWrapperProps
    } = formControlWrapperProps;

    const selectI = React.useRef<CreatableSelectRefType<T, isMulti>>(null);

    return (
        <FormControlWrapper
            // helpText={tooltipText ? helpText : undefined}
            // tooltipText={tooltipText ?? helpText}
            formControlProps={{ id: name, mb: mb ?? 5, ...formControlProps }}
            label={label}
            required={required}
            error={error}
            {...restFormControlWrapperProps}
            hidelabel={otherProps?.isFloatingLabel || hidelabel}
        >
            <Controller
                defaultValue={defaultValue}
                render={(props) => (
                    <CreatableReactSelectStyled
                        menuPlacement="auto"
                        {...otherProps}
                        {...props}
                        isError={Boolean(error)}
                        placeholder={otherProps?.isFloatingLabel ? label : otherProps.placeholder}
                        value={props.value == null ? null : props.value}
                        onChange={(...args) => {
                            props.onChange(args[0]);
                            otherProps?.onChange?.(...args);
                        }}
                        ref={selectI}
                        key={JSON.stringify(props.value)}
                    />
                )}
                name={name}
                control={control}
                rules={{ required }}
                onFocus={() => selectI.current?.focus()}
            />
        </FormControlWrapper>
    );
};

export interface ReactAsyncSelectV2Props<T extends LabelValue>
    extends AsyncSelectStyledProps<T>,
        FormControlWrapperProps {
    name: string;

    control: ReturnType<typeof useForm>["control"];
}

export const ReactAsyncSelectV2 = <T extends LabelValue = LabelValue>(
    props: ReactAsyncSelectV2Props<T>
): JSX.Element => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<ReactAsyncSelectV2Props<T>>(props);
    const {
        formControlProps,
        // tooltipText,
        // helpText,
        required,
        label,
        hidelabel,
        error,
        ...restFormControlWrapperProps
    } = formControlWrapperProps;

    const { name, control, ...otherProps } = restProps as ReactAsyncSelectV2Props<T>;
    const selectI = React.useRef<ReactAsyncSelectRefType<T>>(null);

    return (
        <FormControlWrapper
            // helpText={tooltipText ? helpText : undefined}
            // tooltipText={tooltipText ?? helpText}
            formControlProps={{ id: name, ...formControlProps }}
            label={label}
            required={required}
            error={error}
            {...restFormControlWrapperProps}
            hidelabel={otherProps?.isFloatingLabel || hidelabel}
        >
            <Controller
                render={(props) => (
                    <AsyncSelectStyled
                        menuPlacement="auto"
                        {...otherProps}
                        {...props}
                        isError={Boolean(error)}
                        placeholder={otherProps?.isFloatingLabel ? label : otherProps.placeholder}
                        value={props.value == null ? null : props.value}
                        onChange={(value, action) => {
                            props.onChange(value);
                            otherProps?.onChange?.(value, action);
                        }}
                        ref={selectI}
                        key={JSON.stringify(props.value)}
                    />
                )}
                name={name}
                control={control}
                rules={{ required }}
                onFocus={() => selectI.current?.focus()}
            />
        </FormControlWrapper>
    );
};

export interface LocationInputProps extends Omit<FormInputProps, "name"> {
    latitudeName: string;
    longitudeName: string;
    size?: NonNullable<FormInputProps["inputGroupProps"]>["size"];
}

export const LocationInput: React.FC<LocationInputProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<LocationInputProps>(props);

    const { latitudeName, longitudeName, size, inputGroupProps = {}, ...inputProps } = restProps;
    return (
        <FormControlWrapper {...formControlWrapperProps}>
            <HStack>
                <Input
                    {...inputProps}
                    name={latitudeName}
                    hidelabel
                    inputLeftElement={<InputLeftAddon rounded="md">Lat</InputLeftAddon>}
                    inputGroupProps={{ ...inputGroupProps, size }}
                    type="number"
                />
                <Input
                    {...inputProps}
                    name={longitudeName}
                    hidelabel
                    inputLeftElement={<InputLeftAddon rounded="md">Long</InputLeftAddon>}
                    inputGroupProps={{ ...inputGroupProps, size }}
                    type="number"
                />
            </HStack>
        </FormControlWrapper>
    );
};

export interface StarRatingFormProps extends Omit<StarRatingProps, "onChange" | "value">, FormControlWrapperProps {
    name: string;
    control: ReturnType<typeof useForm>["control"];
}

export const StarRatingForm: React.FC<StarRatingFormProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<StarRatingFormProps>(props);

    const { control, name, ...starRatingProps } = restProps;
    return (
        <FormControlWrapper {...formControlWrapperProps}>
            <Controller
                render={(controllerProps) => {
                    return <StarRating {...controllerProps} {...starRatingProps} />;
                }}
                name={name}
                control={control}
                rules={{ required: formControlWrapperProps?.required }}
            />
        </FormControlWrapper>
    );
};
export interface PhoneNumberInputFormProps
    extends Omit<PhoneNumberInputProps<any>, "required">,
        FormControlWrapperProps {
    name: string;
    control: ReturnType<typeof useForm>["control"];
}

export const PhoneNumberInputForm: React.FC<PhoneNumberInputFormProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<PhoneNumberInputFormProps>(props);

    const { control, name, ...phoneNumberInputProps } = restProps;
    return (
        <FormControlWrapper {...formControlWrapperProps}>
            <Controller
                render={(controllerProps) => {
                    return <PhoneNumberInput {...controllerProps} {...phoneNumberInputProps} />;
                }}
                name={name}
                control={control}
                rules={{ required: formControlWrapperProps?.required }}
            />
        </FormControlWrapper>
    );
};

export interface ContactSearchFormSelectProps extends ContactSearchSelectProps, FormControlWrapperProps {
    name: string;
    control: ReturnType<typeof useForm>["control"];
}

export const ContactSearchFormSelect: React.FC<ContactSearchFormSelectProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<ContactSearchFormSelectProps>(props);

    const { control, name, ...contactSearchSelectProps } = restProps as ContactSearchFormSelectProps;
    return (
        <FormControlWrapper {...formControlWrapperProps}>
            <Controller
                render={(controllerProps) => {
                    return (
                        <ContactSearchSelect
                            {...controllerProps}
                            {...contactSearchSelectProps}
                            key={JSON.stringify(controllerProps.value)}
                        />
                    );
                }}
                name={name}
                control={control}
                rules={{ required: formControlWrapperProps?.required }}
            />
        </FormControlWrapper>
    );
};

export interface FormFieldCommonProps {
    name: string;
    control: UseFormMethods["control"];
    register?: UseFormMethods["register"];
    required?: boolean | string;
    error?: string;
}

type CurrencyFormSelectProps = FormFieldCommonProps & CurrencySelectProps;

export const CurrencyFormSelect: React.FC<CurrencyFormSelectProps> = (props) => {
    const { name, control, required = false, error, ...reactSelectProps } = props;
    const selectI = React.useRef<ReactSelectRefType<CountryAndCurrencyType>>(null);

    return (
        <Controller
            render={(props) => (
                <CurrencySelect
                    menuPlacement="auto"
                    width="150px"
                    {...props}
                    {...reactSelectProps}
                    isError={Boolean(error)}
                    onChange={(...args) => {
                        props.onChange(args[0]);
                        reactSelectProps?.onChange?.(...args);
                    }}
                    ref={selectI}
                    key={JSON.stringify(props.value)}
                />
            )}
            name={name}
            control={control}
            rules={{ required }}
            onFocus={() => selectI.current?.focus()}
        />
    );
};

interface CurrencyFormInputProps extends Omit<CurrencyInputProps, "name">, FormFieldCommonProps {}
export const CurrencyFormInput: React.FC<CurrencyFormInputProps> = (props) => {
    const { control, required, name } = props;
    return (
        <Controller
            render={(controllerProps) => {
                return (
                    <CurrencyInput
                        bgColor="white"
                        borderRadius={"6px"}
                        borderColor="gray.200"
                        {...controllerProps}
                        {...props}
                    />
                );
            }}
            name={name}
            control={control}
            rules={{ required }}
        />
    );
};

export interface CurrencyCompProps extends FormFieldCommonProps, FormControlWrapperProps {
    currencyName: string;
    amountName: string;
    watch: UseFormMethods["watch"];
}

export const CurrencyComp: React.FC<CurrencyCompProps> = (props) => {
    const { formControlWrapperProps, restProps } = spiltFormControlWrapperProps<CurrencyCompProps>(props);

    const { currencyName, amountName, watch, ...otherProps } = restProps as CurrencyCompProps;
    const { required, error } = formControlWrapperProps;
    return (
        <FormControlWrapper {...formControlWrapperProps} error={error}>
            <HStack>
                <CurrencyFormSelect {...otherProps} name={currencyName} required={required} error={error} />
                <CurrencyFormInput
                    {...otherProps}
                    name={amountName}
                    required={required}
                    error={error}
                    locales="en-IN"
                    // currency="EUR"
                    // locales={watch(currencyName)?.locale}
                    currency={watch(currencyName)?.value}
                />
            </HStack>
        </FormControlWrapper>
    );
};
