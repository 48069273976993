import { APP_DOMAIN } from "environment";
import deliverywhatsConfig from "./deliverywhats.config";
import gallaboxConfig from "./gallabox.config";

type Domain = "gallabox" | "deliverywhats";

const domainConfigMap = new Map<Domain, typeof gallaboxConfig>([
    ["deliverywhats", deliverywhatsConfig],
    ["gallabox", gallaboxConfig],
]);

const assets = import.meta.glob<true, "url">("../assets/*.(png|PNG|svg)", { as: "url", eager: true });

const getAssetUrl = (asset: string): string | undefined => {
    if (assets[asset]) return assets[asset];
};

const config = domainConfigMap.get(APP_DOMAIN);

export default {
    ...config,
    branding: {
        ...config?.branding,
        product_logos: Object.entries(config?.branding.product_logos ?? {}).reduce(
            (acc, [k, v]) => {
                if (!v) return acc;
                return { ...acc, [k]: !v.startsWith("http") ? getAssetUrl(v) : v };
            },
            {} as Record<string, string | undefined>
        ),
    },
} as typeof gallaboxConfig;
