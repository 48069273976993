import {
    Box,
    Button,
    CloseButton,
    Grid,
    Heading,
    HStack,
    Icon,
    Image,
    InputLeftElement,
    Link as ChakraLink,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    OrderedList,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import config from "app/app.config";
import AlertDialog from "app/components/AlertDialog/AlertDialogNoTrigger";

import { StyledReactSelect } from "app/components/ChannelSelectV2";
import { IconLabelValue, IconReactSelect } from "app/components/CustomizedReactSelect/IconReactSelect";
import AnimatedLoadingState from "app/components/GBLoadingState";
import {
    useCancelTransaction,
    useListWallet,
    useRechargeWallet,
    useWalletActivation,
} from "app/fetchHooks/prepaid-wallet";
import FormControlWrapper from "app/gbComponents/Misc/FormControlWrapper";
import { useRestrictFeature } from "app/hooks/useRestrictFeature";
import { updateAccountState } from "app/modules/authed";
import { LabelValue, QueryKey, StylesObject } from "app/types/common";
import { IWallet, PendingPayment } from "app/types/prepaid-wallet";
import { Input } from "app/utils/formUtils";
import { useQueryParam } from "app/utils/history";
import { getCurrencySymbol, useAccount, useAccountId } from "app/utils/react-helpers";
import EmptyStateSvg from "assets/EmptyState/NoDataFound.svg";
import HeaderBackground from "assets/HeaderCreditsBackground.svg";
import NegativeCredit from "assets/negativeCredit.svg";
import NewInvoice from "assets/new-invoice.svg";
import RechargeSuccess from "assets/payment-success.gif";
import RazorpayIcon from "assets/Payments/razorpay.svg";
import StripeIcon from "assets/Payments/stripe.svg";
import Wallet from "assets/wallet.svg";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { HiOutlineMinusCircle } from "react-icons/hi";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { OptionsType } from "react-select";
import TransactionConversationTable from "./TransactionConversationTable";
import TransactionPaymentTable from "./TransactionPaymentTable";

const PaymentCreditsBanner = "https://assets.gallabox.com/web/prepaid.gif";

const tabStyles: StylesObject = {
    tab: {
        color: "gray.500",
        fontSize: "sm",
        borderBottom: "2px solid transparent",
        _active: { bg: "transparent" },
        _selected: {
            color: "gray.900",
            borderBottom: "2px solid",
            borderColor: "brand.500",
        },
    },
};

export const usePrepaidAlert = (): {
    negativeBalance: boolean;
    lowBalance: boolean;
    noWallet: boolean;
    lowBalanceThreshold: number;
} => {
    const account = useAccount();
    const wallet = account?.wallets?.[0];
    const currency = wallet?.currencyISO;
    const noWallet = (account?.wallets?.length ?? 0) === 0;
    const amount = (wallet?.amount ?? 0) / 1000;
    const lowBalanceThreshold = wallet?.lowBalanceThreshold ?? 0;
    const negativeBalance = noWallet ? false : amount < lowBalanceThreshold;
    const lowBalance = noWallet ? false : currency === "INR" ? (amount ?? 0) < 100 : amount < 5;
    return {
        negativeBalance,
        lowBalance,
        noWallet,
        lowBalanceThreshold,
    };
};

export const CreateWallet: React.FC = () => {
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const account = useAccount();
    const queryClient = useQueryClient();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { mutateAsync: activateMessageCredits, isLoading } = useWalletActivation({
        onSuccess(res: IWallet) {
            onClose();
            if (account) {
                dispatch(updateAccountState(accountId, { ...account, wallets: [res] }));
                const queryKey = [QueryKey.PrepaidWallet, { accountId }];
                queryClient.invalidateQueries(queryKey);
            }
        },
        onError() {
            onClose();
        },
    });
    const defaultCurrencySymbol = getCurrencySymbol(config.default_currency);
    return (
        <Box py={5} px={10}>
            <VStack w="full" h="full" alignItems="flex-start">
                <HStack w="full" justifyContent="space-between">
                    <Heading as="h1" size="lg" py={4}>
                        Message Credits
                    </Heading>
                    <Button
                        colorScheme="brand"
                        borderRadius="6px"
                        fontSize="sm"
                        fontWeight="normal"
                        isLoading={isLoading}
                        onClick={onOpen}
                    >
                        Activate Message Credits
                    </Button>
                </HStack>
                <Text fontSize="lg" fontWeight="bold">
                    What is Message Credit?
                </Text>
                <Text>
                    Message Credit is a pre-deposited amount for sending uninterrupted messages to your customers from
                    {config.product_name} Account.
                </Text>
                <Text>Now you can add message credits in few clicks.</Text>
                <Text fontSize="lg" fontWeight="bold" pt={5}>
                    Message Credits Features
                </Text>
                <OrderedList px={5} spacing={2}>
                    <ListItem>
                        <b>Get notified </b> by both in the product and by email, when your account balance is running
                        low (₹100 for INR; {defaultCurrencySymbol}5 for {config.default_currency}).
                    </ListItem>
                    <ListItem>
                        <b>Recharge </b> for a minimum of ₹100/{defaultCurrencySymbol}5 to a maximum of ₹1,00,000/
                        {defaultCurrencySymbol}2000 at once. Please note: 18% GST will be levied on INR transactions.
                    </ListItem>
                    <ListItem>
                        If you have a <b>pending payment</b> to be made, pop-ups will arise in your account to complete
                        the payment. You can also cancel pending payments and recharge with new amounts.
                    </ListItem>
                    <HStack w="full" justifyContent="center">
                        <Image src={PaymentCreditsBanner} height="500px" width="max-content" />
                    </HStack>
                    <ListItem>
                        <b>View reports</b> of WhatsApp conversation usage and export the data upto the last 6 months.
                    </ListItem>
                    <ListItem>
                        <b>Estimated amount to be spent for broadcasts</b> will be displayed while creating a new
                        broadcast, if the balance is insufficient the account will be notified.
                    </ListItem>
                </OrderedList>

                <HStack justifyContent="center" w="full">
                    <Button colorScheme="brand" borderRadius="sm" fontSize="sm" fontWeight="normal" onClick={onOpen}>
                        Activate Message Credits
                    </Button>
                </HStack>
            </VStack>
            <AlertDialog
                title="Note"
                body={
                    <Text>
                        Once you activate message credit for your account, you cannot return to post-paid billing for
                        your conversation usage.
                    </Text>
                }
                isOpen={isOpen}
                onOk={activateMessageCredits}
                onCancel={onClose}
                onClose={onClose}
                primaryButtonText="Activate"
                secondaryButtonText="Cancel"
            />
        </Box>
    );
};

const PrepaidCredit: React.FC = () => {
    const accountId = useAccountId();
    const [tabIndex, setTabIndex] = React.useState<number>(0);
    const { data, isLoading, isError } = useListWallet({ accountId });
    const { noWallet } = usePrepaidAlert();
    const walletId = data?.[0]?.id as string;
    const currency = getCurrencySymbol(data?.[0]?.currencyISO || config.default_currency);
    const negativeBalance = useMemo(() => {
        return (data?.[0]?.amount ?? 0) < 0;
    }, [data]);

    const history = useHistory();
    const params = useQueryParam();
    const selectedTabIndex = params.get("tab") ?? undefined;
    const rechargeAmount = params.get("success") ?? undefined;
    const { isOpen, onClose } = useDisclosure({
        defaultIsOpen: !!rechargeAmount,
    });
    const amount = data?.[0]?.amount ?? 0;
    const total = parseFloat(rechargeAmount as string);

    const handleTabIndexChange = React.useCallback<React.Dispatch<React.SetStateAction<number>>>(
        (index) => {
            history.push({
                pathname: history.location.pathname,
                search: `?tab=${index}`,
            });
            setTabIndex(index);
        },
        [history]
    );

    const handleClose = () => {
        history.push({
            pathname: history.location.pathname,
            search: `?tab=${tabIndex}`,
        });

        onClose();
    };

    React.useEffect(() => {
        if (selectedTabIndex == null) return;
        if (!isNaN(Number(selectedTabIndex))) {
            setTabIndex(Number(selectedTabIndex));
        }
    }, [selectedTabIndex]);

    if (noWallet) {
        return <CreateWallet />;
    }

    if (isLoading) {
        return (
            <Grid placeItems="center" bg="white" w="full" h="full" maxH="full" maxW="full">
                <AnimatedLoadingState spinnerSize={40} title="Loading..." />
            </Grid>
        );
    }

    if (isError || !data) {
        return (
            <VStack h="100%" justify="center" align="center">
                <Text color="red.500"> We encountered Error While Loading this Page. Please try after some time</Text>
            </VStack>
        );
    }

    if (data.length < 1) {
        return (
            <VStack h="100%" justify="center" align="center">
                <Image boxSize={40} src={EmptyStateSvg} />
                <Heading fontSize="sm">No Prepaid Credit Linked for this Account</Heading>
            </VStack>
        );
    }

    const lowBalanceThreshold = Math.abs(data[0].lowBalanceThreshold ?? 0) / 1000;

    return (
        <VStack w="full" h="full" p={6} overflowX="hidden" bgColor="gray.50">
            <Heading as="h1" size="md" w="full">
                Message Credits
            </Heading>
            <HStack w="full" gap="4" minH="152px">
                <VStack
                    p={4}
                    rounded="sm"
                    w="full"
                    h="full"
                    alignItems="flex-start"
                    justifyContent="center"
                    bg="white"
                    borderRadius="md"
                    border="1px"
                    borderColor="gray.200"
                    spacing="4"
                >
                    <HStack>
                        <Image w={12} h={12} src={negativeBalance ? NegativeCredit : Wallet} />
                        <VStack spacing={0} w="full">
                            <Text fontSize="sm" w="full" color="gray.500">
                                {negativeBalance ? "Add credits to resolve pending balance" : " Available Balance"}
                            </Text>
                            <Text w="full" textAlign="left" color="gray.800" fontWeight="normal" fontSize="18px">
                                {currency} {(amount / 1000).toFixed(4)}
                            </Text>
                            {lowBalanceThreshold > 0 && (
                                <Text fontSize="sm" w="full" color="gray.500" display="flex">
                                    <Icon as={HiOutlineMinusCircle} margin="3px 3px 0px 0px" /> Indebted Credit Limit:{" "}
                                    {currency} {lowBalanceThreshold.toFixed(4)}
                                </Text>
                            )}
                        </VStack>
                    </HStack>
                    <HStack w="full" align="flex-start">
                        <Icon boxSize="14px" as={AiOutlineInfoCircle} color="gray.500" alignSelf="center" />
                        <Text color="gray.600" fontSize="12px" fontWeight="normal">
                            {"Prepaid balance for sending messages on Gallabox."}
                            <ChakraLink
                                href={config.product_help_guide.message_credits}
                                color="blue.500"
                                isExternal
                                px="2"
                            >
                                Learn more.
                            </ChakraLink>
                        </Text>
                    </HStack>
                </VStack>
                <VStack
                    p={4}
                    rounded="sm"
                    w="full"
                    h="full"
                    alignItems="flex-start"
                    bg="white"
                    borderRadius="md"
                    border="1px"
                    borderColor="gray.200"
                    spacing="2"
                >
                    <HStack>
                        <Text fontSize="md" minW="max-content" textAlign="left" fontWeight="500" color="gray.700">
                            Add Message Credits
                        </Text>
                        <Text w="full" color="gray.500" fontSize="xs" fontWeight="400" alignSelf="center">
                            ({currency === "₹" ? "Min ₹100 Max ₹1,00,000" : "Min $5 Max $2000"})
                        </Text>
                    </HStack>
                    <PrepaidInputBox accountId={accountId} currency={currency} walletId={walletId} />
                </VStack>
            </HStack>
            <HStack w="full" align="flex-start">
                <Icon boxSize={4} as={AiOutlineInfoCircle} color="gray.500" />
                <Text color="gray.500" fontSize="13px" fontWeight="normal">
                    {"Message Credits is the pre-deposited amount added to your account. "}
                    <ChakraLink href={config.product_help_guide.message_credits} color="blue.500" isExternal>
                        Learn more.
                    </ChakraLink>
                </Text>
            </HStack>
            <Tabs index={tabIndex} lazyBehavior="keepMounted" isLazy w="full" onChange={handleTabIndexChange}>
                <TabList>
                    <Tab sx={tabStyles.tab}>Payments</Tab>
                    <Tab sx={tabStyles.tab}>Conversations</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <TransactionPaymentTable
                            accountId={accountId}
                            walletId={walletId}
                            currency={currency}
                            wallet={data?.[0]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <TransactionConversationTable accountId={accountId} walletId={walletId} currency={currency} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay backdropFilter="blur(150px)" />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack py={2} align="center" justify="center">
                            <Image src={RechargeSuccess} />
                            <HStack>
                                <Text fontSize="20px" color="green.500">
                                    {currency}
                                </Text>
                                <Text fontSize="20px" color="green.500" fontWeight="extrabold">
                                    {total.toFixed(2)}
                                </Text>
                            </HStack>
                            <Box>
                                <Text align="center" fontSize="20px" color="gray.800" fontWeight="medium">
                                    Credits Added Successfully
                                </Text>
                                <Text
                                    align="center"
                                    marginTop="0rem"
                                    fontSize="14px"
                                    color="gray.500"
                                    fontWeight="normal"
                                >
                                    Note: The receipt will be sent to your email shortly
                                </Text>
                            </Box>

                            <Box w="full" marginTop="0.75rem" display="flex" justifyContent="center">
                                <Button
                                    borderRadius="6px"
                                    onClick={handleClose}
                                    size="sm"
                                    width="4rem"
                                    colorScheme="brand"
                                >
                                    Ok
                                </Button>
                            </Box>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </VStack>
    );
};

export default PrepaidCredit;

interface PrepaidInputBoxProps {
    accountId: string;
    currency: string;
    walletId: string;
}

interface PaymentError {
    status: string;
    message: string;
    error?: {
        pendingPayment: PendingPayment;
    };
    amount: number;
    tds?: number;
}

const tdsPercentageOption = [
    { label: "10%", value: "10" },
    { label: "5%", value: "5" },
    { label: "2%", value: "2" },
    { label: "1%", value: "1" },
];

const PrepaidInputBox: React.FC<PrepaidInputBoxProps> = (props) => {
    const { accountId, walletId, currency } = props;
    const [paymentGateway, setPaymentGateway] = React.useState<LabelValue>();
    const [tdsPercentage, setTdsPercentage] = React.useState<LabelValue>(tdsPercentageOption[0]);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { mutateAsync: rechargeWallet } = useRechargeWallet({ accountId, walletId });
    const [isLoading, setLoading] = useState<boolean>(false);
    const { isOpen: rechargeOpen, onOpen: rechargeOnOpen, onClose: rechargeOnclose } = useDisclosure();
    const { isOpen: isEstimateOpen, onOpen: estimateOnOpen, onClose: estimateOnclose } = useDisclosure();
    const { mutateAsync: cancelTransaction } = useCancelTransaction(accountId, walletId);
    const [errorMessage, setErrorMessage] = useState<PaymentError>();
    const toast = useToast();
    const options: OptionsType<IconLabelValue<string, string>> = [
        { label: "Stripe", value: "stripe", icon: StripeIcon },
        { label: "Razorpay", value: "razorpay", icon: RazorpayIcon },
    ];
    const failedAmount = ((errorMessage?.error?.pendingPayment.amount as number) / 100).toFixed(2);
    const failedUpdated = dayjs(errorMessage?.error?.pendingPayment.updatedAt).format("MMM DD, YYYY");

    const { register, watch, errors, reset, handleSubmit, formState } = useForm<{ amount: string }>({
        mode: "onChange",
    });

    const {
        data: { pageAccess },
    } = useRestrictFeature("billing_tds", "restrict");

    const handlePayment = async () => {
        setLoading(true);
        try {
            await cancelTransaction({
                paymentLinkId: errorMessage?.error?.pendingPayment.id as string,
            });

            const res = await rechargeWallet({
                ...(pageAccess && currency === "₹" ? { tds: tdsPercentage.value } : {}),
                amount: errorMessage?.amount as number,
                paymentGateway: paymentGateway?.value ?? "stripe",
            });
            const url = res.pendingPayment?.url;
            window.open(url, "_self");
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    const submitHandler = async (data: { amount: string }) => {
        setLoading(true);
        onOpen();
        const amount = parseFloat(data.amount) * 100;
        try {
            const res = await rechargeWallet({
                amount,
                paymentGateway: paymentGateway?.value ?? "stripe",
                ...(pageAccess && currency === "₹" ? { tds: tdsPercentage.value } : {}),
            });
            const url = res.pendingPayment?.url;
            window.open(url, "_self");
            reset();
        } catch (e) {
            const error = e as PaymentError;
            setLoading(false);
            onClose();
            if (error?.error?.pendingPayment) {
                setErrorMessage({
                    ...error,
                    amount,
                    ...(pageAccess && currency === "₹" ? { tds: Number(tdsPercentage.value) } : {}),
                });
                rechargeOnOpen();
            } else {
                toast({
                    title: "Error",
                    description: error.message,
                });
            }
        }
    };
    const amount = Number(watch("amount"));
    const estimateValue = useMemo(() => {
        const value = {
            originalAmount: 0,
            tds: 0,
            subTotal: 0,
            gst: 0,
            finalAmount: 0,
        };
        value.originalAmount = amount;
        if (pageAccess && currency === "₹") {
            const calcTds = (Number(tdsPercentage?.value) / 100) * amount;
            value.tds = Number(calcTds.toFixed(2));
            value.subTotal = amount - calcTds;
        }
        const subTotal = value.subTotal || value.originalAmount;
        const gst = Number((0.09 * value.originalAmount).toFixed(2));
        value.gst = gst;
        value.finalAmount = subTotal + 2 * gst;
        return value;
    }, [amount, tdsPercentage]);
    return (
        <>
            <Modal isOpen={rechargeOpen} onClose={rechargeOnclose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack py={3} align="center" justify="center">
                            <Image src={NewInvoice} h={40} w={40} />
                            <Text align="center" fontSize="16px" fontWeight="medium">
                                {`You have one Pending Transaction for ${currency} ${failedAmount}
                                 Created on ${failedUpdated}`}
                            </Text>
                            <Text align="center" fontSize="13px" fontWeight="normal">
                                If you still want to create a new Payment, please click on Pay Now. Your Pending
                                Transaction will be automatically cancelled.
                            </Text>
                            <Box paddingTop="20px">
                                <Button
                                    fontSize="16px"
                                    fontWeight="medium"
                                    size="md"
                                    variant="solid"
                                    colorScheme="brand"
                                    borderRadius="6px"
                                    isLoading={isLoading}
                                    onClick={handlePayment}
                                >
                                    <span>Pay now</span>
                                </Button>
                            </Box>
                        </VStack>
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>

            <HStack
                as="form"
                onSubmit={handleSubmit(submitHandler)}
                alignItems="baseline"
                spacing={2}
                w="full"
                id="credit"
            >
                <FormControlWrapper label="Amount" formControlProps={{ mt: 1 }}>
                    <Input
                        bg="white"
                        name="amount"
                        type="number"
                        step="any"
                        height="32px"
                        fontSize="sm"
                        placeholder="Enter amount"
                        register={register}
                        error={errors.amount?.message}
                        spacingY="5px"
                        autoFocus
                        registerProps={{
                            required: "Amount is required",
                            min:
                                currency === "₹"
                                    ? { value: 100, message: `Min amount is ${currency} 100` }
                                    : { value: 5, message: `Min amount is ${currency} 5` },
                            max:
                                currency === "₹"
                                    ? { value: 200000, message: `Max amount is ${currency} 2,00,000` }
                                    : { value: 3000, message: `Max amount is ${currency} 3000` },
                            pattern: {
                                value: /^\d+(?:\.\d{1,2})?$/,
                                message: "More than 2 decimal places are not allowed",
                            },
                        }}
                        inputLeftElement={
                            <InputLeftElement pointerEvents="none" color="gray.900" fontSize="1.2em" height="32px">
                                {currency}
                            </InputLeftElement>
                        }
                        autoComplete="off"
                    />
                </FormControlWrapper>
                <FormControlWrapper label="Payment Gateway" formControlProps={{ mt: 1 }}>
                    <IconReactSelect
                        defaultOptions
                        defaultValue={options[0]}
                        value={options.find((a) => a.value === paymentGateway?.value)}
                        options={options}
                        onChange={(data) => setPaymentGateway(options.find((a) => a.value === data?.value))}
                    />
                </FormControlWrapper>
                {currency === "₹" ? (
                    <Button
                        colorScheme="brand"
                        size="medium"
                        onClick={estimateOnOpen}
                        minW="max-content"
                        alignSelf={errors.amount?.message ? "center" : "flex-end"}
                        isDisabled={!formState.isValid}
                    >
                        <span>Add Credits</span>
                    </Button>
                ) : (
                    <Button
                        type="submit"
                        isLoading={isLoading}
                        colorScheme="brand"
                        size="medium"
                        minW="max-content"
                        alignSelf={errors.amount?.message ? "center" : "flex-end"}
                        isDisabled={!formState.isValid}
                        form="credit"
                    >
                        <span>Add Credits</span>
                    </Button>
                )}
                <Modal isOpen={isEstimateOpen} onClose={estimateOnclose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalBody p="0">
                            <VStack w="full" alignItems="flex-start" spacing="4" pb="3">
                                <Image src={HeaderBackground} position="relative" />
                                <HStack
                                    position="absolute"
                                    bgColor="#EDF2F7"
                                    w="full"
                                    pl="6"
                                    py="3"
                                    pr="3"
                                    justifyContent="space-between"
                                >
                                    <Text textColor="gray.700" fontSize="18px" fontWeight="700">
                                        Gallabox Message Credits
                                    </Text>
                                    <CloseButton onClick={estimateOnclose} />
                                </HStack>
                                <HStack w="full" px="6">
                                    <VStack w="full" alignItems="flex-start" flex="1" spacing="0">
                                        <Text textColor="gray.700" fontSize="14px" fontWeight="500">
                                            Message Credits
                                        </Text>
                                        <Text textColor="gray.500" fontSize="xs" fontWeight="400">
                                            Gallabox
                                        </Text>
                                    </VStack>
                                    <Text textColor="gray.900" fontSize="14px" fontWeight="500">
                                        ₹ {estimateValue.originalAmount}
                                    </Text>
                                </HStack>
                                {pageAccess && currency === "₹" ? (
                                    <HStack w="full" px="6">
                                        <HStack w="full" alignItems="flex-start" flex="1" spacing="2">
                                            <Text
                                                textColor="gray.700"
                                                fontSize="14px"
                                                fontWeight="500"
                                                alignSelf="center"
                                            >
                                                TDS Amount
                                            </Text>
                                            <Box minW="85px">
                                                <StyledReactSelect
                                                    options={tdsPercentageOption}
                                                    defaultValue={tdsPercentageOption[0]}
                                                    onChange={(opt) => setTdsPercentage(opt as LabelValue)}
                                                    value={tdsPercentage}
                                                />
                                            </Box>
                                        </HStack>
                                        <Text textColor="gray.900" fontSize="14px" fontWeight="500">
                                            ₹ {estimateValue.tds}
                                        </Text>
                                    </HStack>
                                ) : null}
                                {pageAccess && currency === "₹" ? (
                                    <HStack w="full" px="6">
                                        <Text textColor="gray.700" fontSize="14px" fontWeight="500" flex="1">
                                            Sub total
                                        </Text>
                                        <Text textColor="gray.900" fontSize="14px" fontWeight="500">
                                            ₹ {estimateValue.subTotal}
                                        </Text>
                                    </HStack>
                                ) : null}
                                <HStack w="full" px="6">
                                    <VStack w="full" alignItems="flex-start" flex="1" spacing="0">
                                        <Text textColor="gray.700" fontSize="14px" fontWeight="500">
                                            GST
                                        </Text>
                                        <Text textColor="gray.500" fontSize="xs" fontWeight="400" minW="max-content">
                                            CGST 9%
                                        </Text>
                                        <Text textColor="gray.500" fontSize="xs" fontWeight="400" minW="max-content">
                                            SGST 9%
                                        </Text>
                                    </VStack>
                                    <VStack w="full" alignItems="flex-end" spacing="0" alignSelf="flex-end">
                                        <Text textColor="gray.900" fontSize="14px" fontWeight="500">
                                            ₹ {estimateValue.gst}
                                        </Text>
                                        <Text textColor="gray.900" fontSize="14px" fontWeight="500">
                                            ₹ {estimateValue.gst}
                                        </Text>
                                    </VStack>
                                </HStack>
                                <HStack w="full" px="6">
                                    <Text textColor="gray.700" fontSize="14px" fontWeight="500" flex="1">
                                        Total to be paid
                                    </Text>
                                    <Text textColor="gray.900" fontSize="14px" fontWeight="500">
                                        ₹ {estimateValue.finalAmount}
                                    </Text>
                                </HStack>
                                <HStack w="full" px="6">
                                    <Box flex="0.5">
                                        <IconReactSelect
                                            defaultOptions
                                            defaultValue={options[0]}
                                            value={options.find((a) => a.value === paymentGateway?.value)}
                                            options={options}
                                            onChange={(data) =>
                                                setPaymentGateway(options.find((a) => a.value === data?.value))
                                            }
                                        />
                                    </Box>
                                    <Button
                                        colorScheme="brand"
                                        size="medium"
                                        type="submit"
                                        isLoading={isLoading}
                                        alignSelf="flex-end"
                                        flex="0.5"
                                        w="full"
                                        form="credit"
                                    >
                                        Pay now
                                    </Button>
                                </HStack>
                            </VStack>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </HStack>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody alignItems="center" justifyContent="center">
                        <Grid py={10} placeItems="center">
                            <Text fontSize="sm" fontWeight="bold">
                                Adding credits to your account
                            </Text>
                            <AnimatedLoadingState spinnerSize={40} title="Loading..." />
                        </Grid>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
