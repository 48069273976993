import { useMediaQuery } from "@chakra-ui/react";

interface UseGBMediaQueryProps {
    isMobile: boolean;
    isDesktop: boolean;
    isLessThan1280: boolean;
}
export const useGBMediaQuery = (): UseGBMediaQueryProps => {
    const [isMobile] = useMediaQuery("(max-width: 480px)");
    const [isDesktop] = useMediaQuery("(min-width: 1440px)");
    const [isLessThan1280] = useMediaQuery("(max-width: 970px)");
    return { isMobile, isDesktop, isLessThan1280 };
};
