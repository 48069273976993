import { IconButton, IconButtonProps } from "@chakra-ui/react";
import React from "react";
import { MdArrowBack } from "react-icons/md";

type BackIconButtonProps = Omit<IconButtonProps, "isRound" | "size" | "icon" | "aria-label" | "colorScheme">;
const BackIconButton: React.FC<BackIconButtonProps> = (props) => {
    return <IconButton isRound size="sm" icon={<MdArrowBack />} aria-label="Go Back" colorScheme="gray" {...props} />;
};

export default BackIconButton;
