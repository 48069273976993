import { HStack, Icon, ImageProps, IconProps, Image, SystemStyleObject, Text } from "@chakra-ui/react";
import { isJSXElement } from "app/utils/common";
import React from "react";
import { IconType } from "react-icons";
import { IoMdInformationCircleOutline } from "react-icons/io";
interface InfoLabelContentProps {
    content: string | JSX.Element;
    containerStyle?: SystemStyleObject;
    contentStyle?: SystemStyleObject;
    showIcon?: boolean;
}

interface InfoLabelPropsWithSVGIcon extends InfoLabelContentProps {
    icon?: string;
    iconProps?: ImageProps;
}

interface InfoLabelPropsWithReactIcon extends InfoLabelContentProps {
    icon?: IconType;
    iconProps?: IconProps;
}
export type InfoLabelProps = InfoLabelPropsWithSVGIcon | InfoLabelPropsWithReactIcon;

const isSvgIcon = (props: InfoLabelProps): props is InfoLabelPropsWithSVGIcon => {
    return typeof props.icon === "string";
};

const InfoLabel: React.FC<InfoLabelProps> = (props) => {
    const { content, containerStyle, contentStyle, showIcon = true } = props;

    const IconComponent = React.useMemo(() => {
        if (!showIcon) return;
        if (!props.icon) return <Icon as={IoMdInformationCircleOutline} {...(props.iconProps as IconProps)} />;
        if (isSvgIcon(props)) {
            return <Image src={props.icon} {...props.iconProps} />;
        }
        return <Icon as={props.icon} {...props.iconProps} />;
    }, [props, showIcon]);

    const ContentComponent = React.useMemo(() => {
        if (!content) return null;
        if (isJSXElement(content)) return content;
        return (
            <Text textAlign="center" lineHeight="16px" fontSize={"xs"} sx={contentStyle}>
                {content}
            </Text>
        );
    }, [content, contentStyle]);

    return (
        <HStack
            w="max-content"
            justifyContent={"center"}
            p={1}
            rounded={"md"}
            spacing={1}
            sx={{ bg: "yellow.100", ...containerStyle }}
        >
            <HStack align="flex-start" justify={"center"} spacing="2">
                {IconComponent}
                {ContentComponent}
            </HStack>
        </HStack>
    );
};
export default InfoLabel;
