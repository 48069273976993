import { Badge, Image, ImageProps as ChakraImageProps, Skeleton, UseImageProps, VStack } from "@chakra-ui/react";
import { getUrlContentType } from "app/utils/fetchUtils";
import { URL_REGEX } from "app/utils/validation";
import DocPlaceholder from "assets/broadcast/DocPlaceholder.svg";
import ImagePlaceholder from "assets/broadcast/ImagePlaceholder.svg";
import VideoPlaceholder from "assets/broadcast/VideoPlaceholder.svg";
import React from "react";

export const audioTypes = ["audio/aac", "audio/mp4", "audio/amr", "audio/mpeg"];
export const imageTypes = ["image/png", "image/jpeg"];
export const videoTypes = ["video/mp4", "video/3gpp"];
export const stickerTypes = ["image/webp"];

export type AudioTypes = "audio/aac" | "audio/mp4" | "audio/amr" | "audio/mpeg" | "audio/ogg; codecs=opus";
export type ImageTypes = "image/png" | "image/jpeg";
export type VideoTypes = "video/mp4" | "video/3gpp";
export type StickerTypes = "image/webp";

export type WhatsappMediaTypes = AudioTypes | ImageTypes | VideoTypes | StickerTypes;
export type ContentFormatTypes = "text" | "video" | "image" | "document" | "audio" | "location" | null;

interface PreviewComponentProps {
    source: File | string;
    contentType?: string | null;
    contentFormat?: ContentFormatTypes;
}

const isFile = (source: File | string): source is File => {
    return typeof source !== "string";
};

const PreviewComponent: React.FC<PreviewComponentProps> = (props) => {
    const [contentType, setContentType] = React.useState<string | null>(null);
    const [contentFormat, setContentFormat] = React.useState<string | null>(null);
    const [url, setUrl] = React.useState<string | null>(null);
    const [isLoading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchFile = async () => {
            if (props.contentType) {
                setContentType(props.contentType);
            } else if (props.contentFormat) {
                setContentFormat(props.contentFormat);
            } else {
                const file = isFile(props.source) ? props.source : { type: await getUrlContentType(props.source) };
                setContentType(file.type);
            }
        };

        fetchFile();
    }, [props.source, props.contentType, props.contentFormat]);

    React.useEffect(() => {
        const url = isFile(props.source) ? URL.createObjectURL(props.source) : props.source;
        setUrl(url);
    }, [props.source]);

    const onLoad: React.ReactEventHandler<HTMLElement | HTMLVideoElement | HTMLAudioElement> = () => {
        setLoading(false);
    };

    const onError: React.ReactEventHandler<HTMLElement> = () => {
        setLoading(false);
        // FIXME: need to show a fallback image
        setUrl(null);
    };

    const render = React.useMemo(() => {
        if ((!contentFormat && !contentType) || !url) return null;

        if (imageTypes.includes(contentType ?? "") || contentFormat === "image") {
            return <ImagePreview source={url} onLoad={onLoad} onError={onError} />;
        } else if (videoTypes.includes(contentType ?? "") || contentFormat === "video") {
            return <VideoPreview source={url} onLoad={onLoad} />;
        } else if (audioTypes.includes(contentType ?? "") || contentFormat === "audio") {
            return <AudioPreview source={url} onLoad={onLoad} />;
        }
        return <DocumentPreview source={url} onLoad={onLoad} />;
    }, [contentType, url, contentFormat]);

    return (
        <Skeleton isLoaded={!isLoading} maxW="full">
            {render}
        </Skeleton>
    );
};

interface PreviewPlaceholder {
    placeholderImage: string;
    onLoad: () => {};
    source: string;
}

const PreviewPlaceholder: React.FC<PreviewPlaceholder> = (props) => {
    const { placeholderImage, onLoad, source } = props;
    return (
        <VStack
            align="center"
            justify="center"
            style={{
                backgroundColor: "white",
                maxHeight: "100%",
                maxWidth: "100%",
                overflow: "hidden",
                objectPosition: "center",
                objectFit: "cover",
                width: "100%",
            }}
            spacing={4}
            py={4}
            borderColor="gray.200"
            borderStyle="solid"
            borderWidth="1px"
            borderRadius="base"
            minHeight="max-content"
            minWidth="220px"
        >
            <Image src={placeholderImage} w="80px" h="80px" onLoad={onLoad} />
            <Badge
                overflow="hidden"
                maxWidth="full"
                color="green.500"
                whiteSpace="pre-line"
                overflowWrap="anywhere"
                isTruncated
                title={source}
            >
                {source}
            </Badge>
        </VStack>
    );
};

const isContainsVariable = (data: string): boolean => {
    const findCurlyBracesRegex = /\{{[^)]*\}}/g;
    return findCurlyBracesRegex.test(data);
};

const isUrl = (url: string) => new RegExp(URL_REGEX).test(url);

interface PreviewPropsBase {
    source: string;
    style?: React.CSSProperties;
}

type ImageProps = UseImageProps & PreviewPropsBase & ChakraImageProps;
type DocProps = React.ObjectHTMLAttributes<HTMLObjectElement> & PreviewPropsBase;
interface AudioProps extends React.ObjectHTMLAttributes<HTMLAudioElement>, PreviewPropsBase {
    controls: boolean;
}
interface VideoProps extends React.ObjectHTMLAttributes<HTMLVideoElement>, PreviewPropsBase {
    controls: boolean;
}
type PreviewProps = ImageProps | DocProps | VideoProps | AudioProps;

const ImgPreview: React.FC<PreviewProps> = (props) => {
    const { source, ...imageProps } = props as ImageProps;
    const isVariable = isContainsVariable(source);
    if (isVariable) {
        return (
            <PreviewPlaceholder
                onLoad={props?.onLoad as unknown as PreviewPlaceholder["onLoad"]}
                source={source}
                placeholderImage={ImagePlaceholder}
            />
        );
    }

    if (isUrl(source)) {
        return (
            <Image
                borderRadius="10px"
                src={source}
                minHeight="max-content"
                objectFit="contain"
                width="100%"
                objectPosition="center"
                bg="gray.100"
                {...imageProps}
            />
        );
    }

    return null;
};

const DocPreview: React.FC<PreviewProps> = (props) => {
    const { source, style = {}, ...docProps } = props as Omit<DocProps, "aria-relevant" | "dangerouslySetInnerHTML">;

    const fileType = source.split(".").pop();

    const isVariable = isContainsVariable(source);
    if (isVariable) {
        return (
            <PreviewPlaceholder
                onLoad={props?.onLoad as unknown as PreviewPlaceholder["onLoad"]}
                source={source}
                placeholderImage={DocPlaceholder}
            />
        );
    }

    if (isUrl(source)) {
        return (
            <object
                data={source}
                style={{ backgroundColor: "white", maxHeight: "100%", maxWidth: "100%", overflow: "hidden", ...style }}
                {...docProps}
            >
                <embed src={source} />
            </object>
        );
    }

    return null;
};
export const VideoPreview: React.FC<PreviewProps> = (props) => {
    const {
        source,
        style = {},
        onLoad,
        controls,
        ...videoProps
    } = props as Omit<VideoProps, "aria-relevant" | "dangerouslySetInnerHTML">;

    const isVariable = isContainsVariable(source);
    if (isVariable) {
        return (
            <PreviewPlaceholder
                onLoad={props?.onLoad as unknown as PreviewPlaceholder["onLoad"]}
                source={source}
                placeholderImage={VideoPlaceholder}
            />
        );
    }

    if (isUrl(source)) {
        return (
            <video
                src={source}
                style={{ objectFit: "contain", ...style }}
                controls={controls ?? true}
                autoPlay={false}
                muted
                onLoadedData={onLoad}
                {...videoProps}
            >
                <source src={source} />
            </video>
        );
    }

    return null;
};
export const AudioPreview: React.FC<PreviewProps> = (props) => {
    const {
        source,
        style = {},
        onLoad,
        controls,
        ...audioProps
    } = props as Omit<AudioProps, "aria-relevant" | "dangerouslySetInnerHTML">;
    return (
        <audio controls={controls} style={style} autoPlay={false} muted onLoadedData={onLoad} {...audioProps}>
            <source src={source} />
        </audio>
    );
};
export const ImagePreview = React.memo(ImgPreview);
export const DocumentPreview = React.memo(DocPreview);
export default React.memo(PreviewComponent);
