import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    CloseButton,
    HStack,
    useToast,
    VStack,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { User } from "./types/user";
import { windowOpenNewTab } from "./utils/common";
import { SocketEvents, useSocket } from "./utils/use-socketio";

interface NotificationData {
    id: string;
    title: string;
    description?: string;
    ttlMs?: number;
    status?: "info" | "warning" | "success" | "error";
    link?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta?: Record<string, any>;
}

interface UserConnectResponseData {
    success: "connected";
}

interface NotificationsProps {
    user?: User;
}

const Notifications: FC<NotificationsProps> = (props) => {
    const { user } = props;
    const accountId = props.user?.accounts?.[0]?.accountId;

    const [isConnected, setConnected] = useState<boolean>(false);

    const toast = useToast({ isClosable: true });

    const { socket } = useSocket(SocketEvents.BulkActionMemberConnect, (data: UserConnectResponseData) => {
        setConnected(user?.id != null && data.success === "connected");
    });

    useSocket(SocketEvents.WATemplateStatusChange, (data: NotificationData) => {
        if (!user?.id || !isConnected) return;
        const { id, ttlMs: duration, status, title, description, link } = data;
        if (toast.isActive(id)) return;
        toast({
            id,
            position: "bottom-right",
            duration,
            render: (props) => {
                return (
                    <HStack
                        alignItems="flex-start"
                        w="full"
                        spacing={0}
                        as={Alert}
                        status={status}
                        variant="subtle"
                        onClick={() => {
                            if (!link) return;
                            windowOpenNewTab(link);
                        }}
                        cursor="pointer"
                        position="relative"
                    >
                        <AlertIcon />
                        <VStack flex={1}>
                            <HStack w="full" alignItems="flex-start">
                                <AlertTitle flex={1}>{title}</AlertTitle>
                                <CloseButton
                                    fontSize="xs"
                                    size="sm"
                                    rounded="full"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.onClose();
                                    }}
                                />
                            </HStack>
                            <AlertDescription>
                                {`${description}\nTest now or send it to your contacts after 15 mins!`}
                            </AlertDescription>
                        </VStack>
                    </HStack>
                );
            },
        });
    });

    useEffect(() => {
        if (!accountId || !user?.id) return;
        socket?.emit(SocketEvents.BulkActionMemberConnect, {
            accountId: user?.accounts?.[0]?.accountId,
            userId: user?.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    return null;
};

export default Notifications;
