import EmptyStateSvg from "assets/EmptyState/NoDataFound.svg";
import React from "react";
import CenterToScreen, { CenterToScreenProps } from "./CenterToScreen";
import FailureState, { FailureStateProps } from "./FailureState";

export type EmptyStateProps = FailureStateProps & CenterToScreenProps;

const EmptyState: React.FC<EmptyStateProps> = (props) => {
    const { imageProps = {}, isCentered = false, ...otherProps } = props;

    return (
        <CenterToScreen isCentered={isCentered}>
            <FailureState imageProps={{ src: EmptyStateSvg, ...imageProps }} {...otherProps} />
        </CenterToScreen>
    );
};

export default EmptyState;
