import { SocketEvents } from "./useSocket";

export * from "./context";
export * from "./provider";
export * from "./useSocket";

export const socketEmit = (
    socket: SocketIOClient.Socket | null,
    eventKey: SocketEvents,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
): SocketIOClient.Socket | undefined => {
    console.debug("emit", eventKey, args);
    return socket?.emit(eventKey, ...args);
};
