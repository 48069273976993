import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
    FormControl,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
    FormLabelProps,
    HStack,
    StackProps,
    FormErrorMessageProps,
    FormHelperTextProps,
    FormControlProps,
    Stack,
    Box,
    Link,
    Button,
    Icon,
    Spacer,
    Grid,
} from "@chakra-ui/react";
import GBTooltip, { GBTooltipProps } from "app/components/GBTooltip";
import StatusLabel from "app/components/StatusLabel";
import React, { PropsWithChildren } from "react";
import { FiExternalLink } from "react-icons/fi";

interface LabelFieldProps {
    label?: string | JSX.Element;
    hidelabel?: boolean;
    labelProps?: Partial<FormLabelProps>;
    helpText?: string | JSX.Element | null;
    error?: string;
    tooltipText?: string | JSX.Element | null;
    labelContainerProps?: StackProps;
    fieldContainerProps?: StackProps;
    helperTextProps?: Partial<FormHelperTextProps>;
    errorMessageProps?: Partial<FormErrorMessageProps>;
    toolTipProps?: Partial<GBTooltipProps>;
    isOptional?: boolean;
    infoLink?: string;
    infoLinkLabel?: string;
    labelExtraComponent?: JSX.Element | null;
}

export interface FormControlWrapperProps extends LabelFieldProps {
    required?: boolean | string;
    formControlProps?: Partial<FormControlProps>;
    labelFieldOrientation?: "horizontal" | "vertical";
}

const FormControlWrapper: React.FC<PropsWithChildren<FormControlWrapperProps>> = (props) => {
    const { formControlProps = {}, required, labelFieldOrientation = "vertical", ...labelFieldProps } = props;
    const labelProps = {
        ...props.labelProps,
        isRequired: Boolean(required),
    };
    return (
        <FormControl isInvalid={Boolean(labelFieldProps.error)} {...formControlProps}>
            {labelFieldOrientation === "vertical" && (
                <LabelFieldVerticalOrientation {...props} labelProps={labelProps}>
                    {props.children}
                </LabelFieldVerticalOrientation>
            )}
            {labelFieldOrientation === "horizontal" && (
                <LabelFieldHorizontalOrientation {...props} labelProps={labelProps}>
                    {props.children}
                </LabelFieldHorizontalOrientation>
            )}
        </FormControl>
    );
};

type LabelFieldVerticalOrientationProps = LabelFieldProps;

interface GBFormLabelProps extends FormLabelProps {
    isRequired?: boolean;
}
export const GBFormLabel: React.FC<GBFormLabelProps> = (props) => {
    const { children, isRequired, ...formLabelProps } = props;
    return (
        <FormLabel fontSize="sm" color="gray.500" {...formLabelProps}>
            {children}
            {isRequired && (
                <Box as="span" sx={{ color: "red", marginLeft: "4px" }}>
                    *
                </Box>
            )}
        </FormLabel>
    );
};

const LabelFieldVerticalOrientation: React.FC<PropsWithChildren<LabelFieldVerticalOrientationProps>> = (props) => {
    const {
        label,
        error,
        labelProps,
        helpText,
        labelContainerProps = {},
        fieldContainerProps = {},
        toolTipProps = {},
        helperTextProps = {},
        errorMessageProps = {},
        isOptional = false,
        tooltipText,
        hidelabel = false,
        infoLinkLabel,
        infoLink,
        labelExtraComponent,
    } = props;
    const showLabelSide = (label || tooltipText || helpText) && !hidelabel;

    return (
        <>
            {showLabelSide && (
                <HStack spacing={0} w="full" fontSize="sm" color="gray.600" marginBottom={1} {...labelContainerProps}>
                    {label && (
                        <GBFormLabel marginBottom={0} marginRight={1} {...labelProps}>
                            {label}
                        </GBFormLabel>
                    )}
                    {isOptional && <StatusLabel status="optional" rounded="sm" color="gray.500" />}
                    {tooltipText ? (
                        <GBTooltip label={tooltipText} {...toolTipProps}>
                            <Grid boxSize={4} placeItems="center" ml={[0, "2px !important"]}>
                                <InfoOutlineIcon color="gray.500" boxSize={3} fontSize="xs" />
                            </Grid>
                        </GBTooltip>
                    ) : null}
                    {Boolean(infoLink) && (
                        <>
                            <Spacer />
                            <Button
                                as={Link}
                                size="xs"
                                fontSize="12px"
                                fontWeight="normal"
                                variant="link"
                                colorScheme="brand"
                                rightIcon={<Icon as={FiExternalLink} />}
                                href={infoLink}
                                target="_blank"
                            >
                                {infoLinkLabel}
                            </Button>
                        </>
                    )}
                    {labelExtraComponent}
                </HStack>
            )}
            <Box {...fieldContainerProps}>{props.children}</Box>
            {helpText ? (
                <FormHelperText fontSize="xs" color="gray.500" fontWeight="normal" mt={1} {...helperTextProps}>
                    {helpText}
                </FormHelperText>
            ) : null}
            <FormErrorMessage {...errorMessageProps}>{error}</FormErrorMessage>
        </>
    );
};

type LabelFieldHorizontalOrientationProps = LabelFieldProps;

const LabelFieldHorizontalOrientation: React.FC<PropsWithChildren<LabelFieldHorizontalOrientationProps>> = (props) => {
    const {
        label,
        error,
        labelProps,
        helpText,
        labelContainerProps = {},
        fieldContainerProps = {},
        toolTipProps = {},
        helperTextProps = {},
        errorMessageProps = {},
        isOptional = false,
        tooltipText,
        hidelabel = false,
    } = props;
    const showLabelSide = (label || tooltipText || helpText) && !hidelabel;
    return (
        <Stack direction="column" w="full" spacing={0}>
            <HStack w="full">
                {showLabelSide && !hidelabel && (
                    <Stack direction="column" spacing={0} flex={1} {...labelContainerProps}>
                        {(label || tooltipText) && (
                            <HStack spacing={0}>
                                {label && (
                                    <GBFormLabel marginBottom={0} marginRight={1} {...labelProps}>
                                        {label}
                                    </GBFormLabel>
                                )}
                                {isOptional && <StatusLabel status="optional" rounded="sm" color="gray.500" />}
                                {tooltipText ? (
                                    <GBTooltip label={tooltipText} {...toolTipProps}>
                                        <Grid boxSize={4} placeItems="center" ml={[0, "2px !important"]}>
                                            <InfoOutlineIcon color="gray.500" boxSize={3} fontSize="xs" />
                                        </Grid>
                                    </GBTooltip>
                                ) : null}
                            </HStack>
                        )}
                        {helpText ? (
                            <FormHelperText
                                fontSize="xs"
                                color="gray.500"
                                fontWeight="normal"
                                mt={1}
                                {...helperTextProps}
                            >
                                {helpText}
                            </FormHelperText>
                        ) : null}
                    </Stack>
                )}
                <Box direction="column" flex={1} {...fieldContainerProps}>
                    {props.children}
                </Box>
            </HStack>
            <HStack w="full">
                {showLabelSide && <Stack spacing={0} flex={1} {...labelContainerProps}></Stack>}
                <Box spacing={0} flex={1} {...fieldContainerProps}>
                    <FormErrorMessage {...errorMessageProps}>{error}</FormErrorMessage>
                </Box>
            </HStack>
        </Stack>
    );
};

export default FormControlWrapper;
