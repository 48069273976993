import React from "react";

import { createBrowserHistory } from "history";
import { useHistory, useLocation } from "react-router-dom";

export type History = ReturnType<typeof useHistory>;
export type Location = ReturnType<typeof useLocation>;

export const history = createBrowserHistory();

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQueryParam = (): URLSearchParams => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};
