import { Button, ButtonProps, Icon, Link } from "@chakra-ui/react";
import React, { FC } from "react";
import { FiExternalLink } from "react-icons/fi";

interface ExternalLInkIconProps {
    ButtonProps?: ButtonProps;
    btnText: string;
    externalLink: string;
}

const ExternalLinkIcon: FC<ExternalLInkIconProps> = (props) => {
    const { ButtonProps, btnText, externalLink } = props;
    return (
        <Button
            as={Link}
            size="xs"
            fontSize="12px"
            fontWeight="600"
            variant="link"
            colorScheme="brand"
            rightIcon={<Icon as={FiExternalLink} />}
            href={externalLink}
            target="_blank"
            {...ButtonProps}
        >
            {btnText}
        </Button>
    );
};

export default ExternalLinkIcon;
