import { Dispatch } from "redux";

const CHANGE_IS_MOBILE = "CHANGE_IS_MOBILE";
const CHANGE_DEVICE_TYPE = "CHANGE_DEVICE_TYPE";
const CHANGE_DEVICE_OS = "CHANGE_DEVICE_OS";
const CHANGE_WIDTH_AND_HEIGHT = "CHANGE_WIDTH_AND_HEIGHT";
export const UPDATE_APP_VERSION_FE = "UPDATE_APP_VERSION_FE";
export const SERVER_GIT_HASH = "SERVER_GIT_HASH";

const typeOfDevices = ["mobile", "desktop"] as const;
type DeviceType = (typeof typeOfDevices)[number];

const mobileOS = ["Android", "BlackBerry", "iOS", "Windows"] as const;
type MobileOSType = (typeof mobileOS)[number];

const desktopOS = ["Window", "Mac", "Linux"] as const;
type DesktopOSType = (typeof desktopOS)[number];

type DeviceOSType = MobileOSType | DesktopOSType;

const detectOS = (): DeviceOSType | null => {
    const userAgent = window.navigator.userAgent;

    if (/iPhone|iPad|iPod/.test(userAgent)) {
        return "iOS";
    } else if (/Android/.test(userAgent)) {
        return "Android";
    } else if (/BlackBerry/.test(userAgent)) {
        return "BlackBerry";
    } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
        return "Mac";
    } else if (/Linux/.test(userAgent)) {
        return "Linux";
    } else if (/Win32|IEMobile|Win64|Windows|WinCE/.test(userAgent)) {
        return "Windows";
    }

    return null;
};

const detectDeviceType = (): DeviceType => {
    const os = detectOS();
    return os == "Android" || os == "iOS" || os == "BlackBerry" ? "mobile" : "desktop";
};

interface ChangeIsMobileAction {
    type: typeof CHANGE_IS_MOBILE;
    isMobile: boolean;
}
interface ChangeDeviceType {
    type: typeof CHANGE_DEVICE_TYPE;
    deviceType: DeviceType;
}
interface ChangeDeviceOS {
    type: typeof CHANGE_DEVICE_OS;
    deviceOS: DeviceOSType;
}
interface ChangeWidthAndHeightAction {
    type: typeof CHANGE_WIDTH_AND_HEIGHT;
    height: number;
    width: number;
}
interface FEVersion {
    localFEVersion: string;
    serverFEVersion: string;
}
interface UpdateAppVersionFE extends FEVersion {
    type: typeof UPDATE_APP_VERSION_FE;
}
interface EnvState extends FEVersion {
    isMobile: boolean;
    deviceType?: DeviceType;
    deviceOS?: DeviceOSType;
    height: number | null;
    width: number | null;
}
type EnvActionTypes =
    | ChangeIsMobileAction
    | ChangeWidthAndHeightAction
    | UpdateAppVersionFE
    | ChangeDeviceType
    | ChangeDeviceOS;

const initialState: EnvState = {
    isMobile: false,
    height: null,
    width: null,
    localFEVersion: "",
    serverFEVersion: "",
};

export default function environment(state: EnvState = initialState, action: EnvActionTypes) {
    switch (action.type) {
        case CHANGE_IS_MOBILE:
            return {
                ...state,
                isMobile: action.isMobile,
            };

        case CHANGE_DEVICE_TYPE:
            return {
                ...state,
                deviceType: action.deviceType,
            };
        case CHANGE_DEVICE_OS:
            return {
                ...state,
                deviceOS: action.deviceOS,
            };

        case CHANGE_WIDTH_AND_HEIGHT:
            return {
                ...state,
                height: action.height,
                width: action.width,
            };
        case UPDATE_APP_VERSION_FE: {
            return {
                ...state,
                localFEVersion: action.localFEVersion,
                serverFEVersion: action.serverFEVersion,
            };
        }
        default:
            return state;
    }
}

function changeIsMobile(isMobile: boolean) {
    return {
        type: CHANGE_IS_MOBILE,
        isMobile,
    };
}
function changeDeviceType(deviceType: DeviceType) {
    return {
        type: CHANGE_DEVICE_TYPE,
        deviceType,
    };
}
function changeDeviceOS(deviceOS: DeviceOSType) {
    return {
        type: CHANGE_DEVICE_OS,
        deviceOS,
    };
}

export const updateFEVersion = (
    localFEVersion: FEVersion["localFEVersion"],
    serverFEVersion: FEVersion["serverFEVersion"]
) => {
    return {
        type: UPDATE_APP_VERSION_FE,
        localFEVersion,
        serverFEVersion,
    };
};

export function changeWidthAndHeight(height: number, width: number) {
    return {
        type: CHANGE_WIDTH_AND_HEIGHT,
        height,
        width,
    };
}

export function initEnvironment() {
    return (dispatch: Dispatch) => {
        const os = detectOS();
        const deviceType = detectDeviceType();
        const isMobile = deviceType === "mobile";
        dispatch(changeIsMobile(isMobile));
        dispatch(changeWidthAndHeight(window.innerHeight, window.innerWidth));
        if (deviceType) {
            dispatch(changeDeviceType(deviceType));
        }
        if (os) {
            dispatch(changeDeviceOS(os));
        }

        window.onresize = () => {
            dispatch(changeWidthAndHeight(window.innerHeight, window.innerWidth));
        };
    };
}
