import React from "react";
import { ValueContainerProps } from "react-select";
import { OptionTypeBase } from "react-select";
import CreatableReactSelect, { Props as CreatableSelectProp } from "react-select/creatable";
import FloatingLabelValueContainer from "./FloatingLabelValueContainer";
import { CreatableReactSelectRef } from "./type";
import { useReactSelectStylesCustomized, UseReactSelectStylesCustomizedProps } from "./useReactSelectStylesCustomized";

export type CreatableReactSelectStyledProps<T extends OptionTypeBase, isMulti extends boolean> = CreatableSelectProp<
    T,
    isMulti
> &
    UseReactSelectStylesCustomizedProps;

// eslint-disable-next-line react/display-name
const CreatableReactSelectStyled = React.forwardRef(
    <T extends OptionTypeBase, isMulti extends boolean = false>(
        props: CreatableReactSelectStyledProps<T, isMulti>,
        ref?: CreatableReactSelectRef<T, isMulti>
    ) => {
        const { size, fontSize, menuBgColor, containerBgColor, menuHeight, width, styles = {}, ...selectProps } = props;

        const customStyles = useReactSelectStylesCustomized<isMulti>({
            ...selectProps,
            styles,
            size,
            fontSize,
            menuBgColor,
            containerBgColor,
            menuHeight,
            width,
        });

        const reactSelectComponent: CreatableReactSelectStyledProps<T, isMulti>["component"] = React.useMemo(() => {
            if (selectProps?.isFloatingLabel) {
                return {
                    ValueContainer: FloatingLabelValueContainer as unknown as React.FC<ValueContainerProps<T, isMulti>>,
                };
            }
        }, [selectProps?.isFloatingLabel]);

        return (
            <CreatableReactSelect
                ref={ref}
                styles={{ ...customStyles, ...styles }}
                components={reactSelectComponent}
                {...selectProps}
            />
        );
    }
);

export default CreatableReactSelectStyled as <T extends OptionTypeBase, isMulti extends boolean = false>(
    props: CreatableReactSelectStyledProps<T, isMulti> & { ref?: CreatableReactSelectRef<T, isMulti> }
) => JSX.Element;
