import { Contact, StringObject, WAIMessageLocation, WAIMessageMedia, WAIMessageText, YupSchema } from "app/types";
import { WATemplate, WATemplateMessageFormatType } from "app/types/message/whatsapp-template";
import {
    HeaderValues,
    WAITemplateMessage,
    WAITemplateMessage as WATemplateMessage,
    WATemplateMessageButton,
    WATemplateMessageButtonType,
    WATemplateMessageButtonValue,
    WATemplateMessageButtonValueParameters,
    WATemplateMessageCard,
    WATemplateMessageCardButton,
    WATemplateMessageCardButtonValue,
    WATemplateMessageCardHeader,
    WATemplateMessageHeader,
    WATemplateMessageHeaderType,
} from "app/types/message/whatsapp-template-message";
import { FILE_URL_REGEX } from "app/utils/validation";
import * as yup from "yup";
import {
    FormBodyValues,
    TemplateMessageCardFormValues,
    TemplateMessageFormValues,
    WATemplateValuesWithReplyConfig,
} from "./index";
import { AllowedMediaFormat, getAllowedMediaFormat, getMustacheVariables } from "../../utilities/utils";

interface EditFormAttribs {
    formValues: TemplateMessageFormValues;
    allowedHeaderMediaFormat: AllowedMediaFormat | null;
}

export const deSanitizeBodyValuesToFormValues = (
    bodyValues: Record<string, any> | undefined
): FormBodyValues[] | undefined => {
    if (!bodyValues) return bodyValues;
    return Object.entries(bodyValues).map(([key, value]) => ({ variable: key, value }));
};

export const sanitizeFormValuesToBodyValues = (bodyValues?: FormBodyValues[]): StringObject | undefined => {
    return bodyValues?.reduce((acc, bv) => ({ ...acc, [bv.variable]: bv.value }), {});
};

export const sanitizeFormValuesToWATemplateValues = (
    values: TemplateMessageFormValues
): WATemplateValuesWithReplyConfig => {
    const { buttonValues, bodyValues, cardValues, headerValues, ...rest } = values;
    const formValues: WATemplateValuesWithReplyConfig = {
        ...rest,
        ...(buttonValues ? { buttonValues: buttonValues.filter((b) => Boolean(b.parameters[b.parameters.type])) } : {}),
        ...(Boolean(headerValues?.mediaUrl) ? { headerValues } : {}),
        ...(bodyValues ? { bodyValues: sanitizeFormValuesToBodyValues(bodyValues) } : {}),
        ...(cardValues
            ? {
                  cardValues: cardValues.map(({ bodyValues, buttonValues, headerValues }, index) => ({
                      index,
                      ...(bodyValues ? { bodyValues: sanitizeFormValuesToBodyValues(bodyValues) } : {}),
                      ...(Boolean(headerValues?.mediaUrl) ? { headerValues } : {}),
                      ...(buttonValues
                          ? { buttonValues: buttonValues.filter((b) => Boolean(b.parameters[b.parameters.type])) }
                          : {}),
                  })),
              }
            : {}),
    };
    return formValues;
};

const getBodyValues = (body: string, mapContactWithVar: boolean, contact?: Contact): FormBodyValues[] => {
    const variables = getMustacheVariables(body);
    const bodyValues: FormBodyValues[] = [];
    if (mapContactWithVar && contact) {
        const contactMap = new Map(Object.entries(contact));
        variables.forEach((variable) => {
            const value = contactMap.get(variable);
            if (Array.isArray(value)) bodyValues?.push({ variable, value: value?.[0] ?? "" });
            else bodyValues?.push({ variable, value: value ?? "" });
        });
    } else {
        variables.forEach((variable) => {
            bodyValues?.push({ variable, value: "" });
        });
    }

    return bodyValues;
};

const getHeaderValues = (headers: WATemplateMessageHeader): HeaderValues => {
    const mediaFile = headers[headers.type] as WAIMessageMedia;
    return { mediaUrl: mediaFile?.path, mediaName: mediaFile?.filename };
};

const getButtonValues = (buttons: WATemplateMessageButton[]): WATemplateMessageButtonValue[] => {
    return buttons
        .map((m, i) => {
            if (m.type === "url" && m.url) {
                const variables = getMustacheVariables(m.url);
                const hasVariables = variables.length > 0;

                if (!hasVariables) return null;

                return {
                    ...m,
                    index: i,
                    sub_type: "url",
                    parameters: { type: "text", text: "" },
                };
            }

            if (m.type === "quick_reply") {
                return {
                    index: i,
                    sub_type: "quick_reply",
                    parameters: { type: "payload", payload: "" },
                };
            }

            if (m.type === "flow") {
                return {
                    index: i,
                    sub_type: "flow",
                    parameters: { type: "action", action: { flow_token: "" } },
                };
            }

            return null;
        })
        .filter(Boolean) as WATemplateMessageButtonValue[];
};

const getCardButtonValues = (
    buttons: WATemplateMessageCardButton[],
    includeSampleCardValue?: boolean
): WATemplateMessageCardButtonValue[] => {
    return buttons
        .map((m, i) => {
            if (m.type === "url" && m.url) {
                const variables = getMustacheVariables(m.url);
                const hasVariables = variables.length > 0;

                if (!hasVariables) return null;

                return {
                    ...m,
                    index: i,
                    sub_type: "url",
                    parameters: { type: "text", text: includeSampleCardValue ? m.text : "" },
                };
            }

            if (m.type === "quick_reply") {
                return {
                    index: i,
                    sub_type: "quick_reply",
                    parameters: { type: "payload", payload: includeSampleCardValue ? m.text : "" },
                };
            }

            return null;
        })
        .filter(Boolean) as WATemplateMessageCardButtonValue[];
};

export const deSanitizeWAITemplateToEditForm = ({
    template,
    contact,
    mapContactWithVar = false,
    getMediaFormat = true,
    // copy as api needs sample value
    includeSampleCardValue = false,
}: {
    template: WATemplateMessage;
    mapContactWithVar?: boolean;
    contact?: Contact;
    getMediaFormat?: boolean;
    includeSampleCardValue?: boolean;
}): EditFormAttribs => {
    const formValues: TemplateMessageFormValues = {
        bodyValues: deSanitizeBodyValuesToFormValues(template?.bodyValues),
        headerValues: template?.headerValues,
        buttonValues: template?.buttonValues,
        cardValues: template?.cardValues?.map(({ bodyValues, buttonValues, headerValues }, index) => ({
            index,
            headerValues,
            ...(bodyValues ? { bodyValues: deSanitizeBodyValuesToFormValues(bodyValues) } : {}),
            ...(buttonValues ? buttonValues : {}),
        })),
    };
    let allowedHeaderMediaFormat: AllowedMediaFormat | null = null;

    if (getMediaFormat) {
        if (template?.header) {
            allowedHeaderMediaFormat = getAllowedMediaFormat(
                template.header.type.toUpperCase() as WATemplateMessageFormatType
            );
        } else if (template.cards && Boolean(template.cards.length)) {
            allowedHeaderMediaFormat = getAllowedMediaFormat(
                template.cards[0].header.type.toUpperCase() as WATemplateMessageFormatType
            );
        }
    }

    if (template?.body && !template?.bodyValues)
        formValues.bodyValues = getBodyValues(template.body, mapContactWithVar, contact);

    if (template?.header && !template?.headerValues && ["image", "video", "document"].includes(template.header.type))
        formValues.headerValues = getHeaderValues(template.header);

    if (template.buttons && !template?.buttonValues) formValues.buttonValues = getButtonValues(template.buttons);

    if (template.cards && !template.cardValues)
        formValues.cardValues = template.cards.map((card, index) => {
            const cardFormValues: TemplateMessageCardFormValues = {
                index,
                buttonValues: getCardButtonValues(card.buttons, includeSampleCardValue),
                headerValues: getHeaderValues(card.header as WATemplateMessageHeader),
                bodyValues: getBodyValues(card.body, mapContactWithVar, contact),
            };

            return cardFormValues;
        });

    return { formValues, allowedHeaderMediaFormat };
};

const ParameterSchemaUrl = yup.object().shape<YupSchema<WATemplateMessageButtonValueParameters>>({
    text: yup.mixed().when("type", {
        is: "text",
        then: yup.string().trim().required("Value is required"),
    }),
    payload: yup.mixed().optional(),
    action: yup.mixed().optional(),
    type: yup.string().required(),
});

const ParameterSchemaQuickReply = yup.object().shape<YupSchema<WATemplateMessageButtonValueParameters>>({
    text: yup.mixed().optional(),
    payload: yup.mixed().when("type", {
        is: "payload",
        then: yup.string().trim().optional(),
    }),
    action: yup.mixed().optional(),
    type: yup.string().required(),
});

const ParameterSchemaFlow = yup.object().shape<YupSchema<WATemplateMessageButtonValueParameters>>({
    text: yup.mixed().optional(),
    payload: yup.mixed().optional(),
    action: yup.mixed().when("type", {
        is: "action",
        then: yup.object().nullable().optional(),
        otherwise: yup.object().nullable().optional(),
    }),
    type: yup.string().required(),
});

const buttonSchema = yup.object().shape<YupSchema<WATemplateMessageButtonValue>>({
    sub_type: yup.string().trim().oneOf(["url", "quick_reply", "flow"]),
    index: yup.number().optional(),
    parameters: yup
        .mixed()
        .when("sub_type", {
            is: "url",
            then: ParameterSchemaUrl,
        })
        .when("sub_type", {
            is: "quick_reply",
            then: ParameterSchemaQuickReply,
        })
        .when("sub_type", {
            is: "flow",
            then: ParameterSchemaFlow,
        }),
});

const formBodyValuesSchema = yup.array().of(
    yup.object().shape<YupSchema<FormBodyValues>>({
        variable: yup.string().required("Value is required"),
        value: yup.string().required("Value is required"),
    })
);

const mediaUrlSchema = yup
    .string()
    .required("MediaUrl is required")
    .test("validLink", "Invalid media url", (v) => {
        if (!v) return true;
        const hasMustachedVariable = getMustacheVariables(v).length > 0;
        if (hasMustachedVariable) return true;
        return FILE_URL_REGEX.test(v);
    });

export const templateEditFormSchema = (): YupSchema<TemplateMessageFormValues> => {
    return {
        bodyValues: formBodyValuesSchema,
        headerValues: yup.lazy((value) => {
            if (value === undefined) {
                return yup.mixed().nullable();
            }
            return yup.object().shape({
                mediaName: yup.string().optional(),
                mediaUrl: mediaUrlSchema,
            });
        }) as unknown as yup.AnySchema,
        buttonValues: yup.array().of(buttonSchema),
        cardValues: yup.array().of(
            yup.object().shape<YupSchema<TemplateMessageCardFormValues>>({
                index: yup.number().optional(),
                bodyValues: formBodyValuesSchema,
                headerValues: yup.object().shape({
                    mediaName: yup.string().optional(),
                    mediaUrl: mediaUrlSchema,
                }),
                buttonValues: yup.array().of(buttonSchema),
            })
        ),
        respectiveAssign: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup.string(),
                type: yup.string(),
            })
            .optional(),
        overrideAssignment: yup.boolean().optional(),
    };
};

export const whatsappTemplateToMessage = (template: WATemplate): WAITemplateMessage => {
    const headerComponent = template.components.find((c) => c.type === "HEADER");
    const headerType = headerComponent?.format?.toLowerCase() as WATemplateMessageHeaderType;
    const isHeaderDocs = headerType === "document";
    const isHeaderText = headerType === "text";
    const isHeaderLocation = headerType === "location";

    const bodyText = template.components.find((c) => c.type === "BODY")?.text;
    const footerText = template.components.find((c) => c.type === "FOOTER")?.text;
    const buttonsItems = template.components.find((c) => c.type === "BUTTONS")?.buttons;
    const cardsItems = template.components.find((c) => c.type === "CAROUSEL")?.cards;

    const buttons: WATemplateMessageButton[] = (buttonsItems ?? []).map((btn) => {
        let type = btn.type as WATemplateMessageButtonType;
        if (type !== "CATALOG") {
            type = btn.type.toLowerCase() as WATemplateMessageButtonType;
        }
        const { phone_number, url, text } = btn;
        return {
            type,
            // eslint-disable-next-line camelcase
            phone_number,
            url,
            text,
        };
    });

    const cards: WATemplateMessageCard[] | undefined = cardsItems?.map((crd) => {
        const cardBodyText = crd.components.find((c) => c.type === "BODY")?.text;

        const cardButtonItems = crd.components.find((c) => c.type === "BUTTONS")?.buttons;
        const cardButtons: WATemplateMessageCard["buttons"] = cardButtonItems?.map((btn) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { example, type: tp, ...rest } = btn;
            const type = tp.toLowerCase() as WATemplateMessageCardButton["type"];
            return { type, ...rest };
        }) as unknown as WATemplateMessageCard["buttons"];

        let cardHeader: WATemplateMessageCard["header"] | undefined;
        const { format, filePath, mediaId } = crd.components.find((c) => c.type === "HEADER") ?? {};
        const cardHeaderType = format?.toLowerCase() as WATemplateMessageCardHeader["type"] | undefined;
        if (cardHeaderType && filePath) {
            const media: WAIMessageMedia = { id: mediaId, path: filePath, mimeType: "" };
            cardHeader = { type: cardHeaderType, [cardHeaderType]: media };
        }

        return {
            body: cardBodyText ?? "",
            buttons: cardButtons,
            header: cardHeader,
        };
    }) as WATemplateMessageCard[];

    let header: WATemplateMessageHeader | undefined;

    if (headerComponent && headerType) {
        let media: WAIMessageMedia | null = null;
        let text: WAIMessageText | null = null;
        if (headerComponent.filePath) {
            media = {
                id: headerComponent.mediaId,
                path: headerComponent.filePath,
                mimeType: "",
                ...(isHeaderDocs ? { filename: headerComponent?.fileMetaData?.fileName } : {}),
            };
        }
        if (headerComponent.text) {
            text = { body: headerComponent.text };
        }

        let headerValue: WAIMessageMedia | WAIMessageText | WAIMessageLocation | null = media;

        if (isHeaderText) {
            headerValue = text;
        }

        if (isHeaderLocation) {
            headerValue = headerComponent.location as WAIMessageLocation;
        }
        header = {
            type: headerType,
            [headerType]: headerValue,
        };
    }

    const templateMessage: WAITemplateMessage = {
        templateId: template.id as string,
        template,
        ...(header ? { header } : {}),
        body: bodyText ?? "",
        ...(footerText ? { footer: footerText } : {}),
        buttons,
        cards,
    };

    return templateMessage;
};
