import { useState } from "react";

export interface Options {
    storeInitialValue?: boolean;
}

export default function useLocalStorage<T>(key: string, initialValue: T, { storeInitialValue = false }: Options) {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            if (!item) {
                if (storeInitialValue) {
                    window.localStorage.setItem(key, JSON.stringify(initialValue));
                }
                return initialValue;
            }
            return JSON.parse(item);
        } catch (error) {
            console.log(`error while getting localStorage ${key}::${(error as Error)?.message}`);
            return initialValue;
        }
    });
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.log(`error while getting setValue ${key}::${(error as Error)?.message}`);
        }
    };
    return [storedValue, setValue] as const;
}

export const setWithExpiry = (key: string, value: number | string, ttl: number): any => {
    const now = new Date();

    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string): number | null => {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
};
