import { WindowObjWithFreshDesk } from "./types";

export const hideFreshWorkWidget = (): void => {
    if ((window as WindowObjWithFreshDesk).FreshworksWidget) {
        (window as WindowObjWithFreshDesk).FreshworksWidget("hide");
    }
};

export const openFreshWorksTicketForm = (): void => {
    if ((window as WindowObjWithFreshDesk).FreshworksWidget) {
        (window as WindowObjWithFreshDesk).FreshworksWidget("open", "ticketForm");
    }
};

export const removeSandboxWidget = (): void => {
    const elm = document.getElementsByClassName("chatty-widget");
    const arrayLength = elm?.length ?? 0;
    for (let i = 0; i < arrayLength; i++) {
        elm[i]?.remove?.();
    }
};

export const openZohoWidget = (): void => {
    if ((window as any).ZohoHCAsap) {
        (window as any).ZohoHCAsap.Action?.("open", { tab: "TICKETS" });
    }
};

export const closeZohoWidget = (): void => {
    if ((window as any).ZohoHCAsap) {
        (window as any).ZohoHCAsap.Action?.("close");
    }
};

// Wrapper method for support ticket open
export const openSupportTicketWidget = (): void => {
    openZohoWidget();
};

// Wrapper method for support ticket close
export const closeSupportTicketWidget = (): void => {
    closeZohoWidget();
};
