import { FormControl, Input, FormLabel, InputProps, FormControlProps } from "@chakra-ui/react";
import React from "react";

export interface FloatInputProps extends InputProps {
    label: string;
    formControlProps?: Omit<FormControlProps, "variant">;
}
// eslint-disable-next-line react/display-name
const FloatInput = React.forwardRef<HTMLInputElement, FloatInputProps>((props, ref) => {
    const { label, formControlProps = {}, placeholder, ...inputProps } = props;
    return (
        <FormControl variant={"floating"} {...formControlProps}>
            <Input {...inputProps} ref={ref} placeholder=" " />
            <FormLabel noOfLines={1}>{label}</FormLabel>
        </FormControl>
    );
});

export default FloatInput;
