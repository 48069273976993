import {
    AlertDialog as ChakraAlertDialog,
    AlertDialogProps as ChakraAlertDialogProps,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    ButtonProps,
    ModalBodyProps,
    ModalFooterProps,
} from "@chakra-ui/react";
import React from "react";

export interface AlertDialogProps extends Partial<ChakraAlertDialogProps> {
    body: string | React.ReactElement;
    isOpen: boolean;
    title?: string | React.ReactElement;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    primaryButtonProps?: ButtonProps;
    secondaryButtonProps?: ButtonProps;
    bodyProps?: ModalBodyProps;
    footerProps?: ModalFooterProps;
    customFooter?: JSX.Element;
    onOk?: () => void | Promise<any>;
    onCancel?: () => void;
    onClose?: () => void;
}

const AlertDialog: React.FC<AlertDialogProps> = (props) => {
    const [isLoading, setLoading] = React.useState(false);

    const {
        title,
        body,
        primaryButtonText,
        secondaryButtonText,
        primaryButtonProps = {},
        secondaryButtonProps = {},
        bodyProps = {},
        footerProps = {},
        customFooter,
        isOpen,
        onClose,
        ...alertDialogProps
    } = props;

    const cancelRef = React.useRef<HTMLButtonElement | null>(null);

    const onOk = async () => {
        if (isLoading) return;
        setLoading(true);
        try {
            await props.onOk?.();
        } catch {
            setLoading(false);
            return;
        }
        setLoading(false);
        onClose?.();
    };

    const onCancel = async () => {
        if (isLoading) return;
        props?.onCancel?.();
        onClose?.();
    };

    return (
        <>
            <ChakraAlertDialog
                {...alertDialogProps}
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCancel}
                closeOnEsc={!isLoading}
                closeOnOverlayClick={!isLoading}
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    {onClose && <AlertDialogCloseButton />}
                    {!!title && (
                        <AlertDialogHeader fontSize="lg" fontWeight="semibold">
                            {title}
                        </AlertDialogHeader>
                    )}
                    <AlertDialogBody {...bodyProps}>{body}</AlertDialogBody>
                    <AlertDialogFooter {...footerProps}>
                        {customFooter ?? (
                            <>
                                {secondaryButtonText && (
                                    <Button
                                        ref={cancelRef}
                                        fontSize="sm"
                                        size={"medium"}
                                        fontWeight="normal"
                                        variant="outline"
                                        colorScheme="gray"
                                        {...secondaryButtonProps}
                                        onClick={onCancel}
                                        isDisabled={isLoading}
                                        borderRadius="6px"
                                        data-cy={`secondary-button-${secondaryButtonText}`}
                                    >
                                        <span>{secondaryButtonText}</span>
                                    </Button>
                                )}
                                {primaryButtonText && (
                                    <Button
                                        ml={3}
                                        colorScheme="brand"
                                        fontSize="sm"
                                        size={"medium"}
                                        fontWeight="normal"
                                        {...primaryButtonProps}
                                        onClick={onOk}
                                        isLoading={isLoading}
                                        borderRadius="6px"
                                        data-cy={`primary-button-${primaryButtonText}`}
                                    >
                                        <span>{primaryButtonText}</span>
                                    </Button>
                                )}
                            </>
                        )}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </ChakraAlertDialog>
        </>
    );
};

export default AlertDialog;
