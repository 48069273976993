import React from "react";
import { OptionTypeBase, PlaceholderProps, ValueContainerProps, components } from "react-select";
import { Box } from "@chakra-ui/react";

const { ValueContainer, Placeholder } = components;

const FloatingLabelValueContainer: React.FC<
    ValueContainerProps<OptionTypeBase, false> & PlaceholderProps<OptionTypeBase, false>
> = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, (child) => {
                return (
                    <Box zIndex={3} display="inline-block" mt="1">
                        {child ? child : null}
                    </Box>
                );
            })}
        </ValueContainer>
    );
};
export default FloatingLabelValueContainer;
