import { Button, Grid, HStack, Menu, MenuButton, MenuItem, MenuList, Spacer, Text, VStack } from "@chakra-ui/react";
import CanIReadPII from "app/components/CanIReadPII";
import AnimatedLoadingState from "app/components/GBLoadingState";
import ChannelSelect from "app/components/StylesSelect/ChannelSelect";
import { useChannelsList } from "app/fetchHooks/channel";
import { usePostPrePaidExport } from "app/fetchHooks/prepaid-wallet";
import { LabelValue } from "app/types";
import { ReactSelect } from "app/utils/formUtils";
import dayjs from "dayjs";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { Styles } from "react-select/src/styles";
import ConversationTable from "./ConversationTable";

interface TransactionConversationTableProps {
    accountId: string;
    walletId: string;
    currency: string;
}

export interface MonthValue {
    from: string;
    to: string;
}

interface LabelValueWithDesc extends LabelValue {
    description: string;
}

export const formatAmount = (amount: number, decimalPlaces?: number): string => {
    return (amount / 1000).toFixed(decimalPlaces || 4);
};

const TransactionConversationTable: React.FC<TransactionConversationTableProps> = (props) => {
    const { accountId, walletId, currency } = props;
    const history = useHistory();
    const { data: channelData, isLoading } = useChannelsList({
        accountId,
        channelType: "whatsapp",
    });

    const { mutate } = usePostPrePaidExport({ accountId, walletId });

    const options: LabelValueWithDesc[] = React.useMemo(() => {
        if (!channelData) return [];
        return channelData?.map((ch) => ({
            label: (ch?.whatsapp?.whatsappName || ch?.whatsapp?.whatsappNumber) ?? "",
            value: ch?.id ?? "",
            description: ch?.whatsapp?.whatsappNumber ?? "",
        }));
    }, [channelData]);

    const getPreviousMonths = (startIndex: number, length: number): LabelValue<MonthValue>[] => {
        const monthDetails = [];
        for (let i = startIndex; i <= length; i++) {
            monthDetails.push({
                label: dayjs().subtract(i, "month").format("MMMM"),
                value: {
                    from: dayjs().subtract(i, "M").startOf("M").toDate().toISOString(),
                    to: dayjs().subtract(i, "M").endOf("M").toDate().toISOString(),
                },
            });
        }
        return monthDetails;
    };

    const monthOptions: LabelValue<MonthValue>[] = React.useMemo(() => {
        return [
            {
                label: "This month",
                value: {
                    from: dayjs().startOf("M").toDate().toISOString(),
                    to: dayjs().endOf("M").toDate().toISOString(),
                },
            },
            {
                label: "Last Month",
                value: {
                    from: dayjs().subtract(1, "M").startOf("M").toDate().toISOString(),
                    to: dayjs().subtract(1, "M").endOf("M").toDate().toISOString(),
                },
            },
            ...getPreviousMonths(2, 5),
        ];
    }, []);

    const selectStyle: Partial<Styles<any, false>> = {
        control: (base) => ({
            ...base,
            width: "152px",
            minHeight: "32px",
            height: "32px",
            borderRadius: "6px",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        container: (base) => ({
            ...base,
            height: "32px",
        }),
        placeholder: (base) => ({
            ...base,
            fontSize: "13px",
            color: "#1A202C",
            paddingBottom: "4px",
        }),
        option: (base) => ({
            ...base,
            fontSize: "14px",
            padding: "5px",
            height: "30px",
        }),
        menuList: (base) => ({
            ...base,
            width: "152px",
            paddingLef: "10px",
        }),
        menu: (base) => ({
            ...base,
            width: "152px",
            borderRadius: "10px",
        }),
        singleValue: (base) => ({
            ...base,
            fontSize: "14px",
            paddingBottom: "12px",
        }),
        indicatorsContainer: (base) => ({
            ...base,
            paddingBottom: "6px",
        }),
    };

    const { control, watch } = useForm<{ channel: LabelValue; month: LabelValue<MonthValue> }>({
        defaultValues: { channel: options[0], month: monthOptions[0] },
    });
    const channelId = (watch("channel") ?? options[0])?.value;
    const monthValues = (watch("month") ?? monthOptions[0])?.value;

    const handleExport = async () => {
        await mutate({
            type: "month",
            startDate: monthValues.from,
            endDate: monthValues.to,
        });
        history.push("/message-credits/export");
    };

    if (isLoading) {
        return (
            <Grid placeItems="center" bg="white" w="full" h="full" maxH="full" maxW="full">
                <AnimatedLoadingState spinnerSize={40} title="Loading..." />
            </Grid>
        );
    }

    return (
        <VStack>
            <HStack align="center" w="full" justifyContent="space-between">
                <HStack alignItems="center">
                    <Text color="gray.500" fontSize="sm" whiteSpace="nowrap">
                        Channel Name
                    </Text>
                    <Controller
                        name="channel"
                        control={control}
                        render={(props) => {
                            return (
                                <ChannelSelect
                                    {...props}
                                    placeholder="select channel"
                                    options={options}
                                    isSearchable={false}
                                    defaultValue={options[0]}
                                />
                            );
                        }}
                    />

                    <Text color="gray.500" fontSize="sm" whiteSpace="nowrap">
                        Select by Month
                    </Text>
                    <ReactSelect
                        name="month"
                        placeholder="Select month"
                        options={monthOptions}
                        control={control}
                        styles={selectStyle}
                        isSearchable={false}
                        defaultValue={monthOptions[0]}
                        mb={0}
                    />
                </HStack>
                <Spacer />
                <CanIReadPII>
                    <Menu>
                        <MenuButton
                            as={Button}
                            fontSize="14px"
                            size="sm"
                            variant="outline"
                            color="gray.700"
                            fontWeight="normal"
                            leftIcon={<AiOutlineCloudDownload size="20" />}
                            borderRadius="6px"
                        >
                            Export Summary
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => history.push("/message-credits/export")}>Active Exports</MenuItem>
                            <MenuItem onClick={handleExport}>Start Export</MenuItem>
                        </MenuList>
                    </Menu>
                </CanIReadPII>
            </HStack>
            <ConversationTable
                accountId={accountId}
                walletId={walletId}
                currency={currency}
                channelId={channelId}
                monthValues={monthValues}
            />
        </VStack>
    );
};

export default TransactionConversationTable;
