import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    ButtonGroup,
    FormLabel,
    HStack,
    Icon,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tag,
    TagCloseButton,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledReactSelectStyled from "app/components/CustomizedReactSelect/ControlledReactSelectStyled";
import AnimatedLoadingState from "app/components/GBLoadingState";
import GBTooltip from "app/components/GBTooltip";
import GoBackIcon from "app/components/GoBackIcon";
import {
    CalendlyWorkFlowInput,
    useCreateCalendlyWorkFlow,
    useGetCalendlyEvents,
    useGetCalendlyVariables,
    useUpdateCalendlyWorkFlow,
} from "app/fetchHooks/integration";
import { UsePostCreateTemplate } from "app/fetchHooks/message-gallery-template";
import { useWATemplateMessage } from "app/fetchHooks/whatsapp-template";
import { WATemplate } from "app/types";
import { CalendlyWorkflow, Integration } from "app/types/integration";
import { IntegrationField } from "app/types/integration/integrationField";
import { useQueryParam } from "app/utils/history";
import { useAccountId } from "app/utils/react-helpers";
import capitalize from "lodash/capitalize";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineEdit } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { components, OptionProps } from "react-select";
import { ActionInput } from "../ActionInput";
import { sanitizeBodyFormValues } from "../integrationUtils";
import MappingIntegrationCommonTemplate, { BodyFormValues } from "../MappingIntegrationTemplateCommon";
import SelectTemplateTabs from "../SelectTemplateTabs";
import {
    CalendlyCreateWorkFlowFormData,
    calendlyFormData,
    calendlySchema,
    deSanitizeCalendlyData,
    durationOptions,
} from "./calendlyUtils";

interface CalendlyWorkFlowsProps {
    data: Integration | undefined;
    isLoading: boolean;
}

const remindType = new Map<CalendlyWorkflow["useCase"], CalendlyWorkflow["remind"]>([
    ["confirmation", "now"],
    ["followup", "after"],
    ["reminder", "before"],
]);

const CalendlyWorkFlows: FC<CalendlyWorkFlowsProps> = ({ data, isLoading }) => {
    const [pickTemplate, setPickTemplate] = React.useState<WATemplate | null>(null);
    const [templateValues, setTemplateValues] = React.useState<BodyFormValues | null>(null);
    const param = useQueryParam();
    const selectedUseCase = param.get("usecase") as CalendlyWorkflow["useCase"];
    const workFlowId = param.get("workflowId") ?? undefined;
    const history = useHistory();
    const { isOpen: pickTemplateIsOpen, onClose: pickTemplateOnClose, onOpen: pickTemplateOpen } = useDisclosure();
    const { isOpen: templateIsOpen, onOpen: templateOnOpen, onClose: templateOnClose } = useDisclosure();
    const selectedTemplateValueAvailable = Object.keys(templateValues ?? {}).length > 0;
    const accountId = useAccountId();
    const channelId = data?.calendly?.channelId ?? "";
    const isEnableUpdateButton = Object.keys(templateValues ?? {})?.length > 0;
    const integrationId = data?.id ?? "";

    const currentWorkFlow = React.useMemo(() => {
        return data?.calendly?.workflows?.find((c) => c.id === workFlowId);
    }, [data?.calendly?.workflows, workFlowId]);

    const templateId = currentWorkFlow?.templateId ?? "";

    const { data: WATemplate } = useWATemplateMessage({ accountId, templateId });

    const bodyValuesFromResponse: BodyFormValues = React.useMemo(() => {
        return {
            bodyValues: currentWorkFlow?.templateValues?.bodyValues ?? {},
            buttonValues: currentWorkFlow?.templateValues?.buttonValues ?? [],
            headerValues: currentWorkFlow?.templateValues?.headerValues ?? {},
            recipientName: currentWorkFlow?.calendly?.recipient?.recipientName ?? "",
            recipientNumber: currentWorkFlow?.calendly?.recipient?.recipientNumber ?? "",
        };
    }, [currentWorkFlow]);

    React.useEffect(() => {
        if (currentWorkFlow) {
            setPickTemplate(WATemplate as WATemplate);
            setTemplateValues(bodyValuesFromResponse);
        }
    }, [WATemplate, bodyValuesFromResponse, currentWorkFlow]);

    const { data: eventData, isLoading: optionLoading } = useGetCalendlyEvents({ accountId, integrationId });

    const eventOption = React.useMemo(() => {
        return (
            eventData?.collection?.map((c) => {
                return {
                    label: c.name,
                    value: c.uri,
                    ...c,
                };
            }) ?? []
        );
    }, [eventData?.collection]);

    const { mutate: createWorkFlow, isLoading: createLoading } = useCreateCalendlyWorkFlow({
        accountId,
        integrationId,
        onSuccess: () => {
            setPickTemplate(null);
            setTemplateValues(null);
            history.push({
                pathname: history.location.pathname,
                search: `?tab=2&usecase=${selectedUseCase}`,
            });
        },
    });

    const { mutate: updateWorkFlow, isLoading: updateLoading } = useUpdateCalendlyWorkFlow({
        accountId,
        integrationId,
        onSuccess: () => {
            setPickTemplate(null);
            setTemplateValues(null);
            history.push({
                pathname: history.location.pathname,
                search: `?tab=2&usecase=${selectedUseCase}`,
            });
        },
    });

    const createWorkFlowLoading = updateLoading || createLoading;

    const { mutateAsync: createTemplate } = UsePostCreateTemplate({
        accountId,
        showSuccessMessage: false,
    });

    const defaultValues: CalendlyCreateWorkFlowFormData = React.useMemo(() => {
        let result: CalendlyCreateWorkFlowFormData;

        if (currentWorkFlow && eventOption) {
            result = deSanitizeCalendlyData(currentWorkFlow, eventOption);
        } else {
            result = calendlyFormData;
        }
        return result;
    }, [currentWorkFlow, eventOption]);

    React.useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    const isNotConfirmationUseCase = selectedUseCase != "confirmation";

    const { control, setValue, handleSubmit, watch, errors, reset } = useForm<CalendlyCreateWorkFlowFormData>({
        defaultValues,
        resolver: yupResolver<CalendlyCreateWorkFlowFormData>(calendlySchema(isNotConfirmationUseCase)),
    });

    const handleCreateTemplate = async (id: string) => {
        try {
            const { template } = await createTemplate({
                accountId,
                channelId,
                waTemplateGalleryId: id,
            });
            setPickTemplate(template);
            pickTemplateOnClose();
            templateOnOpen();
        } catch (e) {}
    };

    const handleTemplateClose = () => {
        setPickTemplate(null);
        setTemplateValues(null);
    };

    const handleTemplate = (data: WATemplate) => {
        setPickTemplate(data);
        pickTemplateOnClose();
        templateOnOpen();
    };

    const handleVariables = async (data: BodyFormValues) => {
        const templateValues = await sanitizeBodyFormValues(data);
        setTemplateValues({
            ...templateValues,
            recipientName: data.recipientName,
            recipientNumber: data.recipientNumber,
        });
        templateOnClose();
    };
    const eventUri = watch("eventTypeUri")?.value;
    const { data: variablesList, isLoading: isVariableLoading } = useGetCalendlyVariables({
        accountId,
        integrationId,
        eventUri,
        enabled: Boolean(eventUri),
    });

    const { data: phoneVariablesList, isLoading: isPhoneVariableLoading } = useGetCalendlyVariables({
        accountId,
        integrationId,
        eventUri,
        enabled: Boolean(!!variablesList),
        isPhone: true,
    });

    const sanitizeCalendlyData = (
        data: CalendlyCreateWorkFlowFormData,
        selectedUseCase: CalendlyWorkflow["useCase"]
    ): CalendlyWorkFlowInput => {
        const { duration, eventTypeUri, actions } = data;
        const time = duration?.value as number;
        const remind = remindType.get(selectedUseCase) as CalendlyWorkflow["remind"];

        const actionsInput = actions?.map((a) => {
            if (!a.actionValues?.operation) {
                return {
                    actionItem: a.actionItem,
                    actionValues: { ...a.actionValues, operation: (a.actionItem.operations as any)?.[0] },
                };
            }
            return a;
        });

        return {
            isActive: true,
            workflowActions: actionsInput ?? [],
            templateId: pickTemplate?.id ?? "",
            templateValues: {
                bodyValues: templateValues?.bodyValues ?? {},
                buttonValues: templateValues?.buttonValues ?? [],
                headerValues: templateValues?.headerValues ?? {},
            },
            type: "calendly",
            calendly: {
                duration: time,
                events: "invitee_created",
                eventTypeUri: [{ label: eventTypeUri.label, value: eventTypeUri.value }],
                remind,
                useCase: selectedUseCase,
                recipient: {
                    recipientName: templateValues?.recipientName ?? "",
                    recipientNumber: templateValues?.recipientNumber ?? "",
                },
            },
        };
    };

    const previousValues: BodyFormValues = {
        bodyValues: templateValues?.bodyValues,
        buttonValues: templateValues?.buttonValues,
        headerValues: templateValues?.headerValues,
        recipientName: templateValues?.recipientName ?? "{{calendly_inviteeName}}",
        recipientNumber: templateValues?.recipientNumber,
    };

    const isPreviousValueAvailable = Object.keys(previousValues).length > 0;

    const submitWorkFlow = (data: CalendlyCreateWorkFlowFormData) => {
        const formattedData = sanitizeCalendlyData(data, selectedUseCase);
        if (Boolean(workFlowId)) {
            updateWorkFlow({ ...formattedData, id: workFlowId });
        } else {
            createWorkFlow(formattedData);
        }
    };

    const handleReset = () => {
        if (currentWorkFlow && defaultValues) {
            reset(defaultValues);
            setPickTemplate(WATemplate as WATemplate);
            setTemplateValues(bodyValuesFromResponse);
        } else {
            reset(defaultValues);
            setPickTemplate(null);
            setTemplateValues(null);
        }
    };

    const handleBack = () => {
        history.goBack();
    };
    const Option: React.FC<OptionProps<any, false>> = (props) => {
        const { data, isDisabled } = props;
        return (
            <components.Option {...props}>
                <HStack justifyContent="space-between" width="full">
                    <Text fontSize="sm" fontWeight="medium">
                        {data.label}
                    </Text>
                    {isDisabled && (
                        <GBTooltip label="You don't have Phone Number field in this Event ">
                            <InfoOutlineIcon color="gray.500" boxSize={5} />
                        </GBTooltip>
                    )}
                </HStack>
            </components.Option>
        );
    };

    if (isLoading) return <AnimatedLoadingState />;

    return (
        <>
            <VStack
                as="form"
                onSubmit={handleSubmit(submitWorkFlow)}
                alignItems="flex-start"
                width="full"
                spacing={4}
                p={2}
            >
                <HStack width="full">
                    <IconButton
                        aria-label="back-btn"
                        size="sm"
                        bgColor="unset"
                        icon={<GoBackIcon />}
                        onClick={handleBack}
                    />
                    <Text fontSize="lg" fontWeight="bold">
                        {`${capitalize(selectedUseCase)} Workflow`}
                    </Text>
                </HStack>
                <HStack p={4} width="70%">
                    <FormLabel flex="0.4" color="black" width="100%">
                        Select Events
                        <span style={{ color: "red", paddingLeft: "4px" }}>*</span>
                    </FormLabel>
                    <Box flex="0.3">
                        <ControlledReactSelectStyled
                            name="eventTypeUri"
                            control={control}
                            isLoading={optionLoading}
                            getOptionLabel={(v) => v.label}
                            error={errors?.eventTypeUri?.value?.message}
                            options={eventOption}
                            components={{ Option }}
                        />
                    </Box>
                </HStack>
                {isNotConfirmationUseCase && (
                    <HStack p={4} width="70%">
                        <FormLabel flex="0.4" color="black" width="100%">
                            {selectedUseCase === "followup" ? "Follow-up reminder" : "Reminder"}
                            <span style={{ color: "red", paddingLeft: "4px" }}>*</span>
                        </FormLabel>
                        <Box flex="0.3">
                            <ControlledReactSelectStyled
                                error={errors?.duration?.value?.message}
                                name="duration"
                                control={control}
                                options={durationOptions}
                            />
                        </Box>
                    </HStack>
                )}
                <HStack p={4} width="70%">
                    <FormLabel flex="0.4" color="black" width="100%">
                        Send Template Message
                        <span style={{ color: "red", paddingLeft: "4px" }}>*</span>
                    </FormLabel>
                    <HStack flex="0.3">
                        {selectedTemplateValueAvailable ? (
                            <HStack w="full">
                                <Tag bgColor="gray.200">
                                    <GBTooltip label={pickTemplate?.name ?? ""}>
                                        <Text
                                            maxWidth="15ch"
                                            isTruncated
                                            fontWeight="medium"
                                            fontSize="13px"
                                            color="gray.800"
                                        >
                                            {pickTemplate?.name}
                                        </Text>
                                    </GBTooltip>
                                    <TagCloseButton onClick={handleTemplateClose} />
                                </Tag>
                                <IconButton
                                    aria-label="edit-template"
                                    onClick={templateOnOpen}
                                    bgColor="transparent"
                                    icon={<Icon as={AiOutlineEdit} boxSize={4} />}
                                />
                            </HStack>
                        ) : (
                            <Button size="sm" colorScheme="gray" onClick={pickTemplateOpen}>
                                Pick Template
                            </Button>
                        )}
                    </HStack>
                </HStack>
                {selectedUseCase === "confirmation" ? (
                    <HStack width="70%">
                        <FormLabel flex="0.4" pl={3} color="black" width="100%">
                            Action
                        </FormLabel>
                        <HStack flex="0.6" w="full">
                            <ActionInput control={control} setValue={setValue} />
                        </HStack>
                    </HStack>
                ) : null}
                <HStack width="full" justify="center">
                    <ButtonGroup>
                        <Button size="sm" variant="outline" onClick={handleReset} borderRadius="6px">
                            Cancel
                        </Button>
                        <Button
                            size="sm"
                            colorScheme="brand"
                            isDisabled={!isEnableUpdateButton}
                            borderRadius="6px"
                            type="submit"
                            isLoading={createWorkFlowLoading}
                        >
                            <span>{Boolean(workFlowId) ? "Update Workflow" : "Create Workflow"}</span>
                        </Button>
                    </ButtonGroup>
                </HStack>
            </VStack>
            <Modal size="full" isOpen={pickTemplateIsOpen} onClose={pickTemplateOnClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <SelectTemplateTabs
                            CreateTemplateFromGallery={handleCreateTemplate}
                            PickTemplate={handleTemplate}
                            channelId={channelId}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal size="2xl" isOpen={templateIsOpen} onClose={templateOnClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Calendly Template</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <MappingIntegrationCommonTemplate
                            isRecipientDetailsEnabled
                            handleVariables={handleVariables}
                            isLoading={isVariableLoading || isPhoneVariableLoading}
                            templateId={pickTemplate?.id ?? ""}
                            variables={{
                                bodyValues: variablesList as IntegrationField[],
                                buttonValues: variablesList as IntegrationField[],
                                headerValues: variablesList as IntegrationField[],
                                recipientName: variablesList as IntegrationField[],
                                recipientNumber: phoneVariablesList as IntegrationField[],
                            }}
                            previousValue={isPreviousValueAvailable ? previousValues : {}}
                            closeModal={templateOnClose}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CalendlyWorkFlows;
