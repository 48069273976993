import { CSSProperties } from "react";
import { PlaceholderProps, StylesConfig } from "react-select";

export interface GetReactSelectStyleProps<isMulti extends boolean = false> {
    minHeight?: string | number;
    menuHeight?: string | number;
    fontSize?: string;
    menuBgColor?: string;
    containerBgColor?: string;
    placeholderColor?: string;
    width?: string | number;
    isFloatingLabel?: boolean;
    floatingLabelTopPosition?: string;
    floatingLabelFontSize?: string;
    isError?: boolean;
    styles?: StylesConfig<any, isMulti>;
}

export const getReactSelectStyle = <isMulti extends boolean = false>(
    props: GetReactSelectStyleProps
): StylesConfig<any, isMulti> => {
    const {
        fontSize = "14px",
        minHeight = "32px",
        width = "100%",
        menuBgColor,
        menuHeight,
        placeholderColor,
        containerBgColor,
        isFloatingLabel = false,
        floatingLabelFontSize,
        floatingLabelTopPosition,
        isError = false,
        styles = {},
    } = props;

    return {
        ...styles,
        menuList: (...args) => {
            const [base] = args;
            const givenStyle = styles?.menuList?.(...args) ?? {};
            return {
                ...base,
                ...givenStyle,
                maxHeight: menuHeight ?? "250px",
                backgroundColor: menuBgColor ?? "#EDF2F7",
                fontSize,
            };
        },
        menu: (...args) => {
            const [base] = args;
            const givenStyle = styles?.menu?.(...args) ?? {};
            return {
                ...base,
                zIndex: 2,
                width,
                padding: "2px 4px",
                borderRadius: "6px",
            };
        },
        container: (...args) => {
            const [base] = args;
            const givenStyle = styles?.container?.(...args) ?? {};
            return { ...base, ...givenStyle, boxSizing: "border-box", width, minHeight };
        },
        control: (...args) => {
            const [base, state] = args;
            const givenStyle = styles?.control?.(...args) ?? {};
            return {
                ...base,
                ...givenStyle,
                borderWidth: "1px",
                borderColor: isError ? "#E53E3E" : "#E2E8F0",
                boxShadow: isError ? "0 0 0 1px #e53e3e" : "0 1px 2px 0 rgba(0,0,0,0.05)",
                "&:hover": {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    borderColor: isError ? "#E53E3E" : (base as any)["&:hover"].borderColor,
                },
                backgroundColor: containerBgColor ?? "white",
                minHeight,
                height: state.isMulti ? "auto" : minHeight,
                boxSizing: "border-box",
                fontSize,
                width,
            };
        },
        indicatorSeparator: (...args) => {
            const [base] = args;
            const givenStyle = styles?.indicatorSeparator?.(...args) ?? {};
            return {
                ...base,
                ...givenStyle,
                display: "none",
            };
        },
        dropdownIndicator: (...args) => {
            const [base, state] = args;
            const givenStyle = styles?.dropdownIndicator?.(...args) ?? {};
            return {
                ...base,
                ...givenStyle,
                color: state.isDisabled ? "#A0AEC0" : "#2D3748",
            };
        },
        placeholder: (...args) => {
            const [base, state] = args;
            const typeCasted = args as [CSSProperties, PlaceholderProps<any, false>];
            const givenStyle = styles?.placeholder?.(...typeCasted) ?? {};
            const isFloating = state.hasValue || state.selectProps.inputValue;
            if (isFloatingLabel) {
                return {
                    ...base,
                    ...givenStyle,
                    top: isFloating ? floatingLabelTopPosition : "50%",
                    fontSize: isFloating ? floatingLabelFontSize : fontSize,
                    position: "absolute",
                    transition: "top 0.1s, font-size 0.1s",
                    backgroundColor: "white",
                    padding: "0 5px 0 5px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "100%",
                    whiteSpace: "nowrap",
                    zIndex: 0,
                };
            }
            return {
                ...base,
                ...givenStyle,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                paddingLeft: "4px",
                paddingRight: "4px",
                transform: "translateY(-52%)",
                fontSize,
                color: placeholderColor,
            };
        },
        valueContainer: (...args) => {
            const [base] = args;
            const givenStyle = styles?.valueContainer?.(...args) ?? {};
            return {
                ...base,
                ...givenStyle,
                minHeight,
                padding: "0 6px",
                // overflow: "visible",
                zIndex: 0,
            };
        },
        singleValue: (...args) => {
            const [base] = args;
            const givenStyle = styles?.singleValue?.(...args) ?? {};
            return {
                ...base,
                ...givenStyle,
                transform: "translateY(-52%)",
            };
        },
        input: (...args) => {
            const [base] = args;
            const givenStyle = styles?.input?.(...args) ?? {};
            return {
                ...base,
                ...givenStyle,
                margin: "0px",
            };
        },
        indicatorsContainer: (...args) => {
            const [base] = args;
            const givenStyle = styles?.indicatorsContainer?.(...args) ?? {};
            return {
                ...base,
                ...givenStyle,
                height: minHeight,
            };
        },
        option: (...args) => {
            const [base, props] = args;
            const givenStyle = styles?.option?.(...args) ?? {};
            return {
                ...base,
                ...givenStyle,
                width: "100%",
                ...(props.data.__isNew__ ? { backgroundColor: "#0083FF" } : {}),
                ...(props.isSelected ? { backgroundColor: "#99ceff" } : {}),
            };
        },
    };
};
