export default {
    product_name: "Gallabox",
    host_url: "https://app.gallabox.com",
    product_help_guide: {
        docs: "https://docs.gallabox.com/",
        faq: "https://docs.gallabox.com/frequently-asked-questions",
        free_account_chat: "https://wame.pro/gbx-sales",
        premium_account_chat: "https://wa.me/917825810111",
        subscribed_account_chat: "https://wame.pro/supportchannel",
        youtube_playlist_rss_url:
            "https://www.youtube.com/feeds/videos.xml?playlist_id=PLqLBaOO-5zr2ww9ZywJLwlqrP9--IkDSL",
        conversation_pricing: "https://docs.gallabox.com/pricing-and-billing-modules/conversation-pricing",
        free_trial:
            "https://docs.gallabox.com/set-up-gallabox-account/frequently-asked-questions#does-gallabox-provide-a-free-trial",
        connect_own_number:
            "https://docs.gallabox.com/set-up-gallabox-account/pre-requisites-to-get-started-with-your-own-number",
        plans_subscriptions: "https://docs.gallabox.com/pricing-and-billing-modules/billing-and-subscriptions",
        message_credits: "https://docs.gallabox.com/pricing-and-billing-modules/message-credits",
    },
    general_help_guide: {
        chrome_push_notification_help: "https://support.google.com/chrome/answer/3220216",
    },
    feature_help_guide: {
        onboarding: {
            video: "https://youtu.be/WdtHMWqsZtw",
        },
        broadcast_v1: {
            docs: "https://docs.gallabox.com/broadcast/gallabox-broadcast",
        },
        broadcast_v2: {
            docs: "https://docs.gallabox.com/broadcast/gallabox-broadcast",
            video: "https://www.youtube.com/watch?v=3I_uV1CRgxE&list=PLqLBaOO-5zr1ID5asd1sx5CWY9xSEAuLY&index=4",
            reply_to_settings: {
                docs: "https://docs.gallabox.com/broadcast/review-and-send-broadcast#broadcast-reply-settings",
            },
            select_from_contact_group: {
                video: "https://www.youtube.com/watch?v=yBpV_llptOw&list=PLqLBaOO-5zr1ID5asd1sx5CWY9xSEAuLY&index=3",
            },
            select_audience: { docs: "https://docs.gallabox.com/broadcast/select-audience" },
            schedule: {
                video: "https://www.youtube.com/watch?v=kPdVsJpgx1I&list=PLqLBaOO-5zr1ID5asd1sx5CWY9xSEAuLY&index=1",
            },
            select_template: {
                video: "https://www.youtube.com/watch?v=3I_uV1CRgxE&list=PLqLBaOO-5zr1ID5asd1sx5CWY9xSEAuLY&index=4",
            },
        },
        wa_templates: {
            docs: "https://docs.gallabox.com/basic-modules/whatsapp-templates",
            ai_rewrite: {
                docs: "https://docs.gallabox.com/basic-modules/whatsapp-templates/re-write-with-ai",
            },
            carousel: {
                docs: "https://docs.gallabox.com/basic-modules/whatsapp-templates/carousal-template",
            },
            button_payloads: { docs: "https://docs.gallabox.com/conversations/compose-box#button-payloads" },
        },
        sequence: {
            video: "https://youtu.be/TDtOH2L1PwY",
            video_thumbnail: "https://i.ytimg.com/vi/TDtOH2L1PwY/hqdefault.jpg",
            docs: "https://docs.gallabox.com/whatsapp-drip-campaigns/understanding-drip-marketing",
        },
        canned_response: { docs: "https://docs.gallabox.com/basic-modules/canned-responses" },
        bot_v1: {
            docs: "https://docs.gallabox.com/using-gallabox/bot",
            builder: {
                video: "https://www.youtube.com/watch?v=LRh32YpIEsk",
            },
        },
        bot_v2: {
            docs: "https://docs.gallabox.com/bots/bot-in-gallabox",
            essentials: { docs: "https://docs.gallabox.com/bots/bot-essentials" },
            createOrUpdateFlow: { docs: "https://docs.gallabox.com/bots/flow-in-the-bot" },
            sendText: { docs: "https://docs.gallabox.com/bots/send-card/send-text" },
            sendMedia: { docs: "https://docs.gallabox.com/bots/send-card/send-media" },
            sendCollection: { docs: "https://docs.gallabox.com/bots/send-card/send-collection" },
            sendProducts: { docs: "https://docs.gallabox.com/bots/send-card/send-products" },
            sendTemplate: { docs: "https://docs.gallabox.com/bots/send-card/send-template" },
            sendPrivateMessage: { docs: "https://docs.gallabox.com/bots/bot-actions/add-note-and-mention" },
            sendLocation: { docs: "https://docs.gallabox.com/bots/send-card/send-location" },
            sendVoice: { docs: "https://docs.gallabox.com/bots/send-card/send-voice" },
            askText: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-text" },
            askNumber: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-number" },
            askPhone: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-phone" },
            askEmail: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-email" },
            askDate: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-date" },
            askLocation: {
                docs: "https://docs.gallabox.com/bots/ask-questions/ask-location",
                video: "https://www.youtube.com/watch?v=atnFtWLkN-o",
            },
            askUrl: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-url" },
            askFile: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-file" },
            askButtonOption: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-button-option" },
            askListOption: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-list-option" },
            askKeywordOption: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-keyword-option" },
            askCollectionList: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-collection-list" },
            sendButtonOption: { docs: "https://docs.gallabox.com/bots/bot-utilities/send-button-option" },
            sendListOption: { docs: "https://docs.gallabox.com/bots/bot-utilities/send-list-option" },
            delay: { docs: "https://docs.gallabox.com/bots/bot-utilities/delay" },
            condition: { docs: "https://docs.gallabox.com/bots/bot-utilities/condition" },
            setVariable: { docs: "https://docs.gallabox.com/bots/bot-utilities/set-variable" },
            jumpto: { docs: "https://docs.gallabox.com/bots/bot-utilities/jump-to" },
            switch: { docs: "https://docs.gallabox.com/bots/bot-utilities/switch" },
            assignConversation: { docs: "https://docs.gallabox.com/bots/bot-actions/assign-conversation" },
            unassignConversation: { docs: "https://docs.gallabox.com/bots/bot-actions/unassign-conversations" },
            resolveConversation: { docs: "https://docs.gallabox.com/bots/bot-actions/resolve-conversations" },
            updateConversationFields: { docs: "https://docs.gallabox.com/bots/bot-actions/update-conversation-fields" },
            updateContactFields: { docs: "https://docs.gallabox.com/bots/bot-actions/update-contact-fields" },
            updateCompanyFields: { docs: "https://docs.gallabox.com/bots/bot-actions/update-company-fields" },
            googleSheet: {
                docs: "https://docs.gallabox.com/bots/bot-connectors/connect-whatsapp-and-google-sheets-to-collect-responses",
            },
            apiCall: {
                docs: "https://docs.gallabox.com/bots/bot-connectors/connect-your-system-with-the-whatsapp-chat-bot-using-api",
            },
            razorPay: { docs: "https://docs.gallabox.com/bots/bot-connectors/razorpay" },
            zohoCRM: { docs: "https://docs.gallabox.com/bots/bot-connectors/zoho-crm" },
            shopify: { docs: "https://docs.gallabox.com/bots/bot-connectors/shopify" },
            hubspot: { docs: "https://docs.gallabox.com/bots/bot-connectors/hubspot" },
            zohoBigin: { docs: "https://docs.gallabox.com/bots/bot-connectors/zoho-bigin" },
            leadSquare: { docs: "https://docs.gallabox.com/bots/bot-connectors/leadsquared" },
            wooCommerce: { docs: "https://docs.gallabox.com/bots/bot-connectors/woocommerce" },
            zohoCRMCom: { docs: "https://docs.gallabox.com/bots/bot-connectors/zoho-crm" },
            openAI: { docs: "https://docs.gallabox.com/bots/bot-connectors/open-ai" },
            zoom: { docs: "https://docs.gallabox.com/bots/bot-connectors/zoom" },
            sangam: { docs: "https://docs.gallabox.com/bots/bot-connectors/sangam-crm" },
            pushToSequence: { docs: "https://docs.gallabox.com/bots/bot-actions/push-to-sequence" },
            gptDialog: { docs: "https://docs.gallabox.com/bots/bot-utilities/ai-node-gpt-dialog" },
            gptKnowledgebase: { docs: "https://docs.gallabox.com/bots/bot-utilities/ai-node-gpt-knowledge-base" },
            sendForm: { docs: "https://docs.gallabox.com/bots/send-card/send-form" },
            askForm: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-form" },
            hint: { docs: "https://docs.gallabox.com/bots/bot-utilities/hint" },
            askPayments: { docs: "https://docs.gallabox.com/bots/ask-questions/ask-payments" },
            sendPayments: { docs: "https://docs.gallabox.com/bots/send-card/send-payments" },
            officeHours: { docs: "https://docs.gallabox.com/bots/bot-utilities/office-hours" },
        },
        messages: {
            unsupported_message_link: "https://docs.gallabox.com/extras/whatsapp-business-api-unsupported-messages",
        },
        channels: {
            connect_wa_channel: "https://docs.gallabox.com/connect-whatsapp-channel/connect-your-whatsapp",
            profile_assignments: "https://docs.gallabox.com/whatsapp-channel/account-profile-info",
            fb_business_settings: "https://business.facebook.com/settings",
            message_settings: "https://docs.gallabox.com/whatsapp-channel/configuration-message-settings",
            welcome_message_settings:
                "https://docs.gallabox.com/whatsapp-channel/configuration-message-settings#welcome-message",
            wa_cart_icon:
                "https://docs.gallabox.com/whatsapp-channel/channel-settings#carts-in-whatsapp-business-profile",
            wa_catalog_option: "https://docs.gallabox.com/whatsapp-channel/channel-settings#whatsapp-catalog-option",
            wa_read_receipts: "https://docs.gallabox.com/whatsapp-channel/channel-settings#read-receipts",
            assignment_rules: "https://docs.gallabox.com/whatsapp-channel/assignments",
            tech_partner_payment_setup: "https://docs.gallabox.com/whatsapp-channel/tech-partner/payment-setup",
        },
        users_and_roles: {
            invite_users_docs_link: "https://docs.gallabox.com/account-management/user-and-roles",
        },
        field_collection: {
            docs: "https://docs.gallabox.com/basic-modules/field-collection",
        },
        contacts: {
            add_contacts_docs_link: "https://docs.gallabox.com/contacts/add-contact",
            bulk_import_docs_link: "https://docs.gallabox.com/contacts/add-contact#bulk-contact-import",
            push_to_sequence_docs_link: "https://docs.gallabox.com/contacts/contact-management#push-to-sequence",
            marketing_optin_docs_link: "https://docs.gallabox.com/contacts/marketing-opt-in",
        },
        catalogs: {
            add_compliance_info: "https://business.facebook.com/business/help/1104628230079278",
            create_fb_business_manager: "https://www.facebook.com/business/help/1710077379203657?id=180505742745347",
            verify_fb_business_manager: "https://www.facebook.com/business/help/2058515294227817?id=180505742745347",
            whatsapp_restricted_access:
                "https://developers.facebook.com/docs/whatsapp/embedded-signup/overview/unverified-trial-experience",
            delete_whatsapp: "https://faq.whatsapp.com/android/account-and-profile/how-to-delete-your-account/?lang=en",
            connect_catalog_with_google_sheet:
                "https://docs.gallabox.com/using-gallabox/catalogue/connect-catalogue-to-gallabox#upload-catalogue-via-google-sheets",
            connect_catalog_to_gallabox: {
                mandatory_fields:
                    "https://docs.gallabox.com/using-gallabox/catalogue/connect-catalogue-to-gallabox#step-2-create-your-catalogue-with-mandatory-fields",
                collections_setup:
                    "https://docs.gallabox.com/using-gallabox/catalogue/connect-catalogue-to-gallabox#step-3-set-up-fb-catalogue-and-collection-lists",
                commerce_manager: "https://business.facebook.com/commerce",
                waba_settings: "https://business.facebook.com/settings/whatsapp-business-accounts",
                connect_to_whatsapp:
                    "https://docs.gallabox.com/using-gallabox/catalogue/connect-catalogue-to-gallabox#step-4-connect-fb-catalogue-to-whatsapp",
            },
            know_more_catalog_mandatory_fields:
                "https://www.facebook.com/business/help/120325381656392?id=725943027795860",
        },
        payments: {
            docs: "https://docs.gallabox.com/payments/establish-connection",
        },
        native_payments: {
            docs: "https://docs.gallabox.com/payments/gallabox-native-payments-overview",
        },
        marketing_optin: {
            docs: "https://docs.gallabox.com/using-gallabox/contacts/marketing-opt-in",
        },
        webengage_integration: {
            docs: "https://docs.gallabox.com/integration/marketing-and-engagement-platforms/webengage",
        },
        razorpay_integration: {
            docs: "https://docs.gallabox.com/integration/payment-gateways/razorpay",
        },
        fbleads_integration: {
            docs: "https://docs.gallabox.com/integration/marketing-and-engagement-platforms/facebook-leads",
        },
        shipway_integration: {
            docs: "https://docs.gallabox.com/integration/shipping-and-logistics/shipway",
        },
        calendly_integration: {
            docs: "https://docs.gallabox.com/integration/other-integrations/calendly",
        },
        cashfree_integration: {
            docs: "https://docs.gallabox.com/integration/payment-gateways/cashfree",
        },
        genericWebhook_integration: {
            docs: "https://docs.gallabox.com/integration/other-integrations/generic-webhooks",
        },
        googleSheet_integration: {
            link_URL: "https://docs.google.com/spreadsheets/d/1K9EyGb0gWPy8E1GGVXsN82jWOYJ19epqv-jP_PyvyVc/copy",
            docs: "https://docs.gallabox.com/integration/other-integrations/google-sheets",
        },
        hubspot_integration: {
            docs: "https://docs.gallabox.com/integration/customer-relationship-management-crm-systems/hubspot",
        },
        kylas_integration: {
            link_URL: "https://app.kylas.io/marketplace/all-apps/app-details/30bab16d-70fc-43ce-a5bc-75a9183deb2",
            docs: "https://docs.gallabox.com/integration/customer-relationship-management-crm-systems/kylas",
        },
        leadsquared_integration: {
            docs: "https://docs.gallabox.com/integration/customer-relationship-management-crm-systems/leadsquared",
        },
        pabbly_integration: {
            link_URL: "https://www.pabbly.com/connect/integrations/gallabox/",
            docs: "https://docs.gallabox.com/integration/other-integrations/pabbly",
        },
        moengage_integration: {
            docs: "https://docs.gallabox.com/integration/marketing-and-engagement-platforms/moengage",
        },
        shiprocket_integration: { docs: "https://docs.gallabox.com/integration/shipping-and-logistics/shiprocket" },
        shopify_integration: { docs: "https://docs.gallabox.com/integration/e-commerce-platforms/shopify" },
        woocommerce_integration: { docs: "https://docs.gallabox.com/integration/e-commerce-platforms/woocommerce" },
        zapier_integration: {
            link_URL: "https://zapier.com/developer/public-invite/167332/38b700f410cadf3ab5cee7a432d98123/",
            docs: "https://docs.gallabox.com/integration/other-integrations/zapier",
        },
        zoho_integration: {
            link_URL: "https://marketplace.zoho.com/app/crm/whatsapp-business-messaging-for-zoho-crm",
            docs: "https://docs.gallabox.com/integration/customer-relationship-management-crm-systems/zoho",
        },
        cartFlow_integration: { docs: "https://docs.gallabox.com/integration/e-commerce-platforms/cart-flow" },
        zohoSignals_integration: { docs: "https://docs.gallabox.com/integrations/other-integrations/zoho-signals" },
        zohoBooks_integration: { docs: "https://docs.gallabox.com/integration/other-integrations/zoho-books" },
        stripe_integration: { docs: "https://docs.gallabox.com/integration/payment-gateways/stripe" },
        clevertap_integration: {
            docs: "https://docs.gallabox.com/integration/marketing-and-engagement-platforms/clevertap",
        },
        wa_flows: {
            docs: "https://docs.gallabox.com/whatsapp-flows/overview",
            video: "https://assets.gallabox.com/web/login-page-video-flows.mp4",
        },
        message_tracker: {
            docs: "https://docs.gallabox.com/basic-modules/message-tracker",
        },
        whatsapp_shop: {
            docs: "https://docs.gallabox.com/whatsapp-shop/overview",
        },
        whatsapp_order_summary: {
            docs: "https://docs.gallabox.com/whatsapp-shop/whatsapp-order-summary",
        },
        trigger_bot_from_compose: { docs: "https://docs.gallabox.com/conversations/compose-box#trigger-bot" },
        wa_native_payments: {
            link_payment_account: "https://docs.gallabox.com/payments/establish-connection#whatsapp-pay",
        },
        ip_restriction: {
            docs: "https://docs.gallabox.com/account-security-features/allowed-ips",
        },
        ai_rewrite: {
            docs: "https://docs.gallabox.com/conversations/compose-box#ai-re-write",
        },
        team_inbox: { docs: "https://docs.gallabox.com/conversations/team-inbox" },
        tags: { docs: "https://docs.gallabox.com/basic-modules/tags" },
        two_factor_authentication: {
            docs: "https://docs.gallabox.com/account-security-features/two-factor-authentication",
        },
    },
    notifications: {
        canny: {
            app_id: "6315962ca092c744bb25b32c",
        },
        firebase: {
            api_key: "AIzaSyAejxTTSoz3T4JDEShoCfZqlnMn09ek8P0",
            auth_domain: "gallabox-cloud.firebaseapp.com",
            project_id: "gallabox-cloud",
            storage_bucket: "gallabox-cloud.appspot.com",
            messaging_sender_id: "922442006911",
            app_id: "1:922442006911:web:4e591f6a3280c9b61d5a4a",
            measurement_id: "G-8P23G7S9XG",
            vapidKey: "BHLzc_3DqHVw_8OrlL22E0LNI7XhhuVlczSxqogdGNRwRWMUsGzzQ7N8sHNyrwPsKp93fT4RxYpmZeBd_qz_qIo",
        },
    },
    support: {
        email_address: "support@gallabox.com",
        new_ticket: "https://help.gallabox.com/support/tickets/new",
        reactivate_account_wa_link: "https://wame.pro/gallaboxreactive",
        extend_free_trial: "https://wame.pro/gallaboxfreetrail",
        crisp_chat: { website_id: "" },
        freshdesk: {
            widget_id: "84000001632",
            widget_url: "https://ind-widget.freshworks.com/widgets/84000001632.js",
        },
        zoho_help_widget: {
            script_url: "https://desk.zoho.in/portal/api/web/inapp/98595000001414001?orgId=60019121503",
        },
        book_a_demo_url:
            "https://crm.zoho.in/bookings/DemoandOnboardingSupport?rid=8bae18f94f1a06ffe8366dbcc2cb4025428d1f6813449a6131d0c3dabb9dce58gid07ce26a4507f35aca99f7bf0b9a64938258485602df6dd64bf00dfe6109fd15d",
        webinar_url: "https://meet.zoho.in/r9i4OM3oBw",
        wa_number: "917825877730",
        sandbox_wa_number: "917825875221",
        template_pricing_changes: "https://docs.gallabox.com/extras/template-pricing-change-update",
        wa_template_approval_update: "https://docs.gallabox.com/extras/updates/information-regarding-template-approval",
    },
    branding: {
        gradient: {
            light: "linear-gradient(90deg, #FFFFFF 0.65%, #EDFBFF 54.69%, #DDEFFF 100%)",
            dark: "linear-gradient(91.58deg, #1A82EF 0.04%, #828CE8 100%)",
        },
        theme: {
            colors: {
                brand: {
                    50: "#e6f3ff",
                    100: "#cce6ff",
                    200: "#99ceff",
                    300: "#66b5ff",
                    400: "#339cff",
                    500: "#0083FF",
                    600: "#0069cc",
                    700: "#004f99",
                    800: "#003566",
                    900: "#001a33",
                },
            },
        },
        product_logos: {
            ico: "/favicon.ico",
            icon_16: "/gallabox_16x16.png",
            icon_32: "/gallabox_32x32.png",
            icon_180: "/gallabox_180x180.png",
            icon_192: "/gallabox_192x192.png",
            icon_512: "/gallabox_512x512.png",
            icon: "https://assets.gallabox.com/web/gb-icon-logo.svg",
            text: "../assets/gb-brand-name.svg",
            icon_with_text: "https://assets.gallabox.com/web/gb-full-logo.svg",
        },
        email_logos: {},
        links: {
            privacy_policy: "https://gallabox.com/privacy-policy",
            terms_of_use: "https://gallabox.com/terms",
            contact_us: "https://gallabox.com/contact",
            pricing: "https://gallabox.com/pricing",
            g2_reviews: "https://www.g2.com/products/gallabox/reviews",
            ios_app: "https://apps.apple.com/in/app/gallabox/id1542957177",
            android_app: "https://play.google.com/store/apps/details?id=com.mangoleap.gallabox",
            click_to_whatsapp_ads: "https://gallabox.com/blog/create-click-to-whatsapp-ads",
            whatsapp_chat_button: "https://gallabox.com/whatsapp-chat-button",
            whatsapp_link_generator: "https://gallabox.com/whatsapp-link-generator",
            wa_me_pro: "https://wame.pro",
            zoho_app: "https://marketplace.zoho.com/app/crm/whatsapp-business-messaging-for-zoho-crm",
            pabbly_app: "https://www.pabbly.com/connect/integrations/gallabox",
            google_sheet_app:
                "https://docs.google.com/spreadsheets/d/1K9EyGb0gWPy8E1GGVXsN82jWOYJ19epqv-jP_PyvyVc/copy",
            zapier_app: "https://zapier.com/developer/public-invite/167332/38b700f410cadf3ab5cee7a432d98123/",
            kylas_app: "https://app.kylas.io/marketplace/all-apps/app-details/30bab16d-70fc-43ce-a5bc-75a9183deb2",
            shopify_app: "https://apps.shopify.com/gallabox",
        },
        social_media: {
            twitter_user_name: "gallabox",
        },
        landing_page_url: "https://gallabox.com",
        site_manifest: "/site.webmanifest",
    },
    tracking: {
        gtm_id: "GTM-M6D9TRQ",
    },
    fd_sdk_app_id: "1805232449925698",
    dialog_360: { partnerId: "iDtgTpPA" },
    default_currency: "USD",
};
