import { SystemStyleObject } from "@chakra-ui/react";
import {
    BooleanReactSelectProps,
    ContactSearchFormSelectProps,
    ControlledElementProps,
    CurrencyCompProps,
    FormDateInputProps,
    FormInputProps,
    FormTextareaProps,
    LocationInputProps,
    PhoneNumberInputFormProps,
    ReactSelectV2Props,
    StarRatingFormProps,
} from "app/utils/formUtils";
import React from "react";
import { IconType } from "react-icons";
import { match, RouteProps } from "react-router-dom";
import * as yup from "yup";
import { Channel } from "./channel";
import { User } from "./user";
import { LanguageCodeType } from "lang/types/common";

export interface StylesObject {
    [styleName: string]: SystemStyleObject;
}

export interface Authed {
    accessToken: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    authJWT: any;
    // FIXME: we should make user optional, which would break many components
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user: User;
    isAuthChecked: boolean;
    isIPRestricted: boolean;
    isEmailVerified: boolean;
    waChannels?: Channel[];
}

export interface ProtectedRouteProps extends RouteProps {
    authed: Authed;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.FC<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentProps?: any;
    computedMatch?: match;
    name?: string;
}

export interface PublicRouteProps extends RouteProps {
    authed: Authed;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.FC<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentProps?: any;
    computedMatch?: match;
    name?: string;
}

export interface OAuthCallbacRouteProps extends RouteProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.FC<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentProps?: any;
}

export interface SocketProviderProps {
    authed: Authed;
    onConnect?: (isConnected: boolean) => void;
    children?: React.ReactNode;
}

export interface Status {
    id: string;
    name: string;
    color: string;
    isDefault: boolean;
    isActive: boolean;
    sortOrder: number;
}

export interface Timestamp {
    createdAt?: Date | string;
    updatedAt?: Date | string;
}

export type Creator = {
    creatorId: string;
    creator?: User;
};

export type StringObject = Record<string, string>;
export type UnknownObject = Record<string, unknown>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyObject = Record<string, any>;

export interface LabelValue<T = string, U = string> {
    label: U;
    value: T;
}
export interface LabelValueI18next<T = string, U = string> {
    label: Record<LanguageCodeType, U>;
    value: T;
}

export interface LabelValueObject extends LabelValue {
    id: string;
    name?: string;
}
export interface IDName {
    name: string;
    id: string;
}

export interface SelectOption extends LabelValue {
    __isNew__?: boolean;
}

export type YupSchema<T> = Record<keyof T, yup.AnySchema>;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

const AVAILABILITY_TYPE = ["Available", "Away"] as const;
export type AvailabilityType = (typeof AVAILABILITY_TYPE)[number];

export const daysInWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"] as const;
export type DaysInWeek = (typeof daysInWeek)[number];

export type UnionOmit<T, K extends string | number | symbol> = T extends unknown ? Omit<T, K> : never;
export type ArrayElement<ArrayType extends unknown[]> = ArrayType extends (infer ElementType)[] ? ElementType : never;
export interface PageFilter {
    since?: string;
    limit?: number;
    page?: number;
}

export enum QueryKey {
    GetAccountEntitlement = "get-account-entitlement",
    TagList = "tag-list",
    Mine = "my-conversations",
    Open = "open-conversations",
    Resolved = "resolved-conversations",
    Unassigned = "unassigned-conversations",
    Mention = "mention-conversations",
    Pinned = "pinned-conversations",
    ListConversationTag = "list-conversation-tag",
    ListContactTag = "list-contact-tag",
    CreateTag = "create-tag",
    CreateContactTag = "create-contact-tag",
    DeleteTag = "delete-tag",

    OnlineVisitors = "online-visitors",

    InviteDetailFetch = "invite-detail-fetch",
    AdminInvite = "admin-invite",
    AccountDetailFetch = "account-detail-fetch",
    C2waLeadCheck = "c2wa-lead-check",
    FetchDriver = "fetch-driver",

    WAChannelsList = "whatsapp-channels-list",
    ChannelsList = "channels-list",

    WATemplatesList = "whatsapp-templates-list",
    TemplatesList = "templates-list",
    CannedResponse = "canned-response",
    Variables = "template-variables",

    WATemplateMessagesList = "whatsapp-template-messages-list",

    WAProfileList = "whatsapp-profile-list",
    WAProfileHealth = "whatsapp-health",
    WAProfile = "whatsapp-profile",
    WAProfilePicture = "whatsapp-profile-picture",

    ContactConversationSearch = "contact-conversation-search",
    FileSearch = "file-search",
    ConversationFilter = "conversation-filter",

    ChannelUsersList = "channel-users-list",
    InviteList = "invite-list",
    Channel = "channel",
    UpdateChannel = "update-channel",
    GetChannelWAWidget = "get-channel-wa-widget",

    UpdateUser = "update-user",

    LeadList = "lead-list",

    ContactsList = "contacts-list",
    Contact = "contact",
    ContactPhone = "contact-phone",
    UpdateContact = "update-contact",
    DeleteContact = "delete-contact",
    ContactFilter = "contact-filter",
    CreateContact = "create-contact",
    ContactFields = "contact-fields",
    ContactAdditionalFields = "contact-additional-fields",
    ContactField = "contact-field",
    ContactBlockList = "contact-block-list",
    ContactBlock = "contact-block",
    ContactSimpleImport = "contact-simple-import",
    ContactImport = "contact-import",
    ContactImportList = "contact-import-list",
    ContactImportListPagination = "contact-import-list-pagination",
    ContactImportActions = "contact-import-actions",

    AllAdditionalFields = "all-additional-fields",
    AdditionalFields = "additional-fields",

    Company = "company",
    CompanyList = "company-list",
    CompanyListPagination = "company-list-pagination",
    CompanyFields = "company-fields",
    CompanyAdditionalFields = "company-additional-fields",

    SegmentList = "segment-list",

    WABroadcastList = "wa-broadcast-list",
    WABroadcastLogList = "wa-broadcast-log-list",
    WABroadcastItem = "wa-broadcast-item",
    WABroadcastLimit = "wa-broadcast-limit",
    WABroadcastPreview = "wa-broadcast-preview",
    WABroadcastSegmentCount = "wa-broadcast-segment-count",

    APIKeysList = "apikey-list",
    APIKeys = "apikey",
    CreateAPIKey = "create-api-key",
    UpdateAPIKey = "update-api-key",

    BackStageJobList = "backstage-job-list",
    BackStageMessageList = "backstage-message-list",

    BotList = "bot-list",
    BotVariables = "bot-variables",
    BotExpressions = "bot-expression",
    Bot = "bot",
    BotSearchFlow = "bot-search-flow",
    BotRecentSearchFlow = "bot-recent-search-flow",

    BotBuilderFlow = "bot-builder-flow",
    BotBuilderFlowList = "bot-builder-flow-list",
    BotTemplateFlowList = "bot-template-flow-list",
    BotTemplate = "bot-template",
    BotTemplateList = "bot-template-list",
    ConversationTag = "conversation-tag",
    UserList = "user-list",

    BotFlowBuilder = "bot-flow-builder",
    BotFlowBuilderList = "bot-flow-builder-list",
    BotFlowBuilderListDashboard = "bot-flow-builder-list-dashboard",
    BotElement = "bot-element",

    ActionList = "actions-list",

    ChannelConfiguration = "channel-configuration",
    WelcomeMessage = "create-channel-configuration-welcome-message",
    AwayMessage = "create-channel-configuration-away-message",
    AutoReply = "create-channel-configuration-auto-reply",
    ReEngagementMessage = "create-channel-configuration-re-engagement-message",
    WorkingTime = "create-channel-configuration-working-time",
    AutoReAssign = "create-channel-configuration-auto-re-assign",
    MGTemplateList = "message-gallery-template-list",
    FailedMessage = "create-channel-configuration-failed-message",
    AutoUpdateMsgOptIn = "create-channel-configuration-auto-update-message",
    AutoResolveChat = "create-channel-configuration-auto-resolve-chat",

    WebhookEventList = "webhook-event-list",
    WebhookList = "webhook-list",
    WebhookLogsList = "webhook-log-list",
    CreateWebhook = "create-webhook",
    UpdateWebhook = "update-webhook",
    DeleteWebhook = "delete-webhook",

    ReportList = "report-list",
    SandboxGetMembers = "get-sandbox-invited-member",

    ConversationAnalytics = "conversation-analytics",
    MessageAnalytics = "message-analytics",
    ContactAnalytics = "contact-analytics",
    BotAnalytics = "bot-analytics",
    UserAnalytics = "user-analytics",

    BotSessionAnalytics = "bot-session-analytics",
    FlowSessionAnalytics = "flow-session-analytics",

    CredentialProviderList = "credential-provider-list",
    CredentialList = "credential-list",
    CredentialOAuth2 = "credential-oauth2",
    CatalogList = "catalog-list",
    CatalogDetails = "catalog-details",
    ProductList = "product-list",
    ProductSetList = "product-set-list",
    WACatalogMessageList = "wa-product-message-list",
    WACatalogSetList = "wa-product-set-list",
    MessageTrackerList = "message-tracker-list",
    MessageExportList = "message-export-list",

    BusinessCompliance = "business-compliance",

    PlanList = "plan-list",
    PlanItemList = "plan-item-list",
    ActiveSubscription = "active-subscription",
    ActiveStripeSubscription = "active-stripe-subscription",
    PaymentList = "payment-list",
    InvoiceList = "invoice-list",
    BillingAddressList = "billing-address-list",
    BillingAddress = "billing-address",
    // Workflows
    WorkflowList = "workflow-list",

    //Integrations
    IntegrationFieldsList = "integration-fields-list",
    IntegrationField = "integration-field",
    Integration = "integration",
    ShopifyReAuthenticate = "shopify-reauthenticate",
    IntegrationWorkFlow = "integration-workflow",
    ShopifyWorkFlowList = "shopify-workflow-list",
    MessageGalleryList = "message-gallery-list",
    ConvertMessageTemplate = "convert-message-template",
    IntegrationLogList = "integration-log-list",
    CalendlyEventsList = "calendly-event-list",
    CalendlyIntegrationVariablesList = "calendly-integration-variable-list",

    PromoCode = "promo-code",

    PrepaidWallet = "prepaid-wallet",
    ListTransaction = "list-transaction",
    FbLeadsOption = "fb-leads-option",
    //user teams
    TeamsList = "teams-list",
    TeamUsers = "team-users-list",
    UserDetails = "user-details",
    TeamByUserId = "team-by-userid",
    UserAPIMe = "user-api-me",
    //add assignment rules
    AddAssignmentRules = "assignment-rules",
    //backstagePrepaid
    BackstagePrepaid = "backstage-prepaid",

    //Admin route bot templates

    BotTemplateListAdmin = "bot-template-list-admin",
    BotTemplateDetails = "bot-template-details",
    DeleteBotTemplateAdmin = "delete-bot-template-admin",
    UpdateBotTemplateStatus = "update-bot-template-status",
    CreateBotTemplateAdmin = "create-bot-template-admin",
    UpdateBotTemplateAdmin = "update-bot-template-admin",
    ImportFlowFromBotFlow = "import-flow-from-bot-flow",
    DeleteBotTemplateFlowAdmin = "delete-bot-template-flow-admin",
    AllBotList = "all-bot-list",
    //message search
    ContactWithConversationSearch = "contact-with-conversation-search",
    MessageSearch = "message-search",

    GetSpreadSheetList = "get-spreadsheet-list",
    GetSpreadSheetsSheetList = "get-spreadsheetssheet-list",
    GetSpreadSheetHeader = "get-spreadsheet-header",
    GetCredential = "GetCredential",

    // Dialog 360
    Dialog360ChannelList = "dialog360-channel-list",
    //broadcastV2
    PostBroadcastDraft = "post-broadcast-draft",
    GetBroadcastDraft = "get-broadcast-draft",
    BroadcastTableData = "broadcast-table-data",
    BroadcastReport = "broadcast-report",
    BulkBroadCastSample = "bulk-broadcast-sample",
    CreateBroadCast = "create-broadcast",
    PostBroadCast = "post-broadcast",
    Dialog360IndividualChannel = "dialog360-individual-channel",
    GetConnectionProviderList = "GetConnectionProviderList",
    GetUsers = "GetUsers",
    GetTeams = "GetTeams",
    GetBots = "GetBots",

    //sequence
    ListSequence = "list-sequence",
    Sequence = "sequence",
    SequenceReportList = "sequence-report-list",
    SequenceLogList = "sequence-log-list",
    SequenceLogStat = "sequence-log-stat",
    SequenceLogExport = "sequence-log-export",
    WhatsappOrderList = "whatsapp-order-list",
    WhatsappOrderFilter = "whatsapp-order-filter",
    WhatsappOrder = "whatsapp-order",
    OrderConfigs = "order-configs",

    //bot session logs
    BotSessionLogs = "bot-session-logs",
    BotSessionLogById = "bot-session-log-by-id",

    // Account Onboarding
    AccountOnboarding = "account-onboarding",
    //Payments
    PaymentConfig = "payment-config",
    PaymentChannelWithTemplateStatus = "payment-channel-with-template-status",
    PaymentsList = "payments-list",
    PaymentsCount = "payments-count",
    Payment = "payment",
    PaymentStats = "payment-stats",

    GetGalleryTemplate = "get-gallery-template",

    // Wame link generator
    WameLinkGenerator = "wame-link-generator",
    BotFlowsList = "bot-flows-list",

    GetSubscribedWebhook = "GetSubscribedWebhook",
    CommerceSettings = "wa-commerce-settings",

    AIConfig = "ai-config",
    AIUsage = "ai-usage",

    //Error Code
    ErrorCodeData = "error-code-data",
    AuditLogList = "audit-log-list",
    // Catalogue v2
    CatalogsV2 = "catalogs-v2",
    FBPermissions = "fb-permissions",

    //IP-Config
    MyIP = "my-ip",
    IpConfigList = "ip-config-list",

    GetChannelUsers = "channel-users-list",
    SearchUsers = "search-users",
    GetAccountUsersCount = "get-account-users-count",
    //flexi-list
    AllFlexiList = "all-flexi-list",
    FlexiListData = "flexi-list-data",
    FlexiListFields = "flexi-list-fields",
    FlexiListRecords = "flexi-list-records",

    //WhatsappFlows
    WhatsappFlow = "whatsapp-flow",
    WhatsappFlowList = "whatsapp-flow-list",
    WhatsappFlowLogsList = "whatsapp-flow-logs-list",
    WhatsappFlowLogsColumnPref = "whatsapp-flow-logs-column-pref",
    WhatsappFlowLogsFilter = "whatsapp-flow-logs-filter",
    WhatsappFlowLogsDataTypes = "whatsapp-flow-data-types",
    WhatsappFlowByFbFlowId = "whatsapp-flow-by-fb-flow-id",
    //Knowledge Base
    KnowledgeBase = "knowledge-base",
    KnowledgeBaseList = "knowledge-base-list",

    //messages
    Message = "message",

    //Whatsapp onboarding (Tech provider)
    WARegistrationList = "wa-registration-list",
    MetaChannel = "meta-channel",

    // Account Config
    AccountConfig = "account-config",

    OrderConfig = "order-config",

    CTWAEventsConfigList = "ctwa-events-config-list",
}

export type TQueryKey = [QueryKey, Record<string, any>];

export const FIELD_TYPE = [
    "TEXT",
    "NUMBER",
    "SELECT",
    "MULTI_SELECT",
    "DATE",
    "USER",
    "SWITCH",
    "URL",
    "EMAIL",
    "PHONE",
    "CURRENCY",
    "RATING",
    "RECORD",
    "TEXT_AREA",
    "LOCATION",
    "TEXT_AREA",
    "LOCATION",
    "RECORD",
] as const;

export type FieldType = (typeof FIELD_TYPE)[number];

export type TextFieldType = {
    type: Extract<FieldType, "TEXT" | "EMAIL" | "URL" | "PHONE" | "RECORD" | "TEXT_AREA">;
    value: string;
};

export type NumberFieldType = {
    type: Extract<FieldType, "NUMBER" | "RATING">;
    value: number;
};

export type SelectFieldType = {
    type: Extract<FieldType, "SELECT">;
    value: LabelValue | string;
    options: (LabelValue | string)[];
};

export type MultiSelectFieldType = {
    type: Extract<FieldType, "MULTI_SELECT">;
    value: (LabelValue | string)[];
    options: (LabelValue | string)[];
} & ReactSelectV2Props<LabelValue, true>;

export type DateFieldType = {
    type: Extract<FieldType, "DATE">;
    value: Date;
};

export type UserFieldType = {
    type: Extract<FieldType, "USER">;
    value: LabelValue | string;
    options: (LabelValue | string)[];
};

export type SwitchFieldType = {
    type: Extract<FieldType, "SWITCH">;
    value: boolean;
};

export type CurrencyFieldType = {
    type: Extract<FieldType, "CURRENCY">;
    value: number;
    countryCode: string;
};

export type AllFieldsAndValue =
    | TextFieldType
    | NumberFieldType
    | SelectFieldType
    | MultiSelectFieldType
    | DateFieldType
    | UserFieldType
    | SwitchFieldType
    | CurrencyFieldType;

export interface TextFormFieldType extends FormInputProps {
    type: Extract<FieldType, "TEXT" | "EMAIL" | "URL" | "NUMBER">;
}

// export interface NumberFormFieldType extends FormInputProps {
//     type: Extract<FieldType, "NUMBER">;
// }

export interface SelectFormFieldType extends ReactSelectV2Props<LabelValue, false> {
    type: Extract<FieldType, "SELECT">;
}

export interface MultiSelectFormFieldType extends ReactSelectV2Props<LabelValue, true> {
    type: Extract<FieldType, "MULTI_SELECT">;
}

export interface SwitchFormFieldType extends BooleanReactSelectProps {
    type: Extract<FieldType, "SWITCH">;
}

export interface UserFormFieldType extends ControlledElementProps {
    type: Extract<FieldType, "USER">;
}

export interface DateFormFieldType extends FormDateInputProps {
    type: Extract<FieldType, "DATE">;
}
export interface TextAreaFormFieldType extends FormTextareaProps {
    type: Extract<FieldType, "TEXT_AREA">;
}
export interface LocationFormFieldType extends LocationInputProps {
    type: Extract<FieldType, "LOCATION">;
}
export interface RatingFormFieldType extends StarRatingFormProps {
    type: Extract<FieldType, "RATING">;
}
export interface PhoneFormFieldType extends PhoneNumberInputFormProps {
    type: Extract<FieldType, "PHONE">;
}
export interface RecordFieldType extends ContactSearchFormSelectProps {
    type: Extract<FieldType, "RECORD">;
}
export interface CurrencyFormFieldType extends CurrencyCompProps {
    type: Extract<FieldType, "CURRENCY">;
}

export type AllFormFieldsAndValue =
    | TextFormFieldType
    // | NumberFormFieldType
    | SelectFormFieldType
    | MultiSelectFormFieldType
    | SwitchFormFieldType
    | UserFormFieldType
    | DateFormFieldType
    | TextAreaFormFieldType
    | LocationFormFieldType
    | RatingFormFieldType
    | PhoneFormFieldType
    | RecordFieldType
    | CurrencyFormFieldType;

export interface TextFieldPreview {
    type: Extract<FieldType, "TEXT" | "EMAIL" | "URL">;
}

export interface NumberFieldPreview {
    type: Extract<FieldType, "NUMBER">;
}

export interface SelectFieldPreview extends ReactSelectV2Props<LabelValue, false> {
    type: Extract<FieldType, "SELECT">;
}

export interface MultiSelectFieldPreview extends ReactSelectV2Props<LabelValue, true> {
    type: Extract<FieldType, "MULTI_SELECT">;
}

export interface SwitchFieldPreview extends BooleanReactSelectProps {
    type: Extract<FieldType, "SWITCH">;
}

export interface UserFieldPreview extends ControlledElementProps {
    type: Extract<FieldType, "USER">;
}

export interface DateFieldPreview extends FormDateInputProps {
    type: Extract<FieldType, "DATE">;
}
export interface TextAreaFieldPreview extends FormTextareaProps {
    type: Extract<FieldType, "TEXT_AREA">;
}
export interface LocationFieldPreview extends LocationInputProps {
    type: Extract<FieldType, "LOCATION">;
}
export interface RatingFieldPreview extends StarRatingFormProps {
    type: Extract<FieldType, "RATING">;
}
export interface PhoneFieldPreview extends PhoneNumberInputFormProps {
    type: Extract<FieldType, "PHONE">;
}
export interface RecordFieldPreview extends ContactSearchFormSelectProps {
    type: Extract<FieldType, "RECORD">;
}

export type IconComponent = IconType | JSX.Element;

export type QueryKeyType = [string, Record<string, any>];
