import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import { useGBMediaQuery } from "app/hooks/useGBMediaQuery";
import { useGBTranslation } from "lang/hooks/useGBTranslation";
import React from "react";
import { LuLogOut } from "react-icons/lu";
import { Link as RouterLink } from "react-router-dom";

type LogoutButtonProps = Omit<ButtonProps, "as" | "to">;

const LogoutButton: React.FC<LogoutButtonProps> = (props) => {
    const { isMobile } = useGBMediaQuery();
    const { t } = useGBTranslation("common");
    return (
        <Button
            as={RouterLink}
            to="/logout"
            title="Logout"
            color="gray.600"
            size="small"
            variant="ghost"
            iconSpacing={isMobile ? 0 : undefined}
            pl={isMobile ? 5 : undefined}
            leftIcon={<Icon fontSize="xl" stroke="1px" as={LuLogOut} />}
            {...props}
        >
            {isMobile ? "" : t("Logout", { ns: "common" })}
        </Button>
    );
};

export default LogoutButton;
