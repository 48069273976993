import { Button, Icon } from "@chakra-ui/react";
import React from "react";
import { CgImport } from "react-icons/cg";
import { Link } from "react-router-dom";

const NewImportButton: React.FC = () => {
    return (
        <Button
            borderRadius="base"
            colorScheme="brand"
            variant="solid"
            leftIcon={<Icon as={CgImport} color="white" fontSize="sm" />}
            size="sm"
            as={Link}
            to="/contacts/import/new"
        >
            Import
        </Button>
    );
};

export default NewImportButton;
