import { useToast } from "@chakra-ui/react";
import { IContactTag } from "app/types";
import { QueryKey, TQueryKey } from "app/types/common";
import { BlockContact, BlockContactBase, Contact, ContactBulkAction, ContactWithConversation } from "app/types/contact";
import { IField as Field, IFieldValue } from "app/types/field";
import { FilterData, FilterItem, FilterType } from "app/types/filter";
import { deleteJSON, fetcher, getJSON, mapQueryParams, patchFetcher, patchJSON, postJSON } from "app/utils/fetchUtils";
import { useAccountId } from "app/utils/react-helpers";
import {
    InfiniteData,
    MutateFunction,
    QueryFunction,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from "react-query";

const PAGE_SIZE = 10;

export interface UseGetContactProps extends UseQueryOptions<Contact, Error, Contact, TQueryKey> {
    contactId?: string;
    accountId?: string;
    importId?: string;
    populatePaths?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseGetContactFieldProps extends UseGetContactProps {}

const fetchContact: QueryFunction<Contact, TQueryKey> = ({ queryKey }) => {
    const [, { contactId, accountId, populatePaths }] = queryKey;
    const path = populatePaths?.join(",") ?? "";
    return fetcher(`/api/account/${accountId}/contacts/${contactId}${path ? `?populatePaths=${path}` : ""}`);
};

export const useGetContact = (props: UseGetContactProps): UseQueryResult<Contact, Error> => {
    const { contactId, accountId, populatePaths, ...options } = props;

    const toast = useToast();

    return useQuery<Contact, Error, Contact, TQueryKey>(
        [QueryKey.Contact, { contactId, accountId, populatePaths }],
        fetchContact,
        {
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error?.message,
                });
            },
            ...options,
        }
    );
};

export interface UseGetContactByPhoneProps {
    phone?: string;
    accountId?: string;
    channelId?: string;
}

export const useGetContactByPhone = (
    props: UseGetContactByPhoneProps
): UseQueryResult<Contact | ContactWithConversation, Error> => {
    const { phone, accountId, channelId } = props;
    const fetchContactByPhone: QueryFunction<Contact | ContactWithConversation, TQueryKey> = ({ queryKey }) => {
        const [, { phone, accountId, channelId }] = queryKey;
        const queryParams = mapQueryParams({ channelId });
        return getJSON(`/api/account/${accountId}/contacts/phone/${phone}?${queryParams}`);
    };
    return useQuery<Contact, Error, Contact, TQueryKey>(
        [QueryKey.ContactPhone, { phone, accountId, channelId }],
        fetchContactByPhone
    );
};

type UseCreateContactResult<InputType> = UseMutationResult<Contact, Error, InputType>;

interface UseCreateContactProps {
    accountId: string;
    onSuccess?: (newContact: Contact) => void;
    onFailure?: (error: Error) => void;
    skipAlreadyExistError?: boolean;
    showSuccessMessage?: boolean;
}

export const useCreateContact = <InputType extends Partial<Contact>>(
    props: UseCreateContactProps
): UseCreateContactResult<InputType> => {
    const { accountId, onSuccess, onFailure, skipAlreadyExistError = false, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.CreateContact, { accountId }];

    const createContact = (contact: InputType) => {
        return postJSON<InputType>(`/api/account/${accountId}/contacts`, contact);
    };

    const mutationResult = useMutation<Contact, Error, InputType>(createContact, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            onFailure?.(error);
            if (error.message.startsWith("Contact already exists") && skipAlreadyExistError) return;
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (newContact) => {
            if (showSuccessMessage) {
                toast({
                    title: "Contact Created!",
                    status: "success",
                    description: "Contact created successfully!",
                });
            }
            onSuccess?.(newContact);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface ContactQueryContext {
    previousContact?: Contact;
}

type UseUpdateContactResult<InputType> = UseMutationResult<Contact, Error, InputType, ContactQueryContext>;

interface UseUpdateContactProps {
    accountId: string;
    contactId: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
}

export const useUpdateContact = <InputType extends Partial<Contact>>(
    props: UseUpdateContactProps
): UseUpdateContactResult<InputType> => {
    const { accountId, contactId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Contact, { accountId, contactId }];

    const updateContact = (contact: InputType) => {
        return patchJSON<Contact>(`/api/account/${accountId}/contacts/${contactId}`, contact);
    };

    const mutationResult = useMutation<Contact, Error, InputType, ContactQueryContext>(updateContact, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedContact, context) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: err.message,
            });
            if (context?.previousContact) {
                queryClient.setQueryData<Contact>(queryKey, context.previousContact);
            }
        },
        onSuccess: () => {
            if (showSuccessMessage) {
                toast({
                    title: "Contact Updated!",
                    status: "success",
                    description: "Contact updated successfully!",
                });
            }
            onSuccess?.();
            queryClient.invalidateQueries([QueryKey.ContactsList]);
        },
        // When mutate is called:
        onMutate: async (updatedContact) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const previousContact = queryClient.getQueryData<Contact>(queryKey);

            if (!previousContact) return {};

            const updated = { ...previousContact, ...updatedContact };

            queryClient.setQueryData<Contact & InputType>(queryKey, updated);

            // Optionally return a context containing data to use when for example rolling back
            return { previousContact };
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

type UseDeleteContactCompanyResult<InputType> = UseMutationResult<boolean, Error, InputType, ContactQueryContext>;

interface UseDeleteContactCompanyProps {
    accountId: string;
    contactId: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
}

export const useDeleteContactCompany = <InputType = Pick<Contact, "companyId">>(
    props: UseDeleteContactCompanyProps
): UseDeleteContactCompanyResult<InputType> => {
    const { accountId, contactId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Contact, { accountId, contactId }];

    const updateContact = () => {
        return deleteJSON<boolean>(`/api/account/${accountId}/contacts/${contactId}/company`);
    };

    const mutationResult = useMutation<boolean, Error, InputType, ContactQueryContext>(updateContact, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedContact, context) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: err.message,
            });
            if (context?.previousContact) {
                queryClient.setQueryData<Contact>(queryKey, context.previousContact);
            }
        },
        onSuccess: () => {
            if (showSuccessMessage) {
                toast({
                    title: "Company unlinked from contact!",
                    status: "success",
                    description: "Company unlinked successfully!",
                });
            }
            onSuccess?.();
        },
        // When mutate is called:
        onMutate: async () => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const previousContact = queryClient.getQueryData<Contact>(queryKey);

            if (!previousContact) return {};

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { company, companyId, ...rest } = previousContact;
            const updated: Contact = { ...rest };

            queryClient.setQueryData<Contact>(queryKey, updated);

            // Optionally return a context containing data to use when for example rolling back
            return { previousContact };
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface ContactWithConversationListProps<TData>
    extends UseInfiniteQueryOptions<TData[], Error, TData[], TData[], TQueryKey> {
    accountId: string;
    search: string;
    limit?: number;
    populatePaths?: string[];
}

export const useContactWithConversationList = <TData>(
    props: ContactWithConversationListProps<TData>
): UseInfiniteQueryResult<TData[], Error> => {
    const { accountId, search, limit, populatePaths, ...options } = props;

    const infiniteQueryResult = useInfiniteQuery<TData[], Error, TData[], TQueryKey>(
        [QueryKey.ContactConversationSearch, { accountId, search, limit, populatePaths }],
        ({ pageParam, queryKey }) => {
            const [, { accountId, search, limit, populatePaths }] = queryKey;
            const query = mapQueryParams({
                conversation: 1,
                limit: limit ?? PAGE_SIZE,
                page: pageParam ?? 1,
                nameEmailPhone: search,
                populatePaths: populatePaths?.join(",") ?? undefined,
            });
            return fetcher(`/api/account/${accountId}/contacts?${query}`);
        },
        {
            ...options,
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === (limit ?? PAGE_SIZE) ? pages.length + 1 : undefined;
            },
        }
    );

    return infiniteQueryResult;
};

const fetchContactFields: QueryFunction<Field[], TQueryKey> = ({ queryKey }) => {
    const [, { contactId, accountId, populatePaths }] = queryKey;
    const path = populatePaths?.join(",") ?? "none";
    const queryParams = mapQueryParams({
        populatePaths: path,
        scope: "CONTACT",
        isActive: "true",
        isPrimary: "false",
    });
    return fetcher(`/api/account/${accountId}/contacts/${contactId}/fields?${queryParams}`);
};

export const useGetContactFields = (props: UseGetContactFieldProps): UseQueryResult<Field[], Error> => {
    const { contactId, accountId, populatePaths } = props;

    const toast = useToast();

    return useQuery<Field[], Error, Field[], TQueryKey>(
        [QueryKey.ContactFields, { contactId, accountId, populatePaths }],
        fetchContactFields,
        {
            enabled: Boolean(contactId) && Boolean(accountId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
        }
    );
};

const fetchContactAdditionalFields: QueryFunction<Field[], TQueryKey> = ({ queryKey }) => {
    const [, { accountId }] = queryKey;
    return fetcher(`/api/account/${accountId}/fields?scope=CONTACT&isActive=true&isPrimary=false`);
};

export const useGetContactAdditionalFields = (props: UseGetContactFieldProps): UseQueryResult<Field[], Error> => {
    const { accountId } = props;

    const toast = useToast();

    return useQuery<Field[], Error, Field[], TQueryKey>(
        [QueryKey.ContactAdditionalFields, { accountId }],
        fetchContactAdditionalFields,
        {
            enabled: Boolean(accountId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
        }
    );
};

const fetchContactActiveFields: QueryFunction<Field[], TQueryKey> = ({ queryKey }) => {
    const [, { accountId }] = queryKey;
    return fetcher(`/api/account/${accountId}/fields?scope=CONTACT&isActive=true`);
};

export const useGetContactActiveFields = (props: UseGetContactFieldProps): UseQueryResult<Field[], Error> => {
    const { accountId } = props;

    const toast = useToast();

    return useQuery<Field[], Error, Field[], TQueryKey>(
        [QueryKey.ContactAdditionalFields, { accountId }],
        fetchContactActiveFields,
        {
            enabled: Boolean(accountId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
        }
    );
};

export interface ContactFieldValuesQueryContext {
    previousContactFieldValues?: IFieldValue[];
}

type UseUpdateContactFieldValueResult = UseMutationResult<
    IFieldValue,
    Error,
    IFieldValue,
    ContactFieldValuesQueryContext
>;

export const useUpdateContactFieldValue = (
    contactId: string,
    fieldId: string,
    onSuccess?: () => void
): UseUpdateContactFieldValueResult => {
    const accountId = useAccountId();
    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ContactFields, { contactId, accountId }];

    const updateFieldValue = (fieldValue: IFieldValue) => {
        return patchJSON<IFieldValue>(`/account/${accountId}/contacts/${contactId}/fields/${fieldId}`, fieldValue);
    };

    const mutationResult = useMutation<IFieldValue, Error, IFieldValue, ContactFieldValuesQueryContext>(
        updateFieldValue,
        {
            // If the mutation fails, use the context returned from onMutate to roll back
            onError: (err, _updatedFieldValue, context) => {
                toast({
                    title: "Something went wrong!",
                    status: "error",
                    description: err.message,
                });
                if (context?.previousContactFieldValues) {
                    queryClient.setQueryData(queryKey, context.previousContactFieldValues);
                }
            },
            onSuccess: () => {
                toast({
                    title: "Field value updated!",
                    status: "success",
                    description: "Field value updated successfully!",
                });
                onSuccess?.();
            },
            // When mutate is called:

            onMutate: async (updatedFieldValue) => {
                // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
                await queryClient.cancelQueries(queryKey);

                // Snapshot the previous value
                const previousContactFieldValues = queryClient.getQueryData<IFieldValue[]>(queryKey);

                const newFieldValues = previousContactFieldValues?.map((f) => {
                    if (f.id === updatedFieldValue.id) {
                        return updatedFieldValue;
                    }
                    return f;
                });

                if (newFieldValues) {
                    queryClient.setQueryData<IFieldValue[]>(queryKey, newFieldValues);
                }

                // Optionally return a context containing data to use when for example rolling back
                return { previousContactFieldValues };
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(queryKey);
            },
        }
    );

    return mutationResult;
};

interface UseContactListProps {
    accountId: string;
    search: string;
    currentFilters?: FilterItem[];
    populatePaths?: string[];
    page?: number;
    getCount?: boolean;
    pageSize?: number;
    enabled?: boolean;
    onSuccess?: (data: InfiniteData<IGetContactsResult>) => void;
}

export interface IGetContactsResult {
    count: number;
    data: Contact[];
}

const LIMIT = 20;
export const useContactList = (props: UseContactListProps): UseInfiniteQueryResult<IGetContactsResult, Error> => {
    const {
        accountId,
        search,
        currentFilters,
        populatePaths,
        page,
        getCount = true,
        enabled = true,
        onSuccess,
    } = props;
    const toast = useToast();
    const infiniteQueryResult = useInfiniteQuery<IGetContactsResult, Error, IGetContactsResult, TQueryKey>(
        [QueryKey.ContactsList, { accountId, search, currentFilters, getCount, page }],
        ({ pageParam, queryKey }) => {
            const [, { accountId, page }] = queryKey;
            const filters: FilterItem[] = currentFilters ?? [];
            const path = populatePaths?.join(",");
            const payload = {
                searchQuery: search,
                filter: {
                    condition: "and",
                    rules: filters,
                },
                limit: LIMIT,
                page: page ?? pageParam ?? 1,
            };
            const queryParams = mapQueryParams({ populatePaths: path, getCount });

            return patchFetcher(`/api/account/${accountId}/contacts?${queryParams}`, payload);
        },
        {
            refetchInterval: 120000,
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.data.length) return undefined;
                return lastPage?.data.length === LIMIT ? pages.length + 1 : undefined;
            },
            getPreviousPageParam: (firstPage, pages) => {
                if (!firstPage?.data.length) return undefined;
                return firstPage?.data.length === LIMIT ? pages.length - 1 : undefined;
            },
            enabled: Boolean(accountId) && enabled,
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
            keepPreviousData: true,
            onSuccess: onSuccess,
        }
    );
    return infiniteQueryResult;
};

export const useContactListV2 = (props: UseContactListProps): UseQueryResult<IGetContactsResult, Error> => {
    const { accountId, search, currentFilters, populatePaths, page, pageSize, getCount = false } = props;
    const toast = useToast();
    const pgSize = pageSize ?? LIMIT;
    const queryResult = useQuery<IGetContactsResult, Error, IGetContactsResult, TQueryKey>(
        [QueryKey.ContactsList, { accountId, search, currentFilters, page, pageSize: pgSize, getCount }],
        ({ queryKey }) => {
            const [, { accountId }] = queryKey;
            const filters: FilterItem[] = currentFilters ?? [];
            const path = populatePaths?.join(",");
            const payload = {
                searchQuery: search,
                filter: {
                    condition: "and",
                    rules: filters,
                },
                limit: pgSize,
                page: page ?? 1,
            };

            return patchFetcher(
                `/api/account/${accountId}/contacts?getCount=${String(getCount)}${
                    path ? `&populatePaths=${path}` : ""
                }`,
                payload
            );
        },
        {
            refetchInterval: 120000,
            cacheTime: 1000 * 60 * 10,
            staleTime: 1000 * 60 * 10,
            enabled: Boolean(accountId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
            keepPreviousData: true,
        }
    );
    return queryResult;
};

interface GetContactFilterOptionsProps {
    accountId: string;
}

const fetchContactFilterOptions: QueryFunction<FilterData, TQueryKey> = ({ queryKey }) => {
    const [, { accountId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/contacts/filters`);
};

export const useContactFilterOptions = (props: GetContactFilterOptionsProps): UseQueryResult<FilterData, Error> => {
    const { accountId } = props;
    const toast = useToast();

    return useQuery<FilterData, Error, FilterData, TQueryKey>(
        [QueryKey.ContactFilter, { accountId }],
        fetchContactFilterOptions,
        {
            enabled: Boolean(accountId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
        }
    );
};

interface UseGetContactBlockProps {
    accountId: string;
    channelId: string;
}

const fetchContactBlock: QueryFunction<BlockContactBase[], TQueryKey> = ({ queryKey }) => {
    const [, { accountId, channelId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/channels/${channelId}/blockContact`);
};

export const useGetContactBlock = (props: UseGetContactBlockProps): UseQueryResult<BlockContactBase[], Error> => {
    const { accountId, channelId } = props;
    return useQuery<BlockContactBase[], Error, BlockContactBase[], TQueryKey>(
        [QueryKey.ContactBlockList, { accountId, channelId }],
        fetchContactBlock
    );
};

interface UnblockWAContactResult {
    isUnblocked: boolean;
}

type UnblockWAContactVariables = Pick<BlockContact, "whatsapp">;

type UseUnblockWAContactResult = UseMutationResult<UnblockWAContactResult, Error, UnblockWAContactVariables>;

interface UseUnblockWAContactProps {
    accountId: string;
    channelId: string;
    onSuccess?: (contact: UnblockWAContactResult) => void;
    showSuccessMessage?: boolean;
}
export const useUnblockWAContact = (props: UseUnblockWAContactProps): UseUnblockWAContactResult => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ContactBlockList, { accountId, channelId }];

    const blockContact = (data: UnblockWAContactVariables) => {
        return postJSON<UnblockWAContactVariables>(
            `/api/accounts/${accountId}/channels/${channelId}/whatsapp/unblockContact`,
            data
        );
    };

    const mutationResult = useMutation<UnblockWAContactResult, Error, UnblockWAContactVariables>(blockContact, {
        onError: (error) => {
            toast({ title: "Something went wrong!", status: "error", description: error.message });
        },
        onSuccess: (contact) => {
            if (showSuccessMessage) {
                toast({
                    title: "Contact unblocked",
                    status: "success",
                    description: "Contact unblocked successfully!",
                });
            }
            onSuccess?.(contact);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

type UseBlockWAContactResult = UseMutationResult<BlockContact, Error, BlockWAContactVariables>;

interface UseBlockWAContactProps {
    accountId: string;
    channelId: string;
    onSuccess?: (contact: BlockContact) => void;
    showSuccessMessage?: boolean;
}

type BlockWAContactVariables = Pick<BlockContact, "contactId" | "whatsapp">;

export const useBlockWAContact = (props: UseBlockWAContactProps): UseBlockWAContactResult => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ContactBlockList, { accountId, channelId }];

    const blockContact = (data: BlockWAContactVariables) => {
        return postJSON<BlockWAContactVariables>(
            `/api/accounts/${accountId}/channels/${channelId}/whatsapp/blockContact`,
            data
        );
    };

    const mutationResult = useMutation<BlockContact, Error, BlockWAContactVariables>(blockContact, {
        onError: (error) => {
            toast({ title: "Something went wrong!", status: "error", description: error.message });
        },
        onSuccess: (blockContact) => {
            if (showSuccessMessage) {
                toast({ title: "Contact Blocked", status: "success", description: "Contact blocked successfully!" });
            }
            onSuccess?.(blockContact);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface ExportContactResult {
    status: string;
    message: string;
}
type UseExportContact<InputType> = UseMutationResult<ExportContactResult, Error, InputType>;

interface UseExportContactProps {
    accountId: string;
    onSuccess?: (result: ExportContactResult) => void;
    showSuccessMessage?: boolean;
}

export interface Filters {
    filter: { condition: "and" | "or"; rules: FilterItem[] };
}

export const useExportContact = <InputType extends Record<string, any> = Filters>(
    props: UseExportContactProps
): UseExportContact<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast({ isClosable: true, position: "bottom", duration: 8000 });

    const blockContact = (data: InputType) => {
        return postJSON<InputType>(`/api/accounts/${accountId}/contacts/exportV2`, data);
    };

    const mutationResult = useMutation<ExportContactResult, Error, InputType>(blockContact, {
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (result) => {
            if (showSuccessMessage) {
                toast({ title: result.status, description: result.message, status: "success" });
            }
            onSuccess?.(result);
        },
    });

    return mutationResult;
};

interface SimpleImportResponse {
    status: string;
    message?: string;
}

interface UsePostImportProps {
    accountId: string;
    onSuccess?: (reponse: SimpleImportResponse) => void;
    showSuccessMessage?: boolean;
}

type UseSimpleImportResult = UseMutationResult<SimpleImportResponse, Error, FormData>;

export const usePostSimpleImport = (props: UsePostImportProps): UseSimpleImportResult => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ContactsList, { accountId }];

    const importContact = (contact: FormData) => {
        return postJSON<FormData>(`/api/account/${accountId}/contacts/import`, contact);
    };

    const mutationResult = useMutation<SimpleImportResponse, Error, FormData>(importContact, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            const toastId = "ImportContact";
            if (!toast.isActive(toastId)) {
                toast({
                    title: "Something went wrong!",
                    status: "error",
                    description: error.message,
                });
            }
        },
        onSuccess: (response) => {
            if (showSuccessMessage) {
                toast({
                    title: response.status,
                    status: "success",
                    description: response.message,
                });
            }
            onSuccess?.(response);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface UseCreateContactTagProps {
    accountId: string;
    contactId?: string;
    onSuccess?: (newTag: IContactTag) => void;
    showSuccessMessage?: boolean;
}
type UseCreateTagResult<InputType> = UseMutationResult<IContactTag, Error, InputType>;

export const useCreateContactTag = <InputType extends Partial<IContactTag>>(
    props: UseCreateContactTagProps
): UseCreateTagResult<InputType> => {
    const { accountId, contactId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.CreateContactTag, { accountId, contactId }];

    const createTag = (tag: InputType) => {
        return postJSON<InputType>(`/api/account/${accountId}/contacts/${contactId}/tags`, tag);
    };

    const mutationResult = useMutation<IContactTag, Error, InputType>(createTag, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: error.name,
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (newTag) => {
            if (showSuccessMessage) {
                toast({
                    title: "Tag",
                    status: "success",
                    description: "Tag has been added successfully.",
                });
            }
            // const queryKey = [QueryKey.Contact, { contactId, accountId }];
            // update<CTag>(queryClient, queryKey, input.id ?? "", newTag);
            onSuccess?.(newTag);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type UseDeleteTagResult = UseMutationResult<IContactTag, Error, { tagId: string }> | null;

interface UseDeleteTagParams {
    accountId: string;
    tagId?: string;
    contactId?: string;
    onSuccess?: () => void;
}

export const UseDeleteContactTag = (params: UseDeleteTagParams): UseDeleteTagResult => {
    const { accountId, contactId, onSuccess, tagId } = params;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.DeleteTag, { accountId, contactId, tagId }];

    const deleteTag = () => {
        return deleteJSON<IContactTag>(`/api/account/${accountId}/contacts/${contactId}/tags/${tagId}`);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<IContactTag, Error, { tagId: string }>(deleteTag, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedField, context) => {
            queryClient.setQueryData(queryKey, context);
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while deleting tag",
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                title: "Tag",
                description: "Tag has been removed successfully.",
            });
            onSuccess?.();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

export interface UseGetTagListProps {
    accountId?: string;
    populatePaths?: string[];
}

type UseListTagResult<InputType> = UseMutationResult<IContactTag[], Error, InputType>;

export const useGetTagList = <InputType>(props: UseGetTagListProps): UseListTagResult<InputType> => {
    const { accountId, populatePaths } = props;

    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ListContactTag, { accountId, populatePaths }];
    const path = populatePaths?.join(",") ?? "";
    const getTagList = (inputValue: InputType) => {
        return fetcher(`/api/account/${accountId}/tags?name=${inputValue}&populatePaths=${path}`);
    };

    const mutationResult = useMutation<IContactTag[], Error, InputType>(getTagList, {
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

type UseDeleteContactResult = UseMutationResult<Contact, Error, string[]>;
interface UseDeleteContactProps {
    accountId: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
}

export const useDeleteContacts = (props: UseDeleteContactProps): UseDeleteContactResult => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ContactsList, { accountId }];

    const removeContact = (contactIds: string[]) => {
        const idParams = contactIds.map((id) => `id=${id}`).join("&");
        return deleteJSON<Contact>(`/api/accounts/${accountId}/contacts?${idParams}`);
    };

    const mutationResult = useMutation<Contact, Error, string[]>(removeContact, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err) => {
            toast({ title: err.message, status: "error" });
        },
        onSuccess: () => {
            if (showSuccessMessage) {
                toast({
                    title: "Contact has been deleted successfully",
                    status: "success",
                });
            }
            onSuccess?.();
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface BulkDeleteResponse {
    deletedCount: number;
}

type UseBulkDeleteContact<InputType> = UseMutationResult<BulkDeleteResponse, Error, InputType>;

interface UseBulkDeleteContactProps {
    accountId: string;
    onSuccess?: (result: BulkDeleteResponse) => void;
    showSuccessMessage?: boolean;
}

export interface FiltersDelete {
    contactIds?: (string | undefined)[];
    filterQuery?: { condition: "and" | "or"; rules: FilterItem[] };
}

export const useBulkDeleteContact = <InputType extends FiltersDelete>(
    props: UseBulkDeleteContactProps
): UseBulkDeleteContact<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;
    const queryKey = [QueryKey.ContactsList, { accountId }];

    const toast = useToast({ isClosable: true, position: "bottom", duration: 8000 });
    const queryClient = useQueryClient();
    const bulkContactDelete = (data: InputType) => {
        return postJSON<InputType>(`/api/account/${accountId}/contacts/bulk/delete`, data);
    };

    const mutationResult = useMutation<BulkDeleteResponse, Error, InputType>(bulkContactDelete, {
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (result) => {
            if (showSuccessMessage) {
                toast({
                    title: "Deleted",
                    description: `${result.deletedCount} Contact deleted successfully`,
                    status: "success",
                });
            }
            onSuccess?.(result);
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};
interface ContactBulkUpdateProps {
    accountId: string;
    onSuccess?: () => void;
    onFailure?: (err: Error) => void;
}
type UseContactBulkUpdateProps = ContactBulkUpdateProps &
    UseMutationOptions<Record<string, any>, Error, ContactBulkAction>;

type UseContactBulkUpdateResult = UseMutationResult<Record<string, any>, Error, ContactBulkAction>;

export const useContactBulkUpdate = (props: UseContactBulkUpdateProps): UseContactBulkUpdateResult => {
    const { accountId, onSuccess, onFailure, ...options } = props;
    const toast = useToast();
    const updateContact = (fieldValues: ContactBulkAction) => {
        return patchJSON(`/api/accounts/${accountId}/contacts/bulk`, fieldValues);
    };
    const mutation = useMutation<Record<string, any>, Error, ContactBulkAction>(updateContact, {
        onError: (err) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: err.message,
            });
            onFailure?.(err);
        },
        onSuccess: () => {
            toast({
                status: "success",
                title: "Contact update in progress",
                // description: "Field value updated successfully!",
            });
            onSuccess?.();
        },
        ...options,
    });
    return mutation;
};

interface SubscribeToSequenceProps {
    accountId: string;
}

export interface ContactSubscribeToSequence {
    filterQuery?: FilterType;
    contactIds?: string[];
    sequenceId: string;
    name: string;
    contactCount?: number;
}

type UseSubscribeToSequenceProps = SubscribeToSequenceProps &
    UseMutationOptions<Record<string, any>, Error, ContactSubscribeToSequence>;

type UseSubscribeToSequenceResult = UseMutationResult<Record<string, any>, Error, ContactSubscribeToSequence>;

export const useSubscribeToSequence = (props: UseSubscribeToSequenceProps): UseSubscribeToSequenceResult => {
    const { accountId, onSuccess, onError, ...options } = props;
    const toast = useToast();
    const subscribeToSequence = (data: ContactSubscribeToSequence) => {
        const { name, contactCount, ...apiData } = data;
        return patchJSON(`/api/accounts/${accountId}/contacts/subscribe-to-sequence`, apiData);
    };
    const mutation = useMutation<Record<string, any>, Error, ContactSubscribeToSequence>(subscribeToSequence, {
        onError: (...args) => {
            const [err] = args;
            toast({
                title: "Something went wrong!",
                status: "error",
                description: err.message,
            });
            onError?.(...args);
        },
        onSuccess: (...args) => {
            const [, input] = args;
            toast({
                id: "push-to-sequence-in-progress",
                status: "success",
                description: "You will be notified once all contacts are pushed to the sequence.",
                title: `Initiated to push contacts into ${input.name} sequence`,
            });
            onSuccess?.(...args);
        },
        ...options,
    });
    return mutation;
};

interface ContactSearchProps {
    accountId: string;
    populatePaths?: string[];
    onSuccess?: () => void;
    onError?: (err: Error) => void;
}

interface ContactSearchMutateFnPros {
    search: string;
    limit?: number;
}

type UseContactSearchProps = ContactSearchProps & UseMutationOptions<Contact[], Error, ContactSearchMutateFnPros>;

type UseContactSearchResult = UseMutationResult<Contact[], Error, ContactSearchMutateFnPros>;

export const useContactSearch = (props: UseContactSearchProps): UseContactSearchResult => {
    const { accountId, onError, populatePaths, ...options } = props;
    const toast = useToast();

    const path = populatePaths?.join(",");
    const updateContact: MutateFunction<any, Error, ContactSearchMutateFnPros> = (input) => {
        const payload = {
            searchQuery: input.search,
            limit: input?.limit ?? LIMIT,
        };
        return patchFetcher(`/api/account/${accountId}/contacts?${path ? `&populatePaths=${path}` : ""}`, payload);
    };
    const mutation = useMutation<Contact[], Error, ContactSearchMutateFnPros>(updateContact, {
        onError: (err) => {
            toast({
                title: "Something went wrong while fetching Contacts",
                status: "error",
                description: err.message,
            });
            onError?.(err);
        },
        ...options,
    });
    return mutation;
};
