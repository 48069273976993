import { ReplyTo } from "app/components/ReplyToConfiguration/types";
import { Account } from "..";
import { Channel } from "../channel";
import { Timestamp } from "../common";
import { MessageTracker } from "../tracker";
import { User } from "../user";
import { Location } from "../lego/common.types";

export const WA_TEMPLATE_NAME_LIMIT = 60;
export const WA_TEMPLATE_BODY_LIMIT = 1024;
export const WA_TEMPLATE_CAROUSEL_CARD_BODY_LIMIT = 160;
export const WA_TEMPLATE_HEADER_LIMIT = 60;
export const WA_TEMPLATE_FOOTER_LIMIT = 60;
export const WA_TEMPLATE_REPLY_BUTTON_LIMIT = 25;
export const WA_TEMPLATE_BUTTON_TEXT_LIMIT = 25;
export const WA_TEMPLATE_URL_LIMIT = 2000;
export const WA_TEMPLATE_PHONE_NUMBER_LIMIT = 20;
export const WA_VARIABLE_LIMIT = 50;
export const WA_MPM_SECTION_NAME_LIMIT = 24;

export const RightToLeftLanguage = ["Arabic", "Hebrew", "Persian", "Urdu"];

export const WATemplateLanguages: WATemplateLanguage[] = [
    {
        label: "Afrikaans",
        value: "af",
    },
    {
        label: "Albanian",
        value: "sq",
    },
    {
        label: "Arabic",
        value: "ar",
    },
    {
        label: "Azerbaijani",
        value: "az",
    },
    {
        label: "Bengali",
        value: "bn",
    },
    {
        label: "Bulgarian",
        value: "bg",
    },
    {
        label: "Catalan",
        value: "ca",
    },
    {
        label: "Chinese (CHN)",
        value: "zh_CN",
    },
    {
        label: "Chinese (HKG)",
        value: "zh_HK",
    },
    {
        label: "Chinese (TAI)",
        value: "zh_TW",
    },
    {
        label: "Croatian",
        value: "hr",
    },
    {
        label: "Czech",
        value: "cs",
    },
    {
        label: "Danish",
        value: "da",
    },
    {
        label: "Dutch",
        value: "nl",
    },
    {
        label: "English",
        value: "en",
    },
    {
        label: "English (UK)",
        value: "en_GB",
    },
    {
        label: "English (US)",
        value: "en_US",
    },
    {
        label: "Estonian",
        value: "et",
    },
    {
        label: "Filipino",
        value: "fil",
    },
    {
        label: "Finnish",
        value: "fi",
    },
    {
        label: "French",
        value: "fr",
    },
    {
        label: "German",
        value: "de",
    },
    {
        label: "Greek",
        value: "el",
    },
    {
        label: "Gujarati",
        value: "gu",
    },
    {
        label: "Hausa",
        value: "ha",
    },
    {
        label: "Hebrew",
        value: "he",
    },
    {
        label: "Hindi",
        value: "hi",
    },
    {
        label: "Hungarian",
        value: "hu",
    },
    {
        label: "Indonesian",
        value: "id",
    },
    {
        label: "Irish",
        value: "ga",
    },
    {
        label: "Italian",
        value: "it",
    },
    {
        label: "Japanese",
        value: "ja",
    },
    {
        label: "Kannada",
        value: "kn",
    },
    {
        label: "Kazakh",
        value: "kk",
    },
    {
        label: "Korean",
        value: "ko",
    },
    {
        label: "Lao",
        value: "lo",
    },
    {
        label: "Latvian",
        value: "lv",
    },
    {
        label: "Lithuanian",
        value: "lt",
    },
    {
        label: "Macedonian",
        value: "mk",
    },
    {
        label: "Malay",
        value: "ms",
    },
    {
        label: "Malayalam",
        value: "ml",
    },
    {
        label: "Marathi",
        value: "mr",
    },
    {
        label: "Norwegian",
        value: "nb",
    },
    {
        label: "Persian",
        value: "fa",
    },
    {
        label: "Polish",
        value: "pl",
    },
    {
        label: "Portuguese (BR)",
        value: "pt_BR",
    },
    {
        label: "Portuguese (POR)",
        value: "pt_PT",
    },
    {
        label: "Punjabi",
        value: "pa",
    },
    {
        label: "Romanian",
        value: "ro",
    },
    {
        label: "Russian",
        value: "ru",
    },
    {
        label: "Serbian",
        value: "sr",
    },
    {
        label: "Slovak",
        value: "sk",
    },
    {
        label: "Slovenian",
        value: "sl",
    },
    {
        label: "Spanish",
        value: "es",
    },
    {
        label: "Spanish (ARG)",
        value: "es_AR",
    },
    {
        label: "Spanish (SPA)",
        value: "es_ES",
    },
    {
        label: "Spanish (MEX)",
        value: "es_MX",
    },
    {
        label: "Swahili",
        value: "sw",
    },
    {
        label: "Swedish",
        value: "sv",
    },
    {
        label: "Tamil",
        value: "ta",
    },
    {
        label: "Telugu",
        value: "te",
    },
    {
        label: "Thai",
        value: "th",
    },
    {
        label: "Turkish",
        value: "tr",
    },
    {
        label: "Ukrainian",
        value: "uk",
    },
    {
        label: "Urdu",
        value: "ur",
    },
    {
        label: "Uzbek",
        value: "uz",
    },
    {
        label: "Vietnamese",
        value: "vi",
    },
    {
        label: "Zulu",
        value: "zu",
    },
];

export const TEMPLATE_MESSAGE_CATEGORIES = [
    "ACCOUNT_UPDATE",
    "ALERT_UPDATE",
    "APPOINTMENT_UPDATE",
    "AUTO_REPLY",
    "ISSUE_RESOLUTION",
    "OTP",
    "PAYMENT_UPDATE",
    "PERSONAL_FINANCE_UPDATE",
    "RESERVATION_UPDATE",
    "SHIPPING_UPDATE",
    "TICKET_UPDATE",
    "TRANSACTIONAL",
    "TRANSPORTATION_UPDATE",
    // new categories
    "AUTHENTICATION",
    "MARKETING",
    "UTILITY",
] as const;

export const TEMPLATE_MESSAGE_COMPONENTS = ["BODY", "HEADER", "FOOTER", "BUTTONS", "CAROUSEL"] as const;
export const TEMPLATE_MESSAGE_FORMATS = ["TEXT", "IMAGE", "DOCUMENT", "VIDEO", "LOCATION"] as const;
export const BUTTONS_COMPONENT_TYPES = ["PHONE_NUMBER", "URL", "QUICK_REPLY", "CATALOG", "MPM", "FLOW"] as const;
export const WA_TEMPLATE_STATUS = [
    "created",
    "recently_approved",
    "approved",
    "rejected",
    "pending",
    "submitted",
    "error",
] as const;

export type WATemplateStatus = (typeof WA_TEMPLATE_STATUS)[number];
export type WATemplateMessageCategoryType = (typeof TEMPLATE_MESSAGE_CATEGORIES)[number];

type WATemplateMessageComponentsType = (typeof TEMPLATE_MESSAGE_COMPONENTS)[number];
export type WATemplateMessageFormatType = (typeof TEMPLATE_MESSAGE_FORMATS)[number];
export type ButtonsComponentType = (typeof BUTTONS_COMPONENT_TYPES)[number];

interface WATemplateLanguage {
    label: string;
    value: string;
}

export interface WAButtonsComponent {
    type: ButtonsComponentType;
    text: string;
    url?: string;
    // eslint-disable-next-line camelcase
    phone_number?: string;
    flow_id?: string;
    flow_action?: "NAVIGATE" | "DATA_EXCHANGE";
    navigate_screen?: string;
    example?: string[] | Record<string, unknown>;
}

export interface TemplateMessageComponentHeaderExample {
    // eslint-disable-next-line camelcase
    header_handle?: string[];
    // eslint-disable-next-line camelcase
    header_text?: string[];
}

export interface TemplateMessageComponentBodyExample {
    // eslint-disable-next-line camelcase
    body_text?: [string[]];
}

export type TemplateMessageComponentURLButtonExample = string[];

export interface ProductTemplateMessageExample {
    // eslint-disable-next-line camelcase
    thumbnail_product_retailer_id: string;
    catalogId: string;
}

export interface MultiProductTemplateMessageExample {
    // eslint-disable-next-line camelcase
    thumbnail_product_retailer_id: string;
    catalogId: string;
    sections: {
        title: string;
        // eslint-disable-next-line camelcase
        product_items: { product_retailer_id: string }[];
    }[];
}

export interface TemplateMessageComponentFileMetaData {
    fileName?: string;
    fileType?: string;
}

export interface CarouselCardComponent {
    type: Extract<WATemplateMessageComponentsType, "HEADER" | "BODY" | "BUTTONS">;
    text?: string;
    format?: Extract<WATemplateMessageFormatType, "IMAGE" | "VIDEO">;
    example?: TemplateMessageComponentHeaderExample | TemplateMessageComponentBodyExample;
    buttons?: WAButtonsComponent[];

    // for Gallabox purpose only
    filePath?: string;
    mediaId?: string;
    fileMetaData?: TemplateMessageComponentFileMetaData;
}

interface CarouselCard {
    components: CarouselCardComponent[];
}

export interface WATemplateMessageComponent {
    type: WATemplateMessageComponentsType;
    format?: WATemplateMessageFormatType;
    text?: string;
    example?:
        | TemplateMessageComponentHeaderExample
        | TemplateMessageComponentBodyExample
        | TemplateMessageComponentURLButtonExample
        | ProductTemplateMessageExample
        | MultiProductTemplateMessageExample
        | Record<string, unknown>
        | unknown[];
    buttons?: WAButtonsComponent[];
    cards?: CarouselCard[];

    // for Gallabox purpose only
    filePath?: string;
    mediaId?: string;
    fileMetaData?: TemplateMessageComponentFileMetaData;
    location?: Location;
}

interface WATemplateBase {
    category: WATemplateMessageCategoryType;
    components: WATemplateMessageComponent[];
    name: string;
    language: string;
}

interface FooterVariables {
    variable?: string;
    index?: number;
    type: string;
    required: boolean;
}

export interface WATemplate extends WATemplateBase {
    readonly id?: string;
    accountId: string;
    account?: Account;
    channelId: string;
    channel?: Channel;
    status: WATemplateStatus;
    // eslint-disable-next-line camelcase
    rejected_reason?: string;
    err?: string;
    whatsappTemplateId?: string;
    createdAt?: Date | string;
    updatedAt?: Date | string;
    messageTrackerId?: string;
    variables?: string[];
    messageTracker?: MessageTracker;
    footerVariables: FooterVariables[];
    isSystemTemplate?: boolean;
    enableURLTracking?: boolean;
    allow_category_change: boolean;
    statusUpdatedAt?: Date;
    replyTo?: ReplyTo;
}

export interface WATemplatesWithCount {
    templates: WATemplate[];
    totalCount: number;
}

export interface IWATemplateGalleryTag {
    readonly id: string;
    readonly _id: string;
    name: string;
}

export const industries = [
    "Automobile",
    "Ecommerce",
    "Healthcare",
    "Education",
    "Popular",
    "Finance",
    "Real Estate",
    "Travel",
] as const;
export type Industry = (typeof industries)[number];

export interface TemplateGalleryMeta {
    industry?: Industry[];
}

export interface WaScreenProps extends WATemplateBase, Timestamp {
    readonly id?: string;
    tags?: IWATemplateGalleryTag[];
    caption?: string;
    createdById?: string;
    createdBy?: User;
    meta?: TemplateGalleryMeta;
}
