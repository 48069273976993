import { Flex } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

export interface CenterToScreenProps {
    isCentered?: boolean;
}
const CenterToScreen: React.FC<PropsWithChildren<CenterToScreenProps>> = (props) => {
    const { isCentered = true } = props;
    if (isCentered) {
        return (
            <Flex align="center" justify="center" w="full" maxW="full" h="full" maxH="full">
                {props.children}
            </Flex>
        );
    }
    return <>{props.children}</>;
};

export default CenterToScreen;
