import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputProps } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PhoneInput, { PhoneInputProps as PhoneNumberInputProps } from "react-phone-number-input";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChakraInput = React.forwardRef<any, any>(function ChakraInput(props, ref) {
    return <Input size="sm" {...props} ref={ref} />;
});

const randomKey = () => Math.random().toString();

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ defaultValue, value: v, onChange, ...props }) => {
    const [value, setValue] = useState<string>(v ?? defaultValue);

    const [key, setKey] = useState(randomKey());
    useEffect(() => {
        if (v === null) setKey(randomKey());
    }, [v]);

    useEffect(() => {
        setValue(value);
    }, [value]);
    const handleChange = React.useCallback(
        (v: string) => {
            if (onChange) {
                onChange(v ? (v.startsWith("+") ? v.slice(1) : v) : v);
            }
        },
        [onChange]
    );
    return (
        <PhoneInput
            placeholder="Enter mobile number"
            value={value ? (value?.startsWith("+") ? value : `+${value}`) : value}
            onChange={handleChange}
            inputComponent={ChakraInput}
            key={key}
            {...props}
        />
    );
};

export interface PhoneNumberFormInputProps extends PhoneNumberInputProps {
    name?: string;
    error?: string;
    label?: string;
    helpText?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export const PhoneNumberFormInput: React.FC<PhoneNumberFormInputProps> = ({
    name,
    error,
    label,
    helpText,
    ...props
}) => {
    return (
        <FormControl isInvalid={Boolean(error)} id={name}>
            {label ? <FormLabel mb={1}>{label}</FormLabel> : null}
            <PhoneNumberInput name={name} {...props} />
            {helpText ? <FormHelperText mt={1}>{helpText}</FormHelperText> : null}
            {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
        </FormControl>
    );
};

export type { PhoneNumberInputProps };

export default PhoneNumberInput;
