import { Box, HStack, Image, Skeleton, Text, VStack } from "@chakra-ui/react";
import config from "app/app.config";
import Step52Gif from "assets/onboarding/dialog-360/step-5-2.gif";
import GallboxFreeTrialLogo from "assets/onboarding/free-trial/gallabox-free-trial.svg";
import React from "react";

const Step51Gif = "https://assets.gallabox.com/web/step-5-1.gif";

const Step4: React.FC = () => {
    return (
        <VStack
            bg={{ base: "transparent", sm: "white", lg: "white" }}
            boxShadow={{ base: "none", sm: "none", lg: "base" }}
            w={{ base: "full", sm: "container.md", lg: "container.md" }}
            px={{ base: "unset", sm: 10, lg: 10 }}
            align="start"
            rounded="md"
            spacing={3}
            py={5}
        >
            <HStack w="full">
                <Image src={GallboxFreeTrialLogo} w="36px" h="36px" fallback={<Skeleton w="36px" h="36px" />} />
                <Text color="gray.800" fontWeight="semibold" fontSize={{ base: "md", sm: "md", lg: "lg" }}>
                    {`Step 5: Authorize ${config.product_name} and connect your WABA account.`}
                </Text>
            </HStack>

            <VStack
                w={{ base: "100%", sm: "94%", lg: "94%" }}
                alignSelf={{ base: "unset", sm: "end", lg: "end" }}
                align="start"
            >
                <Text color="gray.800" fontSize="md" fontWeight="medium">
                    Select the connected number from the dropdown and authorise
                </Text>
                <Box w="full" h={{ base: "298px", sm: "320px", lg: "320px" }} bg="#BCDEFB" rounded="lg">
                    <Image
                        src={Step51Gif}
                        objectFit="cover"
                        w="full"
                        h="full"
                        maxH="xs"
                        rounded="lg"
                        fallback={<Skeleton w="full" h="full" />}
                    />
                </Box>
            </VStack>
            <VStack
                w={{ base: "100%", sm: "94%", lg: "94%" }}
                alignSelf={{ base: "unset", sm: "end", lg: "end" }}
                align="start"
            >
                <Text color="gray.800" fontSize="md" fontWeight="medium">
                    {`Connect the created WABA account to your ${config.product_name} account`}
                </Text>
                <Box w="full" h={{ base: "298px", sm: "320px", lg: "320px" }} bg="#BCDEFB" rounded="lg">
                    <Image
                        src={Step52Gif}
                        objectFit="cover"
                        w="full"
                        h="full"
                        maxH="xs"
                        rounded="lg"
                        fallback={<Skeleton w="full" h="full" />}
                    />
                </Box>
            </VStack>
        </VStack>
    );
};

export default Step4;
