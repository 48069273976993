import { mapQueryParams } from "app/utils/fetchUtils";
import { useHistory } from "react-router-dom";
import React from "react";
import { RedirectFnType } from "./type";
import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";

interface UseRedirectToPlansProps {
    params?: Parameters<typeof mapQueryParams>[0];
}
export const useRedirectToPlans = (props: UseRedirectToPlansProps = {}): RedirectFnType => {
    const history = useHistory();
    const { isSandboxChannel } = useAccountMasterHook();

    const redirect = React.useCallback(() => {
        if (isSandboxChannel) {
            history.push("/get-own-number-in-app");
            return;
        }

        const params = mapQueryParams(props?.params ?? {});
        history.push(`/plan-and-subscription?${params}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return redirect;
};
