import { AccountEntitlementContext } from "app/AccountEntitlementContext/context";
import { AccountEntitlement, Entitlement } from "app/AccountEntitlementContext/type";
import { FeatureName } from "app/types";
import React from "react";
import { useContext } from "react";

type EntitlementDataType<T extends FeatureName | undefined> = T extends FeatureName ? Entitlement : null;

interface UseRestrictFeatureReturn<T extends FeatureName | undefined = undefined> {
    data: EntitlementDataType<T>;
    checkRestriction: (featureName: FeatureName, ifNotExistReturn?: "access" | "restrict") => Entitlement;
    accountEntitlement: AccountEntitlement | undefined;
    isLoading: boolean;
    isFetched: boolean;
}

export function useRestrictFeature<T extends FeatureName | undefined = FeatureName>(
    featureName?: T,
    ifNotExistReturn: "access" | "restrict" = "access"
): UseRestrictFeatureReturn<T> {
    const { accountEntitlement, isFetched, isLoading } = useContext(AccountEntitlementContext);

    const checkRestriction = React.useCallback(
        (featureName: FeatureName, ifNotExistReturn: "access" | "restrict" = "access") => {
            const feature = accountEntitlement?.entitlementByFeature[featureName];

            if (feature) return feature;

            if (ifNotExistReturn === "restrict") {
                return {
                    limitExceeds: true,
                    pageAccess: false,
                    restrictPage: true,
                } as Entitlement;
            }
            return {
                limitExceeds: false,
                pageAccess: true,
                restrictPage: false,
            } as Entitlement;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [accountEntitlement, isLoading, isFetched]
    );
    if (!featureName) {
        return {
            data: null,
            checkRestriction,
            accountEntitlement,
            isFetched,
            isLoading,
        } as UseRestrictFeatureReturn<T>;
    }
    const data = checkRestriction(featureName, ifNotExistReturn);
    return { data, checkRestriction, accountEntitlement, isFetched, isLoading } as UseRestrictFeatureReturn<T>;
}
