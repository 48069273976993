import { Heading, Image, ImageProps, Text, VStack, StackProps } from "@chakra-ui/react";
import React from "react";

interface TitleType extends Partial<Omit<StackProps, "title">> {
    title?: string | JSX.Element;
    subHeading?: string | JSX.Element;
}

interface SelfServeIconHeaderPropsWithSVGIcon extends TitleType {
    icon?: string;
    iconProps?: ImageProps;
}

interface SelfServeIconHeaderPropsWithJSXElement extends TitleType {
    icon?: JSX.Element;
}

export type SelfServeIconHeaderProps = SelfServeIconHeaderPropsWithSVGIcon | SelfServeIconHeaderPropsWithJSXElement;

const SelfServeIconHeader: React.FC<SelfServeIconHeaderProps> = (props) => {
    const { title, subHeading, icon, ...vStackProps } = props;

    const IconComponent = React.useMemo(() => {
        if (!props.icon) return null;

        if (typeof props.icon === "string") {
            const { iconProps } = props as SelfServeIconHeaderPropsWithSVGIcon;
            return <Image src={props.icon} {...(iconProps ?? {})} />;
        }

        return props.icon;
    }, [props]);

    const TitleComponent = React.useMemo(() => {
        if (!title) return null;
        if (typeof title !== "string") return title;
        return (
            <Heading zIndex={1} fontSize="2xl" fontWeight="600">
                {title}
            </Heading>
        );
    }, [title]);

    const SubHeadingComponent = React.useMemo(() => {
        if (!subHeading) return null;
        if (typeof subHeading !== "string") return subHeading;
        return (
            <Text color="gray.600" mt="1">
                {subHeading}
            </Text>
        );
    }, [subHeading]);

    return (
        <VStack align="start" mt={[0, "0px"]} {...vStackProps}>
            {IconComponent}
            {TitleComponent}
            {SubHeadingComponent}
        </VStack>
    );
};

export default SelfServeIconHeader;
