import { Namespace } from "i18next";
// import gbi18n from "i18next";
import { UseTranslationResponse } from "react-i18next";
import i18next from "./init";
import { AllNameSpacesAndTypes, I18NextNameSpaceType, LanguageCodeType } from "./types/common";

export const gbTypedTrans = <Ns extends Namespace = "translation", KPrefix = undefined>() => {
    return i18next.t as UseTranslationResponse<Ns, KPrefix>["t"];
};

export const transWithNameSpace = <T extends I18NextNameSpaceType | "translation" = "translation">(ns: T | T[]) => {
    return (key: AllNameSpacesAndTypes[T]) => {
        return gbTypedTrans<T | T[], undefined>()(key, { ns });
    };
};

export const getCurrentLangData = <T>(data: Record<LanguageCodeType, T>): T => {
    const currentLang = i18next.language.split("-")[0];
    return (data as Record<string, T>)[currentLang];
};
