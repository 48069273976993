import { Box, BoxProps, Icon, IconProps } from "@chakra-ui/react";
import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import GBTooltip, { GBTooltipProps } from "./GBTooltip";

export interface InfoTooltipProps extends Omit<GBTooltipProps, "label" | "children"> {
    text: string | JSX.Element;
    infoIconProps?: Omit<IconProps, "as">;
    containerProps?: Omit<BoxProps, "as">;
}

const InfoTooltip: React.FC<InfoTooltipProps> = (props) => {
    const { text, infoIconProps = {}, containerProps = {}, ...rest } = props;
    return (
        <GBTooltip label={text} {...rest}>
            <Box boxSize="4" verticalAlign="middle" as="span" {...containerProps}>
                <Icon as={BiInfoCircle} {...infoIconProps} />
            </Box>
        </GBTooltip>
    );
};

export default InfoTooltip;
