import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    IconButton,
    Link,
    Progress,
    Text,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { UserAsyncSelect } from "app/components/Lead/Selects";
import FormControlWrapper from "app/gbComponents/Misc/FormControlWrapper";
import { FieldTypeEnum, IField } from "app/types/field";
import { File as IFile } from "app/types/file";
import { onDropRejected } from "app/utils/file";
import {
    BooleanReactSelect,
    ControlledElement,
    FormDateInput,
    Input,
    InputArray,
    ReactSelect,
    Textarea,
} from "app/utils/formUtils";
import { formatBytes, uploadMedia, UploadProgress } from "app/utils/react-helpers";
import React, { useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FileIcon } from "react-file-icon";
import { Control, Controller, FieldError, useForm } from "react-hook-form";
import { HiOutlineCloudUpload, HiOutlineTrash } from "react-icons/hi";

const FileuploadComp: React.FC<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (...event: any[]) => void;
    onBlur: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    name: string;
    accountId: string;
    label: string;
    accept?: string;
    disableUploadIcon?: boolean;
    disableDeleteBtn?: boolean;
    maxFileSize: number;
}> = ({
    accountId,
    onChange,
    value,
    label,
    onBlur,
    accept,
    disableUploadIcon = false,
    disableDeleteBtn = false,
    maxFileSize,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const toast = useToast();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const onUploadProgress: UploadProgress = ({ loaded, total }) => {
        setProgress(Math.round((100 * loaded) / total));
    };

    const file: IFile | undefined = value;

    const isPresent = Boolean(file?.id);
    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];
            if (file) {
                setLoading(true);
                const res = await uploadMedia({ accountId, file, onUploadProgress });
                onChange(res);
                onBlur();
                setLoading(false);
                setProgress(0);
                buttonRef.current?.focus();
            }
        },
        [accountId, onChange, onBlur]
    );

    const handleDelete = () => {
        onChange(undefined);
        onBlur();
        buttonRef.current?.focus();
    };
    const { open, getInputProps, getRootProps } = useDropzone({
        noKeyboard: true,
        noClick: true,
        onDrop,
        multiple: false,
        maxFiles: 1,
        maxSize: maxFileSize ?? 20000000,
        onDropRejected: onDropRejected({ toast, maxSize: maxFileSize ?? 20000000 }),
        accept: accept,
    });

    const fileSize = file?.bytes || file?.bits;

    return (
        <FormControlWrapper label={label} formControlProps={{ as: Box, w: "full", ...getRootProps() }}>
            <Box bgColor="white" border="1px solid" borderColor="gray.200" borderRadius="md" p="10px">
                <HStack>
                    <input data-cy="file-upload-cmpt-input" {...getInputProps()} />
                    {isPresent && (
                        <Link download={file?.name} isExternal href={file?.url}>
                            <Box w="30px" h="40px">
                                <FileIcon
                                    extension={file?.extension?.replaceAll(".", "")}
                                    color="brand.100"
                                    labelColor="blue.100"
                                />
                            </Box>
                        </Link>
                    )}
                    <VStack align="start" justify="center" noOfLines={1} flex={1}>
                        {isPresent ? (
                            <>
                                <Link download={file?.name} isExternal href={file?.url}>
                                    <Text sx={{ fontSize: "sm", p: 0 }} title={file?.name}>
                                        {file?.name}
                                    </Text>
                                </Link>
                                <Text sx={{ fontSize: "xs", p: 0 }}>
                                    {fileSize ? formatBytes(fileSize) : "unknown"}
                                </Text>
                            </>
                        ) : (
                            <Text sx={{ p: 0 }}>Please Upload a File</Text>
                        )}
                    </VStack>
                    <IconButton
                        size="sm"
                        onClick={open}
                        ref={buttonRef}
                        hidden={disableUploadIcon}
                        icon={<HiOutlineCloudUpload />}
                        variant="solid"
                        aria-label="upload"
                        type="button"
                    />
                    {isPresent && !disableDeleteBtn && (
                        <IconButton
                            size="sm"
                            icon={<HiOutlineTrash />}
                            variant="solid"
                            aria-label="delete"
                            onClick={handleDelete}
                            type="button"
                        />
                    )}
                </HStack>
                {isLoading && <Progress title={`${progress}%`} size="xs" hasStripe value={progress} mt={1} />}
            </Box>
        </FormControlWrapper>
    );
};

interface FileComponentProps {
    accountId: string;
    name: string;
    label: string;
    control: Control;
    isRequired: boolean;
    error?: string;
    accept?: string;
    disableUploadIcon?: boolean;
    disableDeleteBtn?: boolean;
    fileLimitSize?: number;
    defaultValue?: any;
}

export const FileComponent: React.FC<FileComponentProps> = ({
    accountId,
    name,
    control,
    label,
    isRequired,
    error,
    accept,
    disableUploadIcon,
    disableDeleteBtn,
    fileLimitSize,
    defaultValue,
}) => {
    return (
        <FormControl id={name} isInvalid={Boolean(error)}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={(props) => {
                    return (
                        <FileuploadComp
                            {...props}
                            maxFileSize={fileLimitSize as number}
                            accountId={accountId}
                            label={label}
                            accept={accept}
                            disableUploadIcon={disableUploadIcon}
                            disableDeleteBtn={disableDeleteBtn}
                        />
                    );
                }}
                rules={{ required: isRequired }}
            />
            {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
        </FormControl>
    );
};

interface FieldProps extends IField {
    register: ReturnType<typeof useForm>["register"];
    control: Control;
    accountId: string;
    error?: FieldError | undefined;
}

const Field: React.FC<FieldProps> = (props) => {
    const { register, control, accountId, error, ...field } = props;

    const { id: name, config, isMandatory, fieldValue } = field;
    const { type, placeholder = config.label, options, hint, label } = config;
    const errMessage = error?.message;

    switch (config.type) {
        case FieldTypeEnum.SELECT: {
            const selectOptions = [
                // { label: "Select", value: "" },
                ...(options ?? [])?.map((v) => ({ label: v, value: v })),
            ];
            return (
                <ReactSelect
                    name={name}
                    control={control}
                    options={selectOptions}
                    label={label}
                    placeholder={placeholder}
                    helpText={hint}
                    required={isMandatory}
                    error={errMessage}
                    isClearable
                />
            );
        }
        case FieldTypeEnum.MULTI_SELECT: {
            const selectOptions = [
                // { label: "Select", value: "" },
                ...(options ?? [])?.map((v) => ({ label: v, value: v })),
            ];
            return (
                <ReactSelect<true>
                    name={name}
                    control={control}
                    options={selectOptions}
                    label={label}
                    placeholder={placeholder}
                    helpText={hint}
                    isMulti
                    required={isMandatory}
                    error={errMessage}
                    isClearable
                />
            );
        }
        case FieldTypeEnum.TEXT:
        case FieldTypeEnum.URL:
        case FieldTypeEnum.NUMBER:
            return (
                <Input
                    register={register}
                    name={name}
                    helpText={hint}
                    label={label}
                    placeholder={placeholder}
                    type={type === "NUMBER" ? "number" : "text"}
                    required={isMandatory}
                    error={errMessage}
                />
            );
        case FieldTypeEnum.USER:
            return (
                <ControlledElement
                    element={UserAsyncSelect}
                    name={name}
                    label={label}
                    helpText={hint}
                    placeholder={placeholder}
                    control={control}
                    rules={{ required: isMandatory }}
                    accountId={accountId}
                    error={errMessage}
                />
            );
        case FieldTypeEnum.SWITCH:
            return (
                <BooleanReactSelect
                    control={control}
                    name={name}
                    label={label}
                    helpText={hint}
                    required={isMandatory}
                    placeholder={placeholder}
                    error={errMessage}
                />
            );
        case FieldTypeEnum.DATE:
            return (
                <FormDateInput
                    register={register}
                    name={name}
                    helpText={config.hint}
                    label={config.label}
                    required={field.isMandatory}
                    error={errMessage}
                />
            );
        case FieldTypeEnum.TEXT_AREA:
            return (
                <Textarea
                    register={register}
                    name={name}
                    helpText={hint}
                    label={label}
                    placeholder={placeholder}
                    required={isMandatory}
                    error={errMessage}
                />
            );
        case FieldTypeEnum.FILE:
            return (
                <FileComponent
                    accountId={accountId}
                    name={name}
                    control={control}
                    label={label}
                    isRequired={isMandatory ?? false}
                    error={errMessage}
                />
            );
        case FieldTypeEnum.MULTI_TEXT:
            return (
                <InputArray
                    control={control}
                    required={isMandatory ?? false}
                    helpText={hint}
                    register={register}
                    name={name}
                    label={label}
                    errors={Array.isArray(error) ? error : undefined}
                    error={errMessage}
                />
            );
        default:
            return (
                <Text as="span" fontSize="sm">
                    {fieldValue?.valueString}
                </Text>
            );
    }
};

export default Field;
