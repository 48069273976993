import { Box, VStack, HStack, Flex, FlexProps, Heading, Skeleton, StackProps } from "@chakra-ui/react";
import GBLogoWithBrandName from "app/components/GBLogoWithBrandName";
import { isJSXElement } from "app/utils/common";
import React, { PropsWithChildren } from "react";
import LogoutButton from "../../LogoutButton";
import { useGBMediaQuery } from "app/hooks/useGBMediaQuery";
import BackIconButton from "app/components/Button/BackIconButton";
import { useWindowWidthHeight } from "app/hooks/useWindowWidthHeight";

interface SelfServeLayoutProps extends Omit<StackProps, "title"> {
    title?: string | JSX.Element;
    childrenContainerProps?: FlexProps;
    logout?: boolean;
    onBack?: () => void;
}
const SelfServeLayout: React.FC<PropsWithChildren<SelfServeLayoutProps>> = (props) => {
    const { title, children, childrenContainerProps = {}, logout = false, onBack, ...containerProps } = props ?? {};

    const { isMobile } = useGBMediaQuery();
    const { windowInnerHeight, windowInnerWidth } = useWindowWidthHeight();
    const TitleComponent = React.useMemo(() => {
        if (!title) return null;
        if (isJSXElement(title)) return title;
        return (
            <Heading fontSize="2xl" fontWeight="600" textAlign="center" maxW="600" mx="auto">
                {title}
            </Heading>
        );
    }, [title]);

    return (
        <VStack
            h={{ base: windowInnerHeight, sm: "100vh", lg: "100vh" }}
            w={{ base: windowInnerWidth, sm: "full", lg: "full" }}
            overflow="auto"
            bgColor={{ base: "white", sm: "white", md: "gray.50", lg: "gray.50" }}
            boxSizing={isMobile ? "border-box" : "unset"}
            {...containerProps}
        >
            <HStack
                w="full"
                justify="start"
                align={{ base: "center", sm: "start", lg: "start" }}
                pt={{ base: 5, sm: 6, lg: 6 }}
                px={6}
            >
                {isMobile && (
                    <Box display={{ base: "flex", sm: "none", lg: "none" }} flex={{ base: 1, sm: 0, lg: 0 }}>
                        {onBack && <BackIconButton onClick={onBack} />}
                    </Box>
                )}
                <Box flex={1}>
                    <GBLogoWithBrandName
                        logoProps={{ w: "132px", h: "27px", fallback: <Skeleton w="132px" h="27px" /> }}
                    />
                </Box>
                {TitleComponent && (
                    <Box flex={1} textAlign="center">
                        {TitleComponent}
                    </Box>
                )}
                <Box flex={1}>
                    {logout && (
                        <Flex w="full" flexDir="row-reverse">
                            <LogoutButton />
                        </Flex>
                    )}
                </Box>
            </HStack>
            <Flex w="full" {...childrenContainerProps} flex={1}>
                {children}
            </Flex>
        </VStack>
    );
};

export default SelfServeLayout;
