import { Heading, HeadingProps, Image, ImageProps, Text, TextProps, VStack } from "@chakra-ui/react";
import React from "react";

export interface FailureStateProps {
    title?: string | JSX.Element;
    subtitle?: string | JSX.Element;
    imageProps?: ImageProps;
    titleProps?: HeadingProps;
    subtitleProps?: TextProps;
    cta?: React.ReactNode;
}

const FailureState: React.FC<FailureStateProps> = (props) => {
    const { title, subtitle, imageProps = {}, titleProps = {}, subtitleProps = {}, cta } = props;
    return (
        <VStack justify="center">
            <Image {...imageProps} />
            {title ? (
                <Heading fontSize="xl" {...titleProps}>
                    {title}
                </Heading>
            ) : null}
            {subtitle ? (
                <Text textColor="gray.400" fontSize="sm" {...subtitleProps}>
                    {subtitle}
                </Text>
            ) : null}
            {cta}
        </VStack>
    );
};

export default FailureState;
