// import amplitude, { AmplitudeClient, Callback, Config } from "amplitude-js";
import { Identify, init, track, reset, setUserId, identify } from "@amplitude/analytics-browser";
import { BrowserOptions, ValidPropertyType } from "@amplitude/analytics-types";
import React from "react";

interface InitProps {
    apiKey: string;
    userId?: string;
    config?: BrowserOptions;
}

interface EventTrackProps {
    event: string;
    properties?: Record<string, any>;
    outOfSession?: boolean;
}

export function useInit(props: InitProps): void {
    const { userId, config, apiKey } = props;
    React.useEffect(() => {
        init(apiKey, userId, config);
    }, [apiKey, userId, config]);
}

export const setIdentify = (prop: Record<string, ValidPropertyType>): void => {
    const id = new Identify();
    Object.entries(prop).forEach(([t, p]) => {
        id.set(t, p);
    });
    identify(id);
};

export const trackEvent = (props: EventTrackProps) => {
    const { event, properties } = props;
    track(event, properties);
};

export const disconnect = (): void => {
    reset();
};

export const setUser = (id: string): void => {
    setUserId(id);
};
