import { FieldTypeEnum, FieldValueType, IField, IFieldValue, IMultiSelectValue, ISelectField } from "app/types/field";
import { File as IFile } from "app/types/file";
import { User as IUser } from "app/types/user";
import dayjs from "dayjs";
import pick from "lodash/pick";
import * as yup from "yup";
import { isValidDateString } from "app/utils/date";
import { formatAdditionalFieldDateDisplay } from "app/utils/dateUtils";

interface Option {
    value: string;
    label: string;
}

const getFieldValueKey = (fieldType?: FieldTypeEnum): string | null => {
    switch (fieldType) {
        case FieldTypeEnum.TEXT:
        case FieldTypeEnum.TEXT_AREA:
        case FieldTypeEnum.SELECT:
            return "valueString";
        case FieldTypeEnum.NUMBER:
            return "valueNumber";
        case FieldTypeEnum.MULTI_SELECT:
        case FieldTypeEnum.MULTI_TEXT:
            return "valueArray";
        case FieldTypeEnum.USER:
            return "valueUser";
        case FieldTypeEnum.SWITCH:
            return "valueBoolean";
        case FieldTypeEnum.DATE:
            return "valueDate";
        case FieldTypeEnum.FILE:
            return "valueFile";
        default:
            return null;
    }
};
export const sanitizeFieldValueForForm = (value: unknown, fieldType?: FieldTypeEnum): Option | Option[] | unknown => {
    switch (fieldType) {
        case FieldTypeEnum.SELECT: {
            if (value != null) {
                const val = value as ISelectField;
                return { label: val, value: val };
            }
            return value;
        }
        case FieldTypeEnum.MULTI_TEXT: {
            if (Array.isArray(value)) {
                const val = value as IMultiSelectValue;
                return val.map((v) => ({ value: v }));
            }
            return [{ value: "" }];
        }
        case FieldTypeEnum.MULTI_SELECT: {
            if (Array.isArray(value)) {
                const val = value as IMultiSelectValue;
                return val.map((v) => ({ label: v, value: v }));
            }
            return value;
        }
        case FieldTypeEnum.USER: {
            if (value != null && typeof value === "object") {
                const val = value as Pick<IUser, "id" | "name">;
                return { label: val.name, value: val.id };
            }
            return value;
        }
        case FieldTypeEnum.DATE: {
            if (typeof value === "string") return dayjs(value).format("YYYY-MM-DD");
            return value;
        }
        default:
            return value as FieldValueType;
    }
};

export const getadditionalFieldsDefaultValue = (
    fieldValues: IFieldValue[] | undefined,
    manipulatedFields: IField[]
): Record<string, unknown> => {
    const emptyMultiTextDefaults = manipulatedFields.reduce(
        (acc, cur) => (cur.config.type === FieldTypeEnum.MULTI_TEXT ? { ...acc, [cur.id]: [{ value: "" }] } : acc),
        {}
    );

    const values = fieldValues?.reduce((acc, curr) => {
        const fieldType = (curr.field ?? manipulatedFields?.find((f) => f.id === curr.fieldId))?.config?.type;
        const valueKey = getFieldValueKey(fieldType);
        if (!valueKey) return acc;
        const fieldValuesMap = new Map(Object.entries(curr));
        const value = fieldValuesMap.get(valueKey);
        const fieldValue = sanitizeFieldValueForForm(value, fieldType);
        if (fieldType && fieldValue) return { ...acc, [curr.fieldId]: fieldValue };
        return acc;
    }, {});

    return { ...emptyMultiTextDefaults, ...values };
};
export const mapFieldValues = (fieldValues: Record<string, unknown>, additionalFields: IField[]): IFieldValue[] => {
    const fieldValuesMap = new Map(Object.entries(fieldValues));

    const mapfield = (fieldType: FieldTypeEnum, fieldValue: unknown): Omit<IFieldValue, "fieldId"> => {
        const emptyValue = { value: "", type: fieldType };
        switch (fieldType) {
            case FieldTypeEnum.TEXT:
            case FieldTypeEnum.TEXT_AREA: {
                if (typeof fieldValue !== "string") return emptyValue;
                return { valueString: fieldValue, type: fieldType };
            }
            case FieldTypeEnum.NUMBER: {
                if (typeof fieldValue !== "number" && typeof fieldValue !== "string") return emptyValue;
                const valueInt = Number(fieldValue);
                const value = !Number.isNaN(valueInt) ? parseInt(String(valueInt)) : undefined;
                return { valueNumber: value, type: fieldType };
            }
            case FieldTypeEnum.SELECT: {
                if (!(fieldValue instanceof Object)) return emptyValue;
                if (!fieldValue.hasOwnProperty("value")) return emptyValue;
                const value = (fieldValue as Option).value;
                return { valueString: value, type: fieldType };
            }
            case FieldTypeEnum.MULTI_TEXT:
            case FieldTypeEnum.MULTI_SELECT: {
                if (!Array.isArray(fieldValue)) return emptyValue;
                const value = fieldValue.map((e: unknown) => (e as Option).value.trim()).filter((v) => Boolean(v));
                return { valueArray: value, type: fieldType };
            }
            case FieldTypeEnum.USER: {
                if (!(fieldValue instanceof Object)) return emptyValue;
                if (!fieldValue.hasOwnProperty("value") || !fieldValue.hasOwnProperty("label")) return emptyValue;
                const fValue = fieldValue as Option;
                const value = { id: fValue.value, name: fValue.label }; // FIXME: type change
                return { value: value.id, valueUser: value.id, type: fieldType };
            }
            case FieldTypeEnum.SWITCH: {
                if (typeof fieldValue !== "boolean") return emptyValue;
                return { value: fieldValue, valueBoolean: fieldValue, type: fieldType };
            }
            case FieldTypeEnum.DATE: {
                if (typeof fieldValue !== "string") return emptyValue;
                return { valueDate: dayjs(fieldValue).toISOString(), type: fieldType };
            }
            case FieldTypeEnum.FILE: {
                if (!(fieldValue instanceof Object)) return emptyValue;
                if (!fieldValue.hasOwnProperty("id")) return emptyValue;
                const value = fieldValue as IFile;
                return { valueFile: value, type: fieldType };
            }
            default:
                return emptyValue;
        }
    };

    const fields: (IFieldValue | null)[] = additionalFields.map((field) => {
        const { id: fieldId, config } = field;

        const fieldType = config?.type;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fieldValue = fieldValuesMap.get(fieldId);

        if (typeof fieldId !== "string" || !fieldType || !fieldValue) return null;

        const otherFields = mapfield(fieldType as FieldTypeEnum, fieldValue);

        return {
            fieldId,
            ...otherFields,
        };
    });

    const filteredFields = fields.filter((f: IFieldValue | null) => {
        if (f == null) return false;
        const valueObject = pick(f, [
            "valueString",
            "valueNumber",
            "valueArray",
            "valueUser",
            "valueBoolean",
            "valueDate",
            "valueFile",
        ]);
        const hasSomeValue = Object.entries(valueObject).some(([key, value]) => {
            if (key === "valueBoolean") return value != null;
            return Boolean(value);
        });
        return hasSomeValue;
    });

    return filteredFields as IFieldValue[];
};

export const getValidationSchema = (
    additionalFields: IField[],
    primaryfields?: Record<string, yup.AnySchema>
): yup.AnyObjectSchema => {
    const additionalFieldsShape = additionalFields.reduce((acc, curr) => {
        let validation = yup.mixed().notRequired();
        if (curr.isMandatory && !curr.isDelete) {
            switch (curr.config.type) {
                case "MULTI_TEXT":
                    validation = yup
                        .array()
                        .of(yup.object().shape({ value: yup.string().trim().min(1, `${curr.name} can not be empty`) }))
                        .required(`${curr.name} is Required!`);
                    break;
                case "MULTI_SELECT":
                    validation = yup
                        .array()
                        .ensure()
                        .of(yup.object().shape({ value: yup.string().min(1, `${curr.name} is Required!`) }))
                        .min(1, `${curr.name} is Required!`);

                    break;
                case "TEXT_AREA":
                    validation = yup
                        .string()
                        .trim()
                        .min(1, `${curr.name} is Required!`)
                        .max(5000, `${curr.name} cannot exceed 5000 chars`);
                    break;
                case "DATE":
                    validation = yup.string().test("is-Date", `${curr.name} is Required!`, isValidDateString);
                    break;
                default:
                    validation = yup.mixed().required(`${curr.name} is Required!`);
                    break;
            }
        }
        return { ...acc, [curr.id]: validation };
    }, {});

    const shape = { ...additionalFieldsShape, ...primaryfields };

    return yup.object().shape(shape);
};

export const getFieldDisplay = (fieldValue: IFieldValue): string | number | boolean | undefined => {
    switch (fieldValue.type ?? fieldValue.field?.config.type) {
        case FieldTypeEnum.TEXT:
        case FieldTypeEnum.SELECT:
            return fieldValue.valueString;
        case FieldTypeEnum.NUMBER:
            return fieldValue.valueNumber;
        case FieldTypeEnum.MULTI_SELECT:
            return fieldValue.valueArray?.join(", ");
        case FieldTypeEnum.SWITCH:
            return fieldValue.valueBoolean;
        case FieldTypeEnum.DATE:
            return fieldValue.valueDate ? formatAdditionalFieldDateDisplay(fieldValue.valueDate) : undefined;
        case FieldTypeEnum.USER:
            const value = fieldValue.valueUser;
            return typeof value === "object" ? value.name : undefined;
        case FieldTypeEnum.FILE:
            return fieldValue?.valueFile?.name;
        case FieldTypeEnum.MULTI_TEXT:
            return fieldValue.valueArray?.join(", ");
        default:
            break;
    }
};

export const booleanOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

export const getBooleanValue = (value: boolean | undefined) => {
    return booleanOptions.find((option) => option.value === value);
};
