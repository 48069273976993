import { Tooltip, TooltipProps } from "@chakra-ui/react";
import React from "react";

export type GBTooltipProps = Omit<TooltipProps, "rounded" | "p" | "color" | "fontSize" | "fontWeight">;

const tooltipStyle = {
    rounded: "md",
    p: "2",
    color: "white",
    fontSize: "xs",
    fontWeight: "md",
};

const GBTooltip: React.FC<GBTooltipProps> = (props) => {
    const { children, sx: customStyle = {}, ...rest } = props;
    return (
        <Tooltip hasArrow sx={{ ...tooltipStyle, ...customStyle }} {...rest}>
            {children}
        </Tooltip>
    );
};
export default GBTooltip;
