import { LabelValueWithType } from "app/components/ReplyToConfiguration/types";
import config from "app/app.config";
import {
    ExternalIntegrationType,
    GenericWebhook,
    IMoEngageIntegration,
    IntegrationListType,
    IntegrationType,
    IntegrationTypeV2,
    IntegrationV2,
    IWebEngageIntegration,
    ShipRocketIntegration,
    ShipwayIntegration,
    ZohoCRMIntegration,
} from "app/types/integration";
import { WCAbandonedCart } from "app/types/integration/abandoncart";
import { CashFreeIntegration } from "app/types/integration/cashfree";
import { CleverTapIntegration } from "app/types/integration/cleverTap";
import { IFBLeadsIntegration } from "app/types/integration/fbLeads";
import { LeadSquaredIntegration } from "app/types/integration/leadsquared";
import { IRazorPay } from "app/types/integration/razorPay";
import { IShopifyIntegration } from "app/types/integration/shopify";
import { IWooCommerceIntegration } from "app/types/integration/woocommerce";
import { WCCartFlow } from "app/types/integration/woocommerceCartFlow";
import { ZohoBooksIntegration } from "app/types/integration/zohoBooks";
import abandonedCartLogo from "assets/abandonedCart.png";
import fbImg from "assets/fblogo.svg";
import GoogleSheet from "assets/Google-Sheets.svg";
import Calendly from "assets/Integration/Calendly.svg";
import StripeLogo from "assets/Integration/stripeLogo.svg";
import ShopfloLogo from "assets/Integration/shopfloLogo.svg";
import CashFree from "assets/Integration/Cashfree.svg";
import CleverTap from "assets/Integration/cleverTapLogo.png";
import zohoImg from "assets/Integration/CRM.svg";
import ZohoSignals from "assets/Integration/crm_signals.svg";
import hubspotImg from "assets/Integration/Hubspot.svg";
import Kylas from "assets/Integration/kylas.svg";
import LeadSquared from "assets/Integration/leadsquared.svg";
import Pabbly from "assets/Integration/Pabbly.svg";
import RazorPay from "assets/Integration/razorpay.svg";
import WebHook from "assets/Integration/webhooks.svg";
import MoEngageLogo from "assets/MoEngage.png";
import shiprocket from "assets/shipRocketIcon.png";
import ShipWay from "assets/Shipway.png";
import shopifyImg from "assets/shopifyIcon.svg";
import WebEngageLogo from "assets/WebEngageLogo.png";
import wooCommerceLogo from "assets/woocommerceLogo.svg";
import Zapier from "assets/Zapier.svg";
import { ICalendlyIntegration } from "../types/integration";
import { StripeIntegration } from "app/types/integration/stripe";
import { ShopfloIntegration } from "app/types/integration/shopFlo";

export type ListIntegrationType = IntegrationType | ExternalIntegrationType;

export interface IntegrationListV2Type {
    image: string;
    title: string;
    tags: string[];
    isNew?: boolean;
    description: string;
    linkURL?: string;
    name?: IntegrationType;
    helpURL?: string;
    privacyPolicyURL?: string;
    paid?: string;
}

export const integrationList: Map<ListIntegrationType, IntegrationListType> = new Map([
    [
        "fbleads",
        {
            image: fbImg,
            title: "Facebook Leads",
        },
    ],
    [
        "shipway",
        {
            image: ShipWay,
            title: "ShipWay",
        },
    ],
    [
        "razorpay",
        {
            image: RazorPay,
            title: "RazorPay",
        },
    ],
    [
        "zoho_widget",
        {
            image: zohoImg,
            title: "Zoho",
            linkURL: config.feature_help_guide.zoho_integration.link_URL,
        },
    ],
    [
        "shopify",
        {
            image: shopifyImg,
            title: "Shopify",
        },
    ],
    [
        "woocommerce",
        {
            image: wooCommerceLogo,
            title: "WooCommerce",
        },
    ],
    ["shiprocket", { image: shiprocket, title: "ShipRocket" }],
    [
        "zapier",
        {
            image: Zapier,
            title: "Zapier",
            linkURL: config.feature_help_guide.zapier_integration.link_URL,
        },
    ],
    [
        "pabbly",
        {
            image: Pabbly,
            title: "Pabbly",
            linkURL: config.feature_help_guide.pabbly_integration.link_URL,
        },
    ],
    [
        "hubspot",
        {
            image: hubspotImg,
            title: "HubSpot",
        },
    ],
    [
        "leadsquared",
        {
            image: LeadSquared,
            title: "LeadSquared",
        },
    ],

    [
        "webengage",
        {
            image: WebEngageLogo,
            title: "WebEngage",
        },
    ],
    [
        "moengage",
        {
            image: MoEngageLogo,
            title: "MoEngage",
        },
    ],
    [
        "kylas",
        {
            image: Kylas,
            linkURL: config.feature_help_guide.kylas_integration.link_URL,
            title: "Kylas",
        },
    ],
    [
        "gsheet",
        {
            image: GoogleSheet,
            linkURL: config.feature_help_guide.googleSheet_integration.link_URL,
            title: "Google Sheets",
        },
    ],
    [
        "genericWebhook",
        {
            image: WebHook,
            title: "Generic Webhook",
        },
    ],
    [
        "cashfree",
        {
            image: CashFree,
            title: "Cashfree",
        },
    ],
    [
        "woocommerce_cartflow",
        {
            image: abandonedCartLogo,
            title: "Cartflow",
        },
    ],
    [
        "woocommerce_abandoned_cart",
        {
            image: abandonedCartLogo,
            title: "Abandoned Cart",
        },
    ],
    [
        "cleverTap",
        {
            image: CleverTap,
            title: "CleverTap",
        },
    ],
    [
        "stripe",
        {
            image: StripeLogo,
            title: "Stripe",
        },
    ],
    [
        "shopflo",
        {
            image: ShopfloLogo,
            title: "Shopflo",
        },
    ],
]);

export const integrationListV2: IntegrationListV2Type[] = [
    {
        image: fbImg,
        title: "Facebook Leads",
        description: "Engage with your leads on WhatsApp and send automated follow-up messages.",
        tags: ["Automation", "CRM"],
        name: "fbleads",
        privacyPolicyURL: `${config.branding.links}/#third-party-user-data-use`,
        helpURL: config.feature_help_guide.fbleads_integration.docs,
    },
    {
        image: ShipWay,
        title: "Shipway",
        description: "Send Order updates to your customer on WhatsApp for better experience.",
        tags: ["E-Commerce", "Logistics"],
        name: "shipway",
        helpURL: config.feature_help_guide.shipway_integration.docs,
    },
    {
        image: Calendly,
        title: "Calendly",
        description: "Engage with your leads on WhatsApp and send automated follow-up messages.",
        tags: ["Automation", "CRM"],
        name: "calendly",
        helpURL: config.feature_help_guide.calendly_integration.docs,
    },

    {
        image: CashFree,
        title: "Cashfree",
        description: "Send Payment notifications and subscription updates to drive quick payments",
        tags: ["Payment"],
        name: "cashfree",
        helpURL: config.feature_help_guide.cashfree_integration.docs,
    },

    {
        image: WebHook,
        title: "Generic Webhook",
        description: "Trigger WhatsApp messages from an external system using webhook.",
        tags: ["Automation", "CRM"],
        name: "genericWebhook",
        helpURL: config.feature_help_guide.genericWebhook_integration.docs,
    },

    {
        image: GoogleSheet,
        linkURL: config.feature_help_guide.googleSheet_integration.link_URL,
        title: "Google Sheets",
        description: "Send promotional messages to contacts on your Google Sheets.",
        tags: ["Automation"],
        helpURL: config.feature_help_guide.googleSheet_integration.docs,
    },

    {
        image: hubspotImg,
        title: "HubSpot",
        description: "Engage with your leads and customers on WhatsApp through Hubspot CRM seamlessly",
        tags: ["Automation", "CRM"],
        name: "hubspot",
        helpURL: config.feature_help_guide.hubspot_integration.docs,
    },

    {
        image: Kylas,
        linkURL: config.feature_help_guide.kylas_integration.link_URL,
        title: "Kylas",
        description: "Engage with your leads and customers on WhatsApp through Kylas CRM seamlessly",
        tags: ["Automation", "CRM"],
        helpURL: config.feature_help_guide.kylas_integration.docs,
    },

    {
        image: LeadSquared,
        title: "LeadSquared",
        description: "Engage with your leads and customers on WhatsApp through LeadSquare CRM seamlessly",
        tags: ["Automation", "CRM"],
        name: "leadsquared",
        helpURL: config.feature_help_guide.leadsquared_integration.docs,
    },

    {
        image: Pabbly,
        title: "Pabbly",
        linkURL: config.feature_help_guide.pabbly_integration.link_URL,
        description: "Connect your WhatsApp number with thousands of applications through Zapier",
        tags: ["Automation"],
        helpURL: config.feature_help_guide.pabbly_integration.docs,
    },

    {
        image: RazorPay,
        title: "Razorpay",
        description: "Send Payment notifications and subscription updates to drive quick payments",
        tags: ["Payment"],
        name: "razorpay",
        helpURL: config.feature_help_guide.razorpay_integration.docs,
    },
    {
        image: MoEngageLogo,
        title: "MoEngage",
        description: "Engage your MoEngage contacts by sending personalized WhatsApp messages",
        tags: ["Automation"],
        name: "moengage",
        helpURL: config.feature_help_guide.moengage_integration.docs,
    },
    {
        image: shiprocket,
        title: "Shiprocket",
        description: "Send Order updates to your customer on WhatsApp for better experience",
        tags: ["E-Commerce", "Logistics"],
        name: "shiprocket",
        helpURL: config.feature_help_guide.shiprocket_integration.docs,
    },

    {
        image: shopifyImg,
        title: "Shopify",
        description: "Send Order notifications to your customers and also boost cart recovery",
        tags: ["E-Commerce"],
        name: "shopify",
        helpURL: config.feature_help_guide.shopify_integration.docs,
        paid: "$5/month",
    },

    {
        image: WebEngageLogo,
        title: "WebEngage",
        description: "Engage with your leads and customers on WhatsApp through WebEngage CRM seamlessly",
        tags: ["Automation", "CRM"],
        name: "webengage",
        helpURL: config.feature_help_guide.webengage_integration.docs,
    },

    {
        image: wooCommerceLogo,
        title: "WooCommerce",
        description: "Engage your customers on WhatsApp for notifications, promotions and cart recovery",
        tags: ["E-Commerce"],
        name: "woocommerce",
        helpURL: config.feature_help_guide.woocommerce_integration.docs,
    },

    {
        image: Zapier,
        title: "Zapier",
        linkURL: config.feature_help_guide.zapier_integration.link_URL,
        description: "Connect your WhatsApp number with thousands of applications through Zapier",
        tags: ["Automation"],
        helpURL: config.feature_help_guide.zapier_integration.docs,
    },

    {
        image: zohoImg,
        title: "Zoho CRM",
        linkURL: config.feature_help_guide.zoho_integration.link_URL,
        description: "Engage with your leads and customers on whatsapp through ZOHO CRM seamlessly",
        tags: ["Automation", "CRM"],
        helpURL: config.feature_help_guide.zoho_integration.docs,
    },
    {
        image: abandonedCartLogo,
        title: "CartFlow",
        description: "Revive abandoned shopping carts with WhatsApp nudges – re-engage customers effortlessly.",
        tags: ["E-Commerce"],
        helpURL: config.feature_help_guide.cartFlow_integration.docs,
        name: "woocommerce_cartflow",
    },
    {
        image: ZohoSignals,
        title: "Zoho Signals",
        description: "Receive a notification right in Zoho CRM and never miss important WhatsApp messages again.",
        tags: ["Automation", "CRM"],
        helpURL: config.feature_help_guide.zohoSignals_integration.docs,
        name: "zohoCRM",
    },
    {
        image: ShopfloLogo,
        title: "Shopflo",
        description: "Integrate Shopflo with your WhatsApp Business number and recover abandoned carts quicker!",
        tags: ["Automation", "CRM"],
        // helpURL: "https://docs.gallabox.com/integration/other-integrations/zoho-signals",
        name: "shopflo",
    },
    // {
    //     image: abandonedCartLogo,
    //     title: "Abandoned Cart",
    //     description: "Revive abandoned shopping carts with WhatsApp nudges – re-engage customers effortlessly.",
    //     tags: ["E-Commerce"],
    //     helpURL: config.feature_help_guide.cartFlow_integration.docs,
    //     name: "woocommerce_abandoned_cart",
    // },
    // {
    //     image: ZohoBooks,
    //     title: "Zoho Books",
    //     helpURL: config.feature_help_guide.zohoBooks_integration.docs,
    //     description: "Engage with your leads and customers on whatsapp through ZOHO CRM seamlessly",
    //     tags: ["Automation", "CRM"],
    //     name: "zohobooks",
    // },
    {
        image: CleverTap,
        title: "CleverTap",
        description: "Engage with your leads and customers on WhatsApp through CleverTap seamlessly",
        tags: ["Automation", "CRM"],
        name: "cleverTap",
        helpURL: config.feature_help_guide.clevertap_integration.docs,
    },
    {
        image: StripeLogo,
        title: "Stripe",
        description: "Engage with your leads and customers on WhatsApp through Stripe seamlessly",
        tags: ["Automation", "CRM"],
        name: "stripe",
        helpURL: config.feature_help_guide.stripe_integration.docs,
    },
];

export const sortedIntegrationListV2 = integrationListV2
    .filter((a) => {
        if (config.product_name === "Gallabox") return true;
        return ["Generic Webhook", "Google Sheets"].includes(a.title);
    })
    .sort((a, b) => a.title.localeCompare(b.title));

export const integrationCategoryOptions = [
    "All",
    "Payment",
    "CRM",
    "Logistics",
    "E-Commerce",
    "Automation",
    "Analytics",
];

export const labelValueArray = (integrationItem: IntegrationV2[]): LabelValueWithType[] | undefined => {
    return integrationItem?.map((c: IntegrationV2) => {
        const integrationType = c.type as IntegrationTypeV2;
        const integrationDetails = c[integrationType];
        let brandName: string | undefined;
        let integrationId: string | undefined;

        switch (integrationType) {
            case IntegrationTypeV2.Shopify:
                brandName = (integrationDetails as IShopifyIntegration)?.brandName || "Shopify";
                integrationId = c.id;
                break;
            case IntegrationTypeV2.WooCommerce:
                brandName = (integrationDetails as IWooCommerceIntegration)?.brandName || "WooCommerce";
                integrationId = c.id;
            case IntegrationTypeV2.LeadSquared:
                brandName = (integrationDetails as LeadSquaredIntegration)?.brandName || "LeadSquared Integration";
                integrationId = c.id;
                break;
            case IntegrationTypeV2.CleverTap:
                brandName = (integrationDetails as CleverTapIntegration)?.integrationName || "CleverTap Integration";
                integrationId = c.id;
                break;
            case IntegrationTypeV2.WebEngage:
                brandName = (integrationDetails as IWebEngageIntegration)?.name;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.HubSpot:
                brandName = "HubSpot";
                integrationId = c.id;
                break;
            case IntegrationTypeV2.FBLeads:
                brandName = (integrationDetails as IFBLeadsIntegration)?.brandName;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.CashFree:
                brandName = (integrationDetails as CashFreeIntegration)?.brandName;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.RazorPay:
                brandName = (integrationDetails as IRazorPay)?.brandName;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.ShipRocket:
                brandName = (integrationDetails as ShipRocketIntegration)?.brandName;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.GenericWebhook:
                brandName = (integrationDetails as GenericWebhook)?.brandName;
                integrationId = c.id;
                break;
            case IntegrationTypeV2.Shipway:
                brandName = (integrationDetails as ShipwayIntegration)?.brandName;
                integrationId = c.id;
                break;

            case IntegrationTypeV2.zohoBooks:
                brandName = (integrationDetails as ZohoBooksIntegration)?.integrationName || "Zoho Books Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.Calendly:
                brandName = (integrationDetails as ICalendlyIntegration)?.integrationName;
                integrationId = c.id;
                break;

            case IntegrationTypeV2.woocommerce_abandoned_cart:
                brandName = (integrationDetails as WCAbandonedCart)?.integrationName || "Unknown Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.woocommerce_cartflow:
                brandName = (integrationDetails as WCCartFlow)?.brandName || "Unknown Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.Stripe:
                brandName = (integrationDetails as StripeIntegration)?.integrationName || "Stripe Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.Moengage:
                brandName = (integrationDetails as IMoEngageIntegration)?.brandName || "Moengage Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.Shopflo:
                brandName = (integrationDetails as ShopfloIntegration)?.integrationName || "Shopflo Integration";
                integrationId = c.id;
                break;

            case IntegrationTypeV2.zohoCRM:
                brandName = (integrationDetails as ZohoCRMIntegration)?.integrationName || "Zoho signal Integration";
                integrationId = c.id;
                break;

            default:
                brandName = "Unknown Integration";
                integrationId = c.id;
                break;
        }

        return { label: brandName as string, value: integrationId as string, type: integrationType };
    });
};
