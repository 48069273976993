import { AddIcon } from "@chakra-ui/icons";
import {
    Badge,
    Button,
    Grid,
    HStack,
    Icon,
    IconButton,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    VStack
} from "@chakra-ui/react";
import AnimatedLoadingState from "app/components/GBLoadingState";
import Table from "app/components/Tables/SimpleTableV2";
import { useCancelTransaction, useListTransaction } from "app/fetchHooks/prepaid-wallet";
import { IWallet, ListTransaction as Transaction } from "app/types/prepaid-wallet";
import NoCredit from "assets/empty-balance.svg";
import RazorpayIcon from "assets/Payments/razorpay.svg";
import StripeIcon from "assets/Payments/stripe.svg";
import dayjs from "dayjs";
import startCase from "lodash/startCase";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { CellProps, Column } from "react-table";

interface TransactionPaymentTableProps {
    accountId: string;
    walletId: string;
    currency: string;
    wallet?: IWallet;
}

type RowData = Transaction | IWallet;

const isTransaction = (data: RowData): data is Transaction => {
    return (data as Transaction)?.walletId != null;
};

const TransactionPaymentTable: React.FC<TransactionPaymentTableProps> = (props) => {
    const { accountId, walletId, wallet, currency } = props;

    const { data, isLoading, isError, error } = useListTransaction<Transaction>({
        accountId,
        query: "list",
        type: "credit",
        walletId,
    });

    const { mutate } = useCancelTransaction(accountId, walletId);
    const [loading, setLoading] = React.useState(false);

    const ListData = data ?? [];

    const combinedData = wallet?.pendingPayment?.id ? [wallet, ...ListData] : ListData;

    const CancelTransaction = async (paymentLinkId: string) => {
        if (paymentLinkId == null) return;
        try {
            await mutate({ paymentLinkId });
        } catch (e) {}
    };

    const handleUrl = async (url?: string) => {
        setLoading(true);
        if (url == null) return;
        try {
            await window.open(url, "_self");
        } catch (e) {}
    };

    const columns: Column<RowData>[] = [
        {
            Header: "Description",
            // eslint-disable-next-line react/display-name
            Cell: () => {
                return <Text noOfLines={1}>Message Credits Recharge</Text>;
            },
        },
        {
            Header: "Created At",
            // eslint-disable-next-line react/display-name
            Cell: ({ row }: CellProps<RowData>) => {
                const value = isTransaction(row.original)
                    ? (row.original as Transaction).createdAt
                    : (row.original as IWallet).pendingPayment?.updatedAt;
                const date: string = value ? dayjs(value).format("DD MMM, YYYY ") : "None";
                return <Text noOfLines={1}>{date}</Text>;
            },
        },
        {
            Header: "Amount",
            // eslint-disable-next-line react/display-name
            Cell: ({ row }: CellProps<RowData>) => {
                const data =
                    (row.original as IWallet).pendingPayment?.amount ?? (row.original as Transaction).amount ?? 0;

                return (
                    <HStack>
                        <AddIcon w={2} h={2} />
                        <Text noOfLines={1}>{`${currency} ${(data / 1000).toFixed(4)}`}</Text>
                    </HStack>
                );
            },
        },
        {
            Header: "Status",
            id: "status",
            // eslint-disable-next-line react/display-name
            Cell: ({ row }: CellProps<RowData>) => {
                const data = (row.original as IWallet)?.pendingPayment;
                return (
                    <Badge rounded="base" px={2} colorScheme={data ? "blue" : "green"}>
                        {data ? "Pending" : "Paid"}
                    </Badge>
                );
            },
        },
        {
            Header: "payment gateway",
            id: "meta",
            // eslint-disable-next-line react/display-name
            Cell: ({ row }: CellProps<RowData>) => {
                const data = (row.original as Transaction).meta;
                const gateway = data?.paymentGateway;
                if (!gateway) return null;
                return (
                    <Image
                        src={gateway === "razorpay" ? RazorpayIcon : StripeIcon}
                        boxSize="8"
                        alt="App icon"
                        title={startCase(gateway ?? "")}
                    />
                );
            },
        },
        {
            id: "payNow",
            // eslint-disable-next-line react/display-name
            Cell: ({ row }: CellProps<RowData>) => {
                const { pendingPayment } = (row.original as IWallet) ?? {};
                const { url } = pendingPayment ?? {};
                return (
                    <>
                        {pendingPayment ? (
                            <Button size="xs" colorScheme="brand" isLoading={loading} onClick={() => handleUrl(url)}>
                                Pay Now
                            </Button>
                        ) : null}
                    </>
                );
            },
        },
        {
            id: "cancel",
            // eslint-disable-next-line react/display-name
            Cell: ({ row }: CellProps<RowData>) => {
                const { pendingPayment } = (row.original as unknown as IWallet) ?? {};
                const transactionInvoiceUrl = (row.original as unknown as Transaction)?.meta?.url;
                const { id } = pendingPayment ?? {};
                if (!pendingPayment && !transactionInvoiceUrl) return <></>;
                return (
                    <Menu isLazy>
                        <MenuButton minWidth="30px" as={IconButton}>
                            <Icon as={BsThreeDotsVertical} />
                        </MenuButton>
                        <MenuList px={3}>
                            {pendingPayment ? (
                                <MenuItem
                                    iconSpacing="1.5"
                                    icon={<AiOutlineCloseCircle size="20" />}
                                    fontSize="14px"
                                    borderRadius="6px"
                                    color="gray.500"
                                    boxShadow="sm"
                                    onClick={() => CancelTransaction(id as string)}
                                >
                                    Cancel Transaction
                                </MenuItem>
                            ) : null}
                            {transactionInvoiceUrl ? (
                                <MenuItem
                                    iconSpacing="1.5"
                                    icon={<MdOutlineDownloadForOffline size="20" />}
                                    fontSize="14px"
                                    borderRadius="6px"
                                    color="gray.500"
                                    boxShadow="sm"
                                    as={Link}
                                    href={transactionInvoiceUrl}
                                >
                                    Download Invoice
                                </MenuItem>
                            ) : null}
                        </MenuList>
                    </Menu>
                );
            },
        },
    ];

    if (isLoading) {
        return (
            <Grid placeItems="center" bg="white" w="full" h="full" maxH="full" maxW="full">
                <AnimatedLoadingState spinnerSize={40} title="Loading..." />
            </Grid>
        );
    }

    if (isError) {
        return (
            <VStack w="full" h="full" justifyContent="center" alignItems="center">
                <Text color="red.500">{error.message}</Text>
            </VStack>
        );
    }

    if (!combinedData?.length) {
        return (
            <Grid placeItems="center" bg="white" w="full" h="full">
                <Image h="177px" w="245px" src={NoCredit} />
                <Text fontWeight="medium" color="gray.800" fontSize="18px">
                    Your Message Credits balance is empty
                </Text>
            </Grid>
        );
    }

    return <Table<Transaction | IWallet> data={combinedData} columns={columns} isStickyHeader />;
};

export default TransactionPaymentTable;
