import { ContentFormatTypes } from "app/components/FilePreview";
import TemplateMsgComponent from "app/components/TemplateMsgPreview/TemplateMsgComponent";
import { TemplatePreviewObjProps } from "app/components/TemplateMsgPreview/types";
import { getMustacheVariables, isRightToLeftLanguage } from "app/screens/Account/WATemplatesV2/utilities/utils";
import {
    StringObject,
    TemplateMessageComponentBodyExample,
    WATemplateMessageComponent,
    WATemplateValues,
} from "app/types";
import React from "react";
import { FormBodyValues, TemplateMessageFormValues } from "../SendWATemplates/EditTemplateVariables";

const formValuesToBodyPreviewValues = (bodyValues?: FormBodyValues[]): StringObject | undefined => {
    return bodyValues?.reduce((acc, bv) => ({ ...acc, [bv.variable]: bv.value || `{{${bv.variable}}}` }), {});
};

interface WATemplatePreviewProps {
    components: WATemplateMessageComponent[];
    templateValues?: WATemplateValues;
    templateFormValues?: TemplateMessageFormValues;
    language?: string;
    showBodyVariable?: boolean;
}

const WATemplatePreview: React.FC<WATemplatePreviewProps> = ({
    components,
    templateValues,
    templateFormValues,
    language,
    showBodyVariable = false,
}) => {
    const parsedTemplate = React.useMemo(() => {
        const templateObj: TemplatePreviewObjProps = {};

        // header
        const header = components.find((c) => c.type === "HEADER");

        if (header?.format === "TEXT") {
            templateObj["headerText"] = header.text;
        } else if (header?.format === "DOCUMENT" || header?.format === "IMAGE" || header?.format === "VIDEO") {
            templateObj["headerMedia"] = {
                mediaUrl: header.filePath,
                type: header.fileMetaData?.fileType,
                format: header?.format?.toLocaleLowerCase() as ContentFormatTypes,
            };
        } else if (header?.format === "LOCATION") {
            templateObj["location"] = header.location;
        }

        // body

        const body = components.find((c) => c.type === "BODY");
        templateObj["bodyText"] = body?.text;
        templateObj["bodyTextSampleVarValues"] = getMustacheVariables(body?.text ?? "").reduce(
            (acc, curr, currIndex) => {
                return {
                    ...acc,
                    [curr]: (body?.example as TemplateMessageComponentBodyExample)?.body_text?.[0]?.[currIndex] ?? "",
                };
            },
            {} as Record<string, string>
        );

        // footer
        const footer = components.find((c) => c.type === "FOOTER");
        templateObj["footerText"] = footer?.text;

        //buttons
        const buttons = components.find((c) => c.type === "BUTTONS")?.buttons;

        templateObj["buttons"] = buttons;

        // cards
        const cards = components.find((c) => c.type === "CAROUSEL")?.cards;

        templateObj["cards"] = cards?.map(({ components }) => {
            //card header
            const header = components.find((c) => c.type === "HEADER");
            const body = components.find((c) => c.type === "BODY");
            const buttons = components.find((c) => c.type === "BUTTONS")?.buttons;

            return {
                headerMedia: {
                    mediaUrl: header?.filePath,
                    type: header?.fileMetaData?.fileType,
                    format: header?.format?.toLocaleLowerCase() as ContentFormatTypes,
                },
                bodyText: body?.text,
                bodyTextSampleVarValues: getMustacheVariables(body?.text ?? "").reduce(
                    (acc, curr, currIndex) => {
                        return {
                            ...acc,
                            [curr]:
                                (body?.example as TemplateMessageComponentBodyExample)?.body_text?.[0]?.[currIndex] ??
                                "",
                        };
                    },
                    {} as Record<string, string>
                ),
                buttons,
            };
        });

        return templateObj;
    }, [components]);

    const templateObj = React.useMemo(() => {
        if (templateFormValues) {
            const { bodyValues, cardValues, headerValues } = templateFormValues;

            return {
                ...parsedTemplate,
                bodyTextSampleVarValues: formValuesToBodyPreviewValues(bodyValues),
                ...(parsedTemplate?.headerMedia
                    ? {
                          headerMedia: {
                              ...parsedTemplate.headerMedia,
                              mediaUrl: headerValues?.mediaUrl,
                          },
                      }
                    : {}),

                ...(parsedTemplate?.cards
                    ? {
                          cards: parsedTemplate?.cards?.map((card, cardIndex) => ({
                              ...card,
                              bodyTextSampleVarValues: formValuesToBodyPreviewValues(
                                  cardValues?.[cardIndex]?.bodyValues
                              ),
                              ...(card?.headerMedia
                                  ? {
                                        headerMedia: {
                                            ...card.headerMedia,
                                            mediaUrl: cardValues?.[cardIndex]?.headerValues?.mediaUrl,
                                        },
                                    }
                                  : {}),
                          })),
                      }
                    : {}),
            };
        } else if (templateValues) {
            const { bodyValues, cardValues, headerValues } = templateValues;

            return {
                ...parsedTemplate,
                bodyTextSampleVarValues: bodyValues,
                ...(parsedTemplate?.headerMedia
                    ? {
                          headerMedia: {
                              ...parsedTemplate.headerMedia,
                              mediaUrl: headerValues?.mediaUrl,
                          },
                      }
                    : {}),

                ...(parsedTemplate?.cards
                    ? {
                          cards: parsedTemplate?.cards?.map((card, cardIndex) => ({
                              ...card,
                              bodyTextSampleVarValues: cardValues?.[cardIndex]?.bodyValues,
                              ...(card?.headerMedia
                                  ? {
                                        headerMedia: {
                                            ...card.headerMedia,
                                            mediaUrl: cardValues?.[cardIndex]?.headerValues?.mediaUrl,
                                        },
                                    }
                                  : {}),
                          })),
                      }
                    : {}),
            };
        } else {
            return parsedTemplate;
        }
    }, [parsedTemplate, templateValues, templateFormValues]);

    return (
        <TemplateMsgComponent
            templateObj={templateObj}
            isRTL={isRightToLeftLanguage(language)}
            showBodyVariable={showBodyVariable}
        />
    );
};

export default WATemplatePreview;
