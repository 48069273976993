import { Button, HStack, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import config from "app/app.config";
import { useConnectCalendly, useDeleteCalendlyIntegration } from "app/fetchHooks/integration";
import { YupSchema } from "app/types";
import { Input } from "app/utils/formUtils";
import { useAccountId } from "app/utils/react-helpers";
import Calendly from "assets/Integration/Calendly.svg";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import IntegrationConnectStrip from "../IntegrationConnectStrip";

interface CalendlyConnectFormProps {
    integrationId: string;
}

export interface CalendlyConnectForm {
    integrationName: string;
}

const connectSchema = yup.object().shape<YupSchema<CalendlyConnectForm>>({
    integrationName: yup.string().trim().required("Integration Name is required"),
});

export const CalendlyConnectForm: FC<CalendlyConnectFormProps> = (props) => {
    const { integrationId } = props;
    const accountId = useAccountId();
    const history = useHistory();

    const { mutate: deleteCalendly } = useDeleteCalendlyIntegration({
        accountId,
        integrationId,
        onComplete: () => {
            history.push("/integration");
        },
    });

    const { mutate, isLoading } = useConnectCalendly({
        accountId,
        onSuccess: (data) => {
            window.open(data, "_self");
        },
    });

    const { register, handleSubmit, errors } = useForm<CalendlyConnectForm>({
        resolver: yupResolver<CalendlyConnectForm>(connectSchema),
    });

    const handleDelete = () => deleteCalendly();

    const connectCalendlyIntegration = (data: CalendlyConnectForm) => mutate({ integrationName: data.integrationName });

    return (
        <IntegrationConnectStrip
            name="calendly"
            integrationName="Calendly"
            integrationDescription="Integrate Calendly with your Official WhatsApp Business Number and send event
            notifications to your customers."
            integrationLogo={Calendly}
            integrationId={integrationId}
            onIntegrationDelete={handleDelete}
        >
            {({ onPopoverClose }) => {
                return (
                    <VStack alignItems="stretch" as="form" onSubmit={handleSubmit(connectCalendlyIntegration)}>
                        <Input
                            label="Enter Integration Name"
                            name="integrationName"
                            size="sm"
                            register={register}
                            borderRadius="6px"
                            placeholder="Enter Integration Name"
                            error={errors?.integrationName?.message}
                        />
                        <HStack justify="flex-end" py={3}>
                            <Button onClick={onPopoverClose} borderRadius="6px" size="sm">
                                Cancel
                            </Button>
                            <Button
                                _hover={{
                                    backgroundColor: "blue.600",
                                    boxShadow: "md",
                                }}
                                isLoading={isLoading}
                                borderRadius="6px"
                                size="sm"
                                bg="blue.500"
                                color="white"
                                type="submit"
                            >
                                <span>Submit</span>
                            </Button>
                        </HStack>
                    </VStack>
                );
            }}
        </IntegrationConnectStrip>
    );
};
