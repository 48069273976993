import config from "app/app.config";
import React from "react";
import { WindowObjWithCanny } from "./types";

interface UseCannyWidgetProps {
    isCollapsed?: boolean;
}

const useCannyWidget = (props: UseCannyWidgetProps = {}): void => {
    React.useEffect(() => {
        const appID = config.notifications.canny.app_id;
        if (!appID) {
            console.warn("Canny app id is missing");
            return;
        }
        if ((window as WindowObjWithCanny).Canny) {
            (window as WindowObjWithCanny).Canny("initChangelog", {
                appID,
                position: "top",
                align: "left",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isCollapsed]);
};

export default useCannyWidget;
