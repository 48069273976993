import { CloseIcon, Icon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    ButtonProps,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    useDisclosure,
} from "@chakra-ui/react";
import { useDebounce } from "app/hooks/useDebounce";
import React from "react";
import { FiSearch } from "react-icons/fi";
import { RiSearchLine } from "react-icons/ri";
import LoadingState from "../LoadingState";

interface SearchButtonProps {
    value: string;
    placeholder?: string;
    onChange: (event: string) => void;
    delay?: number;
    inputProps?: React.ComponentProps<typeof Input>;
    isLoading?: boolean;
    prefixAttribute?: string;
}

export const SearchBox: React.FC<SearchButtonProps> = (props) => {
    const { value, onChange, delay, placeholder, inputProps, isLoading = false, prefixAttribute } = props;
    const [InternalValue, setInternalValue] = React.useState(value);

    const debouncedSearchTerm = useDebounce<string>(InternalValue, delay || 0);
    React.useEffect(() => {
        onChange(debouncedSearchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    return (
        <InputGroup h="2rem">
            <InputLeftElement h="2rem" pointerEvents="none">
                {isLoading ? <LoadingState /> : <Icon as={FiSearch} color="gray.300" boxSize={3} />}
            </InputLeftElement>
            <Input
                type="text"
                placeholder={placeholder ?? "Search"}
                value={InternalValue}
                onChange={(e) => setInternalValue(e.target.value)}
                backgroundColor="white"
                pl="2rem"
                lineHeight="1.2"
                size="sm"
                autoFocus
                data-cy={`${prefixAttribute}-search-box`}
                {...inputProps}
            />
            {InternalValue && (
                <InputRightElement width="3rem" h="2rem">
                    <IconButton
                        onMouseDown={() => setInternalValue("")}
                        aria-label="Clear"
                        size="xs"
                        isRound
                        _hover={{ bg: "gray.100" }}
                        colorScheme="gray.300"
                        transition="0.3s"
                        icon={<CloseIcon color="gray.300" boxSize={3} />}
                        data-cy={`${prefixAttribute}-search-box-clear`}
                    />
                </InputRightElement>
            )}
        </InputGroup>
    );
};

export const CollapsibleSearch: React.FC<SearchButtonProps> = (props) => {
    const { value, prefixAttribute } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    React.useEffect(() => {
        if (value.length < 1) {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    if (isOpen)
        return (
            <Box
                w="240px"
                h="2rem"
                onBlur={() => {
                    if (value.length < 1) {
                        onClose();
                    }
                }}
            >
                <SearchBox {...props} />
            </Box>
        );
    return (
        <IconButton
            aria-label="Search"
            size="sm"
            transition="all 0.2s"
            rounded="md"
            variant="outline"
            bg="white"
            onClick={onOpen}
            data-cy={`${prefixAttribute}-search-iconButton`}
            icon={<Icon as={RiSearchLine} boxSize={4} textColor="gray.600" />}
        />
    );
};

interface CreateButtonProps extends ButtonProps {
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
    displayText: string;
}
export const CreateButton: React.FC<CreateButtonProps> = ({ onClick, displayText, ...props }) => {
    return (
        <Button variant="solid" size="sm" onClick={onClick} colorScheme="brand" {...props}>
            {displayText}
        </Button>
    );
};

export const SaveButton: React.FC<ButtonProps> = ({ children, sx, ...props }) => {
    return (
        <Button
            variant="solid"
            colorScheme="brand"
            sx={{
                borderRadius: "6px",
                bg: "brand.500",
                h: "40px",
                w: "94px",
                fontSize: "16px",
                fontWeight: 400,
                fontStyle: "normal",
                ...sx,
            }}
            {...props}
        >
            <span>{children}</span>
        </Button>
    );
};

export const CancelButton: React.FC<ButtonProps> = ({ children, sx, ...props }) => {
    return (
        <Button
            variant="outline"
            sx={{
                borderRadius: "6px",
                bg: "gray.200",
                h: "40px",
                w: "94px",
                fontSize: "16px",
                fontWeight: 400,
                fontStyle: "normal",
                color: "gray.600",
                ...sx,
            }}
            {...props}
        >
            <span>{children}</span>
        </Button>
    );
};

export const RemoveButton: React.FC<ButtonProps> = ({ children, sx, ...props }) => {
    return (
        <Button
            variant="outline"
            sx={{
                borderRadius: "full",
                bg: "rgba(255, 84, 84, 0.1);",
                borderColor: "rgba(255, 84, 84, 0.1);",
                h: "20px",
                fontSize: "11px",
                letterSpacing: "0.02em",
                fontWeight: 400,
                fontStyle: "normal",
                color: "#FD3737",
                ...sx,
            }}
            {...props}
        >
            <span>{children}</span>
        </Button>
    );
};
