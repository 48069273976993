import { ComponentWithAs, HStack, Icon, IconProps, Text, useToken, VStack } from "@chakra-ui/react";
import { WhatsappIcon } from "app/icons";
import { Channel, LabelValue } from "app/types";
import { formatPhoneNumber, useWaChannels } from "app/utils/react-helpers";
import React, { FC, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import { IconType as ChakraIconType } from "react-icons/lib";
import ReactSelect, {
    components,
    IndicatorContainerProps,
    OptionProps,
    OptionTypeBase,
    Props as ReactSelectInputProps,
    SingleValueProps,
    Styles,
} from "react-select";
type IconType = ChakraIconType | ComponentWithAs<"svg", IconProps>;

interface LabelValueWithDescription extends LabelValue {
    labelDescription?: string;
}

export interface LabelValueWithIcon extends LabelValueWithDescription {
    icon?: IconType;
}

const IconOption = (props: OptionProps<LabelValueWithIcon, false>) => (
    <components.Option {...props}>
        <HStack w="full">
            {props.data.icon && <Icon as={props.data.icon} boxSize="4" />}
            <VStack align="flex-start" flex={1} w="full" spacing={0}>
                <Text color="gray.700" fontSize="xs" noOfLines={1}>
                    {props.data.label}
                </Text>
                <Text color="gray.500" fontSize="x-small" noOfLines={1}>
                    {props.data.labelDescription}
                </Text>
            </VStack>
        </HStack>
    </components.Option>
);

const SingleValue = (props: SingleValueProps<LabelValueWithIcon>) => (
    <components.SingleValue {...props}>
        <HStack w="full">
            {props.data.icon && <Icon as={props.data.icon} boxSize="4" />}
            <Text color="gray.700" fontSize="sm" noOfLines={1}>
                {props.data.label}
            </Text>
            {props.data?.labelDescription && (
                <Text color="gray.700" flex={1} fontSize="xs" noOfLines={1}>
                    {`(${props.data.labelDescription})`}
                </Text>
            )}
        </HStack>
    </components.SingleValue>
);

const DropdownIndicator = (props: IndicatorContainerProps<LabelValueWithIcon, false>) => (
    <components.IndicatorsContainer {...props}>
        <HStack w="full" pr="4">
            <Icon as={FaChevronDown} boxSize="3" />
        </HStack>
    </components.IndicatorsContainer>
);

export interface StyledReactSelectProps extends Omit<ReactSelectInputProps<LabelValueWithIcon>, "onChange" | "value"> {
    defaultValue?: LabelValueWithIcon;
    value?: LabelValueWithIcon;
    onChange?: (value?: LabelValueWithIcon) => void;
    options: LabelValueWithIcon[];
    placeholder?: string;
}
export const useReactSelectStyles = <OptionType extends OptionTypeBase = LabelValue, isMulti extends boolean = false>(
    overrides?: Partial<Styles<OptionType, isMulti>>
): Partial<Styles<OptionType, isMulti>> => {
    const [gray800, gray200, gray100] = useToken("colors", ["gray.800", "gray.200", "gray.100"]);
    return {
        container: (base) => ({
            ...base,
            width: "100%",
            fontSize: "12px",
        }),
        option: (base, state) => {
            return {
                ...base,
                backgroundColor: state.isSelected ? gray200 : "transparent",
                cursor: state.isDisabled ? "not-allowed" : "pointer",
                ":hover": {
                    backgroundColor: state.isSelected ? gray200 : gray100,
                },
                padding: "8px",
                ...(state.isSelected ? { color: gray800 } : {}),
            };
        },
        menu: (base) => ({
            ...base,
            paddingInline: "5px",
        }),
        indicatorSeparator: () => ({}),
        placeholder: (base) => ({
            ...base,
            fontSize: "14px",
        }),
        control: (base) => ({
            ...base,
            fontSize: "14px",
            borderRadius: "6px",
            minHeight: "auto",
            border: `1.5px solid ${gray200}`,
            cursor: "text",
            "&:hover": {
                border: `1.5px solid ${gray200}`,
            },
        }),
        ...overrides,
    };
};

export const StyledReactSelect: FC<StyledReactSelectProps> = (props) => {
    const { defaultValue, value, onChange, options, placeholder = "Search", ...restProps } = props;
    const styles = useReactSelectStyles<LabelValueWithIcon>();

    return (
        <ReactSelect
            styles={styles}
            {...restProps}
            components={{ Option: IconOption, SingleValue, DropdownIndicator }}
            options={options}
            placeholder={placeholder}
            onChange={(v) => onChange?.(v as LabelValueWithIcon | undefined)}
            defaultValue={defaultValue}
            value={value}
        />
    );
};

export const getWaChannelSelectOption = (waChannelList?: Channel[]): Partial<LabelValueWithIcon>[] | undefined => {
    return waChannelList?.map((waChannel) => ({
        label: waChannel.whatsapp?.whatsappName,
        value: waChannel?.id,
        labelDescription: formatPhoneNumber(waChannel.whatsapp?.whatsappNumber),
        icon: WhatsappIcon,
    }));
};

interface ReactChannelSelectProps extends Omit<StyledReactSelectProps, "options"> {
    selectFirstOnEmpty?: boolean;
}

const ReactChannelSelect: FC<ReactChannelSelectProps> = (props) => {
    const { selectFirstOnEmpty, ...otherProps } = props;
    const selectProps = otherProps as StyledReactSelectProps;

    const waChannelList = useWaChannels() ?? [];

    useEffect(() => {
        if (!selectFirstOnEmpty || selectProps.value?.value) return;
        const [firstChannel] = waChannelList;
        if (!firstChannel?.id || !firstChannel.whatsapp) return;
        const { whatsappName, whatsappNumber } = firstChannel.whatsapp;
        selectProps.onChange?.({
            label: whatsappName ?? whatsappNumber,
            value: firstChannel.id,
            icon: WhatsappIcon,
            labelDescription: whatsappName ? formatPhoneNumber(whatsappNumber) : undefined,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waChannelList]);

    const options = getWaChannelSelectOption(waChannelList) as LabelValueWithIcon[];

    return <StyledReactSelect {...props} options={options} />;
};

export default ReactChannelSelect;
