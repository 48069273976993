import { Box, StackProps, Progress, ProgressProps, VStack } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import SelfServeIconHeader, { SelfServeIconHeaderProps } from "./SelfServeIconHeader";

interface ContainerProps {
    containerProps?: StackProps;
    progressValue?: number;
    progressBarProps?: ProgressProps;
}

export type SelfServeContainerProps = ContainerProps & SelfServeIconHeaderProps;

const SelfServeContainer: React.FC<SelfServeContainerProps> = (props) => {
    const { children, containerProps = {}, progressBarProps = {}, ...rest } = props;
    return (
        <Box
            mx="auto"
            w={{ base: "full", sm: "unset", md: "unset", lg: "unset" }}
            h={{ base: "full", sm: "unset", lg: "unset" }}
        >
            {!!progressBarProps?.value && (
                <Progress
                    size="xs"
                    {...progressBarProps}
                    display={{ base: "none", sm: "none", md: "flex", lg: "flex" }}
                />
            )}
            <VStack
                p={{
                    base: "32px 32px 16px 32px",
                    sm: "32px 32px 40px 32px",
                    md: "32px 65px 40px 65px",
                    lg: "32px 65px 40px 65px",
                }}
                width={{ base: window.innerWidth, sm: "450px", md: "480px", lg: "480px" }}
                border={{ base: "none", sm: "none", md: "1px solid", lg: "1px solid" }}
                borderColor="gray.200"
                boxShadow={{
                    base: "none",
                    sm: "none",
                    md: "0px 9px 10px rgba(0, 0, 0, 0.05)",
                    lg: "0px 9px 10px rgba(0, 0, 0, 0.05)",
                }}
                mx="auto"
                fontFamily="Inter"
                backgroundColor="white"
                borderBottomRadius="md"
                alignItems="start"
                spacing="5"
                h={{ base: "full", sm: "unset", lg: "unset" }}
                {...containerProps}
            >
                <SelfServeIconHeader {...rest} />
                {children}
            </VStack>
        </Box>
    );
};

interface SelfServeContainerV2Props {
    containerProps?: StackProps;
    paperProps?: StackProps;
    progressValue?: number;
    progressBarProps?: ProgressProps;
    paperChildren: React.ReactNode;
}

export const SelfServeContainerV2: React.FC<PropsWithChildren<SelfServeContainerV2Props>> = (props) => {
    const { paperChildren, children, containerProps, paperProps = {}, progressBarProps = {} } = props;
    return (
        <VStack w="full" {...containerProps}>
            {progressBarProps.value && (
                <Progress
                    size="xs"
                    {...progressBarProps}
                    display={{ base: "none", sm: "none", md: "flex", lg: "flex" }}
                />
            )}
            <VStack
                border={{ base: "none", sm: "none", md: "1px solid", lg: "1px solid" }}
                borderColor="gray.200"
                boxShadow={{
                    base: "none",
                    sm: "none",
                    md: "0px 9px 10px rgba(0, 0, 0, 0.05)",
                    lg: "0px 9px 10px rgba(0, 0, 0, 0.05)",
                }}
                mx="auto"
                bg="white"
                rounded="md"
                align="start"
                {...paperProps}
            >
                {paperChildren}
            </VStack>
            {children}
        </VStack>
    );
};

export default SelfServeContainer;
