import React from "react";
import StateManager, { ValueContainerProps } from "react-select";
import ReactSelect, { OptionTypeBase } from "react-select";
import { Props as SelectProps } from "react-select";
import FloatingLabelValueContainer from "./FloatingLabelValueContainer";
import { ReactSelectRef } from "./type";
import { useReactSelectStylesCustomized, UseReactSelectStylesCustomizedProps } from "./useReactSelectStylesCustomized";

export type ReactSelectStyledProps<T extends OptionTypeBase, isMulti extends boolean = false> = SelectProps<
    T,
    isMulti
> &
    UseReactSelectStylesCustomizedProps;

// eslint-disable-next-line react/display-name
const ReactSelectStyled = React.forwardRef(
    <T extends OptionTypeBase>(props: ReactSelectStyledProps<T>, ref?: React.LegacyRef<StateManager<T>>) => {
        const { size, fontSize, menuBgColor, containerBgColor, menuHeight, width, styles = {}, ...selectProps } = props;

        const isMulti = selectProps.isMulti || false;

        const customStyles = useReactSelectStylesCustomized({
            ...selectProps,
            styles,
            size,
            fontSize,
            menuBgColor,
            containerBgColor,
            menuHeight,
            width,
        });

        const reactSelectComponent: ReactSelectStyledProps<T>["component"] = React.useMemo(() => {
            if (selectProps?.isFloatingLabel) {
                return {
                    ValueContainer: FloatingLabelValueContainer as unknown as React.FC<
                        ValueContainerProps<T, typeof isMulti>
                    >,
                };
            }
        }, [selectProps?.isFloatingLabel]);

        return <ReactSelect ref={ref} styles={customStyles} components={reactSelectComponent} {...selectProps} />;
    }
);

export default ReactSelectStyled as <T extends OptionTypeBase, isMulti extends boolean = false>(
    props: ReactSelectStyledProps<T, isMulti> & { ref?: ReactSelectRef<T, isMulti> }
) => JSX.Element;
