import {
    Button,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    VStack,
} from "@chakra-ui/react";
import config from "app/app.config";
import { RootState } from "app/modules";
import { User } from "app/types/user";
import AccountSuspend from "assets/lock-circle.svg";
import UpgradeTrial from "assets/upgrade-trial.svg";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

interface AlertProps {
    onClose: () => void;
    user: User;
    type?: AlertType;
    initialFocusRef?: React.RefObject<HTMLButtonElement>;
}

const UpgradeAlert: React.FC<AlertProps> = (props) => {
    const history = useHistory();
    const { initialFocusRef } = props;
    return (
        <VStack justifyContent="center" spacing={4} py={8}>
            <Image src={config.branding.product_logos.icon_with_text} height="25px" />
            <Image src={UpgradeTrial} height="170px" width="200px" />
            <VStack>
                <Text color="gray.900" fontWeight="medium">
                    Plan Ended
                </Text>
                <Text color="gray.500" fontSize="sm">
                    Your plan has ended! Upgrade now to continue using {config.product_name}.
                </Text>
            </VStack>
            <Button
                rounded="md"
                colorScheme="brand"
                fontWeight="medium"
                ref={initialFocusRef}
                size="sm"
                onClick={() => {
                    history.push("/get-dedicated-number");
                }}
            >
                Upgrade now
            </Button>
        </VStack>
    );
};

const AccountSuspendAlert: React.FC<AlertProps> = (props) => {
    const history = useHistory();
    const { onClose, initialFocusRef, type } = props;

    const AccountSuspendContent = React.useMemo(() => {
        if (!type) return;
        if (type === "payment_due_7days" || type === "payment_due_30days") {
            return (
                <Text color="gray.500" fontSize="sm" textAlign="center" maxW="xs">
                    You have a pending invoice, Pay now to continue using {config.product_name}
                </Text>
            );
        } else {
            return (
                <Text color="gray.500" fontSize="sm" textAlign="center" maxW="xs">
                    Please clear the dues to continue using {config.product_name}. All your outgoing messages will not
                    deliver.
                </Text>
            );
        }
    }, [type]);

    return (
        <VStack justify="center" spacing={4} py={8}>
            <Image src={AccountSuspend} height="50px" px={4} objectFit="contain" />
            <VStack spacing={1}>
                <Text color="gray.900" fontSize="lg" fontWeight="medium">
                    Payment Pending
                </Text>
                <VStack spacing={0}>{AccountSuspendContent}</VStack>
            </VStack>
            <VStack>
                <Button
                    borderRadius="6px"
                    colorScheme="brand"
                    fontWeight="normal"
                    size="sm"
                    ref={initialFocusRef}
                    px={6}
                    onClick={() => {
                        onClose();
                        history.push("/plan-and-subscription");
                    }}
                >
                    Pay now
                </Button>
                <Button
                    hidden={type === "payment_due_30days" || type === "payment_due_60days"}
                    size="sm"
                    colorScheme="brand"
                    fontWeight="normal"
                    variant="link"
                    onClick={onClose}
                >
                    Pay Later
                </Button>
            </VStack>
        </VStack>
    );
};

export type AlertType = "payment_due_7days" | "upgrade" | "payment_due_30days" | "payment_due_60days" | null;

const alertTypeComponentMap = new Map<AlertType, React.FC<AlertProps>>([
    ["payment_due_7days", AccountSuspendAlert],
    ["upgrade", UpgradeAlert],
    ["payment_due_30days", AccountSuspendAlert],
    ["payment_due_60days", AccountSuspendAlert],
]);

interface AlertModalProps {
    onClose: () => void;
    isDismissible: boolean;
    type: AlertType;
}

const AlertModal: React.FC<AlertModalProps> = (props) => {
    const { type, onClose, isDismissible } = props;
    const initialRef = React.useRef<HTMLButtonElement>(null);
    const user = useSelector((state: RootState) => state.authed.user);
    const Component = alertTypeComponentMap.get(type);
    if (!Component) return null;
    return (
        <Modal
            isOpen={!!type}
            onClose={onClose}
            size="lg"
            closeOnOverlayClick={isDismissible || type === "payment_due_30days" ? false : true}
            closeOnEsc={isDismissible || type === "payment_due_30days" ? false : true}
            initialFocusRef={initialRef}
        >
            <ModalOverlay />
            <ModalContent shadow="lg">
                <ModalCloseButton hidden={isDismissible || type === "payment_due_30days"} />
                <ModalBody>
                    <Component type={type} user={user} onClose={onClose} initialFocusRef={initialRef} />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AlertModal;
