import config from "app/app.config";
import { RootState } from "app/modules";
import React from "react";
import { useSelector } from "react-redux";

const ZohoHelpWidget: React.FC = () => {
    const user = useSelector((state: RootState) => state.authed.user);
    const waChannels = useSelector((state: RootState) => state.authed.waChannels);
    const WaNumber = waChannels?.[0]?.whatsapp?.whatsappNumber ?? "";
    const account = user.accounts?.[0]?.account;

    React.useEffect(() => {
        const scriptUrl = config.support.zoho_help_widget.script_url;
        if (user && waChannels && scriptUrl) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).ZohoHCAsapSettings = {
                hideLauncherIcon: true,
                ticketsSettings: {
                    preFillFields: {
                        email: {
                            defaultValue: user?.email ?? "",
                        },
                        contactId: {
                            defaultValue: user.name ?? "",
                        },
                        customFields: {
                            "Business Name": {
                                defaultValue: account?.name ?? "",
                            },
                            "Activated Number": {
                                defaultValue: WaNumber,
                            },
                        },
                    },
                },
            };

            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = config.support.zoho_help_widget.script_url;
            script.defer = true;
            document.head.appendChild(script);

            return () => {
                document.head.removeChild(script);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                delete (window as any).ZohoHCAsapSettings;
            };
        }
    }, [WaNumber, account?.name, user, waChannels]);

    return null;
};

export default ZohoHelpWidget;
