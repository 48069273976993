import config from "app/app.config";
import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";
import { useCheckAbility } from "app/hooks/useCheckAbility";
import { useRestrictFeature } from "app/hooks/useRestrictFeature";
import { openSupportTicketWidget } from "app/screens/Widget/utils";
import React from "react";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { BiBot, BiChart } from "react-icons/bi";
import { CiViewList } from "react-icons/ci";
import { CgPlug, CgShoppingCart, CgViewList } from "react-icons/cg";
import { HiOutlineChat, HiOutlineHome, HiOutlineSpeakerphone } from "react-icons/hi";
import { MdOutlineHelp } from "react-icons/md";
import { RiContactsBook2Line, RiSettings4Line } from "react-icons/ri";
import { TiFlowChildren } from "react-icons/ti";
import { NavigationAccordionItemProps } from "../components/NavigationAccordionItem";
import { AbilityActionSubject, NavigationMenuList, SubNavigationItem } from "../types";

const accountReadActionSubject: AbilityActionSubject = {
    action: "read",
    subject: "Account",
};

type NavItemProps = Record<
    NavigationMenuList,
    Pick<NavigationAccordionItemProps, "mainNav"> & Partial<Pick<NavigationAccordionItemProps, "subNav">>
>;
interface UseNavigationListProps {
    navigationItems: NavItemProps;
}
export const useNavigationList = (): UseNavigationListProps => {
    const { isSandboxChannel, isNewAccount, isFreeTrial } = useAccountMasterHook();
    const { canIReadWebhook } = useCheckAbility();
    const {
        data: { restrictPage: restrictIPConfigPage },
    } = useRestrictFeature("accounts_ip_restriction", "restrict");
    const {
        data: { pageAccess: accessPremiumSupport },
    } = useRestrictFeature("accounts_support", "restrict");

    const hideRaiseTicket = isSandboxChannel || isNewAccount;

    const chatWithUsURL =
        isFreeTrial || isSandboxChannel || isNewAccount
            ? config.product_help_guide?.free_account_chat
            : accessPremiumSupport
              ? config.product_help_guide?.premium_account_chat
              : config.product_help_guide?.subscribed_account_chat;

    const navigationItems = React.useMemo((): NavItemProps => {
        const webChatSubNav: SubNavigationItem = {
            title: "Web-Chat Channel",
            to: "/channels/web",
            action: "update",
            subject: "Channel",
            eventName: "LNAV_SETT_WCCH",
        };
        return {
            "get-started": {
                mainNav: {
                    title: "Home",
                    icon: HiOutlineHome,
                    to: "/getting-started",
                    action: "update",
                    subject: "Account",
                    eventName: "LNAV_HOME",
                },
            },
            conversations: {
                mainNav: {
                    title: "Conversations",
                    icon: HiOutlineChat,
                    to: "/conversations",
                    eventName: "LNAV_CONV",
                    closeNavigationOnItemSelect: true,
                },
            },
            contacts: {
                mainNav: { title: "Contacts", icon: RiContactsBook2Line, to: "/contacts", eventName: "LNAV_CONT_CONT" },
            },
            bots: {
                mainNav: { title: "Bots", icon: BiBot, to: "/bots", eventName: "LNAV_BOT_MYBO" },
            },
            broadcast: {
                mainNav: {
                    title: "Broadcast",
                    icon: HiOutlineSpeakerphone,
                    to: "/broadcast",
                    eventName: "LNAV_BROD",
                },
            },
            sequence: {
                mainNav: {
                    title: "Sequence",
                    icon: TiFlowChildren,
                    to: "/sequence",
                    badge: "new",
                    iconProps: { fontSize: "18px" },
                    eventName: "LNAV_SEQU",
                },
            },
            commerce: {
                mainNav: { title: "Commerce", icon: CgShoppingCart, action: "read", subject: "Commerce" },
                subNav: [
                    {
                        title: "Catalogues",
                        to: "/facebook-catalogs",
                        eventName: "LNAV_COMM_CATA",
                        ...accountReadActionSubject,
                    },

                    {
                        title: "Orders",
                        to: "/whatsapp-order",
                        action: "read",
                        subject: "Message",
                        eventName: "LNAV_COMM_ORDE",
                    },
                    ...(config.product_name === "Gallabox"
                        ? ([
                              { title: "Payments", to: "/payments", badge: "new", eventName: "LNAV_COMM_PAY" },
                          ] as SubNavigationItem[])
                        : ([] as SubNavigationItem[])),
                ],
            },
            flows: {
                mainNav: {
                    title: "Flows",
                    to: "/whatsapp-flows",
                    icon: CiViewList,
                    iconProps: { fontSize: "20px" },
                    eventName: "LNAV_WHAT_FLOWS",
                    badge: "new",
                },
            },
            integration: {
                mainNav: {
                    title: "Integration",
                    icon: CgPlug,
                    to: "/integration",
                    eventName: "LNAV_SETT_INTE",
                    iconProps: { fontSize: "20px" },
                    action: "read",
                    subject: "Integration",
                },
            },

            settings: {
                mainNav: {
                    title: "Settings",
                    icon: RiSettings4Line,
                },
                subNav: [
                    {
                        title: "WhatsApp templates",
                        to: "/whatsapp-template-messagesv2",
                        ...accountReadActionSubject,
                        eventName: "LNAV_SETT_WATE",
                    },
                    {
                        title: "CTWA",
                        to: "/ctwa-leads",
                        eventName: "LNAV_C2WA",
                    },
                    {
                        title: "Canned response",
                        to: "/cannedresponse",
                        action: "read",
                        subject: "CannedResponse",
                        eventName: "LNAV_SETT_CANE",
                    },
                    {
                        title: "Message tracker",
                        to: "/message-tracking",
                        eventName: "LNAV_SETT_MESS",
                        // hide: !messageTrackerPageAccess,
                    },

                    { type: "divider" },

                    {
                        title: "Field collection",
                        to: "/fields",
                        ...accountReadActionSubject,
                        eventName: "LNAV_SETT_FIEL",
                    },
                    { title: "Tags", to: "/tags", ...accountReadActionSubject, eventName: "LNAV_SETT_TAGS" },

                    { type: "divider" },

                    {
                        title: "WhatsApp Channel",
                        to: "/channels/whatsapp",
                        action: "update",
                        subject: "Channel",
                        eventName: "LNAV_SETT_WACH",
                    },
                    ...(config.product_name === "Gallabox" ? [webChatSubNav] : ([] as SubNavigationItem[])),

                    { type: "divider" },

                    {
                        title: "Webhooks",
                        to: "/webhook",
                        hide: !canIReadWebhook,
                        eventName: "LNAV_SETT_WEBH",
                    },
                    {
                        title: "API keys",
                        to: "/apikey",
                        action: "read",
                        subject: "APIKey",
                        eventName: "LNAV_SETT_APIK",
                    },
                    {
                        title: "Activity log",
                        to: "/activities",
                        action: "read",
                        subject: "APIKey",
                        eventName: "LNAV_SETT_ACTV",
                    },
                    {
                        title: "Allowed IPs",
                        to: "/allowed-ips",
                        action: "update",
                        subject: "Account",
                        eventName: "LNAV_SETT_ACTV",
                        hide: restrictIPConfigPage,
                    },
                    /* {
                        title: "Knowledge Base",
                        to: "/knowledge-base",
                        action: "update",
                        subject: "Account",
                        eventName: "LNAV_SETT_ACTV",
                    }, */
                ],
            },
            analytics: {
                mainNav: { title: "Analytics", icon: BiChart },
                subNav: [
                    {
                        title: "Dashboard",
                        to: "/dashboard",
                        action: "readMany",
                        subject: "Analytics",
                        eventName: "LNAV_ANAL_DASH",
                    },
                    {
                        title: "WA Failed message",
                        to: "/analytics/report/failed-message",
                        action: "readMany",
                        subject: "Analytics",
                        eventName: "LNAV_ANAL_WAFAI",
                    },
                    {
                        title: "WA Notification message",
                        to: "/analytics/report/notification-message",
                        action: "readMany",
                        subject: "Analytics",
                        eventName: "LNAV_ANAL_NOTI",
                    },
                    {
                        title: "Conversation report",
                        to: "/conversation-report",
                        action: "readMany",
                        subject: "Analytics",
                        eventName: "LNAV_ANAL_CONV",
                    },
                ],
            },
            help: {
                mainNav: {
                    title: "Help",
                    icon: MdOutlineHelp,
                    buttonProps: {
                        color: "cyan.400",
                        fontWeight: "bold",
                    },
                    iconProps: {
                        color: "cyan.400",
                        fontWeight: "bold",
                        fontSize: "18px",
                    },
                },
                subNav: [
                    { title: "Chat with us", href: chatWithUsURL, eventName: "LNAV_HELP_CHAT" },
                    ...(config.product_name === "Gallabox"
                        ? [
                              {
                                  title: "Raise a ticket",
                                  onClick: openSupportTicketWidget,
                                  eventName: "LNAV_HELP_RAIS",
                                  hide: hideRaiseTicket,
                              },
                          ]
                        : ([] as SubNavigationItem[])),
                    { type: "divider" },
                    { title: "Help Docs", href: config.product_help_guide?.docs, eventName: "LNAV_HELP_HELP" },
                    { title: "FAQs", href: config.product_help_guide?.faq, eventName: "LNAV_HELP_FAQ" },
                ],
            },
            "whats-new": {
                mainNav: {
                    title: "What's New",
                    icon: AiOutlineThunderbolt,
                    buttonProps: { "data-canny-changelog": true },
                    eventName: "LNAV_WHATSNEW",
                },
            },
        };
    }, [canIReadWebhook, restrictIPConfigPage, chatWithUsURL, hideRaiseTicket]);

    return { navigationItems };
};
