import { combineReducers } from "redux";
import authed from "./authed";
import environment from "./environment";

const RESET_AUTHED = "RESET_AUTHED";

const appReducer = combineReducers({
    authed,
    environment,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === RESET_AUTHED) {
        state = undefined;
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
