import { useToast } from "@chakra-ui/react";
import { BillingAddress } from "app/types/account";
import { AnyObject, QueryKey, TQueryKey } from "app/types/common";
import {
    AvailableModulesType,
    Invoice,
    Plan,
    PlanItem,
    PromoCode,
    StripeSubscription,
    Subscription,
} from "app/types/pricing";
import { fetcher, getJSON, mapQueryParams, patchJSON, postJSON } from "app/utils/fetchUtils";
import {
    MutationFunction,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from "react-query";

const PAGE_SIZE = 10;

interface UsePlanListProps extends UseQueryOptions<Plan[], Error, Plan[], TQueryKey> {
    period: Plan["period"];
    currency: Plan["currency"];
}

export const usePlanList = (props: UsePlanListProps): UseQueryResult<Plan[], Error> => {
    const { period, currency = "INR", ...options } = props;
    const queryResult = useQuery<Plan[], Error, Plan[], TQueryKey>(
        [QueryKey.PlanList, { period }],
        ({ queryKey }) => {
            const [, { period }] = queryKey;
            const queryParams = mapQueryParams({ period, currency, limit: 3, isActive: true });
            const url = `/api/plans?${queryParams}`;
            return fetcher(url);
        },
        options
    );
    return queryResult;
};

interface UseAlternatePaymentLinkProps {
    accountId: string;
    onSuccess: (paymentLink: string) => void;
}

interface PaymentLink {
    paymentLink: string;
}

export const useAlternatePaymentLink = (
    props: UseAlternatePaymentLinkProps
): UseMutationResult<PaymentLink, Error, string> => {
    const { accountId, onSuccess } = props;
    const toast = useToast({ isClosable: true, position: "bottom-left", duration: 2000 });

    const getPaymentUrl = (invoiceId: string) => {
        return postJSON(`/api/accounts/${accountId}/payment-link/invoices/${invoiceId}`, {});
    };

    const mutationResult = useMutation<PaymentLink, Error, string>(getPaymentUrl, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                status: "error",
                title: error.message ?? "Something went wrong!",
                duration: 9000,
            });
        },
        onSuccess: ({ paymentLink }) => onSuccess(paymentLink),
    });

    return mutationResult;
};

interface UsePlanItemListProps extends UseQueryOptions<PlanItem[], Error, PlanItem[], TQueryKey> {
    type: string | string[];
    period: NonNullable<PlanItem["period"]>;
    currency: NonNullable<PlanItem["currency"]>;
}

export const usePlanItemList = (props: UsePlanItemListProps): UseQueryResult<PlanItem[], Error> => {
    const { type = "addon", period, currency = "INR", ...options } = props;
    const queryResult = useQuery<PlanItem[], Error, PlanItem[], TQueryKey>(
        [QueryKey.PlanItemList, { type, period }],
        ({ queryKey }) => {
            const [, { type }] = queryKey;
            const queryParams = mapQueryParams({ type, period, currency, limit: 3, isActive: true });
            const url = `/api/plan-items?${queryParams}`;
            return fetcher(url);
        },
        { ...options }
    );
    return queryResult;
};

type AccountOrStripeSubscription = Subscription | StripeSubscription;

type SubscriptionAccountOrStripe = UseQueryOptions<
    AccountOrStripeSubscription,
    Error,
    AccountOrStripeSubscription,
    TQueryKey
>;

interface UseGetSubscriptionProps extends SubscriptionAccountOrStripe {
    accountId: string;
}

export const useGetSubscription = (
    props: UseGetSubscriptionProps
): UseQueryResult<Subscription | StripeSubscription, Error> => {
    const { accountId, ...options } = props;
    const queryResult = useQuery<AccountOrStripeSubscription, Error, AccountOrStripeSubscription, TQueryKey>(
        [QueryKey.ActiveSubscription, { accountId }],
        ({ queryKey }) => {
            const [, { accountId }] = queryKey;
            const gbUrl = `/api/accounts/${accountId}/subscription`;
            const stripeUrl = `/api/accounts/${accountId}/stripe-subscription`;
            const subscriptionData = fetcher(stripeUrl).catch(() => fetcher(gbUrl));
            return subscriptionData;
        },
        options
    );
    return queryResult;
};

interface UseActiveSubscriptionProps extends UseQueryOptions<Subscription, Error, Subscription, TQueryKey> {
    accountId: string;
}

export const useActiveSubscription = (props: UseActiveSubscriptionProps): UseQueryResult<Subscription, Error> => {
    const { accountId, ...options } = props;
    const queryResult = useQuery<Subscription, Error, Subscription, TQueryKey>(
        [QueryKey.ActiveSubscription, { accountId }],
        ({ queryKey }) => {
            const [, { accountId }] = queryKey;
            const url = `/api/accounts/${accountId}/subscription`;
            return fetcher(url);
        },
        { ...options }
    );
    return queryResult;
};

interface UseActiveStripeSubscriptionProps
    extends UseQueryOptions<StripeSubscription | null, Error, StripeSubscription | null, TQueryKey> {
    accountId: string;
}

export const useActiveStripeSubscription = (
    props: UseActiveStripeSubscriptionProps
): UseQueryResult<StripeSubscription | null, Error> => {
    const { accountId, ...options } = props;
    const queryResult = useQuery<StripeSubscription | null, Error, StripeSubscription | null, TQueryKey>(
        [QueryKey.ActiveStripeSubscription, { accountId }],
        ({ queryKey }) => {
            const [, { accountId }] = queryKey;
            const url = `/api/accounts/${accountId}/stripe-subscription`;
            return fetcher(url);
        },
        options
    );
    return queryResult;
};

export interface UpgradeSubscriptionPayload {
    planId: string;
    amount: number;
    addons: PlanItem[];
    billingAddress: BillingAddress;
    promoCodeId?: string;
    quantity?: number;
}

interface UpgradeSubscriptionResponse {
    hostedInvoiceUrl?: string | null;
    subscriptionId: string;
    subscriptionProviderId: string;
    clientSecret?: string | null;
    forceActivate: boolean;
}

type UpdateSubscriptionResponse = Pick<UpgradeSubscriptionResponse, "subscriptionId">;

type UseUpgradeSubscriptionResult<InputType> = UseMutationResult<UpgradeSubscriptionResponse, Error, InputType>;

interface UseUpgradeSubscriptionProps {
    accountId: string;
    onSuccess?: (response: UpgradeSubscriptionResponse) => void;
    showSuccessMessage?: boolean;
}

export const useCreateSubscription = <InputType extends AnyObject = UpgradeSubscriptionPayload>(
    props: UseUpgradeSubscriptionProps
): UseUpgradeSubscriptionResult<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast({ isClosable: true, position: "bottom-left", duration: 2000 });

    const upgradeSubscription: MutationFunction<UpgradeSubscriptionResponse, InputType> = (data: InputType) => {
        return postJSON<InputType>(`/api/accounts/${accountId}/subscriptions`, data);
    };

    const mutationResult = useMutation<UpgradeSubscriptionResponse, Error, InputType>(upgradeSubscription, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            const isWarning = error.message === "Add a billing address to proceed!";
            toast({
                status: isWarning ? "warning" : "error",
                title: error.message ?? "Something went wrong!",
                duration: 9000,
            });
        },
        onSuccess: (response) => {
            if (showSuccessMessage) {
                toast({ title: "Subscription Created!", status: "success" });
            }
            onSuccess?.(response);
        },
    });

    return mutationResult;
};

type UpdateSubscriptionPayload = Omit<UpgradeSubscriptionPayload, "promoCodeId">;

type UseUpdateSubscriptionResult<InputType> = UseMutationResult<UpdateSubscriptionResponse, Error, InputType>;

interface UseUpdateSubscriptionProps {
    accountId: string;
    onSuccess?: (response: UpdateSubscriptionResponse) => void;
    showSuccessMessage?: boolean;
}

export const useUpdateSubscription = <InputType extends AnyObject = UpdateSubscriptionPayload>(
    props: UseUpdateSubscriptionProps
): UseUpdateSubscriptionResult<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast({ isClosable: true, position: "bottom-left", duration: 2000 });
    const queryClient = useQueryClient();

    const updateSubscription: MutationFunction<UpdateSubscriptionResponse, InputType> = (data: InputType) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return patchJSON<UpdateSubscriptionResponse>(`/api/accounts/${accountId}/subscriptions`, data);
    };

    const mutationResult = useMutation<UpdateSubscriptionResponse, Error, InputType>(updateSubscription, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({ status: "error", title: error.message ?? "Something went wrong!", duration: 9000 });
        },
        onSuccess: (response) => {
            if (showSuccessMessage) {
                toast({ title: "Subscription Updated!", status: "success" });
            }
            const activeSubscriptionQueryKey = [QueryKey.ActiveSubscription, { accountId }];
            queryClient.invalidateQueries(activeSubscriptionQueryKey);
            onSuccess?.(response);
        },
    });

    return mutationResult;
};

interface UseInvoiceListProps extends UseInfiniteQueryOptions<Invoice[], Error, Invoice[], Invoice[], TQueryKey> {
    accountId: string;
}

export const useInvoiceList = (props: UseInvoiceListProps): UseInfiniteQueryResult<Invoice[], Error> => {
    const { accountId, ...options } = props;
    const infiniteQueryResult = useInfiniteQuery<Invoice[], Error, Invoice[], TQueryKey>(
        [QueryKey.InvoiceList, { accountId }],
        ({ pageParam, queryKey }) => {
            const [, { accountId }] = queryKey;
            const queryParams = mapQueryParams({ limit: PAGE_SIZE, since: pageParam });
            const url = `/api/accounts/${accountId}/invoices?${queryParams}`;
            return fetcher(url);
        },
        {
            ...options,
            getNextPageParam: (lastPage) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === PAGE_SIZE ? [...lastPage].reverse()?.[0]?.id : undefined;
            },
        }
    );
    return infiniteQueryResult;
};

interface UseBillingAddressProps extends UseQueryOptions<BillingAddress, Error, BillingAddress, TQueryKey> {
    accountId: string;
}

export const useBillingAddress = (props: UseBillingAddressProps): UseQueryResult<BillingAddress, Error> => {
    const { accountId, ...options } = props;
    const queryResult = useQuery<BillingAddress, Error, BillingAddress, TQueryKey>(
        [QueryKey.BillingAddress, { accountId }],
        ({ queryKey }) => {
            const [, { accountId }] = queryKey;
            const url = `/api/accounts/${accountId}/billing-address`;
            return fetcher(url);
        },
        { ...options }
    );
    return queryResult;
};

type UseUpdateBillingAddressResult<InputType> = UseMutationResult<BillingAddress, Error, InputType>;

interface UseUpdateBillingAddressProps {
    accountId: string;
    onSuccess?: (response: BillingAddress) => void;
    showSuccessMessage?: boolean;
    onError?: (error: Error) => void;
    showErrorMessage?: boolean;
}

export type UpdateBillingAddressPayload = Partial<Omit<BillingAddress, "id">>;

export const useUpdateBillingAddress = (
    props: UseUpdateBillingAddressProps
): UseUpdateBillingAddressResult<UpdateBillingAddressPayload> => {
    const { accountId, onSuccess, showSuccessMessage = true, onError, showErrorMessage = true } = props;

    const toast = useToast({ isClosable: true, position: "bottom-left", duration: 2000 });
    const queryClient = useQueryClient();

    const updateBillingAddress: MutationFunction<BillingAddress, UpdateBillingAddressPayload> = async (data) => {
        const response = await patchJSON(`/api/accounts/${accountId}/billing-address`, data);
        return response as unknown as BillingAddress;
    };

    const mutationResult = useMutation<BillingAddress, Error, UpdateBillingAddressPayload>(updateBillingAddress, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            if (showErrorMessage) {
                toast({ status: "error", title: error.message ?? "Something went wrong!", duration: 9000 });
            }
            onError?.(error);
        },
        onSuccess: (billingAddress) => {
            if (showSuccessMessage) {
                toast({ title: "Billing Address Updated!", status: "success" });
            }
            queryClient.setQueryData([QueryKey.BillingAddress, { accountId }], billingAddress);
            onSuccess?.(billingAddress);
        },
    });

    return mutationResult;
};

interface OpenCustomerPortalProps {
    accountId: string;
}

interface OpenCustomerPortalResponse {
    url: string;
}

type OpenCustomerPortalPropsResult = UseMutationResult<OpenCustomerPortalResponse, Error, void>;

export const useOpenCustomerPortal = (props: OpenCustomerPortalProps): OpenCustomerPortalPropsResult => {
    const { accountId } = props;

    const openCustomerPortal: MutationFunction<OpenCustomerPortalResponse, void> = () => {
        return postJSON(`/api/accounts/${accountId}/customer-portal`, {});
    };

    const mutationResult = useMutation<OpenCustomerPortalResponse, Error, void>(openCustomerPortal);

    return mutationResult;
};

interface GetPromoCodeParams {
    code: string;
    priceId?: string;
}

interface UsePromoCodeProps extends UseMutationOptions<PromoCode, Error, GetPromoCodeParams> {
    accountId: string;
}

export const usePromoCode = (props: UsePromoCodeProps): UseMutationResult<PromoCode, Error, GetPromoCodeParams> => {
    const { accountId, ...options } = props;
    const getPromoCode: MutationFunction<PromoCode, GetPromoCodeParams> = ({ code, priceId }) => {
        if (!priceId) throw new Error("Select a base plan to apply the referral code");
        const url = `/api/accounts/${accountId}/promotion-code/${priceId}/${code}`;
        return getJSON(url);
    };
    const mutationResult = useMutation<PromoCode, Error, GetPromoCodeParams>(getPromoCode, { ...options });
    return mutationResult;
};

interface AlertUpgradeEventInput {
    action: string; // upgrade, downgrade, add-user, etc.,
    module: AvailableModulesType; // bot, sequence, etc.,
    sub_module?: string; // voice-bot, image-template, etc.,
}

interface UseUpdateAlertUpgradeEvent extends UseMutationOptions<void, Error, AlertUpgradeEventInput> {
    accountId: string;
}

type UpdateAlertUpgradeEventResponse = UseMutationResult<void, Error, AlertUpgradeEventInput>;

export const useAlertUpgradeEvent = (props: UseUpdateAlertUpgradeEvent): UpdateAlertUpgradeEventResponse => {
    const { accountId, ...rest } = props;

    const createAlertUpgradeEvent: MutationFunction<void, AlertUpgradeEventInput> = (data: AlertUpgradeEventInput) => {
        return postJSON(`/api/accounts/${accountId}/alert-upgrade`, data);
    };

    const responseUpdateWorkflow = useMutation<void, Error, AlertUpgradeEventInput>(createAlertUpgradeEvent, {
        ...rest,
    });

    return responseUpdateWorkflow;
};
