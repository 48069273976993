import { useGetAccountEntitlement } from "app/fetchHooks/account-config";
import { useDebounce } from "app/hooks/useDebounce";
import { Authed } from "app/types";
import { SAccountEntitlement } from "app/types/account-config";
import { useAccountId } from "app/utils/react-helpers";
import React, { PropsWithChildren, useEffect } from "react";
import { AccountEntitlementContext } from "./context";
import { AccountEntitlement, UpdateAccountEntitlementUsage, UpdateAccountEntitlementUsageArgs } from "./type";
import { sanitizeAccountEntitlement, updateAccountEntitlement } from "./utils";

const AccountEntitlementProviderAuthed: React.FC<PropsWithChildren> = (props) => {
    const accountId = useAccountId();

    const [accountEntitlement, setAccountEntitlement] = React.useState<AccountEntitlement>();
    const { data: SAccountEntitlement, isLoading, isFetched, isFetching } = useGetAccountEntitlement({ accountId });

    const setSanitizedAccountEntitlement = React.useCallback((SAccountEntitlement: SAccountEntitlement) => {
        setAccountEntitlement(sanitizeAccountEntitlement(SAccountEntitlement));
    }, []);

    const isAccountEntitlementLoading = useDebounce(isLoading || isFetching, 0);
    const isAccountEntitlementFetched = useDebounce(isFetched && !isFetching, 0);

    const updateEntitlement = React.useCallback(
        (updatedData: UpdateAccountEntitlementUsage, type: UpdateAccountEntitlementUsageArgs["type"]) => {
            if (!accountEntitlement) return;
            const updatedEntitlement = updateAccountEntitlement({
                accountEntlmt: accountEntitlement,
                updatedData,
                type,
            });
            setTimeout(() => {
                setSanitizedAccountEntitlement(updatedEntitlement);
            }, 500);
        },
        [accountEntitlement, setSanitizedAccountEntitlement]
    );

    useEffect(() => {
        if (isLoading || !SAccountEntitlement) return;
        setSanitizedAccountEntitlement(SAccountEntitlement);
    }, [SAccountEntitlement, isLoading, setSanitizedAccountEntitlement]);

    return (
        <AccountEntitlementContext.Provider
            value={{
                accountEntitlement,
                isLoading: isAccountEntitlementLoading,
                isFetched: isAccountEntitlementFetched,
                setAccountEntitlement: setSanitizedAccountEntitlement,
                updateEntitlement,
            }}
        >
            {props.children}
        </AccountEntitlementContext.Provider>
    );
};

const AccountEntitlementProvider: React.FC<PropsWithChildren<{ authed: Authed }>> = ({ authed, children }) => {
    if (authed?.user) {
        return <AccountEntitlementProviderAuthed>{children}</AccountEntitlementProviderAuthed>;
    }
    return <>{children}</>;
};
export default AccountEntitlementProvider;
