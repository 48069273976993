import { AllNameSpacesAndTypes, I18NextNameSpaceType } from "lang/types/common";
import { useTranslation } from "react-i18next";

type ExcludeFirstTwoElement<T extends unknown[]> = T extends [unknown, unknown, ...infer U] ? U : never;

export const useGBTranslation = <T extends I18NextNameSpaceType | "translation" = "translation">(
    ...props: Parameters<typeof useTranslation<T | T[], undefined>>
) => {
    const result = useTranslation<T | T[], undefined>(...props);

    const translate = <U extends I18NextNameSpaceType | "translation">(
        key: U extends I18NextNameSpaceType
            ? AllNameSpacesAndTypes[T]
            : AllNameSpacesAndTypes[T] | AllNameSpacesAndTypes[T][],
        secondArg: Parameters<typeof result.t>[1] & { ns?: U } = {},
        ...props: ExcludeFirstTwoElement<Parameters<typeof result.t>>
    ) => {
        return result.t(key, secondArg as unknown as any, ...props) as string;
    };
    return {
        ...result,
        t: translate,
    };
};
