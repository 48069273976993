import { createStandaloneToast } from "@chakra-ui/react";
import {
    RightToLeftLanguage,
    WATemplate,
    WATemplateLanguages,
    WATemplateMessageFormatType,
} from "app/types/message/whatsapp-template";
import {
    deSanitizeWAITemplateToEditForm,
    whatsappTemplateToMessage,
} from "../SendWATemplates/EditTemplateVariables/helpers";

export const variableGroups: { title: string; variables: string[] }[] = [
    { title: "Contact", variables: ["name", "email", "phone"] },
];

export const supportedLanguages = WATemplateLanguages.map((l) => l.value);

export const getMustacheVariables = (bodyText: string, duplicate?: boolean): string[] => {
    const variablePattern = /{{\s*[\w-\[\]\?.]+\s*}}/g;
    const wordPattern = /[\w-.]+/;
    const variables = bodyText?.match(variablePattern)?.map((x) => {
        return x.match(wordPattern)?.[0];
    });
    if (duplicate) return (variables ?? []).filter((v) => Boolean(v)) as string[];
    const uniqVariables: string[] = Array.from(new Set(variables ?? [])).filter((v) => v) as string[];
    return uniqVariables;
};

export const getMustacheVariablesIsAvailable = (bodyText: string): boolean => {
    const doubleCurlyBracePattern = /\{{[^)]*\}}/g;
    return doubleCurlyBracePattern.test(bodyText);
};

export interface AllowedMediaFormat {
    mimeTypes: string[];
    extensions: string[];
    type?: WATemplateMessageFormatType;
}

export const getAllowedMediaFormat = (format?: WATemplateMessageFormatType): AllowedMediaFormat => {
    if (!format) return { mimeTypes: [], extensions: [] };

    switch (format) {
        case "IMAGE":
            return { mimeTypes: ["image/jpeg", "image/png"], extensions: [".jpeg", ".jpg", ".png"], type: "IMAGE" };
        case "VIDEO":
            return { mimeTypes: ["video/mp4", "video/3gpp"], extensions: [".mp4", ".3gpp"], type: "VIDEO" };
        case "DOCUMENT":
            return { mimeTypes: ["application/pdf"], extensions: [".pdf"], type: "DOCUMENT" };
        default:
            return { mimeTypes: [], extensions: [] };
    }
};

export const copyTemplateAsAPI = (template?: WATemplate): void => {
    if (!template) return;
    const templateLength = Object.keys(template).length;
    if (templateLength < 1) return;

    const { name: templateName, components, channelId } = template as WATemplate;
    const bodyComponent = components.find((m) => m.type === "BODY");
    const buttonsComponent = components.find((b) => b.type === "BUTTONS")?.buttons;
    const variable = getMustacheVariables(bodyComponent?.text ?? "");
    const bodyValues = variable.reduce((obj, item) => ({ ...obj, [item]: `{{${item}}}` }), {});

    const buttonValues = buttonsComponent
        ?.map((v, index) => {
            if (v.type === "PHONE_NUMBER") return undefined;
            if (v.type === "QUICK_REPLY") {
                return {
                    index: index,
                    sub_type: "quick_reply",
                    parameters: { type: "payload", payload: v.text },
                };
            }
            if (v.type === "FLOW") {
                return {
                    index: index,
                    sub_type: "flow",
                    parameters: { type: "action", action: { flow_token: "" } },
                };
            }

            const hasVariables = getMustacheVariables(v?.url || "").length;
            if (!v?.url || !hasVariables) return undefined;

            return {
                index: index,
                sub_type: "url",
                parameters: {
                    type: "text",
                    text: `{{${getMustacheVariables(v.url)[0]}}}`,
                    url: v?.url,
                },
            };
        })
        .filter(Boolean);

    const { formValues } = deSanitizeWAITemplateToEditForm({
        template: whatsappTemplateToMessage(template),
        includeSampleCardValue: true,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const cardValues = formValues?.cardValues?.map(({ headerValues, ...cardValue }) => {
        const buttonValues = cardValue.buttonValues?.map((btValue) => ({
            ...(btValue?.url && getMustacheVariables(btValue?.url).length
                ? {
                      ...btValue,
                      parameters: {
                          ...btValue.parameters,
                          text: `{{${getMustacheVariables(btValue?.url)?.[0]}}}`,
                      },
                  }
                : btValue),
        }));
        return { ...cardValue, buttonValues };
    });
    const value = {
        channelId,
        channelType: "whatsapp",
        recipient: { name: "{{name}}", phone: "{{phone}}" },
        whatsapp: {
            type: "template",
            template: {
                templateName,
                ...(Object.keys(bodyValues).length > 0 ? { bodyValues } : {}),
                ...((buttonValues || [])?.length > 0 ? { buttonValues } : {}),
                ...((cardValues || [])?.length > 0 ? { cardValues } : {}),
            },
        },
    };

    navigator.clipboard.writeText(JSON.stringify(value, null, 4));
    const { toast } = createStandaloneToast();
    toast({ title: "Copied", variant: "subtle", description: "API copied" });
};

export const getErrorORRejectionReason = (template?: WATemplate): string | null | undefined => {
    if (template?.status === "rejected") {
        const rejectedReason = template.rejected_reason;
        return rejectedReason
            ? `Rejected Reason: ${rejectedReason} You can duplicate the template to make changes & resubmit for approval`
            : "You can duplicate the template to make changes & resubmit for approval";
    }

    if (template?.status === "error") {
        return template.err;
    }

    return null;
};

export const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    const message = "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
};

export const removeEmojis = (string: string): string => {
    const regex =
        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, "");
};

export function formatTemplateBodyText(inputText: string, disableNewLine: boolean | undefined = false): string {
    const regex = /{{(.*?)}}/g;

    const formattedText = inputText.replace(regex, (match, variable) => {
        variable = variable.replace(/[\s\n\t!@#$%^&*()\-+=,.<>/?;:'"[\]{}\\|]/g, "_");
        return `{{${removeEmojis(variable)}}}`;
    });

    const finalText = disableNewLine ? formattedText.replace(/\n/g, "") : formattedText;

    return finalText.replace(/\n{3,}/g, "\n\n");
}

export const accordionDefaultIndex = Array.from({ length: 10 }, (_, index) => index);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getNestedProperty(obj: any, propertyPath: string): string | undefined {
    const keys = propertyPath.split(/\[|\]|\./).filter(Boolean);
    if (!obj) return undefined;

    try {
        return keys.reduce((acc, key) => {
            if (Array.isArray(acc) && /^\d+$/.test(key)) {
                return acc[Number(key)];
            } else if (acc && typeof acc === "object") {
                return acc[key];
            } else {
                return undefined;
            }
        }, obj);
    } catch (e) {
        console.log(e);
        return "";
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleArrayMove = (arr: any, source: number, dest: number) => {
    const temp = arr[source];

    arr.splice(source, 1);
    arr.splice(dest, 0, temp);
    return arr;
};

export const isRightToLeftLanguage = (language?: string): boolean => {
    if (!language) return false;

    return RightToLeftLanguage.includes(language);
};
