import { useEventListener } from "app/utils/react-helpers";
import React from "react";
interface UseWindowWidthHeightResult {
    windowInnerHeight: number;
    windowInnerWidth: number;
}
export const useWindowWidthHeight = (): UseWindowWidthHeightResult => {
    const defaultWidth = React.useMemo(() => window.innerWidth, []);
    const defaultHeight = React.useMemo(() => window.innerHeight, []);
    const [windowInnerHeight, setWindowInnerHeight] = React.useState(window.innerHeight);
    const [windowInnerWidth, setWindowInnerWidth] = React.useState(window.innerWidth);
    const onResize = React.useCallback(() => {
        const detectObj = {
            device: !!navigator.maxTouchPoints ? "mobile" : "computer",
            orientation: !navigator.maxTouchPoints
                ? "desktop"
                : !window?.screen?.orientation?.angle
                ? "portrait"
                : "landscape",
        };
        if (detectObj.device === "mobile" && detectObj.orientation === "portrait") {
            setWindowInnerHeight(defaultHeight);
            setWindowInnerWidth(defaultWidth);
            return;
        }

        setWindowInnerHeight(window.innerHeight);
        setWindowInnerWidth(window.innerWidth);
    }, [defaultHeight, defaultWidth]);

    useEventListener("resize", onResize);
    return { windowInnerHeight, windowInnerWidth };
};
