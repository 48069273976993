import { RawRuleOf } from "@casl/ability";
import { Boundary, PermissionRule, User } from "app/types";
import { Authed } from "app/types/common";
import React, { PropsWithChildren } from "react";
import { AppAbility, createAbility } from "./ability";
import { AbilityContext } from "./can";

const buildRule = (accountId: string, pr: PermissionRule): RawRuleOf<AppAbility> => {
    return {
        action: pr.actions,
        subject: pr.subjects || "all",
        fields: pr.fields?.[0] ? pr.fields : undefined,
        conditions: {
            ...pr.conditions,
            accountId,
        },
        inverted: pr.inverted ?? false,
    };
};

const computeRules = (
    accountId: string,
    user: User,
    boundaries: Boundary[],
    pr: PermissionRule
): RawRuleOf<AppAbility>[] => {
    const rules: RawRuleOf<AppAbility>[] = [];
    if (!pr.subjects) {
        rules.push(buildRule(accountId, { ...pr }));
        return rules;
    }

    pr.subjects.forEach((s) => {
        const conditions = { ...(pr.conditions ? { ...pr.conditions } : {}) };
        if (s === "Conversation") {
            if (boundaries.includes("own")) {
                const ownConditions = { ...conditions, userId: user.id, botId: null };
                rules.push(buildRule(accountId, { ...pr, subjects: [s], conditions: ownConditions }));

                const mentionConditions = {
                    ...conditions,
                    mentionedUsers: {
                        $elemMatch: {
                            userId: user.id,
                        },
                    },
                };
                rules.push(buildRule(accountId, { ...pr, subjects: [s], conditions: mentionConditions }));
            }
            if (boundaries.includes("unknown")) {
                const unknownConditions = { ...conditions, userId: null, botId: null };
                rules.push(buildRule(accountId, { ...pr, subjects: [s], conditions: unknownConditions }));
            }
            if (boundaries.includes("all")) {
                rules.push(buildRule(accountId, { ...pr, subjects: [s], conditions }));
            }
        } else if (s === "User" && boundaries.includes("own")) {
            rules.push(buildRule(accountId, { ...pr, subjects: [s], conditions: { id: user.id } }));
        } else {
            rules.push(buildRule(accountId, { ...pr, subjects: [s], conditions }));
        }
    });
    return rules;
};

export const defineRulesFor = (accountId: string, user: User): RawRuleOf<AppAbility>[] => {
    const rules: RawRuleOf<AppAbility>[] = [];

    const role = user?.accounts?.find((a) => a.accountId.toString() === accountId)?.role;
    role?.rolePermissions.forEach((p) => {
        p.permission?.rules.forEach((r) => {
            rules.push(...computeRules(accountId, user, p.boundary, r));
        });
    });
    return rules;
};

const AbilityProvider: React.FC<PropsWithChildren<{ authed: Authed }>> = (props) => {
    const { authed, children } = props;
    if (authed?.user) {
        const accountId = authed.user.accounts?.[0]?.accountId;
        if (accountId) {
            const rules = defineRulesFor(accountId, authed.user);
            const ability = createAbility(rules);
            return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
        }
    }
    return <>{children}</>;
};

export default AbilityProvider;
