import config from "app/app.config";

export const PAGE_HAS_BEEN_FORCE_REFRESHED = "page-has-been-force-refreshed";
export const OLDER_VERSION_ALERT_HEADER = `${config.product_name}'s New Update Available!`;
export const ALERT_HEADER = "Aaaah! Something went wrong!";
export const GENERIC_DESC = "Looking like you are using the older version of the app or failed to connect our server.";
export const OLDER_VERSION_DESC =
    "Looks like there are some newer changes on your way. Worry not, this would just take few seconds. Let's get started?";
export const RAISE_A_TICKET = "raise a ticket";
export const DEFAULT_ERROR_MESSAGE = "Something Went Wrong";
