import {
    Box,
    Grid,
    GridItem,
    HStack,
    Image,
    Skeleton,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
} from "@chakra-ui/react";
import config from "app/app.config";
import { Integration } from "app/types/integration";
import { useQueryParam } from "app/utils/history";
import Icon1 from "assets/Icon-1.svg";
import Icon2 from "assets/Icon-2.svg";
import Icon4 from "assets/Icon-4.svg";
import Icon5 from "assets/Icon-5.svg";
import Icon3 from "assets/icon1.png";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import LogTable from "../LogTable";
import { CalendlyConfiguration } from "./CalendlyConfiguration";
import CalendlyUseCase from "./CalendlyUseCase";
interface CalendlyTabsProps {
    data: Integration | undefined;
    isLoading: boolean;
}

export const calendlyDetails = [
    {
        icon: Icon1,
        text: "Send Event Confirmation Messages",
    },
    { icon: Icon2, text: "Send Event Reminder Messages" },
    { icon: Icon3, text: "Ask Feedback for the event" },
    { icon: Icon4, text: "Send WhatsApp Broadcast to Targeted Customers" },
    { icon: Icon5, text: "Send a Sequence of Follow-up Messages post event" },
];

export const CalendlyTabs: FC<CalendlyTabsProps> = (props) => {
    const { data, isLoading } = props;
    const [tabIndex, setTabIndex] = React.useState<number>(0);
    const { id: integrationId, calendly, accountId } = data ?? {};
    const { channelId } = calendly ?? {};
    const history = useHistory();
    const params = useQueryParam();
    const selectedTabIndex = params.get("tab") ?? undefined;

    React.useEffect(() => {
        if (integrationId) setTabIndex(1);
        if (channelId && integrationId) setTabIndex(2);
        if (!isNaN(Number(selectedTabIndex))) {
            setTabIndex(Number(selectedTabIndex));
        }
    }, [channelId, integrationId, selectedTabIndex]);

    const handleTabIndexChange = React.useCallback<React.Dispatch<React.SetStateAction<number>>>(
        (index) => {
            history.push({ pathname: history.location.pathname, search: `?tab=${index}` });
            setTabIndex(index);
        },
        [history]
    );

    return (
        <Box px={6}>
            <Tabs index={tabIndex} onChange={handleTabIndexChange} isLazy w="100%">
                <TabList>
                    <Tab fontSize="sm" fontWeight="medium">
                        Overview
                    </Tab>
                    <Tab hidden={!integrationId} fontSize="sm" fontWeight="medium">
                        Configuration
                    </Tab>
                    <Tab hidden={!integrationId || !channelId} fontSize="sm" fontWeight="medium">
                        Workflows
                    </Tab>
                    <Tab hidden={!integrationId || !channelId} fontSize="sm" fontWeight="medium">
                        Logs
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <VStack w="full" alignItems="flex-start">
                            <Text color="gray.500" fontSize="sm" fontStyle="normal">
                                {config.product_name} helps you to integrate your Official WhatsApp Business Account
                                with Calendly and send automated schedule-related notifications to your customers.
                            </Text>
                        </VStack>
                        <Box paddingTop={6}>
                            <Text
                                paddingBottom={"25px"}
                                textAlign={"left"}
                                fontSize="18px"
                                fontWeight="semibold"
                                color="black"
                            >
                                What can I do?
                            </Text>
                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                {calendlyDetails.map((v, idx) => {
                                    return (
                                        <GridItem key={idx}>
                                            <HStack>
                                                <Image src={v.icon} boxSize={8} fallback={<Skeleton boxSize={8} />} />
                                                <Text fontWeight="normal" fontSize="sm" color="gray.500">
                                                    {v.text}
                                                </Text>
                                            </HStack>
                                        </GridItem>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <CalendlyConfiguration data={data} isLoading={isLoading} />
                    </TabPanel>
                    <TabPanel>
                        <CalendlyUseCase data={data} isLoading={isLoading} />
                    </TabPanel>
                    <TabPanel>
                        <LogTable accountId={accountId} integrationId={integrationId} integrationType="calendly" />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};
