import { BackgroundProps, useToken } from "@chakra-ui/react";
import { StylesConfig } from "react-select";
import { TextProps } from "recharts";
import { getReactSelectStyle, GetReactSelectStyleProps } from "./utils";

type ReactSelectSize = "xs" | "sm" | "md";

export interface UseReactSelectStylesCustomizedProps
    extends Omit<GetReactSelectStyleProps, "fontSize" | "menuBgColor" | "containerBgColor" | "placeholderColor"> {
    size?: ReactSelectSize;
    fontSize?: ReactSelectSize;
    menuBgColor?: BackgroundProps["bgColor"];
    containerBgColor?: BackgroundProps["bgColor"];
    placeholderColor?: TextProps["color"];
}

const selectSize: Record<
    ReactSelectSize,
    Pick<GetReactSelectStyleProps, "minHeight" | "fontSize" | "floatingLabelTopPosition" | "floatingLabelFontSize">
> = {
    xs: {
        minHeight: "24px",
        fontSize: "12px",
        floatingLabelTopPosition: "0px",
        floatingLabelFontSize: "10px",
    },
    sm: {
        minHeight: "32px",
        fontSize: "14px",
        floatingLabelTopPosition: "-3px",
        floatingLabelFontSize: "12px",
    },
    md: {
        minHeight: "40px",
        fontSize: "16px",
        floatingLabelTopPosition: "-1px",
        floatingLabelFontSize: "14px",
    },
};

export const useReactSelectStylesCustomized = <isMulti extends boolean = false>(
    props: UseReactSelectStylesCustomizedProps = {}
): StylesConfig<any, isMulti> => {
    const { size = "sm", width, menuHeight, fontSize, menuBgColor, containerBgColor, placeholderColor } = props;
    const [menuBg, containerBg, placeholderCol] = useToken("colors", [
        (menuBgColor as string) ?? "white",
        (containerBgColor as string) ?? "white",
        (placeholderColor as string) ?? "gray.500",
    ]);

    const selectSizeStyles = selectSize[size];
    return getReactSelectStyle({
        ...props,
        ...selectSizeStyles,
        fontSize: fontSize ? selectSize[fontSize].fontSize : selectSize[size].fontSize,
        menuHeight,
        menuBgColor: menuBg,
        containerBgColor: containerBg,
        placeholderColor: placeholderCol,
        width,
    });
};
