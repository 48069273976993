import { Text } from "@chakra-ui/react";
import { useCheckAbility } from "app/hooks/useCheckAbility";
import { formatPhoneNumber } from "app/utils/react-helpers";
import React from "react";
import PhoneEmailText from "./PhoneEmailText";
import PhoneNumberMasked, { PhoneNumberMaskedProps } from "./PhoneNumberMasked";

export type PhoneNumberTextProps = PhoneNumberMaskedProps & { disablePhoneNumberFormatting?: boolean };

const PhoneNumberText: React.FC<PhoneNumberTextProps> = (props) => {
    const { value, disablePhoneNumberFormatting = false, ...textProps } = props;
    const { title, ...textPropsOmittedTitle } = textProps;
    const { canIReadPII } = useCheckAbility();
    const formattedPhoneNumber = disablePhoneNumberFormatting ? value : formatPhoneNumber(value);
    if (!Number(value))
        return (
            <Text {...textPropsOmittedTitle} title={title}>
                {value}
            </Text>
        );

    if (!canIReadPII)
        return (
            <PhoneNumberMasked value={value} title={value !== title ? title : undefined} {...textPropsOmittedTitle} />
        );

    return (
        <PhoneEmailText value={value} {...textPropsOmittedTitle} title={title}>
            {formattedPhoneNumber}
        </PhoneEmailText>
    );
};

export default React.memo(PhoneNumberText);
