import { Icon, Text, useDisclosure } from "@chakra-ui/react";
import config from "app/app.config";
import { AlertBannerPropsWithVisibility } from "app/components/AlertBanner/MultiAlert";
import { useAccountOverUsage } from "app/screens/Account/AccountOverUsage/useAccountOverUsage";
import NewFreeTrialModal from "app/screens/Onboarding/New/NewFreeTrialModal";
import { windowOpenNewTab } from "app/utils/common";
import dayjs from "dayjs";
import React from "react";
import { HiInformationCircle } from "react-icons/hi";
import { LuCalendarHeart } from "react-icons/lu";
import { RiAlertFill, RiUser3Line } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";
import { trackEvent } from "./productAnalytics/tracker";
import { useAccountMasterHook } from "./useAccountMasterHook";
import { useRedirectToDialog360Page } from "./useRedirectToDialog360Page";

const SANDBOX_DAYS = 3;
const TRIAL_DAYS = 6;

interface ShowTopAlertBannerProps {
    multiAlertProps: AlertBannerPropsWithVisibility[];
}

const alertInfoIcon = <Icon as={HiInformationCircle} color="gray.500" />;
const alertWarningIcon = <Icon as={RiAlertFill} color="red.500" />;

export const useShowTopAlertBanner = (): ShowTopAlertBannerProps => {
    const history = useHistory();
    const location = useLocation();

    const {
        isSuspended,
        isSandboxChannel,
        isNewAccount,
        isFreeTrial,
        hasOpenInvoice,
        needActivation,
        sandboxExpireDate,
        freeTrialExpireDate,
        isOFC,
        sandboxLifeSpan,
    } = useAccountMasterHook();
    const { isAccountOverUsed } = useAccountOverUsage();
    const { redirectToDialog360Page } = useRedirectToDialog360Page();
    // free trail details

    const freeTrailExpireContent = React.useMemo(() => {
        if (!freeTrialExpireDate) return "";

        const daysLeft = dayjs(freeTrialExpireDate).utc().local().endOf("day").diff(dayjs().startOf("day"), "day");
        if (daysLeft > TRIAL_DAYS) return "";
        if (daysLeft > 0)
            return (
                <Text fontSize="xs" color="gray.500">
                    Your {config.product_name} free trial with{" "}
                    <Text as="span" fontWeight="bold">
                        GROWTH
                    </Text>{" "}
                    plan will expire in {daysLeft}
                    {daysLeft > 1 ? " days" : " day"}
                </Text>
            );
        if (daysLeft === 0) return `You’re on the ${config.product_name} Pro Plan. Your free trial ends today!`;
        if (daysLeft > -3) return `Your ${config.product_name} free trial is expired!`;
        return "⚠️ Payment required!";
    }, [freeTrialExpireDate]);

    // sandbox details
    const sandboxAlertContent = React.useMemo(() => {
        if (!sandboxExpireDate) return "";
        const daysLeft = dayjs(sandboxExpireDate).utc().local().endOf("day").diff(dayjs().startOf("day"), "day");
        if (daysLeft > (sandboxLifeSpan ? sandboxLifeSpan + 1 : SANDBOX_DAYS)) return "";
        if (daysLeft > 0)
            return `You’re currently using ${config.product_name} demo account which ends in ${daysLeft} ${
                daysLeft > 1 ? "days" : "day"
            }`;
        if (daysLeft === 0) return `You’re currently using ${config.product_name} demo account which ends today!`;
        if (daysLeft > -3) return `Your ${config.product_name} demo account is expired!`;
        return "⚠️ Action required!";
    }, [sandboxExpireDate, sandboxLifeSpan]);

    const newFreeTrialModalDisclosure = useDisclosure();

    const onGetYourOwnNumber = () => {
        trackEvent({ event: "GS_CONNECT_NUMBER_TOP" });

        history.push("/get-own-number-in-app");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const multiAlertProps = React.useMemo(() => {
        const alerts: AlertBannerPropsWithVisibility[] = [
            {
                isShow: isFreeTrial,
                content: {
                    text: freeTrailExpireContent,
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "I’m ready to upgrade",
                        buttonProps: {
                            color: "gray.600",
                            fontSize: "xs",
                            fontWeight: "medium",
                            textDecor: "underline",
                            variant: "unstyled",
                            colorScheme: undefined,
                            _hover: {
                                bg: "yellow.200",
                            },
                            onClick: () => {
                                history.push("/plan-and-subscription?tab=1");
                            },
                        },
                    },

                    secondaryButton: {
                        buttonText: "Book a free consultation",
                        buttonProps: {
                            leftIcon: <Icon as={LuCalendarHeart} />,
                            onClick: () => {
                                trackEvent({
                                    event: "GS_BOOK_DEMO_TOP",
                                });
                            },
                            rightIcon: undefined,
                            ml: 4,
                        },
                        href: config.support.book_a_demo_url,
                    },
                },
            },
            {
                isShow: isSandboxChannel,
                content: {
                    text: sandboxAlertContent,
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "I’m ready to connect my number",
                        buttonProps: {
                            color: "gray.600",
                            fontSize: "xs",
                            fontWeight: "medium",
                            textDecor: "underline",
                            variant: "unstyled",
                            colorScheme: undefined,
                            onClick: onGetYourOwnNumber,
                            _hover: {
                                bg: "yellow.200",
                            },
                        },
                    },
                    ...(!isOFC
                        ? {
                              secondaryButton: {
                                  buttonText: "Book a free consultation",
                                  buttonProps: {
                                      leftIcon: <Icon as={LuCalendarHeart} />,
                                      rightIcon: undefined,
                                      ml: 4,
                                  },
                                  href: config.support.book_a_demo_url,
                              },
                          }
                        : {}),
                    canPermissionCheck: true,
                    additionalCmptRender: (
                        <NewFreeTrialModal
                            isOpen={newFreeTrialModalDisclosure.isOpen}
                            onClose={newFreeTrialModalDisclosure.onClose}
                        />
                    ),
                },
            },
            {
                isShow: isNewAccount,
                content: {
                    text: "Your are almost there, Get started with your own Whatsapp number",
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "Connect your number",
                        buttonProps: {
                            onClick: () => {
                                trackEvent({ event: "GS_CONNECT_NUMBER_TOP" });
                                history.push("/get-own-number");
                            },
                        },
                    },
                    secondaryButton: {
                        buttonText: "Connect with our product expert",
                        href: config.support.book_a_demo_url,
                    },
                    canPermissionCheck: true,
                },
            },
            {
                isShow: needActivation,
                content: {
                    text: "Your channel is yet to be activated",
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "Activate now",
                        buttonProps: {
                            onClick: () => {
                                redirectToDialog360Page();
                            },
                        },
                    },

                    canPermissionCheck: true,
                },
            },
            {
                isShow: isAccountOverUsed,
                content: {
                    text: "Your user limit is exceeded, Please add more users for your Subscription Plan.",
                },
                leftIcon: <Icon as={RiUser3Line} color="gray.800" fontSize="sm" />,
                ctaButton: {
                    primaryButton: {
                        buttonText: "Add Users",
                        buttonProps: {
                            onClick: () => history.push("/plan-and-subscription?tab=1"),
                        },
                    },

                    canPermissionCheck: true,
                },
            },
            {
                isShow: hasOpenInvoice,
                content: {
                    text: "You have an unpaid invoice",
                },
                leftIcon: alertWarningIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "Pay now",
                        buttonProps: {
                            onClick: () => history.push("/plan-and-subscription#invoice"),
                        },
                    },
                },
            },
            {
                isShow: isSuspended,
                content: {
                    text: "Your Account is Suspended",
                },
                leftIcon: alertWarningIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "Pay now",
                        buttonProps: {
                            onClick: () => history.push("/plan-and-subscription#invoice"),
                        },
                    },
                },
            },
            {
                isShow: true,
                content: {
                    text: "Are Your Marketing messages getting failed? This could be one of the reasons.",
                },
                ctaButton: {
                    primaryButton: {
                        buttonText: "Know Why",
                        buttonProps: {
                            onClick: () => {
                                windowOpenNewTab(
                                    "https://docs.gallabox.com/extras/whatsapps-new-per-user-messaging-limits"
                                );
                            },
                        },
                    },
                },
                isClosable: true,
            },
        ];

        return alerts.filter((ob) => ob.isShow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        freeTrailExpireContent,
        hasOpenInvoice,
        history,
        isAccountOverUsed,
        isFreeTrial,
        isNewAccount,
        isSandboxChannel,
        isSuspended,
        needActivation,
        newFreeTrialModalDisclosure.isOpen,
        redirectToDialog360Page,
        sandboxAlertContent,
        isOFC,
    ]);

    return { multiAlertProps };
};
