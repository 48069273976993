import { initializeApp } from "firebase/app";
import {
    deleteToken as deleteFirebaseToken,
    getMessaging,
    getToken as getFirebaseToken,
    isSupported as checkIsSupported,
    MessagePayload,
    NextFn,
    onMessage,
    Unsubscribe,
} from "firebase/messaging";
import config from "app/app.config";

const firebaseConfig = {
    apiKey: config.notifications.firebase.api_key,
    authDomain: config.notifications.firebase.auth_domain,
    projectId: config.notifications.firebase.project_id,
    storageBucket: config.notifications.firebase.storage_bucket,
    messagingSenderId: config.notifications.firebase.messaging_sender_id,
    appId: config.notifications.firebase.app_id,
    measurementId: config.notifications.firebase.measurement_id,
};
const firebaseApp = initializeApp(firebaseConfig);

export const getToken = async (): Promise<string> => {
    const isSupported = await checkIsSupported();
    if (!isSupported) return Promise.resolve("--NOT-SUPPORTED--");
    const messaging = getMessaging(firebaseApp);
    return getFirebaseToken(messaging, { vapidKey: config.notifications.firebase.vapidKey });
};
export const deleteToken = async (): Promise<boolean | void> => {
    const isSupported = await checkIsSupported();
    if (!isSupported || Notification.permission !== "granted") return Promise.resolve(false);
    const messaging = getMessaging(firebaseApp);
    return deleteFirebaseToken(messaging).catch((e) => console.error(e, "error while unsubscribing"));
};
export const onMessageListener = (callBack: NextFn<MessagePayload>): Unsubscribe => {
    const messaging = getMessaging(firebaseApp);
    return onMessage(messaging, callBack);
};

export default firebaseApp;
