import { Icon, Box, IconProps } from "@chakra-ui/react";
import React from "react";
import { FaStar } from "react-icons/fa";

export interface StarRatingProps {
    totalNoOfStars: number;
    value?: number;
    onChange?: (rating: number) => void;
    isReadOnly?: boolean;
    size?: IconProps["fontSize"];
}

const StarRating: React.FC<StarRatingProps> = (props) => {
    const [rating, setRating] = React.useState(props.value ?? 0);
    const [hoverItem, setHoverItem] = React.useState(0);
    const [isHovering, setHovering] = React.useState(false);

    const isControlled = Boolean(props.onChange);

    React.useEffect(() => {
        if (isControlled) return;
        setRating(props.value ?? 0);
    }, [isControlled, props.value]);

    const handleStarClick = (index: number) => {
        setRating(index);
    };

    const value = isControlled ? props.value ?? 0 : rating;
    const onChange = isControlled ? props.onChange : handleStarClick;

    return (
        <Box>
            {[...Array(props.totalNoOfStars)].map((_, index) => (
                <Icon
                    as={FaStar}
                    key={index}
                    fontSize={props.size}
                    color={index < (isHovering ? hoverItem : value) ? "yellow.500" : "gray.300"}
                    onClick={() => {
                        if (props.isReadOnly) return;
                        onChange?.(index + 1);
                    }}
                    onMouseOver={() => {
                        if (props.isReadOnly) return;
                        setHovering(true);
                        setHoverItem(index + 1);
                    }}
                    onMouseLeave={() => {
                        if (props.isReadOnly) return;
                        setHovering(false);
                        setHoverItem(-1);
                    }}
                    _hover={{ color: index < (isHovering ? hoverItem : value) ? "yellow.500" : "gray.300" }}
                    style={{ cursor: "pointer" }}
                />
            ))}
        </Box>
    );
};

export default StarRating;
