import { Ability, AbilityClass, ForcedSubject, RawRuleOf } from "@casl/ability";

export const readActions = ["read", "readMany", "export"] as const;
export const writeActions = ["manage", "create", "update", "delete", "createMany", "import"];
export const actions = [...readActions, ...writeActions] as const;

export const subjects = [
    "Contact",
    "Visitor",
    "Conversation",
    "Message",
    "User",
    "Teams",
    "Account",
    "Channel",
    "APIKey",
    "Analytics",
    "CannedResponse",
    "TemplateMessage",
    "Webhook",
    "Bot",
    "Wallet",
    "Subscription",
    "Payments",
    "all",
    "Broadcast",
    "PII",
    "FlexiList",
    "WhatsappFlow",
    "KnowledgeBase",
    "Integration",
    "Commerce",
] as const;
export type Action = (typeof actions)[number];
export type Subject = (typeof subjects)[number];

export type Abilities = [Action, Subject | ForcedSubject<Exclude<Subject, "all">>];
export type AppAbility = Ability<Abilities>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export const createAbility = (rules: RawRuleOf<AppAbility>[]): Ability<Abilities> => new AppAbility(rules);
