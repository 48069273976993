import { Input, InputGroup, InputLeftElement, InputProps } from "@chakra-ui/react";
import { LocalOptions, toLocaleNumber } from "app/utils/currency";
import * as React from "react";

export interface CurrencyInputProps extends InputProps, Pick<LocalOptions, "locales" | "currency"> {}
// eslint-disable-next-line react/display-name
const CurrencyInput = React.forwardRef(
    (props: CurrencyInputProps, ref: React.LegacyRef<HTMLInputElement>): JSX.Element => {
        const { locales, currency, value } = props;
        const result = toLocaleNumber((value as string) ?? 0, {
            currency,
            locales,
            includeDecimal: true,
        });

        const currencySymbol = result.match(/[^\d.,]+/g)?.[0] || ""; // Extracts the currency symbol

        return (
            <InputGroup size="sm">
                <InputLeftElement zIndex={1} w="48px">
                    {currencySymbol}
                </InputLeftElement>
                <Input
                    ref={ref}
                    rounded="md"
                    borderColor="gray.200"
                    errorBorderColor="red.300"
                    {...props}
                    paddingInlineStart="2.5rem !important"
                    paddingStart="2.5rem !important"
                    type="number"
                    onChange={(...args) => {
                        const sanitizedValue = args[0].target.value.replace(/[^0-9]/g, "").slice(0, 10);
                        if (sanitizedValue.length >= 10) return;
                        props?.onChange?.(...args);
                    }}
                    _focusVisible={{ zIndex: 0 }}
                />
            </InputGroup>
        );
    }
);

export default CurrencyInput as (
    props: CurrencyInputProps & { ref?: React.LegacyRef<HTMLInputElement> }
) => JSX.Element;
