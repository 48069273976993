import { subject } from "@casl/ability";
import { Text, useToast } from "@chakra-ui/react";
import { onMessageListener } from "app/config/firebase";
import { useCheckAbility } from "app/hooks/useCheckAbility";
import captureException from "app/utils/captureException";
import { useUser } from "app/utils/react-helpers";
import { MessagePayload, Unsubscribe, isSupported as checkIsSupported } from "firebase/messaging";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const PushMessageListener: React.FC<{ userId?: string }> = ({ userId }) => {
    const toast = useToast();
    const history = useHistory();
    const { checkAbility } = useCheckAbility();
    const user = useUser();

    const routeTo = (url: string) => {
        if (url.startsWith("/")) {
            history.push(url);
            return;
        }
        try {
            const fullpath = new URL(url).pathname;
            history.push(fullpath);
        } catch (e) {
            captureException(e);
        }
    };

    useEffect(() => {
        let unsubscribeListener: Unsubscribe | null = null;

        const listen = async () => {
            const isSupported = await checkIsSupported();
            if (!isSupported) return null;

            unsubscribeListener = onMessageListener((payload: MessagePayload) => {
                if (!userId) return;

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let notificationData: Record<string, any> | null = null;

                // Bail out, if notification is for the current conversation
                const { data, notification: payloadNotification, fcmOptions } = payload;
                if (data?.data && typeof data?.data === "string") {
                    try {
                        notificationData = JSON.parse(data.data);
                    } catch (error) {}
                }

                if (data?.redirectAction === "notify_conversation_detail" && notificationData != null) {
                    if (notificationData.conversation?.id) {
                        if (
                            window.location.pathname
                                .toLowerCase()
                                .includes(`conversations/${notificationData.conversation?.id}`)
                        ) {
                            return;
                        }
                    }
                }

                // Bail out, if notification is for the current template
                if (data?.redirectAction === "notify_template_detail" && notificationData != null) {
                    if (notificationData.template?.id) {
                        if (window.location.pathname.toLowerCase().includes(`/${notificationData.template?.id}`)) {
                            return;
                        }
                    }

                    const isUnassignedConversation = !Boolean(notificationData?.conversation?.assigneeId);
                    if (isUnassignedConversation) {
                        const doesHaveAccess = checkAbility("read", "Conversation", {
                            userId: null,
                            botId: null,
                        });

                        if (!doesHaveAccess) {
                            return;
                        }
                    }

                    const isOpenConversation =
                        notificationData?.conversation?.assigneeId &&
                        notificationData?.conversation?.assigneeId !== user.id;
                    if (isOpenConversation) {
                        const doesHaveAccess = checkAbility("read", "Conversation", {
                            userId: "some_random_user",
                        });
                        const isMentionedConversation = notificationData?.conversation?.mentionedUsers?.includes(
                            user.id
                        );

                        if (!doesHaveAccess && !isMentionedConversation) {
                            return;
                        }
                    }
                }

                let notification = payloadNotification ?? {};
                if (!notification?.title && data?.notification) {
                    try {
                        notification = JSON.parse(data.notification);
                    } catch {}
                }
                if (!notification?.title) {
                    notification.title = "New Message";
                }

                toast({
                    title: (
                        <Text
                            onClick={(e) => {
                                e.preventDefault();
                                if (fcmOptions?.link) return routeTo(fcmOptions.link);
                                return;
                            }}
                            pb={notification.body ? 1 : 0}
                        >
                            {notification.title}
                        </Text>
                    ),
                    description: notification.body,
                    status: notificationData?.variant ?? "info",
                    variant: "left-accent",
                    position: "bottom-right",
                });
            });
        };

        listen();

        return () => {
            unsubscribeListener?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, checkAbility]);

    return null;
};

export default PushMessageListener;
