export const SERVER_SOCKET_URL_ENV = import.meta.env.REACT_APP_SERVER_SOCKET_URL;
export const SERVER_URL_ENV = import.meta.env.REACT_APP_SERVER_URL;
export const WIDGET_URL_ENV = import.meta.env.REACT_APP_WIDGET_URL;
export const SENTRY_DSN_ENV = import.meta.env.REACT_APP_SENTRY_DSN;
export const MAP_API_KEY_ENV = import.meta.env.REACT_APP_MAP_API_KEY;
export const COOKIE_DOMAIN_ENV = import.meta.env.REACT_APP_COOKIE_DOMAIN;
export const CDN_IMGPROXY_URL_ENV = import.meta.env.REACT_APP_CDN_IMGPROXY_URL;
export const RECAPTCHA_KEY_ENV = import.meta.env.REACT_APP_RECAPTCHA_KEY;
export const STRIPE_KEY_ENV = import.meta.env.REACT_APP_STRIPE_KEY;
export const AMPLITUDE_DEVELOPMENT_API_KEY_ENV = import.meta.env.REACT_APP_AMPLITUDE_DEVELOPMENT_API_KEY;
export const GB_ADMIN_ACC_ENV = import.meta.env.REACT_APP_GB_ADMIN_ACC;
export const CANNY_APP_ID_ENV = import.meta.env.REACT_APP_CANNY_APP_ID;
export const HOME_APP_URL_ENV = import.meta.env.REACT_APP_HOME_APP_URL;
export const NODE_ENV = import.meta.env.MODE;
export const PUBLIC_URL = import.meta.env.REACT_APP_PUBLIC_URL;
export const WA_FORM_PIXEL_APP_URL_ENV = import.meta.env.REACT_APP_WA_FORM_PIXEL_APP_URL;
export const APP_DOMAIN = import.meta.env.REACT_APP_APP_DOMAIN;
