import { Link } from "@chakra-ui/layout";
import { Tag, TagLabel } from "@chakra-ui/tag";
import { MentionedUsers } from "app/types/message/private-message";
import classNames from "classnames";
import React, { ElementType } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import breaks from "remark-breaks";
import UserAvatar from "app/components/CustomAvatar";

export const convertWhatsappFormattingToMarkdown = (markdown: string): string => {
    const italicRegex = /(_([^_]+(\s\w+)*)_)/gi;
    const italicSub = "*$2*";
    const boldRegex = /(\*([^*]+(\s\w+)*)\*)/gi;
    const boldSub = "**$2**";
    const strikeRegex = /(~([^~]+(\s\w+)*)~)/gi;
    const strikeSub = "~~$2~~";
    const monoRegex = /(```([^`]+(\s\w+)*)```)/gi;
    const monoSub = "`$2`";
    return markdown
        .replace(boldRegex, boldSub)
        .replace(italicRegex, italicSub)
        .replace(strikeRegex, strikeSub)
        .replace(monoRegex, monoSub);
};

const chakraRender = (mentionedUsers: MentionedUsers): { [nodeType: string]: ElementType } => ({
    // eslint-disable-next-line react/display-name
    link: (value) => {
        const title = value?.node?.children?.[0]?.value;
        const url = value.node.url;
        const mentionUrl = "mention://users/";
        const isMention = url.indexOf(mentionUrl) === 0;
        const id = url?.slice(mentionUrl.length);

        const avaUrl = mentionedUsers.find((m) => m.id === id)?.picture;
        if (isMention) {
            return (
                <Tag size="sm" borderRadius="full" colorScheme="brand">
                    <UserAvatar size="2xs" ml={-1} mr={1} src={avaUrl} name={title} colorKey={id || "unknown"} />
                    <TagLabel>{title}</TagLabel>
                </Tag>
            );
        }

        return (
            <Link href={url} isExternal color="brand.500">
                {title}
            </Link>
        );
    },
});
const plainTextRender: { [nodeType: string]: ElementType } = {
    // eslint-disable-next-line react/display-name
    link: (value) => {
        const title = value?.node?.children?.[0]?.value;
        const url = value.node.url;
        const isMention = url.indexOf("mention://users/") === 0;

        if (isMention) {
            return <span style={{ color: "brand.500" }}>{`@${title}`}</span>;
        }

        return <span>{title}</span>;
    },
};

interface MarkdownRenderProps {
    source: string;
    isPlainText?: boolean;
    mentionedUsers?: MentionedUsers;
    compact?: boolean;
    isWhatsAppMarkdown?: boolean;
}
const MarkdownRender: React.FC<MarkdownRenderProps> = ({
    source,
    isPlainText,
    mentionedUsers,
    compact = false,
    isWhatsAppMarkdown = false,
}) => {
    const plainTextSource = isPlainText ? source : source.replace(/\n/gi, "&nbsp; \n");
    const formattedSource = isWhatsAppMarkdown ? convertWhatsappFormattingToMarkdown(plainTextSource) : plainTextSource;

    return (
        <ReactMarkdown
            className={classNames({ compact })}
            source={formattedSource}
            plugins={isPlainText ? [gfm] : [gfm, breaks]}
            renderers={isPlainText ? plainTextRender : chakraRender(mentionedUsers ?? [])}
        />
    );
};

export default MarkdownRender;
