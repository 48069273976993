import React from "react";
import TwoFAIcon from "assets/onboarding/two-fa.svg";
import { VStack, Text, Button, ButtonGroup, Heading } from "@chakra-ui/react";
import SelfServeContainer from "../../../SelfServe/components/SelfServeContainer";
import SelfServeIconHeader from "../../../SelfServe/components/SelfServeIconHeader";
import SelfServeLayout from "../../../SelfServe/components/SelfServeLayout";
import { useHistory } from "react-router-dom";
import useLocalStorage from "app/hooks/useLocalStorage";
import { useGBTranslation } from "lang/hooks/useGBTranslation";
import GBTrans from "lang/components/GBTrans";

export const TWO_FA_LOCAL_STORAGE_ACCESSOR = "show_2fa_nudge";

const TwoFANudge: React.FC = () => {
    const history = useHistory();
    const { t } = useGBTranslation(["auth", "common"]);

    const [, setTwoFALocalState] = useLocalStorage(TWO_FA_LOCAL_STORAGE_ACCESSOR, false, {});

    const disableNudge = () => {
        setTwoFALocalState(false);
    };
    const onSkip = () => {
        disableNudge();
        history.push("/home");
    };
    const onEnable = () => {
        disableNudge();
        history.push("/profile?two-fa=enable");
    };

    return (
        <SelfServeLayout>
            <SelfServeContainer
                containerProps={{
                    bg: { base: "transparent", sm: "white", lg: "white" },
                    boxShadow: { base: "none", sm: "base", lg: "base" },
                    px: { base: 5, sm: 14, lg: 14 },
                    py: { base: 5, sm: 10, lg: 10 },
                }}
            >
                <VStack align="start" spacing={3} w="full" h="full">
                    <SelfServeIconHeader
                        title={
                            <Heading zIndex={1} fontSize="2xl" fontWeight="bold">
                                <GBTrans
                                    ns="auth"
                                    components={{ 1: <br /> }}
                                    i18nKey="Protect your account with two-factor authentication"
                                >
                                    Protect your account with
                                    <br /> two-factor authentication
                                </GBTrans>
                            </Heading>
                        }
                        icon={TwoFAIcon}
                        spacing={5}
                    />
                    <Text color="gray.500" fontSize="sm">
                        <GBTrans
                            ns="auth"
                            i18nKey="Take control of your account's security. Enable two-factor authentication today for an added layer of protection."
                        >
                            Take control of your {"account's"} security. Enable <br />
                            two-factor authentication today for an added layer of protection.
                        </GBTrans>
                    </Text>
                    <ButtonGroup w="full" mt="6 !important">
                        <Button w="full" rounded="md" variant="outline" onClick={onSkip}>
                            {t("Skip", { ns: "common" })}
                        </Button>
                        <Button w="full" rounded="md" colorScheme="brand" onClick={onEnable}>
                            {t("Enable", { ns: "common" })}
                        </Button>
                    </ButtonGroup>
                </VStack>
            </SelfServeContainer>
        </SelfServeLayout>
    );
};

export default TwoFANudge;
