import { Box, HStack, Icon, Text, Image } from "@chakra-ui/react";
import ReactSelectStyled, { ReactSelectStyledProps } from "app/components/CustomizedReactSelect/ReactSelectStyled";
import { LabelValue } from "app/types";
import { isJSXElement } from "app/utils/common";
import React from "react";
import { IconType } from "react-icons";
import { components, OptionProps, OptionTypeBase, ValueContainerProps } from "react-select";
import { ContainerState } from "react-select/src/components/containers";

const { Option, ValueContainer } = components;

export interface IconLabelValue<T = string, U = string> extends LabelValue<T, U> {
    icon: IconType | string;
}

const IconOptions = (props: OptionProps<OptionTypeBase, true>) => {
    return (
        <Option {...props}>
            <HStack spacing={3}>
                {isJSXElement(props.data.icon) ? (
                    <Icon as={props.data.icon} color="gray.600" />
                ) : (
                    <Image src={props.data.icon} boxSize="6" alt="App icon" />
                )}
                <Text noOfLines={1} maxW="200px" color="gray.600">
                    {props.data.label}
                </Text>
            </HStack>
        </Option>
    );
};

const IconValueContainer = (props: ValueContainerProps<OptionTypeBase, true> & ContainerState) => {
    const values = props.getValue() ?? [];
    const icon = values?.[0]?.icon;
    return (
        <ValueContainer {...props}>
            {icon ? (
                <HStack spacing={1.5} color="gray.600">
                    {isJSXElement(icon) ? (
                        <Icon
                            as={icon as unknown as IconType}
                            color={props?.isDisabled ? "gray.400" : "gray.600"}
                            mb="3px"
                        />
                    ) : (
                        <Image src={icon} boxSize="6" alt="App icon" />
                    )}
                    <Box>{props.children}</Box>
                </HStack>
            ) : (
                props.children
            )}
        </ValueContainer>
    );
};

type IconReactSelectProps<T extends IconLabelValue = IconLabelValue> = ReactSelectStyledProps<T>;

export const IconReactSelect = <T extends IconLabelValue = IconLabelValue>(
    props: IconReactSelectProps<T>
): JSX.Element => {
    return (
        <ReactSelectStyled<T>
            mb={0}
            {...props}
            components={{
                Option: IconOptions as typeof Option,
                ValueContainer: IconValueContainer as typeof ValueContainer,
            }}
        />
    );
};
