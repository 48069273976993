import config from "app/app.config";
import { Dialog360CallbackObject, Dialog360ConnectButtonProps } from "app/types/dialog360";
import { callFnsInSequence } from "app/utils/common";
import { useAccountId } from "app/utils/react-helpers";
import { PUBLIC_URL } from "environment";
import { useHistory } from "react-router-dom";

export interface UseDialog360ConnectButtonProps {
    from?: "onboarding" | "channels";
}
interface UseDialog360ConnectButtonReturnType {
    getDialog360ButtonProps: (buttonProps: Partial<Dialog360ConnectButtonProps>) => Dialog360ConnectButtonProps;
}
export const useDialog360ConnectButton = (
    props?: UseDialog360ConnectButtonProps
): UseDialog360ConnectButtonReturnType => {
    const history = useHistory();
    const accountId = useAccountId();

    const from = props?.from ?? "channels";

    const onDialog360Callback = (data: Dialog360CallbackObject) => {
        history.push(`/channels/whatsapp/dialog360/updateClient?from=${from}&client=${data.client}`);
    };

    const getDialog360ButtonProps = (
        buttonProps?: Partial<Dialog360ConnectButtonProps>
    ): Dialog360ConnectButtonProps => {
        const { callback, ...otherProps } = buttonProps ?? {};
        return {
            callback: callFnsInSequence(onDialog360Callback, callback),
            partnerId: config.dialog_360.partnerId,
            queryParameters: {
                redirect_url: `${PUBLIC_URL}/dialog360-callback?from=${from}`,
                state: accountId,
                partner: accountId,
                plan_selection: "basic",
            },
            ...otherProps,
        };
    };
    return { getDialog360ButtonProps };
};
