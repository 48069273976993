/* eslint-disable @typescript-eslint/no-explicit-any */
import { RootState } from "app/modules";
import config from "app/app.config";
import { Channel } from "app/types";
import { Account } from "app/types/account";
import { File as IFile, FileContextModule } from "app/types/file";
import { WebMessageMediaType } from "app/types/message/web-message";
import { WAIMessageMediaType } from "app/types/message/whatsapp-message";
import { User } from "app/types/user";
import axios from "axios";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js/min";
import Mustache from "mustache";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postJSON } from "./fetchUtils";

// type ReactRef<T> = React.Ref<T> | React.RefObject<T> | React.MutableRefObject<T>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mergeRefs = (...refs: any[]) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];
    return (inst: any) => {
        for (const ref of filteredRefs) {
            if (typeof ref === "function") {
                ref(inst);
            } else if (ref) {
                ref.current = inst;
            }
        }
    };
};

export const getOSFromUserAgent = (userAgent: string): string => {
    let OSName = "Unknown OS";
    if (userAgent.indexOf("Win") != -1) OSName = "Windows";
    if (userAgent.indexOf("Mac") != -1) OSName = "Macintosh";
    if (userAgent.indexOf("Linux") != -1) OSName = "Linux";
    if (userAgent.indexOf("Android") != -1) OSName = "Android";
    if (userAgent.indexOf("like Mac") != -1) OSName = "iOS";
    return OSName;
};

export function usePageTop(): boolean {
    const [top, setTop] = React.useState(false);

    React.useEffect(() => {
        function handleScroll() {
            if (!document?.documentElement) return;

            const isTop = document.documentElement.scrollTop === document.documentElement.offsetHeight * 0.1;
            console.log(document.documentElement.scrollTop, document.documentElement.offsetHeight);
            setTop(isTop);
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return top;
}

export function usePageBottomV2(element: React.MutableRefObject<null | HTMLDivElement>): boolean {
    const [bottom, setBottom] = React.useState(false);

    React.useEffect(() => {
        const handleScroll = () => {
            const scrollHeight = element?.current?.scrollHeight ?? 0;
            const scrollTop = Math.abs(element?.current?.scrollTop ?? 0);
            const clientHeight = element?.current?.clientHeight;
            const isBottom = scrollHeight - scrollTop === clientHeight;
            // console.log(scrollHeight, scrollTop, clientHeight);
            setBottom(isBottom);
        };
        const el = element.current;
        el?.addEventListener("scroll", handleScroll);
        return () => {
            el?.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return bottom;
}

export function scrollNotAtBottom(
    element: React.MutableRefObject<null | HTMLDivElement>,
    offset: number = 0.05
): boolean {
    const [notAtBottom, setNotAtBottom] = React.useState(false);

    React.useEffect(() => {
        const handleScroll = () => {
            const scrollHeight = element?.current?.scrollHeight ?? 0;
            const scrollTop = Math.abs(element?.current?.scrollTop ?? 0);

            // console.log(scrollHeight, scrollTop, clientHeight);
            setNotAtBottom(scrollHeight * offset < scrollTop);
        };
        const el = element.current;
        el?.addEventListener("scroll", handleScroll);
        return () => {
            el?.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return notAtBottom;
}

interface ExtendedHTMLElement extends HTMLElement {
    previousScrollHeightMinusTop?: number;
}

type UsePreventScroll = (element: React.RefObject<ExtendedHTMLElement | null>) => (() => void) | void;

export const usePreventScroll: UsePreventScroll = (element) => {
    if (!element.current) return;

    element.current.previousScrollHeightMinusTop = -(element.current.scrollHeight + element.current.scrollTop);

    const maintainScrollPosition = () => {
        if (!element.current) return;
        element.current.scrollTop = -(
            element.current.scrollHeight + (element.current.previousScrollHeightMinusTop ?? 0)
        );
    };

    return maintainScrollPosition;
};
export const useInfiniteScroll = (
    element: React.RefObject<null | HTMLElement>,
    callback: () => void,
    callFuncManually: boolean = false
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
    const [isFetching, setIsFetching] = React.useState(false);

    const isScrolling = () => {
        const { scrollHeight = 1, clientHeight = 1, scrollTop = 0 } = element?.current ?? {};

        const height = Math.floor(clientHeight);
        const position = Math.floor(scrollTop + scrollHeight);
        const threshold = height * 0.3;

        const isBottom = position <= height + threshold;
        if (!isBottom || isFetching) return;

        setIsFetching(true);
    };

    React.useEffect(() => {
        if (!callFuncManually) {
            const el = element.current;
            el?.addEventListener("scroll", isScrolling);
            return () => {
                el?.removeEventListener("scroll", isScrolling);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!isFetching) return;
        if (!callFuncManually) callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);
    return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
type useTopBottomInfiniteScrollResult = [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
];

// custom hook for getting previous value
export const usePrevious = <T>(value: T): T | undefined => {
    const ref = React.useRef<T>();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useCopyToClipboard = (text?: string, resetInterval: number = 2000): [boolean, () => void] => {
    const copyToClipboard = (str: string) => {
        const el = document.createElement("textarea");
        el.value = str;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        const selected =
            (document.getSelection()?.rangeCount ?? 0) > 0 ? document.getSelection()?.getRangeAt(0) : false;
        el.select();
        const success = document.execCommand("copy");
        document.body.removeChild(el);
        if (selected) {
            document.getSelection()?.removeAllRanges();
            document.getSelection()?.addRange?.(selected);
        }
        return success;
    };

    const [copied, setCopied] = React.useState(false);

    const copy = React.useCallback(
        (txt?: string) => {
            let copyText;

            if (txt && typeof txt === "string") copyText = txt;
            else copyText = text;

            if (!copied && copyText) setCopied(copyToClipboard(copyText));
        },
        [copied, text]
    );

    React.useEffect(() => () => setCopied(false), [text]);

    React.useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (copied && resetInterval) {
            timeout = setTimeout(() => setCopied(false), resetInterval);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [copied, resetInterval]);

    return [copied, copy];
};

export const useCopyToClipboardV2 = (resetInterval: number = 2000): [boolean, (t: string) => void] => {
    const [copied, setCopied] = React.useState(false);

    const copy = React.useCallback(async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
        } catch (error) {
            setCopied(false);
        }
    }, []);

    React.useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (copied && resetInterval) {
            timeout = setTimeout(() => setCopied(false), resetInterval);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [copied, resetInterval]);

    return [copied, copy];
};

const audioMimeTypes = ["audio/aac", "audio/mp4", "audio/amr", "audio/mpeg", "audio/ogg"];
const imageMimeTypes = ["image/jpeg", "image/png"];
const stickerMimeTypes = ["image/webp"];
const videoMimeTypes = ["video/mp4", "video/3gpp"];
const documentMimeTypes = ["application/vnd.ms-excel", "application/pdf"];

export const FILE_TYPES = [
    ...audioMimeTypes,
    ...imageMimeTypes,
    ...stickerMimeTypes,
    ...videoMimeTypes,
    ...documentMimeTypes,
] as const;

export type UPLOADED_FILE_TYPE = (typeof FILE_TYPES)[number];

export const getFileType = (mimeType: string = ""): WAIMessageMediaType | WebMessageMediaType => {
    if (audioMimeTypes.includes(mimeType)) return "audio";
    if (imageMimeTypes.includes(mimeType)) return "image";
    if (stickerMimeTypes.includes(mimeType)) return "sticker";
    if (videoMimeTypes.includes(mimeType)) return "video";
    if (documentMimeTypes.includes(mimeType)) return "document";
    return "document";
};

export type UploadProgress = (progressEvent: ProgressEvent) => void;

export interface UploadMessageMediaProps {
    file: File;
    accountId: string;
    onUploadProgress?: UploadProgress;
    module?: FileContextModule;
}

export const uploadMedia = async (props: UploadMessageMediaProps): Promise<IFile> => {
    const { file, accountId, onUploadProgress, module } = props;
    const formData = new FormData();
    formData.append("file", file);
    const fileDetails = await uploadMediaToS3({ file, accountId, module, onUploadProgress });
    return fileDetails;
};

export interface ISignedURLObj extends IFile {
    url: string;
    fields: { [key: string]: string };
    fileLocation: string;
}

export const postS3signedUrl = async (args: UploadMessageMediaProps): Promise<ISignedURLObj> => {
    const { file, accountId, module } = args;
    const url = `/api/accounts/${accountId}/fileUrl`;
    const fileExt = file.name.slice(file.name.lastIndexOf("."));
    const body = {
        name: file.name,
        fileExt,
        fileType: file.type,
        size: file.size,
        fileMode: module ?? "unknown",
    };
    const signedObj: ISignedURLObj = await postJSON(url, body);
    return signedObj;
};

export const uploadMediaToS3 = async (args: UploadMessageMediaProps): Promise<ISignedURLObj> => {
    const { file, onUploadProgress } = args;
    const presignedObj = await postS3signedUrl(args);
    const formData = new FormData();
    Object.entries(presignedObj.fields).forEach(([k, v]) => {
        formData.append(k, v as string);
    });
    formData.append("Content-Type", file.type);
    formData.append("file", file); // The file has be the last element
    const response = await axios.post(presignedObj.url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress,
    });
    if (response.status >= 200 && response.status < 300) {
        return { ...presignedObj, url: presignedObj.fileLocation };
    }
    throw new Error(response.statusText);
};

export const useDebounce = <T>(value: T, delay: number): T => {
    const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

interface ImageParams {
    zoom?: number;
    width?: number;
    height?: number;
    format?: "png" | "png8" | "gif" | "jpg" | "jpg-baseline";
    mapType?: "roadmap" | "satellite" | "terrain" | "hybrid";
}

interface LinkParams {
    zoom?: number;
    mapType?: MapLinkType;
}

enum MapLinkType {
    Map = "m",
    Satellite = "k",
    Hybrid = "h",
    Terrain = "p",
    GoogleEarth = "e",
}

export const getMapImageAndLink = (
    latitude: number,
    longitude: number,
    imageParams?: ImageParams,
    linkParams?: LinkParams
): { image: string; link: string } => {
    const { zoom = 15, width = 300, height = 300, format = "jpg", mapType = "roadmap" } = imageParams ?? {};
    const { zoom: z = 12, mapType: t = MapLinkType.Map } = linkParams || {};
    const { REACT_APP_MAP_API_KEY } = import.meta.env;
    const image = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&markers=color:blue%7C${latitude},${longitude}&zoom=${zoom}&size=${width}x${height}&format=${format}&maptype=${mapType}&key=${REACT_APP_MAP_API_KEY}`;
    const link = `https://maps.google.com/maps?z=${z}&q=loc:${latitude}+${longitude}&t=${t}`;
    return { image, link };
};

const sumDigit = (num: number, sum: number = 0): number => {
    if (num) {
        return sumDigit(Math.floor(num / 10), sum + (num % 10));
    }
    return sum;
};

const sumRepeatedly = (num: number): number => {
    while (num > 9) {
        num = sumDigit(num);
    }
    return num;
};

export const hashCode = (str: string): number => {
    let hash = 0,
        i,
        chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return sumRepeatedly(Math.abs(hash));
};

interface AvatarColor {
    bg: string;
    color: string;
}

export const avatarColor: AvatarColor[] = [
    { bg: "gray.100", color: "gray.500" },
    { bg: "red.100", color: "gray.500" },
    { bg: "orange.100", color: "gray.500" },
    { bg: "yellow.100", color: "gray.500" },
    { bg: "green.100", color: "gray.500" },
    { bg: "teal.100", color: "gray.500" },
    { bg: "blue.100", color: "gray.500" },
    { bg: "cyan.100", color: "gray.500" },
    { bg: "purple.100", color: "gray.500" },
    { bg: "pink.100", color: "gray.500" },
];

export const getAvatarColor = (name: string): AvatarColor => {
    return avatarColor[hashCode(name)];
};

export const createFileList = (file: File): FileList => {
    const dt = new DataTransfer();
    dt.items.add(file);
    return dt.files;
};

interface useIntersectionObserverProps {
    root?: React.RefObject<HTMLElement>;
    target: React.RefObject<HTMLElement> | null;
    onIntersect: () => void;
    threshold?: number;
    rootMargin?: string;
    enabled?: boolean;
}

export const useIntersectionObserver = ({
    root,
    target,
    onIntersect,
    threshold = 1.0,
    rootMargin = "0px",
    enabled = true,
}: useIntersectionObserverProps): void => {
    React.useEffect(() => {
        if (!enabled) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) =>
                entries.forEach((entry) => {
                    if (entry.isIntersecting) onIntersect();
                }),
            {
                root: root && root.current,
                rootMargin,
                threshold,
            }
        );

        const el = target && target.current;
        if (!el) {
            return;
        }

        observer.observe(el);

        return () => {
            observer.unobserve(el);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [target?.current, enabled, onIntersect]);
};

export const formatBytes = (bytes: number, decimals: number = 2): string => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const isMustacheTemplateValid = (template: string): boolean => {
    try {
        Mustache.parse(template);
        return true;
    } catch (error) {
        return false;
    }
};

export const mustacheRender: typeof Mustache.render = (...args) => {
    const [template, ...otherArgs] = args;
    try {
        const unescapedTemplate = template
            .replace(/\{\{\{([^{}]*)\}\}\}/g, "{{$1}}")
            .replace(/\{\{([^{}]*)\}\}/g, "{{{$1}}}");
        const rendered = Mustache.render(unescapedTemplate, ...otherArgs);
        return rendered;
    } catch (e) {
        return template;
    }
};

export const unescapeHtml = (html?: string): string | undefined => {
    if (!html) return html;
    return (
        html
            // eslint-disable-next-line quotes
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'")
            .replace(/&#x3A;/g, ":")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&amp;/g, "&")
    );
};

// Hook
export const useEventListener = (eventName: string, handler: EventListener, element = window): void => {
    // Create a ref that stores handler
    const savedHandler = React.useRef<EventListener | null>(null);

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    React.useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    React.useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            const eventListener: EventListener = (event) => savedHandler.current?.(event);

            // Add event listener
            element.addEventListener(eventName, eventListener);

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
};

export const useAccount = (): Account | undefined =>
    useSelector((state: RootState) => state.authed.user.accounts?.[0]?.account);

export const useAccountId = (): string =>
    useSelector((state: RootState) => state.authed.user.accounts?.[0]?.accountId) as string;

export const useUser = (): User => useSelector((state: RootState) => state.authed.user) as User;
export const useUserId = (): string => useSelector((state: RootState) => state.authed.user._id) as string;

export const useCurrency = (): string =>
    useSelector((state: RootState) =>
        (state.authed.user.accounts?.[0]?.account as Account)?.country.toLowerCase() === "india"
            ? "INR"
            : config.default_currency
    );

export const useCountry = (): string =>
    useSelector((state: RootState) => (state.authed.user.accounts?.[0]?.account as Account)?.country || "India");

export const useWaChannels = (): Channel[] | undefined => useSelector((state: RootState) => state.authed.waChannels);

export const useUserChannelIds = (): string[] | undefined =>
    useSelector((state: RootState) => state.authed.user.accounts?.[0].channelIds);

export const formatPhoneNumber = (phone?: string): string | undefined => {
    try {
        if (!phone || !Number(phone)) return phone;
        if (["+52", "52", "+55", "55"].some((code) => phone.startsWith(code)))
            return phone.startsWith("+") ? phone : `+${phone}`;

        return parsePhoneNumber(phone.startsWith("+") ? phone : `+${phone}`).format("INTERNATIONAL", {
            humanReadable: true,
        });
    } catch (err) {
        console.log(err);
        return phone;
    }
};

export const isValidPhone = (phone: string, strictValidationOnly = false): boolean => {
    if (!phone) return false;
    const isValidPhoneStrictly = isValidPhoneNumber(phone.startsWith("+") ? phone : `+${phone}`);
    if (strictValidationOnly) return isValidPhoneStrictly;
    return isValidPhoneStrictly || Boolean(Number(phone) && phone.length > 9);
};

export const useBackgroundNotification = (): void => {
    const history = useHistory();

    const handler = (event: MessageEvent<any>): void => {
        if (event.data && event.data.type === "BACKGROUND_NOTIFICATION_CONVERSATION_ID") {
            history.push(`/conversations/${event.data.conversationId}`);
        }
    };
    React.useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener("message", handler);

            return () => {
                navigator.serviceWorker.removeEventListener("message", handler);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export const getCurrencySymbol = (iso: string): string => {
    const currencySymbols: Record<string, string> = {
        BRL: "R$",
        USD: "$",
        EUR: "€",
        GBP: "£",
        INR: "₹",
        // Add more currency symbols as needed
    };
    return currencySymbols[iso] ?? currencySymbols["USD"];
};
