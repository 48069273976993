import { ColorProps } from "@chakra-ui/react";
import { Industry, WATemplateStatus } from "app/types";
import fbImg from "assets/fblogo.svg";
import GoogleSheet from "assets/Google-Sheets.svg";
import hubspotImg from "assets/hubspot.png";
import Pabbly from "assets/Pabblylogo.png";
import RazorPay from "assets/razorpay.svg";
import shopifyImg from "assets/shopifyIcon.svg";
import WebEngageLogo from "assets/WebEngageLogo.png";
import wooCommerceLogo from "assets/woocommerceLogo.svg";
import Zapier from "assets/Zapier.svg";
import zohoImg from "assets/ZOHO.svg";
import { TemplateCategoryGroupOption, TemplateCategoryOption, TemplateCategoryOptionType } from "./types";

export const NEW_TO_CAROUSEL_FOOTER_TEXT =
    "Carousel templates allow you to send a single text message accompanied by a set of up to 10 carousel cards in a horizontally scrollable view.";

export const MESSAGE_HEADER_INFO_TEXT = "Highlight your message by adding a custom text or media.";

export const MESSAGE_BODY_INFO_TEXT = "Type in the message you want to share with your users.";

export const CAROUSEL_CARD_FOOTER_TEXT = "Carousel templates support up to 10 carousel cards.";

export const MARKETING_TEMPLATE_FOOTER_TEXT = "Reply with 'STOP' to unsubscribe from marketing messages.";

export const MESSAGE_BODY_SAMPLE_VALUE_TEXT =
    "Enter a sample value for your variables to give context to the WhatsApp’s template approval team.";

export const ONE_BYTE = 1 * 1024 * 1024;
export const MAX_FILE_SIZE_IN_BYTES = 10 * ONE_BYTE;
export const MAX_IMAGE_FILE_SIZE_IN_BYTES = 5 * ONE_BYTE;
export const MAX_VIDEO_FILE_SIZE = 16 * ONE_BYTE;

interface ColorCodeWithText {
    bgColor: ColorProps["color"];
    color: ColorProps["textColor"];
}

export const industryWiseColorCode = new Map<Industry, ColorCodeWithText>([
    [
        "Automobile",
        {
            bgColor: "teal.100",
            color: "teal.600",
        },
    ],
    [
        "Travel",
        {
            bgColor: "blue.100",
            color: "blue.600",
        },
    ],
    [
        "Healthcare",
        {
            bgColor: "purple.100",
            color: "purple.600",
        },
    ],
    [
        "Education",
        {
            bgColor: "cyan.100",
            color: "cyan.600",
        },
    ],
    ["Ecommerce", { bgColor: "green.100", color: "gray.600" }],
    [
        "Finance",
        {
            bgColor: "yellow.100",
            color: "yellow.600",
        },
    ],
    [
        "Real Estate",
        {
            bgColor: "pink.100",
            color: "pink.600",
        },
    ],
    [
        "Popular",
        {
            bgColor: "red.100",
            color: "red.600",
        },
    ],
]);

interface ColorCode {
    bgColor: ColorProps["color"];
    fontColor: ColorProps["textColor"];
}

export const templateTagColor = new Map<WATemplateStatus, ColorCode>([
    ["recently_approved", { bgColor: "blue.100", fontColor: "blue.500" }],
    ["approved", { bgColor: "green.100", fontColor: "green.500" }],
    ["pending", { bgColor: "yellow.100", fontColor: "yellow.500" }],
    ["submitted", { bgColor: "blue.100", fontColor: "blue.500" }],
    ["rejected", { bgColor: "red.100", fontColor: "red.500" }],
    ["error", { bgColor: "red.100", fontColor: "red.500" }],
    ["created", { bgColor: "gray.200", fontColor: "gray.500" }],
]);

export const appImage: Record<string, string> = {
    Shopify: shopifyImg,
    WooCommerce: wooCommerceLogo,
    zoho: zohoImg,
    FbLeads: fbImg,
    pabbly: Pabbly,
    Gsheet: GoogleSheet,
    webengage: WebEngageLogo,
    Razorpay: RazorPay,
    zapier: Zapier,
    hubspot: hubspotImg,
};

export const templateCategorySelectOptions: TemplateCategoryOptionType[] = [
    {
        label: "Marketing template",
        options: [
            {
                value: "CUSTOM MARKETING",
                type: "CUSTOM MARKETING",
                label: "Custom marketing",
                category: "MARKETING",
                description: "Send with media and buttons",
            },
            {
                value: "PRODUCT MARKETING",
                type: "PRODUCT MARKETING",
                label: "Product marketing",
                category: "MARKETING",
                description: "Send catalogue products",
            },
            {
                value: "CAROUSEL_MARKETING",
                type: "CAROUSEL",
                label: "Carousel marketing",
                category: "MARKETING",
                description: "Send 10 media cards",
                isNew: true,
            },
        ],
    },
    {
        label: "Utility template",
        options: [
            {
                value: "UTILITY",
                type: "UTILITY",
                label: "Custom utility",
                category: "UTILITY",
                description: "Send with media and buttons",
            },
            {
                value: "CAROUSEL_UTILITY",
                type: "CAROUSEL",
                label: "Carousel utility",
                category: "UTILITY",
                description: "Send 10 media cards",
                isNew: true,
            },
        ],
    },
    {
        label: "Other template",
        options: [
            {
                value: "AUTHENTICATION",
                type: "AUTHENTICATION",
                label: "Authentication template",
                category: "AUTHENTICATION",
                description: "coming soon",
                isDisabled: true,
            },
        ],
    },
];

export const currentTemplateCategoryOptions = templateCategorySelectOptions.flatMap((category) =>
    (category as TemplateCategoryGroupOption).options ? (category as TemplateCategoryGroupOption).options : [category]
) as TemplateCategoryOption[];

export const QUICK_REPLY_BUTTON_FB_DOC =
    "https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/components#quick-reply-buttons";
export const PHONE_NUMBER_BUTTON_FB_DOC =
    "https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/components#phone-number-buttons";
export const URL_BUTTON_FB_DOC =
    "https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/components#url-buttons";
