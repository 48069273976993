import { LabelValue, User } from "app/types";
import { Contact } from "app/types/contact";
import { AsyncSelectErrorHandler, getJSON } from "app/utils/fetchUtils";
import { useAccountId } from "app/utils/react-helpers";
import React from "react";
import Async from "react-select/async";
import { StylesConfig } from "react-select/src/styles";
import AsyncSelectStyled, { AsyncSelectStyledProps } from "../CustomizedReactSelect/AsyncSelectStyled";
import { ReactAsyncSelectRef } from "../CustomizedReactSelect/type";

const customStyles: StylesConfig<any, false> = {
    menuList: (base) => ({
        ...base,
        maxHeight: "200px",
        backgroundColor: "#F4F4F4",
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: "13px",
        paddingBottom: "6px",
    }),
    control: (base) => ({
        ...base,
        background: "transparent",
        minHeight: "32px",
        height: "32px",
        paddingTop: 0,
        paddingBottom: 0,
        borderColor: "#E2E8F0",
        boxShadow: "0 1px 2px 0 rgba(0,0,0,0.05)",
        fontSize: "0.875rem",
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: "#2D3748",
    }),
    indicatorsContainer: (base) => ({
        ...base,
        height: "32px",
        paddingTop: 0,
        paddingBottom: 0,
    }),
    singleValue: (base) => ({
        ...base,
        top: "calc(50% - 1px)",
        display: "flex",
        alignItems: "center",
    }),
};

export const UserAsyncSelect: React.FC<any> = React.forwardRef(function UserAsyncSelect(
    { accountId, limit = 10, ...props },
    ref
) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [defaultOptions, setDefaultOptions] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const options = async (inputValue: string) => {
        setIsLoading(true);
        const data = await getJSON(`/api/account/${accountId}/users?limit=${limit}&name=${inputValue}`).catch(
            AsyncSelectErrorHandler
        );
        setIsLoading(false);
        return data.map((d: Contact) => {
            return { value: d.id, label: d.name };
        });
    };

    const onSelectFocus = async () => {
        if (!defaultOptions.length) {
            setIsLoading(true);
            const option = await options("");
            setDefaultOptions(option);
            setIsLoading(false);
        }
    };

    return (
        <Async
            ref={ref}
            isClearable
            cacheOptions
            styles={customStyles}
            onFocus={onSelectFocus}
            defaultOptions={defaultOptions}
            isLoading={isLoading}
            loadOptions={options}
            {...props}
        />
    );
});

interface UserAsyncSelectV2Props
    extends Omit<
        AsyncSelectStyledProps<User & LabelValue>,
        "loadOptions" | "isClearable" | "cacheOptions" | "onFocus" | "defaultOptions" | "isLoading"
    > {
    limit?: number;
}

export const UserAsyncSelectV2: React.FC<UserAsyncSelectV2Props> = React.forwardRef(function UserAsyncSelect(
    { limit = 10, ...props },
    ref
) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [defaultOptions, setDefaultOptions] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const accountId = useAccountId();
    const options = async (inputValue: string) => {
        setIsLoading(true);
        const data = await getJSON(`/api/account/${accountId}/users?limit=${limit}&name=${inputValue}`).catch(
            AsyncSelectErrorHandler
        );
        setIsLoading(false);
        return data.map((d: Contact) => {
            return { value: d.id, label: d.name };
        });
    };

    const onSelectFocus = async () => {
        if (!defaultOptions.length) {
            setIsLoading(true);
            const option = await options("");
            setDefaultOptions(option);
            setIsLoading(false);
        }
    };

    return (
        <AsyncSelectStyled
            ref={ref}
            isClearable
            cacheOptions
            onFocus={onSelectFocus}
            defaultOptions={defaultOptions}
            isLoading={isLoading}
            loadOptions={options}
            {...props}
        />
    );
}) as (props: UserAsyncSelectV2Props & { ref?: ReactAsyncSelectRef<User & LabelValue> }) => JSX.Element;
