import FailureState from "app/components/FailureState";
import ErrorStateSvg from "assets/ErrorState.svg";
import React from "react";
import CenterToScreen, { CenterToScreenProps } from "./CenterToScreen";

export interface ErrorStateProps extends CenterToScreenProps {
    error?: string;
    errorTitle?: string;
}
const ErrorState: React.FC<ErrorStateProps> = (props) => {
    const { error, isCentered = false, errorTitle } = props;
    return (
        <CenterToScreen isCentered={isCentered}>
            <FailureState
                title={errorTitle ?? "Something went wrong!"}
                imageProps={{ src: ErrorStateSvg }}
                subtitle={error ?? "Something went wrong! Please reload the page or try again after sometime."}
            />
        </CenterToScreen>
    );
};
export default ErrorState;
