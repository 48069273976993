import { createStandaloneToast } from "@chakra-ui/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import { COOKIE_DOMAIN_ENV, SERVER_URL_ENV } from "environment";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { compile } from "path-to-regexp";
import { useHistory } from "react-router-dom";

export const COOKIE_PATH = "accessToken";
export const TWO_FACTOR_COOKIE_PATH = "mfaToken";

export const gbAPI = axios.create({
    baseURL: SERVER_URL_ENV,
    validateStatus: function (status) {
        return status >= 200 && status <= 399;
    },
});

export function setAuthCookie(token: string): void {
    const authJWT = jwtDecode<AuthJWT>(token);
    const tokenExpiryDate = authJWT && new Date(authJWT.exp * 1000);
    const oneDay = new Date(new Date().getTime() + 60 * 24 * 60 * 1000);
    Cookies.set(COOKIE_PATH, token, {
        expires: tokenExpiryDate ?? oneDay,
        domain: COOKIE_DOMAIN_ENV,
    });
}

export function setTwoFactorAuthCookie(token: string): void {
    const authJWT = jwtDecode<AuthJWT>(token);
    const tokenExpiryDate = authJWT && new Date(authJWT.exp * 1000);
    const oneDay = new Date(new Date().getTime() + 60 * 24 * 60 * 1000);
    Cookies.set(TWO_FACTOR_COOKIE_PATH, token, {
        expires: tokenExpiryDate ?? oneDay,
        domain: COOKIE_DOMAIN_ENV,
    });
}

export function getmfaToken() {
    return Cookies.get(TWO_FACTOR_COOKIE_PATH);
}

export function removemfaToken() {
    return Cookies.remove(TWO_FACTOR_COOKIE_PATH);
}

// Function that will be called to refresh authorization
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const refreshAuthLogic = (
    history: ReturnType<typeof useHistory>
): Parameters<typeof createAuthRefreshInterceptor>[1] => {
    return async (failedRequest) => {
        const { data, config } = failedRequest.response;

        if (data.message == "TOKEN_INVALIDATED_ON_PWD_CHANGE") {
            history.push("/logout?reason=password-change");
            return Promise.resolve();
        }

        if (data.message == "TOKEN_INVALIDATED_ON_ACCOUNT_NOT_FOUND") {
            history.push("/logout?reason=account-not-found");
            return Promise.resolve();
        }

        const customAxiosRequestConfig: AxiosAuthRefreshRequestConfig = { skipAuthRefresh: true };
        try {
            const tokenRefreshResponse = await postJSON("/auth/refresh-token", {}, customAxiosRequestConfig, true);
            const token = tokenRefreshResponse.data?.token;
            if (!token) throw new Error("No token on refresh!");
            setAuthCookie(token);
            config.headers["Authorization"] = `Bearer ${token}`;
            return Promise.resolve();
        } catch (error) {
            history.push("/logout");
            return Promise.resolve();
        }
    };
};

class HttpError extends Error {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(message?: string, response?: any) {
        super(message);
        this.response = response;
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

export function handleError(title: string, ex: HttpError): void {
    const { toast } = createStandaloneToast();
    toast({
        title,
        description: ex.message ?? ex?.response?.data.Message ?? "Please try again...",
        status: "error",
        duration: 9000,
        isClosable: true,
    });
}
export function setHeaders(): AxiosRequestConfig {
    const accessToken = Cookies.get(COOKIE_PATH);
    const h: HeadersInit = {};
    if (accessToken) {
        h.Authorization = `Bearer ${accessToken}`;
    }
    return {
        headers: h,
    };
}

export function setRequestConfig(): AxiosRequestConfig {
    return { withCredentials: true };
}

interface AuthJWT {
    email: string;
    name: string;
    id: string;
    iat: number;
    exp: number;
}

const handleAxiosError = (err: AxiosError, throwErrData?: boolean) => {
    if (err.response) {
        const error = err.response?.data.error;
        const message = err.response?.data.message;
        if (throwErrData) {
            throw err.response?.data;
        }
        if (error ?? message) {
            throw new Error(error ?? message);
        }
    } else if (err.request) {
        throw new Error("Possibly your network got disconnected. Please check your internet");
    }
    throw err;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyPromise = Promise<any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getJSON<R = any>(url: string, config?: AxiosRequestConfig): Promise<R> {
    const requestConfig: AxiosRequestConfig = setRequestConfig();
    return gbAPI
        .get(url, { ...(config ?? {}), ...requestConfig })
        .then((response) => response.data)
        .catch(handleAxiosError);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postJSON<T extends Record<string, any>>(
    url: string,
    payload: T,
    config?: AxiosRequestConfig,
    throwFullErrData?: boolean
): AnyPromise {
    const headers: AxiosRequestConfig = setHeaders();
    return gbAPI
        .post(url, payload, {
            ...config,
            headers: {
                ...config?.headers,
                ...headers.headers,
                Authorization: config?.headers?.Authorization || headers.headers.Authorization,
            },
        })
        .then((response) => response.data)
        .catch((err) => handleAxiosError(err, throwFullErrData));
}

export function patchJSON<T, R = T>(url: string, payload?: T | Partial<T>, config?: AxiosRequestConfig): Promise<R> {
    const headers: AxiosRequestConfig = setHeaders();
    return gbAPI
        .patch(url, payload, {
            ...config,
            headers: {
                ...config?.headers,
                ...headers.headers,
                Authorization: config?.headers?.Authorization || headers.headers.Authorization,
            },
        })
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function putJSON<T>(url: string, payload: T): AnyPromise {
    const config: AxiosRequestConfig = setHeaders();
    return gbAPI
        .put(url, payload, config)
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function deleteJSON<T>(url: string): Promise<T> {
    const config: AxiosRequestConfig = setHeaders();
    return gbAPI
        .delete(url, config)
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function getCompliedUrl(url: string): string {
    const accessToken = Cookies.get(COOKIE_PATH);
    let authJWT: Record<string, unknown> | null = null;
    if (accessToken) {
        try {
            authJWT = jwtDecode(accessToken);
        } catch (ex) {}
    }
    const toPath = compile(url);
    return authJWT ? toPath({ ...authJWT }) : url;
}

export function fetcherComplied(url: string): AnyPromise {
    const accessToken = Cookies.get(COOKIE_PATH);
    let authJWT: Record<string, unknown> | null = null;
    if (accessToken) {
        try {
            authJWT = jwtDecode(accessToken);
        } catch (ex) {}
    }
    const toPath = compile(url);
    const compiledUrl = authJWT ? toPath({ ...authJWT }) : url;
    const config: AxiosRequestConfig = setRequestConfig();
    return gbAPI
        .get(compiledUrl, config)
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function fetcher(url: string, throwFullErrData?: boolean): AnyPromise {
    const config: AxiosRequestConfig = setRequestConfig();
    return gbAPI
        .get(url, config)
        .then((response) => response.data)
        .catch((err) => handleAxiosError(err, throwFullErrData));
}

export function patchFetcher<T>(url: string, payload?: T): AnyPromise {
    const config: AxiosRequestConfig = setHeaders();
    return gbAPI
        .patch(url, payload, config)
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export async function createFile(
    url: string,
    defaultFileName: string = `${new Date().toLocaleString()}`,
    defaultType: string = "image/jpeg"
): Promise<File> {
    const fileName = url.substr(url.lastIndexOf("/") + 1);
    const response = await axios.get(url, { responseType: "blob" });
    const contentType = response.headers["content-type"] ?? defaultType;
    const blob = response.data as Blob;
    const file = new File([blob], defaultFileName ?? fileName, { type: contentType });
    return file;
}

export async function getUrlContentType(url: string): Promise<string | null> {
    const req = await axios.head(`${url}?t=${new Date().getTime()}`);
    return req.headers["content-type"] ?? null;
}

export function mapQueryParams(
    params: Record<string, string | Date | number | boolean | undefined | null | unknown[] | readonly unknown[]>
): string {
    return Object.entries(params)
        .map(([key, value]) => {
            if (value == null || value === "") return "";
            if (Array.isArray(value)) {
                return value.map((v) => `${key}=${v}`).join("&");
            }
            return `${key}=${value}`;
        })
        .filter(Boolean)
        .join("&");
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AsyncSelectErrorHandler = () => [];
export const handleNetworkError = (err: Error | string) => {
    if (typeof err === "string") {
        console.log(err);
        return;
    }
    console.log(err?.message);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleNetworkErrorReturnArray = (err: Error | string): any[] => {
    handleNetworkError(err);
    return [];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleNetworkErrorReturnObj = (err: Error | string): Record<string, any> => {
    handleNetworkError(err);
    return {};
};
