import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import { useRedirectToPlans } from "app/redirect/useRedirectToPlans";
import { callFnsInSequence } from "app/utils/common";
import React from "react";
import { RiVipCrownFill } from "react-icons/ri";

type UpgradeButtonProps = ButtonProps;
const UpgradeButton: React.FC<UpgradeButtonProps> = (props) => {
    const redirectToPlans = useRedirectToPlans({ params: { tab: 1 } });

    return (
        <Button
            colorScheme="brand"
            size="sm"
            {...props}
            leftIcon={<Icon as={RiVipCrownFill} color={props?.color} />}
            onClick={callFnsInSequence(redirectToPlans, props?.onClick)}
        >
            Upgrade
        </Button>
    );
};

export default UpgradeButton;
