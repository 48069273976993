import { BoxProps } from "@chakra-ui/react";
import { StylesObject } from "app/types";

export const styles: StylesObject = {
    headerText: {
        fontSize: "sm",
        fontWeight: "semibold",
        color: "gray.700",
        lineHeight: "18px",
        p: 1,
    },
    bodyText: {
        whiteSpace: "pre-line",
        lineHeight: "18px",
        fontSize: "sm",
        color: "gray.800",
        p: 1,
    },
    footerText: {
        fontSize: "xs",
        fontWeight: "light",
        color: "gray.400",
        fontStyle: "italic",
        lineHeight: "18px",
        p: 1,
    },
    buttons: {
        color: "blue.500",
        width: "full",
        borderTop: "1px solid",
        borderTopColor: "gray.100",
        padding: 2,
        paddingBottom: 1,
        fontSize: "2xs",
        justifyContent: "center",
    },
    mediaContainerStyle: {
        width: "full",
        height: "128px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bg: "gray.50",
        borderRadius: "4px",
    },
};

export const msgContainerProps: BoxProps = {
    p: 1,
    gap: 1,
    display: "flex",
    flexDirection: "column",
    width: "full",
    bg: "white",
    borderRadius: "10px",
    lineHeight: "18px",
    border: "1px",
    borderColor: "gray.100",
    boxShadow: "sm",
};
