import { useToast } from "@chakra-ui/react";
import { TemplateViewType } from "app/screens/Account/WATemplatesV2/MyTemplatesView";
import { ReplyTo } from "app/components/ReplyToConfiguration/types";
import { QueryKey, TQueryKey } from "app/types/common";
import { WATemplate, WATemplateMessageComponent, WATemplatesWithCount } from "app/types/message/whatsapp-template";
import { WAITemplateMessage as WATemplateMessage } from "app/types/message/whatsapp-template-message";
import { MessageTracker } from "app/types/tracker";
import { calculateAllWATemplateStatus, getStatusCalculatedWATemplate } from "app/utils/common";
import { deleteJSON, fetcher, mapQueryParams, patchJSON, postJSON } from "app/utils/fetchUtils";
import { useAccountId } from "app/utils/react-helpers";
import { QueryDataResponse, remove, update } from "app/utils/react-query";
import {
    InfiniteData,
    MutationFunction,
    QueryFunction,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";

interface WATemplateMessagesListProps<TData>
    extends UseInfiniteQueryOptions<TData[], Error, WATemplateMessage[], WATemplateMessage[], TQueryKey> {
    accountId: string;
    channelId?: string | null;
    search?: string | null;
    ignoreSampleTemplates?: boolean;
    limit?: number;
    status?: WATemplate["status"];
}
export type UseWATemplateMessagesListResult = UseInfiniteQueryResult<WATemplateMessage[], Error>;

export const useWATemplateMessagesList = (
    props: WATemplateMessagesListProps<WATemplateMessage>
): UseWATemplateMessagesListResult => {
    const PAGE_SIZE = 20;

    const { accountId, search, ignoreSampleTemplates, channelId, limit, status, ...options } = props;

    const fetchWATemplates: QueryFunction<WATemplateMessage[], TQueryKey> = ({ pageParam, queryKey }) => {
        const [, { accountId, channelId, status, search, ignoreSampleTemplates }] = queryKey;

        const queryParams = mapQueryParams({
            page: pageParam ?? 1,
            limit: limit ?? PAGE_SIZE,
            name: search,
            ignoreSampleTemplates,
            channelId,
            status,
        });

        return fetcher(`/api/account/${accountId}/whatsappTemplateMessages?${queryParams}`);
    };

    const infiniteQueryResult = useInfiniteQuery<WATemplateMessage[], Error, WATemplateMessage[], TQueryKey>(
        [QueryKey.WATemplateMessagesList, { accountId, channelId, status, search, ignoreSampleTemplates }],
        fetchWATemplates,
        {
            ...options,
            select: (data) => {
                const updatedData: InfiniteData<WATemplateMessage[]>["pages"] = data.pages.map((pageData) => {
                    return pageData.map((data) => {
                        if (!data.template) return data;
                        const updateTemplateData = getStatusCalculatedWATemplate(data.template);
                        return {
                            ...data,
                            template: updateTemplateData,
                        };
                    });
                });
                return { ...data, pages: updatedData };
            },
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === PAGE_SIZE ? pages.length + 1 : undefined;
            },
        }
    );

    return infiniteQueryResult;
};

type WATemplateMessageInput = Omit<WATemplate, "id" | "whatsappTemplateId" | "createdAt" | "updatedAt">;

type UseCreateWATemplateProps = UseMutationOptions<WATemplate, Error, WATemplateMessageInput> & {
    enableToast?: boolean;
};

export const useCreateWATemplate = (
    props: UseCreateWATemplateProps
): UseMutationResult<WATemplate, Error, WATemplateMessageInput> => {
    const { onSuccess, onError, enableToast = true, ...options } = props;
    const accountId = useAccountId();
    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.WATemplatesList, { accountId, search: "" }];

    const createTemplate = (template: WATemplateMessageInput) => {
        return postJSON<WATemplate>(
            `/api/account/${accountId}/channel/${template.channelId}/whatsappTemplate`,
            template
        );
    };

    const mutationResult = useMutation<WATemplate, Error, WATemplateMessageInput>(createTemplate, {
        onError: (error, ...args) => {
            if (enableToast) {
                toast({
                    title: "Something went wrong!",
                    status: "error",
                    description: error.message,
                });
            }
            onError?.(error, ...args);
        },
        onSuccess: async (...args) => {
            if (enableToast) {
                toast({
                    title: "Template Created!",
                    status: "success",
                    description: "Template created successfully!",
                });
            }
            await queryClient.invalidateQueries(queryKey);
            onSuccess?.(...args);
        },
        ...options,
    });

    return mutationResult;
};

interface CreateMessageTracker {
    name: string;
    description?: string;
}

interface updateTemplateMessageTracker extends CreateMessageTracker {
    messageTrackerId: string;
}

type UseUpdateTemplateMessageTracker = UseMutationResult<MessageTracker, Error, updateTemplateMessageTracker>;

interface useUpdateTemplateMessageTrackerProps {
    onSuccess?: (tracker: MessageTracker) => void;
    onError?: (error: Error) => void;
    accountId: string;
    channelId: string;
    templateId?: string;
}

export const useUpdateTemplateMessageTracker = (
    props: useUpdateTemplateMessageTrackerProps
): UseUpdateTemplateMessageTracker => {
    const { onSuccess, onError, accountId, channelId, templateId } = props;
    const toast = useToast();
    const updateMessageTracker: MutationFunction<MessageTracker, updateTemplateMessageTracker> = (data) => {
        // /account/:accountId/channel/:channelId/whatsappTemplate/:templateId/messageTracker
        return patchJSON(
            `/api/account/${accountId}/channel/${channelId}/whatsappTemplate/${templateId}/messageTracker`,
            {
                ...data,
                accountId,
            }
        );
    };
    const queryClient = useQueryClient();
    const queryKey = [QueryKey.WATemplatesList, { accountId }];
    const mutationResult = useMutation<MessageTracker, Error, updateTemplateMessageTracker>(updateMessageTracker, {
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
            onError?.(error);
        },
        onSuccess: async (tracker) => {
            toast({
                title: "Updated",
                status: "success",
                description: "Message tracker updated successfully!",
            });
            onSuccess?.(tracker);
            await queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

interface SyncWATemplatesResponse {
    updated: WATemplate[];
    created: WATemplate[];
    failed: WATemplate[];
}
export const useSyncWATemplates = (
    channelId: string,
    accountId: string,
    onSuccess?: () => void
): UseMutationResult<SyncWATemplatesResponse, Error, void> => {
    const toast = useToast();

    const syncTemplates = () => {
        return postJSON(`/api/account/${accountId}/channel/${channelId}/whatsappTemplates/sync`, {});
    };

    const mutationResult = useMutation<SyncWATemplatesResponse, Error, void>(syncTemplates, {
        onError: () => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: "Please try again later.",
            });
        },
        onSuccess: async () => {
            onSuccess?.();
        },
    });

    return mutationResult;
};

interface UseUpdateWATemplateProps extends UseMutationOptions<WATemplate, Error, WATemplateMessageInput> {
    templateId?: string;
}
type UseUpdateWATemplateResult = UseMutationResult<WATemplate, Error, WATemplateMessageInput> | null;

export const useUpdateWATemplate = (props: UseUpdateWATemplateProps): UseUpdateWATemplateResult => {
    const { templateId, onSuccess, onError, ...options } = props;

    const accountId = useAccountId();
    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.WATemplatesList, { accountId, search: "" }];

    const updateTemplate = (template: WATemplateMessageInput) => {
        if (!templateId) throw new Error("Template ID not found");
        return patchJSON<WATemplate>(
            `/api/account/${accountId}/channel/${template.channelId}/whatsappTemplate/${templateId}`,
            template
        );
    };

    const mutationResult = useMutation<WATemplate, Error, WATemplateMessageInput>(updateTemplate, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, ...args) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: err.message,
            });
            queryClient.invalidateQueries(queryKey);
            onError?.(err, ...args);
        },
        onSuccess: (...args) => {
            toast({
                title: "Template Updated!",
                status: "success",
                description: "Template updated successfully!",
            });
            onSuccess?.(...args);
        },
        // When mutate is called:
        ...(templateId
            ? {
                  onMutate: async (updatedTemplate) => {
                      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
                      await queryClient.cancelQueries(queryKey);

                      update<WATemplate>(queryClient, queryKey, templateId, {
                          ...updatedTemplate,
                          updatedAt: new Date(),
                      });
                  },
              }
            : {}),
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
        ...options,
    });

    return templateId ? mutationResult : null;
};

interface WATemplateMediaInput {
    filePath: string;
    channelId?: string;
    templateId?: string;
    fileMetaData?: WATemplateMessageComponent["fileMetaData"];
}

interface UseUpdateWATemplateMediaProps {
    accountId?: string;
    onSuccess?: (updatedTemplate: WATemplate) => void;
}
type UseUpdateWATemplateMediaResult = UseMutationResult<WATemplate, Error, WATemplateMediaInput>;
type UseUpdateWATemplateMedia = (props: UseUpdateWATemplateMediaProps) => UseUpdateWATemplateMediaResult;

export const useUpdateWATemplateMedia: UseUpdateWATemplateMedia = (props) => {
    const { accountId = "accountId", onSuccess } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.WATemplatesList, { accountId, search: "" }];

    const updateTemplateMedia: MutationFunction<WATemplate, WATemplateMediaInput> = (media) => {
        const { channelId, templateId, filePath, fileMetaData } = media;
        if (!channelId || !templateId) throw new Error("Invalid Template/Channel IDs");
        return postJSON<WATemplateMediaInput>(
            `/api/account/${accountId}/channel/${channelId}/whatsappTemplate/${templateId}/media`,
            { filePath, fileMetaData }
        );
    };

    const mutationResult = useMutation<WATemplate, Error, WATemplateMediaInput>(updateTemplateMedia, {
        onError: (err) => {
            toast({ title: err.message ?? "Something went wrong!", status: "error" });
        },
        onSuccess: (updatedTemplate) => {
            toast({ title: "Template Media updated!", status: "success" });
            onSuccess?.(updatedTemplate);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

// update carouselMedia

interface WATemplateCarouselMediaInput {
    filePath: string;
    channelId?: string;
    templateId?: string;
    fileMetaData?: WATemplateMessageComponent["fileMetaData"];
    cardIndex: number;
}

interface UseUpdateWATemplateCarouselMediaProps {
    accountId?: string;
    onSuccess?: (updatedTemplate: WATemplate) => void;
}
type UseUpdateWATemplateCarouselMediaResult = UseMutationResult<WATemplate, Error, WATemplateCarouselMediaInput>;
type UseUpdateWATemplateCarouselMedia = (
    props: UseUpdateWATemplateCarouselMediaProps
) => UseUpdateWATemplateCarouselMediaResult;

export const useUpdateWATemplateCarouselMedia: UseUpdateWATemplateCarouselMedia = (props) => {
    const { accountId = "accountId", onSuccess } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.WATemplatesList, { accountId, search: "" }];

    const updateTemplateMedia: MutationFunction<WATemplate, WATemplateCarouselMediaInput> = (media) => {
        const { channelId, templateId, filePath, fileMetaData, cardIndex } = media;
        if (!channelId || !templateId) throw new Error("Invalid Template/Channel IDs");
        return postJSON<WATemplateCarouselMediaInput>(
            `/api/account/${accountId}/channel/${channelId}/whatsappTemplate/${templateId}/carousal-media`,
            { filePath, fileMetaData, cardIndex }
        );
    };

    const mutationResult = useMutation<WATemplate, Error, WATemplateCarouselMediaInput>(updateTemplateMedia, {
        onError: (err) => {
            toast({ title: err.message ?? "Something went wrong!", status: "error" });
        },
        onSuccess: (updatedTemplate) => {
            toast({ title: "Template Media updated!", status: "success" });
            onSuccess?.(updatedTemplate);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type UseDeleteWATemplateResult = UseMutationResult<WATemplate, Error, {}> | null;

interface UseDeleteWATemplateParams {
    templateName?: string;
    channelId?: string;
    onSuccess?: () => void;
}

export const useDeleteWATemplate = (params: UseDeleteWATemplateParams): UseDeleteWATemplateResult => {
    const { templateName, channelId, onSuccess } = params;

    const accountId = useAccountId();
    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.WATemplatesList, { accountId }];

    const deleteTemplate = () => {
        return deleteJSON<WATemplate>(
            `/api/account/${accountId}/channel/${channelId}/whatsappTemplate/${templateName}`
        );
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<WATemplate, Error, {}>(deleteTemplate, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: err.message,
            });
            queryClient.invalidateQueries(queryKey);
        },
        onSuccess: () => {
            toast({
                title: "Template Deleted!",
                status: "success",
            });
            queryClient.invalidateQueries(queryKey);

            onSuccess?.();
        },
        // When mutate is called:
        ...(templateName
            ? {
                  onMutate: async () => {
                      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
                      await queryClient.cancelQueries(queryKey);

                      // Snapshot the previous value
                      const previousTemplates = queryClient.getQueryData<QueryDataResponse<WATemplate[][]>>(queryKey);
                      const deletedTemplates = previousTemplates?.pages
                          ?.flat()
                          ?.filter((t) => t?.name === templateName);

                      (deletedTemplates ?? [])?.forEach((template) => {
                          if (template?.id) remove<WATemplate>(queryClient, queryKey, template?.id);
                      });
                  },
              }
            : {}),
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return templateName ? mutationResult : null;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type UseContentSyncTemplateResult = UseMutationResult<{}, Error, {}> | null;

interface UseContentSyncWATemplateParams {
    templateId?: string;
    channelId?: string;
    onSuccess?: () => void;
}

export const useContentSyncWATemplate = (params: UseContentSyncWATemplateParams): UseContentSyncTemplateResult => {
    const { templateId, channelId, onSuccess } = params;

    const accountId = useAccountId();
    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.WATemplatesList, { accountId }];

    const contenSync = () => {
        return postJSON(`/api/accounts/${accountId}/channels/${channelId}/whatsappTemplate/${templateId}/sync`, {});
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<{}, Error, {}>(contenSync, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err) => {
            toast({ title: "Unable to sync the template content!", status: "error", description: err.message });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            toast({ title: "Template content sync is initiated", status: "info" });
            onSuccess?.();
        },
    });

    return mutationResult;
};

interface WATemplateListProps<T> extends UseInfiniteQueryOptions<T, Error, T, T, TQueryKey> {
    accountId: string;
    search: string;
    ignoreSampleTemplates: boolean;
    limit?: number;
    status?: string;
    channelId?: string;
    populatePaths?: string;
    getCount?: boolean;
    page?: number;
    selectedView?: TemplateViewType;
}
export type UseWATemplateListResult<T> = UseInfiniteQueryResult<T, Error>;

export const useWATemplateList = <T extends WATemplate[] | WATemplatesWithCount = WATemplate[]>(
    props: WATemplateListProps<T>
): UseWATemplateListResult<T> => {
    const {
        accountId,
        search,
        ignoreSampleTemplates,
        status,
        limit,
        channelId,
        populatePaths,
        getCount,
        page,
        selectedView,
        ...options
    } = props;

    const PAGE_SIZE = limit ?? 20;

    const infiniteQueryResult = useInfiniteQuery<T, Error, T, TQueryKey>(
        [
            QueryKey.WATemplatesList,
            { accountId, search, ignoreSampleTemplates, status, channelId, populatePaths, page, selectedView },
        ],
        ({ pageParam, queryKey }) => {
            const [, { accountId, search, ignoreSampleTemplates, channelId, populatePaths }] = queryKey;
            const queryParams = mapQueryParams({
                page: selectedView === "list" ? page : pageParam ?? 1,
                limit: PAGE_SIZE,
                name: search,
                ignoreSampleTemplates,
                populatePaths,
                status,
                channelId,
                sort: "-createdAt",
                getCount,
            });

            return fetcher(`/api/account/${accountId}/whatsappTemplates?${queryParams}`);
        },
        {
            ...options,
            select: (data) => {
                const updatedData: InfiniteData<T>["pages"] = data.pages.map((pageData) => {
                    if (Array.isArray(pageData)) {
                        return calculateAllWATemplateStatus(pageData);
                    }
                    return {
                        ...pageData,
                        templates: calculateAllWATemplateStatus(pageData.templates),
                    };
                }) as InfiniteData<T>["pages"];

                return { ...data, pages: updatedData };
            },
            getNextPageParam: (lastPage, pages) => {
                const templatesLength =
                    getCount && !Array.isArray(lastPage)
                        ? lastPage?.templates?.length
                        : (lastPage as WATemplate[])?.length;
                return templatesLength ? pages.length + 1 : undefined;
            },
        }
    );

    return infiniteQueryResult;
};

export interface IGetVariableResultItem {
    entity: { label: string };
    options: { label: string; value: string }[];
}

interface GetTemplateVariablesProps {
    accountId: string;
}

const fetchVariables: QueryFunction<IGetVariableResultItem[], TQueryKey> = ({ queryKey }) => {
    const [, { accountId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/variables`);
};

export const useTemplateVariables = (
    props: GetTemplateVariablesProps
): UseQueryResult<IGetVariableResultItem[], Error> => {
    const { accountId } = props;
    const toast = useToast();

    return useQuery<IGetVariableResultItem[], Error, IGetVariableResultItem[], TQueryKey>(
        [QueryKey.Variables, { accountId }],
        fetchVariables,
        {
            enabled: Boolean(accountId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
            keepPreviousData: true,
        }
    );
};

interface WATemplateMessagesProps {
    accountId: string;
    templateId?: string;
    populatePaths?: string;
}

export const useWATemplateMessage = (props: WATemplateMessagesProps): UseQueryResult<WATemplate, Error> => {
    const { accountId, templateId, populatePaths } = props;

    const fetchWATemplates: QueryFunction<WATemplate, TQueryKey> = ({ queryKey }) => {
        const [, { accountId, templateId, populatePaths }] = queryKey;
        const queryParams = mapQueryParams({
            populatePaths,
        });
        return fetcher(`/api/account/${accountId}/whatsappTemplate/${templateId}?${queryParams}`);
    };

    const infiniteQueryResult = useQuery<WATemplate, Error, WATemplate, TQueryKey>(
        [QueryKey.WATemplateMessagesList, { accountId, templateId, populatePaths }],
        fetchWATemplates,
        {
            enabled: Boolean(accountId) && Boolean(templateId),
            select: (data) => {
                return getStatusCalculatedWATemplate(data);
            },
        }
    );

    return infiniteQueryResult;
};

interface WATemplateReplyToConfig {
    accountId: string;
    templateId: string;
    channelId: string;
    onSuccess?: (template: WATemplate) => void;
}

export const useUpdateWATemplateReplyToConfig = (
    props: WATemplateReplyToConfig
): UseMutationResult<WATemplate, Error, ReplyTo> => {
    const { accountId, templateId, channelId, onSuccess } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: TQueryKey = [QueryKey.WATemplateMessagesList, { accountId, templateId }];

    const updateTemplate = (replyTo: ReplyTo) => {
        return patchJSON<ReplyTo, WATemplate>(
            `/api/account/${accountId}/channel/${channelId}/whatsappTemplate/${templateId}/replyToConfig`,
            replyTo
        );
    };

    const mutationResult = useMutation<WATemplate, Error, ReplyTo>(updateTemplate, {
        onError: (error, ...args) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (...args) => {
            toast({
                title: "Template Updated",
                status: "success",
                description: "Reply To settings got updated!",
            });
            queryClient.invalidateQueries(queryKey);
            onSuccess?.(args?.[0]);
        },
    });
    return mutationResult;
};

export const useDeleteWATemplateReplyToConfig = (
    props: WATemplateReplyToConfig
): UseMutationResult<WATemplate, Error, void> => {
    const { accountId, templateId, channelId, onSuccess } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: TQueryKey = [QueryKey.WATemplateMessagesList, { accountId, templateId }];

    const deleteReplyToConfig = () => {
        return deleteJSON<WATemplate>(
            `/api/account/${accountId}/channel/${channelId}/whatsappTemplate/${templateId}/replyToConfig`
        );
    };

    const mutationResult = useMutation<WATemplate, Error>(deleteReplyToConfig, {
        onError: (error, ...args) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (...args) => {
            toast({
                title: "Template Updated",
                status: "success",
                description: "Reply To settings got updated!",
            });
            queryClient.invalidateQueries(queryKey);
            onSuccess?.(args?.[0]);
        },
    });
    return mutationResult;
};
