import { initEnvironment } from "app/modules/environment";
import * as fetchUtils from "app/utils/fetchUtils";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { AnyAction, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { deleteToken } from "app/config/firebase";
import * as prdAnalyticsTracker from "app/hooks/productAnalytics/tracker";
import { Channel, User } from "app/types";
import { Account } from "app/types/account";
import { Authed } from "app/types/common";
import { IWallet } from "app/types/prepaid-wallet";
import captureException from "app/utils/captureException";
import { COOKIE_DOMAIN_ENV } from "environment";
import { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import { useDispatch } from "react-redux";
import { AxiosError } from "axios";

const COOKIE_PATH = "accessToken";

const RECEIVE_ACCESS_TOKEN = "RECEIVE_ACCESS_TOKEN";
export const RECEIVE_AUTHED_USER = "RECEIVE_AUTHED_USER";
export const RECEIVE_WA_CHANNELS = "RECEIVE_WA_CHANNELS";
const RESET_AUTHED = "RESET_AUTHED";
const CHECKED_AUTHED = "CHECKED_AUTHED";
const CHECK_IP = "CHECK_IP";
const VERIFY_EMAIL = "VERIFY_EMAIL";
export const UPDATE_ONBOARDING_STATE = "UPDATE_ONBOARDING_STATE";
const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
const UPDATE_WA_CHANNELS_ASSIGN_TO_IDS = "UPDATE_WA_CHANNELS_ASSIGN_TO_IDS";
const ADD_USER_TO_WA_CHANNELS = "ADD_USER_TO_WA_CHANNELS";
const REMOVE_USER_FROM_WA_CHANNELS = "REMOVE_USER_FROM_WA_CHANNELS";

interface ReceiveAccessTokenAction {
    type: typeof RECEIVE_ACCESS_TOKEN;
    accessToken: string | null;
    authJWT: any;
}
interface ReceiveAuthedUserAction {
    type: typeof RECEIVE_AUTHED_USER;
    user: any;
    isAuthChecked: boolean;
}
interface ReceiveChannelsAction {
    type: typeof RECEIVE_WA_CHANNELS;
    waChannels: Channel[];
    isAuthChecked: boolean;
}
interface CheckedAuthedAction {
    type: typeof CHECKED_AUTHED;
    isAuthChecked: boolean;
}
interface CheckIP {
    type: typeof CHECK_IP;
    isIPRestricted: boolean;
}
interface VerifyEmailAction {
    type: typeof VERIFY_EMAIL;
    isEmailVerified: boolean;
}
export interface UpdateOnboardingStateAction {
    type: typeof UPDATE_ONBOARDING_STATE;
    accountId: string;
    onboarding: NonNullable<Account["onboarding"]>;
}
interface UpdatedWalletBalance {
    type: typeof UPDATE_WALLET_BALANCE;
    accountId: string;
    wallet: IWallet;
}
export interface UpdateAccountAction {
    type: typeof UPDATE_ACCOUNT;
    accountId: string;
    account: Account;
}

export interface UpdateWAChannelsAssignToIdsAction {
    type: typeof UPDATE_WA_CHANNELS_ASSIGN_TO_IDS;
    assignToIds: string[];
    channelId: string;
}
export interface AddUserToWAChannelsAction {
    type: typeof ADD_USER_TO_WA_CHANNELS;
    userId: string;
    accountId: string;
    channelId: string;
}
export interface RemoveUserFromWAChannelsAction {
    type: typeof REMOVE_USER_FROM_WA_CHANNELS;
    userId: string;
    accountId: string;
    channelId: string;
}

type AuthedActionTypes =
    | ReceiveAccessTokenAction
    | ReceiveAuthedUserAction
    | CheckedAuthedAction
    | CheckIP
    | VerifyEmailAction
    | UpdateOnboardingStateAction
    | UpdatedWalletBalance
    | ReceiveChannelsAction
    | UpdateAccountAction
    | UpdateWAChannelsAssignToIdsAction
    | AddUserToWAChannelsAction
    | RemoveUserFromWAChannelsAction;

const initialState: Authed = {
    accessToken: null,
    authJWT: null,
    user: {} as Authed["user"],
    isAuthChecked: false,
    isIPRestricted: false,
    isEmailVerified: false,
};
type UnknownRecord = Record<string, unknown>;

export default function authed(state: Authed = initialState, action: AuthedActionTypes): Authed {
    switch (action.type) {
        case RECEIVE_ACCESS_TOKEN: {
            const { accessToken } = action;
            return {
                ...state,
                accessToken,
                authJWT: accessToken && jwtDecode(accessToken),
            };
        }
        case RECEIVE_AUTHED_USER:
            return {
                ...state,
                user: action.user,
            };
        case RECEIVE_WA_CHANNELS:
            return {
                ...state,
                waChannels: action.waChannels,
            };

        case CHECK_IP: {
            return {
                ...state,
                isIPRestricted: action.isIPRestricted != null ? action.isIPRestricted : false,
            };
        }
        case CHECKED_AUTHED:
            return {
                ...state,
                isAuthChecked: action.isAuthChecked,
            };
        case VERIFY_EMAIL:
            return {
                ...state,
                isEmailVerified: action.isEmailVerified,
            };
        case UPDATE_ONBOARDING_STATE: {
            const { accountId, onboarding } = action;
            const updatedAccounts: Authed["user"]["accounts"] = state.user.accounts?.map((a) => {
                if (!a.account || a.accountId !== accountId) return a;
                return { ...a, account: { ...a.account, onboarding } };
            });
            return {
                ...state,
                user: { ...state.user, accounts: updatedAccounts },
            };
        }
        case UPDATE_WALLET_BALANCE: {
            const { accountId, wallet } = action;
            const updatedAccounts: Authed["user"]["accounts"] = state.user.accounts?.map((a) => {
                if (a.accountId !== accountId || !a.account?.wallets) return a;
                const updatedWallet = a.account?.wallets.map((w) => (w.id === wallet.id ? { ...w, ...wallet } : w));
                return { ...a, account: { ...a.account, wallets: updatedWallet } };
            });
            return {
                ...state,
                user: { ...state.user, accounts: updatedAccounts },
            };
        }
        case UPDATE_ACCOUNT: {
            const { accountId, account } = action;
            const updatedAccounts = state.user.accounts?.map((acc) => {
                if (acc.account?.id === accountId) {
                    acc.account = account;
                }
                return acc;
            });
            return {
                ...state,
                user: { ...state.user, accounts: updatedAccounts },
            };
        }
        case UPDATE_WA_CHANNELS_ASSIGN_TO_IDS: {
            const { assignToIds, channelId } = action;

            const updatedWaChannels = state?.waChannels?.map((channel) => {
                if (channel?.id === channelId) {
                    channel.assignToIds = assignToIds;
                }
                return channel;
            });

            return {
                ...state,
                waChannels: [...(state?.waChannels || []), ...(updatedWaChannels || [])],
            };
        }

        case ADD_USER_TO_WA_CHANNELS: {
            const { accountId, channelId, userId } = action;
            if (state.user.id !== userId) return state;

            const updatedUserDetails = state.user?.accounts?.map((userAcc) => {
                if (userAcc.accountId === accountId) {
                    const isUserAlreadyExistInChannel = userAcc.channelIds?.some((chId) => channelId === chId);
                    if (!isUserAlreadyExistInChannel) {
                        return {
                            ...userAcc,
                            channelIds: [...(userAcc.channelIds ?? []), channelId],
                        };
                    }
                    return userAcc;
                }
                return userAcc;
            });

            return {
                ...state,
                user: {
                    ...state.user,
                    accounts: updatedUserDetails,
                },
            };
        }

        case REMOVE_USER_FROM_WA_CHANNELS: {
            const { accountId, channelId, userId } = action;
            if (state.user.id !== userId) return state;

            const updatedUserDetails = state.user?.accounts?.map((userAcc) => {
                if (userAcc.accountId === accountId) {
                    const channelIds = userAcc.channelIds.filter((chId) => channelId !== chId);
                    return {
                        ...userAcc,
                        channelIds,
                    };
                }

                return userAcc;
            });
            return {
                ...state,
                user: {
                    ...state.user,
                    accounts: updatedUserDetails,
                },
            };
        }
        default:
            return state;
    }
}

function resetAuthed() {
    return {
        type: RESET_AUTHED,
    };
}

export function updateOnboardingState(
    accountId: string,
    onboarding: NonNullable<Account["onboarding"]>
): UpdateOnboardingStateAction {
    return {
        type: UPDATE_ONBOARDING_STATE,
        accountId,
        onboarding,
    };
}
export function updateAccountState(accountId: string, account: Account): UpdateAccountAction {
    return {
        type: UPDATE_ACCOUNT,
        accountId,
        account,
    };
}

export function updateWaChannelAssignToIdsState(
    assignToIds: string[],
    channelId: string
): UpdateWAChannelsAssignToIdsAction {
    return {
        type: UPDATE_WA_CHANNELS_ASSIGN_TO_IDS,
        assignToIds,
        channelId,
    };
}

export function addUserToWaChannelAssign(
    userId: string,
    accountId: string,
    channelId: string
): AddUserToWAChannelsAction {
    return {
        type: ADD_USER_TO_WA_CHANNELS,
        userId,
        accountId,
        channelId,
    };
}

export function removeUserFromWaChannelAssign(
    userId: string,
    accountId: string,
    channelId: string
): RemoveUserFromWAChannelsAction {
    return {
        type: REMOVE_USER_FROM_WA_CHANNELS,
        userId,
        accountId,
        channelId,
    };
}

export const useUpdateWalletBalance = (): {
    mutate: (accountId: string, wallet: IWallet) => UpdatedWalletBalance;
} => {
    const dispatch = useDispatch();
    return {
        mutate: (accountId: string, wallet: IWallet) =>
            dispatch<UpdatedWalletBalance>({ accountId, wallet, type: UPDATE_WALLET_BALANCE }),
    };
};

function receiveAccessToken(accessToken: string) {
    return {
        type: RECEIVE_ACCESS_TOKEN,
        accessToken,
    };
}

function receiveAuthedUser(user: any) {
    return {
        type: RECEIVE_AUTHED_USER,
        user,
    };
}
function receiveAuthedChannels(waChannels: Channel[]) {
    return {
        type: RECEIVE_WA_CHANNELS,
        waChannels,
    };
}

function receiveAuthedUserPre(accessToken: string, user: any) {
    return (dispatch: Dispatch) => {
        dispatch(receiveAuthedUser(user));
        dispatch(receiveAccessToken(accessToken));
        return dispatch({ type: CHECKED_AUTHED, isAuthChecked: true });
    };
}

export const isIPRestricted = (err: AxiosError | null): boolean => {
    if (!err) return false;
    const errMsg = (err.message ?? "").toLowerCase();
    const ipRestrictionMsg = "Access denied to IP address".toLocaleLowerCase();

    return errMsg.includes(ipRestrictionMsg);
};

export const checkIPRestriction = (err: AxiosError | null) => {
    return (dispatch: Dispatch) => {
        dispatch({ type: CHECK_IP, isIPRestricted: isIPRestricted(err) });
    };
};

type AuthUserCallback = (user: User) => void;
export function fetchAuthedUser(accessToken: string, callback: AuthUserCallback = dummyFunction) {
    return (dispatch: ThunkDispatch<UnknownRecord, UnknownRecord, AnyAction>) =>
        fetchUtils
            .getJSON("/api/me")
            .then((u: User) => {
                dispatch(receiveAuthedUserPre(accessToken, u));
                callback(u);
                return u.accounts?.[0].account as Account;
            })
            .then((account: Account) => {
                if (!account || !account?.status) {
                    captureException("Invalid account info", { data: account });
                    return;
                }
                const disconnected = account?.status === "disconnected";
                if (disconnected) {
                    return;
                }
                fetchUtils
                    .getJSON(`/api/accounts/${account.id}/channels?channelType=whatsapp`)
                    .then((waChannels: Channel[]) => {
                        dispatch(receiveAuthedChannels(waChannels));
                    });
            })
            .catch((err: AxiosError) => {
                captureException("Error while fetching account details");
                dispatch(receiveAccessToken(accessToken));
                dispatch(checkIPRestriction(err));
                dispatch({ type: CHECKED_AUTHED, isAuthChecked: true });
            });
}

export function authUser(accessToken: string, callback: AuthUserCallback = dummyFunction) {
    return (dispatch: ThunkDispatch<UnknownRecord, UnknownRecord, AnyAction>) =>
        dispatch(fetchAuthedUser(accessToken, callback));
}

export function initAuth() {
    return (dispatch: ThunkDispatch<UnknownRecord, UnknownRecord, AnyAction>) => {
        const accessToken = Cookies.get(COOKIE_PATH);
        if (accessToken) {
            return dispatch(authUser(accessToken));
        }
        return dispatch({ type: CHECKED_AUTHED, isAuthChecked: true });
    };
}

function mapChangePasswordFormData(formData: any) {
    return {
        oldPassword: formData.oldPassword,
        password: formData.password,
    };
}

export function changePassword(formData: any) {
    return (dispatch: Dispatch) => {
        fetchUtils
            .postJSON<{ oldPassword: string; password: string }>(
                "/api/password/change",
                mapChangePasswordFormData(formData)
            )
            .then((data) => {
                /* if (data.Status !== "Success") {
                    toast({
                        title: "An error occurred.",
                        description: "Couldn't able to change password...",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Password Changed",
                        description: "Your password has been successfully updated...",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    });
                } */
                return data;
            })
            .catch((ex) => {
                /* toast({
                    title: "An error occurred.",
                    description: "Couldn't able to change password...",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                }); */
            });
    };
}

export function verifyEmail(data: any) {
    return (dispatch: Dispatch) => {
        fetchUtils
            .postJSON("/api/user/verify/email", data)
            .then((data) => {
                dispatch({
                    type: VERIFY_EMAIL,
                    isEmailVerified: data.Status === "Success",
                });
            })
            .catch((ex) => {
                fetchUtils.handleError("Login failed", ex);
            });
    };
}

export function refreshToken() {
    return (dispatch: ThunkDispatch<UnknownRecord, UnknownRecord, AnyAction>) => {
        fetchUtils
            .postJSON("/auth/refresh-token", {})
            .then((data) => {
                fetchUtils.setAuthCookie(data.token);
                return dispatch(authUser(data.token));
            })
            .catch((ex) => {
                console.log("RefreshToken failed", ex);
            });
    };
}

export function handleAuth(token: string) {
    return (dispatch: ThunkDispatch<UnknownRecord, UnknownRecord, AnyAction>) => {
        fetchUtils.setAuthCookie(token);
        dispatch(authUser(token));
    };
}

function mapLoginFormData(formData: LoginInput) {
    return {
        email: formData.email,
        password: formData.password,
    };
}

export type LoginCallbackType = (err: AxiosError | null) => void;
const dummyFunction = () => {
    return;
};

export type AuthContext = "new-ip-detection";

interface LoginInput {
    email: string;
    password: string;
}
export type LoginDataType = {
    token?: string;
    isTwoFARequired: boolean;
    mfaToken?: string;
};
type OnLoginSuccess = (data: LoginDataType) => void;
type OnLoginFailure = (err: AxiosError<any>) => void;
export function loginUser(
    formData: LoginInput,
    onSuccess: OnLoginSuccess = dummyFunction,
    onFailure: OnLoginFailure = dummyFunction
) {
    return (dispatch: ThunkDispatch<UnknownRecord, UnknownRecord, AnyAction>) =>
        fetchUtils
            .postJSON<{ email: string; password: string }>("/auth/v2/login", mapLoginFormData(formData), {
                skipAuthRefresh: true,
            } as AxiosAuthRefreshRequestConfig)
            .then((data) => {
                if (data?.mfaToken) {
                    fetchUtils.setTwoFactorAuthCookie(data.mfaToken);
                }
                onSuccess(data);
                dispatch(checkIPRestriction(null));
                if (!data.token) return;
                fetchUtils.setAuthCookie(data.token);
                return dispatch(authUser(data.token));
            })
            .catch((ex) => {
                onFailure(ex);
                dispatch(checkIPRestriction(ex));
                // Don't show a toast for IP restriction error
                if (!isIPRestricted(ex)) {
                    fetchUtils.handleError("Login failed", ex);
                }
            });
}

function mapSignupFormData(formData: any) {
    return {
        name: formData.name,
        email: formData.email,
        password: formData.password,
    };
}

interface SignupInput {
    name: string;
    email: string;
    password: string;
}

export function signupUser(formData: SignupInput) {
    return (dispatch: ThunkDispatch<UnknownRecord, UnknownRecord, AnyAction>) => {
        fetchUtils
            .postJSON("/auth/signup", mapSignupFormData(formData))
            .then((data) => {
                fetchUtils.setAuthCookie(data.token);
                return data;
            })
            .then((data) => dispatch(authUser(data.token)))
            .catch((ex) => {
                fetchUtils.handleError("Something went wrong!", ex);
            });
    };
}

export const disablePushNotification = () => {
    try {
        const pushConfig: {
            s: "unknown" | "loading" | "success" | "failed" | "disabled";
        } = JSON.parse(localStorage.getItem("pushConfig") ?? "{}");

        if (pushConfig?.s !== "disabled") {
            localStorage.setItem("pushConfig", JSON.stringify({ s: "unknown" }));
        }
    } catch (e) {
        captureException(e);
    }
    return deleteToken();
};

export function logoutUser() {
    return (dispatch: ThunkDispatch<UnknownRecord, UnknownRecord, AnyAction>) => {
        Cookies.remove(COOKIE_PATH, {
            domain: COOKIE_DOMAIN_ENV,
        });
        prdAnalyticsTracker.disconnect();
        disablePushNotification();
        dispatch(resetAuthed());
        dispatch(initEnvironment());
        dispatch(initAuth());
        // toast({
        //     title: "See you soon!",
        //     description: "You have logged out successfully",
        //     status: "info",
        //     duration: 9000,
        //     isClosable: true,
        // });
    };
}
