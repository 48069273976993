import { Action, Subject } from "app/config/abilities/ability";
import { AbilityContext } from "app/config/abilities/can";
import { useAccountId } from "app/utils/react-helpers";
import React, { useContext } from "react";
import { subject } from "@casl/ability";

const abilities = [
    "canIReadAccount",
    "canIUpdateAccount",
    "canIReadWallet",
    "canIReadSubscription",
    "canIReadManyContact",
    "canIReadBot",
    "canIReadManyAnalytics",
    "canIReadWebhook",
    "canIReadPII",
    "canIReadFlexiList",
    "canIDeleteContact",
    "canIReadManyMessage",
    "canIExportContact",
    "canIReadWhatsappFlow",
    "canIExportWhatsappFlow",
] as const;
type abilitiesType = typeof abilities[number];
interface UseCheckAbilityReturn extends Record<abilitiesType, boolean> {
    checkAbility: (action: Action, sub: Subject, params?: Record<string, any>) => boolean;
}

export const useCheckAbility = (): UseCheckAbilityReturn => {
    const abilityContext = useContext(AbilityContext);
    const accountId = useAccountId();

    const checkAbility = React.useCallback(
        (action: Action, sub: Subject, params: Record<string, any> = {}) => {
            return abilityContext.can(
                action,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                subject<Subject, any>(sub, {
                    accountId,
                    ...params,
                })
            );
        },
        [abilityContext, accountId]
    );

    const ability = {
        canIReadAccount: checkAbility("read", "Account"),
        canIUpdateAccount: checkAbility("update", "Account"),
        canIReadWallet: checkAbility("read", "Wallet"),
        canIReadSubscription: checkAbility("update", "Account") && checkAbility("read", "Subscription"),
        canIReadManyContact: checkAbility("readMany", "Contact"),
        canIReadBot: checkAbility("read", "Bot"),
        canIReadManyAnalytics: checkAbility("readMany", "Analytics"),
        canIReadWebhook: checkAbility("read", "Webhook"),
        canIReadPII: checkAbility("read", "PII"),
        canIReadFlexiList: checkAbility("read", "FlexiList"),
        canIDeleteContact: checkAbility("delete", "Contact"),
        canIReadManyMessage: checkAbility("readMany", "Message"),
        canIExportContact: checkAbility("export", "Contact"),
        canIReadWhatsappFlow: checkAbility("read", "WhatsappFlow"),
        canIExportWhatsappFlow: checkAbility("export", "WhatsappFlow"),
    };

    return { ...ability, checkAbility };
};
