import React from "react";
import { ValueContainerProps } from "react-select";
import { OptionTypeBase } from "react-select";
import { useReactSelectStylesCustomized, UseReactSelectStylesCustomizedProps } from "./useReactSelectStylesCustomized";
import AsyncSelect, { Props as AsyncSelectProps } from "react-select/async";
import FloatingLabelValueContainer from "./FloatingLabelValueContainer";
import { ReactAsyncSelectRef } from "./type";

export type AsyncSelectStyledProps<T extends OptionTypeBase, isMulti extends boolean = false> = AsyncSelectProps<
    T,
    isMulti
> &
    UseReactSelectStylesCustomizedProps;

// eslint-disable-next-line react/display-name
const AsyncSelectStyled = React.forwardRef(
    <T extends OptionTypeBase, isMulti extends boolean = false>(
        props: AsyncSelectStyledProps<T, isMulti>,
        ref?: React.LegacyRef<AsyncSelect<T, isMulti>>
    ) => {
        const { size, fontSize, menuBgColor, containerBgColor, menuHeight, ...selectProps } = props;

        const styles = useReactSelectStylesCustomized<isMulti>({
            ...selectProps,
            size,
            fontSize,
            menuBgColor,
            containerBgColor,
            menuHeight,
        });

        const reactSelectComponent: AsyncSelectStyledProps<T>["component"] = React.useMemo(() => {
            if (selectProps?.isFloatingLabel) {
                return {
                    ValueContainer: FloatingLabelValueContainer as unknown as React.FC<ValueContainerProps<T, isMulti>>,
                };
            }
        }, [selectProps?.isFloatingLabel]);

        return <AsyncSelect ref={ref} styles={styles} components={reactSelectComponent} {...selectProps} />;
    }
);

export default AsyncSelectStyled as <T extends OptionTypeBase, isMulti extends boolean = false>(
    props: AsyncSelectStyledProps<T, isMulti> & { ref?: ReactAsyncSelectRef<T> }
) => JSX.Element;
