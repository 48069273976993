import { useCheckAbility } from "app/hooks/useCheckAbility";
import React from "react";
import EmailMasked, { EmailMaskedProps } from "./EmailMasked";
import PhoneEmailText from "./PhoneEmailText";

export type EmailTextProps = EmailMaskedProps;

const EmailText: React.FC<EmailTextProps> = (props) => {
    const { value, ...textProps } = props;
    const { title, ...textPropsOmittedTitle } = textProps;

    const { canIReadPII } = useCheckAbility();

    if (!canIReadPII)
        return <EmailMasked value={value} title={value !== title ? title : undefined} {...textPropsOmittedTitle} />;
    return (
        <PhoneEmailText value={value} title={title} {...textPropsOmittedTitle}>
            {value}
        </PhoneEmailText>
    );
};

export default EmailText;
