import { Box } from "@chakra-ui/react";
import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

export interface PlayerProps extends ReactPlayerProps {
    lazy?: boolean;
    offset?: number | number[] | undefined;
}

const Player: React.FC<PlayerProps> = ({ url, width, lazy = true, height, offset, ...restProps }) => (
    <Box style={{ width, height }}>
        <ReactPlayer url={url} width={width} height={height} {...restProps} />
    </Box>
);

export default Player;
