import React from "react";
import Select, { components, OptionProps, Props as SelectProps, Styles } from "react-select";
import { Text } from "@chakra-ui/react";
import { formatPhoneNumber } from "app/utils/react-helpers";

const doubleSelectStyle: Partial<Styles<any, false>> = {
    control: (base) => ({
        ...base,
        width: "152px",
        minHeight: "32px",
        height: "32px",
        borderRadius: "6px",
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none",
    }),
    container: (base) => ({
        ...base,
        height: "32px",
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: "13px",
        color: "#1A202C",
        paddingBottom: "4px",
    }),
    option: (base) => ({
        ...base,
        fontSize: "14px",
        padding: "5px",
        backgroundColor: "#EDF2F7",
    }),
    menuList: (base) => ({
        ...base,
        width: "152px",
        paddingLef: "10px",
    }),
    menu: (base) => ({
        ...base,
        width: "152px",
        borderRadius: "10px",
    }),
    singleValue: (base) => ({
        ...base,
        fontSize: "14px",
        paddingBottom: "12px",
    }),
    indicatorsContainer: (base) => ({
        ...base,
        paddingBottom: "6px",
    }),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Option: React.FC<OptionProps<any, false>> = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <Text fontSize="14px" color="gray.800">
                {data.label}
            </Text>
            <Text fontSize="12px" color="gray.500">
                ({formatPhoneNumber(data.description)})
            </Text>
        </components.Option>
    );
};

const ChannelSelect = (props: SelectProps): JSX.Element => {
    const { ...selectProps } = props;
    return <Select styles={doubleSelectStyle} components={{ Option }} {...selectProps} />;
};

export default ChannelSelect;
