import React from "react";
import { maskPhoneNumber } from "app/utils/common";
import PhoneEmailText, { PhoneEmailTextProps } from "./PhoneEmailText";

export type PhoneNumberMaskedProps = PhoneEmailTextProps;

const PhoneNumberMasked: React.FC<PhoneNumberMaskedProps> = (props) => {
    const { value, ...textProps } = props;
    const maskedPhoneNumber = maskPhoneNumber(value);

    return (
        <PhoneEmailText value={value} fontSize="2xs" {...textProps}>
            {maskedPhoneNumber}
        </PhoneEmailText>
    );
};

export default React.memo(PhoneNumberMasked);
