import { AuthResourcesType, CommonJSONType } from "./resources";

//if adding new namespace then add it in AllNameSpacesAndTypes also
export const namespaces = ["auth", "common"] as const;
export type I18NextNameSpaceType = (typeof namespaces)[number];

//if adding new namespace then add it in AllNameSpacesAndTypes also
export interface AllNameSpacesAndTypes {
    translation: "";
    auth: AuthResourcesType;
    common: CommonJSONType;
}

export type NestedKeyOf<ObjectType extends object> = {
    [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
        ? ObjectType[Key] extends (infer U)[]
            ? `${Key}`
            : `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
        : `${Key}`;
}[keyof ObjectType & (string | number)];

const languageCode = ["en", "pt", "es"] as const;
export type LanguageCodeType = (typeof languageCode)[number];
