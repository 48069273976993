import createRootReducer from "app/modules";
import { applyMiddleware, compose, createStore, Middleware } from "redux";
import thunk from "redux-thunk";

const configureStore = (initialState?: any) => {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const middlewares: Middleware[] = [thunk];

    return createStore(createRootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
};

export default configureStore;
