import { CircularProgress, CircularProgressProps, ColorProps, Text, TypographyProps, VStack } from "@chakra-ui/react";
import React from "react";
import CenterToScreen, { CenterToScreenProps } from "./CenterToScreen";

const LoadingState: React.FC<LoadingStateProps & CenterToScreenProps> = (props) => {
    const {
        title,
        spinnerSize = 20,
        isCentered = false,
        textSize = "xs",
        textColor = "gray.600",
        spinnerColor = "brand.500",
    } = props;
    return (
        <CenterToScreen isCentered={isCentered}>
            <VStack justify="center">
                <CircularProgress isIndeterminate capIsRound color={spinnerColor} size={`${spinnerSize}px`} />
                {title ? (
                    <Text color={textColor} fontSize={textSize}>
                        {title}
                    </Text>
                ) : null}
            </VStack>
        </CenterToScreen>
    );
};

export interface LoadingStateProps {
    title?: string;
    spinnerSize?: number;
    textSize?: TypographyProps["fontSize"];
    textColor?: ColorProps["color"];
    spinnerColor?: CircularProgressProps["color"];
}

export default LoadingState;
