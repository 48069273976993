import { Avatar, AvatarProps } from "@chakra-ui/react";
import { getAvatarColor } from "app/utils/react-helpers";
import { CDN_IMGPROXY_URL_ENV } from "environment";
import React from "react";

const encodeUrl = (url: string) =>
    Buffer.from(url).toString("base64").replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");

const sizeToPx: Record<string, number> = {
    "2xs": 32,
    xs: 32,
    sm: 32,
    md: 48,
    lg: 64,
    xl: 96,
    "2xl": 128,
    full: 0,
};

const resizingType = "fill";
const defaultWidth = 300;
const defaultHeight = 300;
const gravity = "no";
const enlarge = 1;
const extension = "png";

const CDNURL = CDN_IMGPROXY_URL_ENV;

export interface CustomAvatarProps extends AvatarProps {
    colorKey: string;
}

// eslint-disable-next-line react/display-name
const CustomAvatar = React.forwardRef<HTMLSpanElement, CustomAvatarProps>(({ children, colorKey, ...props }, ref) => {
    return (
        <Avatar ref={ref} {...props} {...getAvatarColor(colorKey)}>
            {children}
        </Avatar>
    );
});

export default CustomAvatar;
