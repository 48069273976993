import { TQueryKey } from "app/types/common";
import { QueryClient } from "react-query";

export interface QueryDataResponse<TData> extends Record<string, unknown> {
    pages: TData;
}

interface ID {
    id?: string;
}

function findPageAndIndex<TData extends ID>(pages: TData[][], id: string): { pageIndex: number; itemIndex: number } {
    let pageIndex = -1;
    let itemIndex = -1;
    pages.forEach((page, pI) => {
        if (!Array.isArray(page)) return;
        const iI = page.findIndex((c) => c.id === id);
        if (iI > -1) {
            pageIndex = pI;
            itemIndex = iI;
        }
    });
    return { pageIndex, itemIndex };
}

export function update<TData extends ID>(
    queryClient: QueryClient,
    queryKey: string | unknown[],
    id: string,
    update: Partial<TData> | TData
): TData | null {
    const queryData = queryClient.getQueryData<QueryDataResponse<TData[][]>>(queryKey);

    if (!queryData) return null;

    const { pageIndex, itemIndex } = findPageAndIndex(queryData.pages, id);

    if (pageIndex < 0) return null;

    const beforeUpdate: TData = { ...queryData.pages[pageIndex][itemIndex] };

    const newPages = queryData.pages.map((p, pI) => {
        if (pI === pageIndex) {
            return p.map((i, iI) => {
                if (iI === itemIndex) {
                    return { ...i, ...update };
                }
                return i;
            });
        }
        return p;
    });

    queryClient.setQueryData<QueryDataResponse<TData[][]>>(queryKey, (old) => ({
        ...old,
        pages: newPages,
    }));

    return beforeUpdate;
}

export function updateQueryKey<TData extends ID>(
    queryClient: QueryClient,
    queryKey: string | unknown[],
    update: Partial<TData> | TData
): TData | null {
    const queryData = queryClient.getQueryData<TData>(queryKey);

    if (!queryData) return null;

    const beforeUpdate: TData = { ...queryData };

    const newPages = () => {
        return { ...queryData, ...update };
    };

    queryClient.setQueryData<TData>(queryKey, newPages);

    return beforeUpdate;
}

export function remove<TData extends ID>(queryClient: QueryClient, queryKey: string | unknown[], id: string): boolean {
    const queryData = queryClient.getQueryData<QueryDataResponse<TData[][]>>(queryKey);

    if (!queryData) return false;

    const newPages = queryData.pages.map((page: TData[]) => {
        if (!Array.isArray(page) || !page) return [];
        return page.filter((item: TData) => item.id !== id);
    });

    queryClient.setQueryData<QueryDataResponse<TData[][]>>(queryKey, (old) => ({
        ...old,
        pages: newPages,
    }));

    return newPages.flat().length < queryData.pages.flat().length;
}

export function add<TData extends ID>(
    queryClient: QueryClient,
    queryKey: string | unknown[],
    data: TData,
    position: "first" | "last" = "first"
): TData | null {
    const queryData = queryClient.getQueryData<QueryDataResponse<TData[][]>>(queryKey);

    if (!queryData || !data.id || !queryData.pages || queryData.pages.length < 1) {
        return null;
    }

    let newPages: TData[][] = [];

    const { pageIndex, itemIndex } = findPageAndIndex(queryData.pages, data.id);

    let beforeUpdate: TData | null = null;

    if (pageIndex < 0) {
        if (position === "last") {
            newPages = [...queryData.pages.slice(0, -1), [...queryData.pages[queryData.pages.length - 1], data]];
        } else {
            newPages = [[data, ...queryData.pages[0]], ...queryData.pages.slice(1)];
        }
    } else {
        beforeUpdate = { ...queryData.pages[pageIndex][itemIndex] };
        newPages = queryData.pages.map((p, pI) => {
            if (pI === pageIndex) {
                return p.map((i, iI) => {
                    if (iI === itemIndex) {
                        return { ...data };
                    }
                    return i;
                });
            }
            return p;
        });
    }

    queryClient.setQueryData<QueryDataResponse<TData[][]>>(queryKey, (old) => ({
        ...old,
        pages: newPages,
    }));

    return beforeUpdate;
}

export const getMatchingQueryKeys = (queryClient: QueryClient, matchQueryKey: TQueryKey): TQueryKey[] => {
    const matchedQueryKeys = queryClient
        .getQueryCache()
        .findAll(matchQueryKey)
        .map(({ queryKey }) => {
            return queryKey as TQueryKey;
        });
    return matchedQueryKeys;
};
