import React from "react";
import { maskMailId } from "app/utils/common";
import PhoneEmailText, { PhoneEmailTextProps } from "./PhoneEmailText";

export type EmailMaskedProps = PhoneEmailTextProps;

const EmailMasked: React.FC<EmailMaskedProps> = (props) => {
    const { value, ...textProps } = props;
    const maskedEmailId = maskMailId(value);

    return (
        <PhoneEmailText value={value} fontSize="2xs" {...textProps}>
            {maskedEmailId}
        </PhoneEmailText>
    );
};

export default EmailMasked;
