import { extendTheme } from "@chakra-ui/react";
import config from "app/app.config";
import { StepsTheme as Steps } from "chakra-ui-steps";
import { buttonSizes, buttonVariants } from "./ButtonTheme";

import "react-day-picker/lib/style.css";

const PICKER_OVERLAY = 570;
const DATE_INPUT_WIDTH = 120;

const DataRangePickerGlobalStyle = {
    ".DataRangePicker .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)":
        {
            backgroundColor: "#f0f8ff !important",
            color: "#4a90e2",
        },
    ".DataRangePicker .DayPicker-Day": {
        borderRadius: "0 !important",
    },
    ".DataRangePicker .DayPicker-Day--start": {
        borderTopLeftRadius: "50% !important",
        borderBottomLeftRadius: "50% !important",
    },
    ".DataRangePicker .DayPicker-Day--end": {
        borderTopRightRadius: "50% !important",
        borderBottomRightRadius: "50% !important",
    },
    ".DataRangePicker .DayPickerInput-Overlay": {
        width: `${PICKER_OVERLAY}px`,
        marginTop: "12px",
        marginLeft: `-${PICKER_OVERLAY - DATE_INPUT_WIDTH * 2}px`,
    },
    ".DataRangePicker-to .DayPickerInput-Overlay": {
        marginLeft: `-${PICKER_OVERLAY - DATE_INPUT_WIDTH}px`,
        marginTop: "12px",
    },
    ".DataRangePicker input": {
        outline: "2px solid transparent",
        outlineOffset: 0,
        width: `${DATE_INPUT_WIDTH}px`,
    },
};

const DatePickerSelectionGlobalStyle = {
    ".DataRangePicker .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)":
        {
            backgroundColor: "#f0f8ff !important",
            color: "#4a90e2",
        },
    ".DataRangePicker .DayPicker-Day": {
        borderRadius: "0 !important",
    },
    ".DataRangePicker .DayPicker-Day--start": {
        borderTopLeftRadius: "50% !important",
        borderBottomLeftRadius: "50% !important",
    },
    ".DataRangePicker .DayPicker-Day--end": {
        borderTopRightRadius: "50% !important",
        borderBottomRightRadius: "50% !important",
    },
    ".DataRangePicker .DayPickerInput-Overlay": {
        width: "570px",
    },
    ".DataRangePicker-to .DayPickerInput-Overlay": {
        marginLeft: "-198px",
    },
};

const activeLabelStyles = {
    transform: "scale(0.85) translateY(-24px)",
};

const floatingLabelStyle = {
    _focusWithin: {
        label: {
            ...activeLabelStyles,
        },
    },
    "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
        {
            ...activeLabelStyles,
        },
    label: {
        top: 0,
        left: 0,
        zIndex: 2,
        position: "absolute",
        backgroundColor: "white",
        pointerEvents: "none",
        color: "gray.600",
        mx: 3,
        px: 1,
        my: 2,
        transformOrigin: "left top",
    },
};

const gbTheme = extendTheme({
    fonts: {
        body: "Inter, system-ui, sans-serif",
        heading: "Inter, Georgia, serif",
    },
    colors: config.branding.theme?.colors,
    sizes: {
        "2md": "40rem",
    },
    fontSizes: {
        "2xs": "0.8125rem",
    },
    styles: {
        global: {
            //The emerging W3C standard that is currently Firefox-only
            "*": { scrollbarWidth: "thin", scrollbarColor: "#00000033 #ffffff1a" },
            //Works on Chrome/Edge/Safari
            "*::-webkit-scrollbar": { width: "6px", height: "6px" },
            "*::-webkit-scrollbar-track": { background: "#ffffff1a" },
            "*::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
                transition: "all 200ms ease",
            },
            "*:hover::-webkit-scrollbar-thumb": {
                backgroundColor: "#00000033",
            },

            body: {
                overflow: "hidden",
                minHeight: "0 !important",
            },

            //https://github.com/WICG/focus-visible
            ".js-focus-visible :focus:not([data-focus-visible-added])": {
                outline: "none",
                boxShadow: "none",
            },
            ".chakra-collapse": {
                overflow: "inherit !important",
            },

            ".chakra-input__group": {
                isolation: "auto !important",
            },

            //react-day-picker
            ...DatePickerSelectionGlobalStyle,
            ...DataRangePickerGlobalStyle,
        },
    },
    components: {
        Popover: {
            variants: {
                responsive: {
                    popper: {
                        maxWidth: "unset",
                        width: "unset",
                    },
                },
            },
        },
        Steps,
        Tabs: {
            baseStyle: () => {
                return {
                    tab: {
                        color: "gray.500",
                        borderBottom: "2px solid transparent",
                        _active: { bg: "transparent" },
                        _selected: {
                            color: "gray.900",
                            fontSize: "md",
                            fontWeight: "medium",
                            borderBottom: "2px solid",
                            borderColor: "brand.500",
                        },
                    },
                };
            },
        },
        Form: {
            variants: {
                floating: { container: floatingLabelStyle },
                "floating-and-disabled": {
                    container: {
                        ...floatingLabelStyle,
                        ...{ label: { ...floatingLabelStyle.label, color: "gray.500" } },
                    },
                },
            },
        },
        Button: {
            variants: buttonVariants,
            sizes: buttonSizes,
        },
    },
});

export default gbTheme;
