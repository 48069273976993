import { Image, ImageProps } from "@chakra-ui/react";
import config from "app/app.config";
import React from "react";

interface GBLogoWithBrandNameProps {
    logoProps?: Omit<ImageProps, "src">;
}

const GBLogoWithBrandName: React.FC<GBLogoWithBrandNameProps> = (props) => {
    return <Image {...props} src={config.branding.product_logos.icon_with_text} />;
};

export default GBLogoWithBrandName;
