import { isValidEmail } from "app/utils/common";
import React from "react";
import EmailText, { EmailTextProps } from "./EmailText";
import PhoneNumberText, { PhoneNumberTextProps } from "./PhoneNumberText";
import { ComponentWithAs, Text } from "@chakra-ui/react";
import { isValidPhone } from "app/utils/react-helpers";

export type PIIValidatedTextProps = (PhoneNumberTextProps | EmailTextProps) & {
    disableFormatting?: PhoneNumberTextProps["disablePhoneNumberFormatting"];
};

export const PIIValidatedText: ComponentWithAs<"p", PIIValidatedTextProps> = (props) => {
    const { value, disableFormatting, ...restProps } = props;

    const isValidPhoneNumber = Number(value) ? isValidPhone(value) : false;
    if (isValidPhoneNumber)
        return <PhoneNumberText value={value} disablePhoneNumberFormatting={disableFormatting} {...restProps} />;

    const isValidEmailId = isValidEmail(value);

    if (isValidEmailId) return <EmailText value={value} {...restProps} />;

    return <Text {...restProps}>{value}</Text>;
};

export default PIIValidatedText;
