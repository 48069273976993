import { useToast } from "@chakra-ui/react";
import { BackStageJob, BackStageJobType } from "app/types/account";
import { QueryKey, TQueryKey } from "app/types/common";
import { IWallet } from "app/types/prepaid-wallet";
import { deleteJSON, fetcher, mapQueryParams, postJSON } from "app/utils/fetchUtils";
import { useAccountId } from "app/utils/react-helpers";
import {
    MutationFunction,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";

interface ListWalletProps {
    accountId: string;
}

export const useListWallet = (props: ListWalletProps) => {
    const { accountId, ...options } = props;
    const queryResult = useQuery<IWallet[], Error, IWallet[], TQueryKey>(
        [QueryKey.PrepaidWallet, { accountId }],
        ({ queryKey }) => {
            const [, { accountId }] = queryKey;
            const url = `/api/accounts/${accountId}/wallets`;
            return fetcher(url);
        },
        options
    );
    return queryResult;
};

// Get ListTransaction

interface ListTransactionProps {
    accountId: string;
    walletId: string;
    type: string;
    channelId?: string;
    startDate?: string;
    endDate?: string;
    query: string;
}

export const useListTransaction = <T>(props: ListTransactionProps): UseQueryResult<T[], Error> => {
    const { accountId, walletId, query, type, channelId, endDate, startDate, ...options } = props;
    const PAGE_SIZE = 20;

    const QueryResult = useQuery<T[], Error, T[], TQueryKey>(
        [QueryKey.ListTransaction, { accountId, walletId, channelId, startDate, endDate, type, query }],
        ({ pageParam, queryKey }) => {
            const [, { accountId, walletId, channelId }] = queryKey;
            const queryParams = mapQueryParams({
                page: pageParam ?? 1,
                type,
                query,
                channelId,
                startDate,
                endDate,
            });

            return fetcher(`/api/accounts/${accountId}/wallets/${walletId}/transactions?${queryParams}`);
        },
        {
            ...options,
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === PAGE_SIZE ? pages.length + 1 : undefined;
            },
        }
    );

    return QueryResult;
};

//cancel transacction

type UseDeleteTransaction = UseMutationResult<IWallet, Error, { paymentLinkId: string }>;

export const useCancelTransaction = (
    accountId: string,
    walletId: string,
    onSuccess?: () => void
): UseDeleteTransaction => {
    const queryClient = useQueryClient();
    const toast = useToast({ isClosable: true, duration: 2000 });

    const queryKey: string | unknown[] = [QueryKey.PrepaidWallet, { accountId }];

    const deleteTransaction: MutationFunction<IWallet, { paymentLinkId: string }> = ({ paymentLinkId }) => {
        return deleteJSON<IWallet>(
            `/api/accounts/${accountId}/wallets/${walletId}/recharge?paymentLinkId=${paymentLinkId}`
        );
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<IWallet, Error, { paymentLinkId: string }>(deleteTransaction, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedField, context) => {
            queryClient.setQueryData(queryKey, context);
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while Cancelling Transaction",
            });
        },
        onSuccess: () => {
            toast({
                title: "Transaction  Cancelled",
                status: "success",
                description: "Transaction  Cancelled successfully!",
            });
            onSuccess?.();
        },
        onMutate: async () => {
            // Cancel ListTransaction outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const prevWallet = queryClient.getQueryData<IWallet[]>(queryKey);

            // Update the data
            const updatedWallet = prevWallet?.map((w) => (w.id === walletId ? { ...w, pendingPayment: undefined } : w));
            queryClient.setQueryData(queryKey, updatedWallet);

            // Optionally return a context containing data to use when for example rolling back
            return prevWallet ?? [[]];
        },

        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

//Recharge Wallet
type UseRechargeWalletFirst<InputType> = UseMutationResult<IWallet, Error, InputType>;
interface RechargeWalletProps {
    accountId: string;
    walletId: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
}
interface Recharge {
    amount: number;
    paymentGateway?: string;
    tds?: string;
}

export const useRechargeWallet = (props: RechargeWalletProps): UseRechargeWalletFirst<Recharge> => {
    const { accountId, walletId, onSuccess, showSuccessMessage = true } = props;

    // const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [
        QueryKey.PrepaidWallet,
        {
            accountId,
        },
    ];

    const payRecharge = (recharge: Recharge) => {
        return postJSON(`/api/accounts/${accountId}/wallets/${walletId}/recharge`, recharge, {}, true);
    };

    const mutationResult = useMutation<IWallet, Error, Recharge>(payRecharge, {
        // If the mutation fails, use the context returned from onMutate to roll back
        // onError: (error) => {
        //     toast({
        //         title: "Something went wrong!",
        //         status: "error",
        //         description: error.message.split(",")[0] ?? error.message,
        //     });
        // },
        //Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

//

//post export

type PrepaidStartExport<InputType> = UseMutationResult<BackStageJob, Error, InputType>;
interface usePostPrePaidExportProps {
    accountId: string;
    walletId: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
}

interface PostPrePaidExport {
    startDate: string;
    endDate: string;
    type: "month" | "day";
}

export const usePostPrePaidExport = (props: usePostPrePaidExportProps): PrepaidStartExport<PostPrePaidExport> => {
    const { accountId, walletId, onSuccess, showSuccessMessage = false } = props;

    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [
        QueryKey.BackstagePrepaid,
        {
            accountId,
        },
    ];

    const prePaidBackStage = (profile: PostPrePaidExport) => {
        const { endDate, startDate, type } = profile;
        const queryParams = mapQueryParams({ type, startDate, endDate });
        return postJSON(`/api/accounts/${accountId}/wallets/${walletId}/exports?${queryParams}`, {});
    };

    const mutationResult = useMutation<BackStageJob, Error, PostPrePaidExport>(prePaidBackStage, {
        // If the mutation fails, use the context returned from onMutate to roll back
        // onError: (error) => {
        //     toast({
        //         title: "Something went wrong!",
        //         status: "error",
        //         description: error.message.split(",")[0] ?? error.message,
        //     });
        // },
        onSuccess: () => onSuccess?.(),
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

export const useWalletActivation = (props: {
    onSuccess?: (res: IWallet) => void;
    onError?: () => void;
}): UseMutationResult<IWallet, Error, void> => {
    const accountId = useAccountId();
    const toast = useToast();
    const payRecharge = () => {
        return postJSON<Recharge>(`/api/accounts/${accountId}/wallets`, {
            amount: 0,
        });
    };

    const mutationResult = useMutation<IWallet, Error, void>(payRecharge, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while Cancelling Transaction",
            });
            props.onError?.();
        },
        onSuccess: (res) => {
            toast({
                title: "Wallet Created",
                status: "success",
                description: "Wallet Created successfully!",
            });
            props.onSuccess?.(res);
        },
    });

    return mutationResult;
};
