import { Resource } from "i18next";
import authEn from "./locale/en/auth.json";
import authPt from "./locale/pt/auth.json";
import authEs from "./locale/es/auth.json";
import commonEn from "./locale/en/common.json";
import commonPt from "./locale/pt/common.json";
import commonEs from "./locale/es/common.json";
import { I18NextNameSpaceType } from "./types/common";

const english: Record<I18NextNameSpaceType, Record<string, unknown>> = {
    auth: authEn,
    common: commonEn,
};
const portuguese: Record<I18NextNameSpaceType, Record<string, unknown>> = {
    auth: authPt,
    common: commonPt,
};
const spanish: Record<I18NextNameSpaceType, Record<string, unknown>> = {
    auth: authEs,
    common: commonEs,
};

export const resources: Resource = {
    en: english,
    pt: portuguese,
    es: spanish,
};
