import { useCheckAbility } from "app/hooks/useCheckAbility";
import React, { PropsWithChildren } from "react";

const CanIReadPII: React.FC<PropsWithChildren> = (props) => {
    const { canIReadPII } = useCheckAbility();
    if (!canIReadPII) return null;
    return <>{props.children}</>;
};

export default CanIReadPII;
