import { Text } from "@chakra-ui/react";
import { LabelValue } from "app/types";
import { AnyObject } from "app/types/common";
import { WATemplate } from "app/types/message/whatsapp-template";
import { isValidPhone } from "app/utils/react-helpers";
import { URL_REGEX } from "app/utils/validation";
import dayjs from "dayjs";
import isEqual from "lodash/isEqual";
import numeral from "numeral";
import React from "react";
import { IconType } from "react-icons";
import { formatPhoneNumber } from "./react-helpers";

export const callFnsInSequence =
    (...fns: any[]) =>
    (...args: any[]) =>
        fns.forEach((fn) => fn && fn(...args));

export const isJSXElement = (elm: string | JSX.Element | undefined): elm is JSX.Element => {
    return typeof elm !== "string";
};
export const boldText = (text: string) => {
    return (
        <Text as="span" fontWeight="bold" color="gray.700">
            {text}
        </Text>
    );
};
export const boldTextWhite = (text: string) => {
    return (
        <Text as="span" fontWeight="bold" color="white">
            {text}
        </Text>
    );
};

export const windowOpenNewTab = (link: string) => {
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("target", "_blank");
    a.click();
    a.remove();
};

export const getPercentage = (value: number, totalValue: number): number => {
    if (!Number(value) || !Number(totalValue) || value === 0 || totalValue === 0) return 0;

    const percentage = (value / totalValue) * 100;
    return Number(percentage.toFixed(2));
};

export function removeFormArrayOfObject<T>(array: T[], removeElement: T): T[] {
    const check = [...array];
    const index = check.indexOf(removeElement);
    return removeElementFromArray(check, index);
}

export const removeElementFromArray = <T,>(array: T[], index: number) => {
    if (index > -1) {
        return array.filter((_, idx) => idx !== index);
    }
    return array;
};

export const screenWidth = window.innerWidth > 1600 ? 1600 : window.innerWidth;
const padding = 32;
export const containerWidth = screenWidth - padding;

export const getFileNameWithExtension = (filename: string, ext: string): string => {
    if (!ext) return filename ?? "unknown";
    if (!filename) return `unknown.${ext}`;

    const fileNameAsArray = filename.split(".");

    if (fileNameAsArray.length === 1) {
        return `${filename}.${ext}`;
    }

    const fileExt = fileNameAsArray.pop();
    if (!fileExt || ext !== fileExt) {
        fileNameAsArray.push(ext);
        return fileNameAsArray.join(".");
    }

    return filename;
};

export const arrayOfObjectCompare = (objectKey: string) => {
    return (a: any, b: any) => {
        if (a[objectKey] < b[objectKey]) {
            return -1;
        }
        if (a[objectKey] > b[objectKey]) {
            return 1;
        }
        return 0;
    };
};

export const getValidHref = (url: string) => {
    const urlReg = new RegExp(URL_REGEX);
    return url.match(urlReg) ? url : `https://${url}`;
};

export const checkOS = (): string => {
    const platform: string = window.navigator.userAgent || window.navigator.platform;
    return platform.toLowerCase();
};

export const isMacOS = checkOS().includes("mac");

export const getDateInIST = (currentDate: string | number | Date = ""): Date => {
    const currentTime = currentDate ? new Date(currentDate) : new Date();

    const currentOffset = currentTime.getTimezoneOffset();

    const ISTOffset = 330; // IST offset UTC +5:30

    const ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);

    return ISTTime;
};

export const isWeekdayWorkingHour = (): boolean => {
    // Get the current date and time
    const now = getDateInIST();

    // Check if the current day is a weekday (Monday to Friday)
    const day = now.getDay();
    if (day === 0 || day === 6) {
        // 0 is Sunday, 6 is Saturday
        return false;
    }

    // Check if the current time is between 10am to 1pm or 2pm to 5pm
    const hour = now.getHours();
    if ((hour >= 10 && hour < 13) || (hour >= 14 && hour < 17)) {
        return true;
    }

    return false;
};

export const isTemplateCoolingPeriodIsOver = (date?: string | Date): boolean => {
    if (!date) return false;
    return dayjs().utc().isAfter(dayjs(date).add(15, "minute"));
};

export const getWATemplateStatus = (
    status: WATemplate["status"],
    statusUpdatedAt: WATemplate["statusUpdatedAt"]
): WATemplate["status"] => {
    if (!statusUpdatedAt) return status;
    if (status === "approved" && !isTemplateCoolingPeriodIsOver(statusUpdatedAt)) return "recently_approved";
    return status;
};

export const getStatusCalculatedWATemplate = (template: WATemplate): WATemplate => {
    const status: WATemplate["status"] = getWATemplateStatus(template.status, template.statusUpdatedAt);
    return { ...template, status };
};

export const calculateAllWATemplateStatus = (templates: WATemplate[]): WATemplate[] => {
    return templates.map((template) => getStatusCalculatedWATemplate(template));
};
export const findChildByDisplayName = (children: React.ReactNode, targetDisplayName: string[]) => {
    const targetComps: (React.ReactChild | React.ReactFragment | React.ReactPortal)[] = [];
    const otherComps: (React.ReactChild | React.ReactFragment | React.ReactPortal)[] = [];

    React.Children.toArray(children).forEach((child) => {
        if (React.isValidElement(child)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { type } = child as React.ReactElement<any> & { type: React.NamedExoticComponent<any> };
            if (type.displayName && targetDisplayName.includes(type.displayName)) targetComps.push(child);
            else otherComps.push(child);
        }
    });
    return { targetComps, otherComps };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isIconType = (component: any): component is IconType => {
    return typeof component === "function";
};

export interface CountryAndCurrencyType extends LabelValue {
    locale: string;
    currencyLabel: string;
    currencyValue: string;
    name: string;
    iso: string;
    region: string;
}

export const CountryAndCurrency: CountryAndCurrencyType[] = [
    {
        label: "India-Rupee",
        value: "INR",
        currencyLabel: "India-Rupee",
        currencyValue: "INR",
        name: "India",
        iso: "IND",
        region: "Asia",
        locale: "en-IN",
    },
    {
        label: "United States-Dollar",
        value: "USD",
        currencyLabel: "United States Dollar",
        currencyValue: "USD",
        name: "United States",
        iso: "USA",
        region: "North America",
        locale: "en-US",
    },
    {
        label: "United Arab Emirates-Dirham",
        value: "AED",
        currencyLabel: "United Arab Emirates Dirham",
        currencyValue: "AED",
        name: "United Arab Emirates",
        iso: "ARE",
        region: "Middle East",
        locale: "ar-AE",
    },
    {
        label: "Euro",
        value: "EUR",
        currencyLabel: "Euro",
        currencyValue: "EUR",
        name: "Eurozone",
        iso: "Varies by country",
        region: "Europe",
        locale: "de-DE",
    },
    {
        label: "Brazil-Real",
        value: "BRL",
        currencyLabel: "Brazilian Real",
        currencyValue: "BRL",
        name: "Brazil",
        iso: "BRA",
        region: "South America",
        locale: "pt-BR",
    },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pickNonFunctionKeyValuePairInObject = <T extends AnyObject = AnyObject>(object: T): T => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: AnyObject = {};
    Object.keys(object).forEach((key) => {
        if (typeof object[key] !== "function") {
            data[key] = object[key];
        }
    });
    return data as T;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIsTwoArrayOfObjectIdentical = <T extends AnyObject = AnyObject>(arr1: T[], arr2: T[]): boolean => {
    const filteredArr1 = arr1?.map((value) => {
        return pickNonFunctionKeyValuePairInObject(value);
    });
    const filteredArr2 = arr2?.map((value) => {
        return pickNonFunctionKeyValuePairInObject(value);
    });

    return isEqual(filteredArr1, filteredArr2);
};

export const formatIntegersToShortScale = (num?: string | number): string => {
    return numeral(num).format("0a");
};
const ipv4Regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const CIDRRegex = /^(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/;

export const isIPv4CIDR = (ipAddress: string): boolean => {
    return ipv4Regex.test(ipAddress) || CIDRRegex.test(ipAddress);
};
export const isIPv4WithoutNetworkMask = (ipAddress: string): boolean => {
    return ipv4Regex.test(ipAddress);
};
export const maskMailId = (mail: string): string => {
    if (!mail) return mail;

    const [base, domain] = mail.split("@");

    const splitDomain = domain.split(".");
    const topLevelDomain = splitDomain.pop();
    const subDomain = [...splitDomain].join("");
    const maskedDomain = subDomain[0] + "****" + subDomain[subDomain.length - 1] + "." + topLevelDomain;

    return base[0] + "*****" + base[base.length - 1] + "@" + maskedDomain;
};

const mashPhoneNumberWithoutCountryCode = (phnNo: string) => {
    if (!Number(phnNo)) return phnNo;
    return `${phnNo.substring(0, 2)}${"*".repeat(phnNo.length - 4)}${phnNo.substring(phnNo.length - 2)}`;
};

export const maskPhoneNumber = (phnNo: string): string => {
    if (!isValidPhone(phnNo)) return phnNo;

    if (phnNo.length <= 10) {
        return mashPhoneNumberWithoutCountryCode(phnNo);
    }

    const formattedPhoneNumber = formatPhoneNumber(phnNo);
    if (!formattedPhoneNumber) return phnNo;

    const [countryCode, ...phoneNumberArray] = formattedPhoneNumber.split(" ");
    const phoneNumber = phoneNumberArray.join("");

    const maskedNumber = `${countryCode} ${mashPhoneNumberWithoutCountryCode(phoneNumber)}`;

    return maskedNumber;
};

export const validateAndMaskText = (value: string, canIReadPII: boolean = false) => {
    if (canIReadPII) return value;

    const isValidPhoneNumber = Number(value) ? isValidPhone(value) : false;
    const isValidEmailId = isValidEmail(value);

    if (isValidPhoneNumber) return maskPhoneNumber(value);
    if (isValidEmailId) return maskMailId(value);
    return value;
};

export const isValidEmail = (email: string): boolean => {
    const emailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(email);
};

export const findAndMaskPIIDataInString = (value: string): string => {
    const emailRegex = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/g;
    const phoneRegex = /(\+\d{1,3}\s*)?(\(\d{3}\)|\d{3})([-\s.]?)\d{3}([-.\s]?)\d{4}\b/g;

    let maskedString = value;
    maskedString = maskedString.replace(emailRegex, (email) => validateAndMaskText(email));
    maskedString = maskedString.replace(phoneRegex, (phone) => validateAndMaskText(phone));
    return maskedString;
};

export const maskPIIinJSON = (
    jsonData: AnyObject,
    canIReadPII = false,
    piiFieldSearchAndReplace: "fullText" | "regular" = "regular"
) => {
    if (canIReadPII) return jsonData;
    const isFullTextValidation = piiFieldSearchAndReplace === "fullText";
    const traverse = (obj: AnyObject) => {
        for (const key in obj) {
            if (typeof obj[key] === "string") {
                if (isFullTextValidation) {
                    obj[key] = findAndMaskPIIDataInString(obj[key]);
                    continue;
                }
                const isValidPhoneNumber = Number(obj[key]) ? isValidPhone(obj[key], true) : false;
                const isValidEmailId = isValidEmail(obj[key]);
                if (isValidPhoneNumber) {
                    obj[key] = maskPhoneNumber(obj[key]);
                } else if (isValidEmailId) {
                    obj[key] = maskMailId(obj[key]);
                }
            } else if (typeof obj[key] === "object") {
                traverse(obj[key]);
            }
        }
    };

    traverse(jsonData);

    // Convert the modified JSON back to a string
    return jsonData;
};

export const isPIIText = (value: string) => {
    const isValidPhoneNumber = Number(value) ? isValidPhone(value) : false;
    const isValidEmailId = isValidEmail(value);
    return isValidPhoneNumber || isValidEmailId;
};

export const levenshteinDistance = (a: string, b: string) => {
    let equivalency = 0;
    const minLength = a.length > b.length ? b.length : a.length;
    const maxLength = a.length < b.length ? b.length : a.length;
    for (let i = 0; i < minLength; i++) {
        if (a[i] == b[i]) {
            equivalency++;
        }
    }

    const weight = equivalency / maxLength;
    return weight;
};

export const extractFileName = (url: string) => {
    // Extract file name using regex based on the last hyphen "-"
    const regex = /[^-]+$/; // Matches one or more characters that are not a hyphen at the end of the string
    const match = url.match(regex);
    if (!match) return url;
    return match[0];
};

const getNewKey = (key: string, currentKey: string = ""): string => {
    if (!currentKey) return key;
    const isKeyWithSpace = /\s/.test(key);
    if (isKeyWithSpace) {
        return `${currentKey}["${key}"]`;
    }
    if (Number(key) || Number(key) == 0) {
        return `${currentKey}[${key}]`;
    }

    return `${currentKey}.${key}`;
};

export const getAllKeyCombinations = (obj: any, currentKey = ""): string[] => {
    const combinations = [];

    for (const key in obj) {
        const newKey = getNewKey(key, currentKey);
        if (typeof obj[key] === "object" && obj[key] !== null) {
            combinations.push(newKey);
            combinations.push(...getAllKeyCombinations(obj[key], newKey));
        } else {
            combinations.push(newKey);
        }
    }

    return combinations;
};

export const getMustacheVariablesV2 = (text: string) => {
    const variablePattern = /\{\{([^{}]+)\}\}/g;

    const matches = [];
    let match;

    while ((match = variablePattern.exec(text)) !== null) {
        matches.push(match[1]);
    }
    return matches;
};
