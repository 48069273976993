import React, { useRef, useEffect, PropsWithChildren } from "react";
import io from "socket.io-client";
import { SocketIOContext } from "./context";

export interface SocketIOProviderProps {
    url: string;
    opts?: SocketIOClient.ConnectOpts;
    onConnect?: (isConnected: boolean) => void;
}

export const SocketIOProvider: React.FC<PropsWithChildren<SocketIOProviderProps>> = ({
    url,
    opts,
    onConnect,
    children,
}) => {
    const connectOpts = Object.assign({ transports: ["websocket"], autoConnect: false }, opts);
    const socketRef = useRef(io(url, connectOpts));
    socketRef.current.on("connect", () => {
        if (typeof onConnect === "function") onConnect(socketRef.current.connected);
    });
    socketRef.current.on("error", (error: Error) => {
        console.debug("Socket::error::");
        console.debug(error);
    });
    socketRef.current.on("connect_error", (error: Error) => {
        console.debug("Socket::connect_error::error::");
        console.debug(error);
    });
    socketRef.current.on("connect_timeout", (timeout: number) => {
        console.debug(`Socket::connect_timeout::timeout::${timeout}`);
    });
    socketRef.current.on("reconnect", (attemptNumber: number) => {
        console.debug(`Socket::reconnect::attemptNumber::${attemptNumber}`);
    });
    socketRef.current.on("reconnect_attempt", (attemptNumber: number) => {
        console.debug(`Socket::reconnect_attempt::attemptNumber::${attemptNumber}`);
    });
    socketRef.current.on("reconnect_error", (error: Error) => {
        console.debug("Socket::reconnect_error::error::");
        console.debug(error);
    });
    socketRef.current.on("reconnect_failed", () => {
        console.debug("Socket::reconnect_failed");
    });
    socketRef.current.on("disconnect", () => {
        if (typeof onConnect === "function") onConnect(socketRef.current.connected);
    });
    useEffect(() => {
        const scr = socketRef.current;
        scr.open();
        return () => {
            if (scr.connected) {
                scr.close();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <SocketIOContext.Provider value={socketRef.current}>{children}</SocketIOContext.Provider>;
};
