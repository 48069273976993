import { useToast } from "@chakra-ui/react";
import { MAX_FILE_SIZE_IN_BYTES } from "app/contants";
import GenericSvg from "assets/File/GenericDocument.svg";
import PDFSvg from "assets/File/PdfDocument.svg";
import ZIPSvg from "assets/File/ZipDocument.svg";
import startCase from "lodash/startCase";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { FileRejection } from "react-dropzone";
import { v4 as uuid } from "uuid";

interface FileObjBase {
    id: string;
    name: string;
    size?: number;
    type: string;
    progress?: number;
}

export interface EditorFileObj extends FileObjBase {
    file: File;
    uploadedPath?: string;
}

export interface CannedFileObj extends FileObjBase {
    file?: File;
    uploadedPath: string;
    mediaId?: string;
}

interface ResizeFile {
    file: File;
    maxWidth?: number;
    maxHeight?: number;
    compressFormat?: string;
    quality?: number;
    rotation?: number;
    outputType?: "base64" | "blob" | "file";
}

export type FileObj = EditorFileObj | CannedFileObj;

export const resizeFile = (props: ResizeFile): Promise<string | Blob | File | ProgressEvent<FileReader>> => {
    const {
        file,
        maxWidth = 1280,
        maxHeight = 854,
        compressFormat = "jpeg",
        quality = 80,
        rotation = 0,
        outputType = "file",
    } = props;
    return new Promise((resolve) => {
        resolve(file);
        /* Resizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            compressFormat,
            quality,
            rotation,
            (uri) => {
                resolve(uri);
            },
            outputType
        ); */
    });
};
export const fileToFileObj = (file: File): FileObj => {
    const { name, size, type } = file;
    const id = uuid();
    return { file, name, size, type, id };
};

export interface useFileReturntype {
    myFiles: FileObj[];
    setMyFiles: Dispatch<SetStateAction<FileObj[]>>;
    onDropFiles: (acceptedFiles: File[]) => void;
    onRemoveFileById: (id: string) => () => void;
    onUpdateProgressById: (id: string, progress: number | undefined) => void;
}
export const useFile = (): useFileReturntype => {
    const [myFiles, setMyFiles] = useState<FileObj[]>([]);
    const onDropFiles = useCallback(
        (acceptedFiles: File[]) => {
            const fileObj = acceptedFiles.map(fileToFileObj);
            setMyFiles((prev) => [...prev, ...fileObj]);
        },
        [setMyFiles]
    );
    const onUpdateProgressById = useCallback(
        (id: string, progress?: number) => {
            setMyFiles((prev) => prev.map((f) => (f.id === id ? { ...f, progress } : f)));
        },
        [setMyFiles]
    );
    const onRemoveFileById = useCallback(
        (id: string) => () => {
            setMyFiles((prev) => prev.filter((f) => f.id !== id));
        },
        [setMyFiles]
    );
    return {
        myFiles,
        setMyFiles,
        onDropFiles,
        onRemoveFileById,
        onUpdateProgressById,
    };
};

export const formatBytes = (bytes: number, decimals: number = 2): string => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getFileSvg = (type: string): string => {
    if (type.includes("pdf")) return PDFSvg;
    if (type.includes("zip")) return ZIPSvg;
    return GenericSvg;
};

interface OnDropRejectProps {
    toast: ReturnType<typeof useToast>;
    maxSize?: number;
}

export const onDropRejected =
    (props: OnDropRejectProps) =>
    (fileRejections: FileRejection[]): void => {
        const { maxSize = MAX_FILE_SIZE_IN_BYTES, toast } = props;
        const maxFileSize = maxSize / (1 * 1024 * 1024);
        fileRejections.forEach((rejection) => {
            rejection.errors.forEach((error) => {
                const isLarge = error.code === "file-too-large";
                const msg = isLarge ? `File too large. Upload less than ${maxFileSize} mb` : startCase(error.code);
                if (!toast.isActive("file-upload-error-rejected")) {
                    toast({
                        id: "file-upload-error-rejected",
                        title: "Error",
                        description: msg,
                        status: "error",
                        position: "top",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            });
        });
    };

export const getExtension = (path: string): string => {
    const basename = path.split(/[\\/]/).pop();
    if (!basename) return "";
    const pos = basename.lastIndexOf(".");
    if (pos < 1) return "";
    return basename.slice(pos + 1);
};

export const removeExtension = (filename: string): string => {
    const lastDotPosition = filename.lastIndexOf(".");
    if (lastDotPosition === -1) return filename;
    return filename.substr(0, lastDotPosition);
};

export const getFilenameFromPath = (path: string): string => {
    const lastSlashPosition = path.lastIndexOf("/");
    return path.substr(lastSlashPosition + 1);
};
