import { Box, Button, ButtonProps, HStack, Icon, Link, StackProps, Text, TextProps } from "@chakra-ui/react";
import { Can } from "app/config/abilities/can";
import React, { PropsWithChildren } from "react";
import { MdOutlineOpenInNew } from "react-icons/md";

interface AlertContentType {
    text: string | JSX.Element;
    textProps?: TextProps;
}

interface CTAButtonProps {
    buttonProps?: ButtonProps;
    buttonText: string;
}

interface LinkButtonProps {
    buttonText: string;
    href: string;
    buttonProps?: ButtonProps;
}

interface AlertCTAButtonType {
    primaryButton?: CTAButtonProps;
    secondaryButton?: LinkButtonProps;
    canPermissionCheck?: boolean;
    additionalCmptRender?: JSX.Element;
}

export interface AlertBannerProps {
    leftIcon?: JSX.Element;
    content: AlertContentType;
    ctaButton?: AlertCTAButtonType;
    containerProps?: StackProps;
    onClose?: () => void;
    secondaryComponent?: JSX.Element | null;
    contentLeftComp?: JSX.Element | null;
    backButtonComp?: JSX.Element | null;
}

const ValidateUserAccountPermission: React.FC<PropsWithChildren> = (props) => {
    const { children } = props;
    return (
        <Can I="update" a="Account" passThrough>
            {(allowed: boolean) => (allowed ? children : null)}
        </Can>
    );
};

const AlertBanner: React.FC<AlertBannerProps> = (props) => {
    const {
        leftIcon,
        content,
        ctaButton,
        containerProps,
        onClose,
        contentLeftComp,
        secondaryComponent,
        backButtonComp,
    } = props;

    const ContentComp = React.useMemo(() => {
        return (
            <Text textAlign="center" fontSize={"xs"} color="gray.600" {...(content?.textProps ?? {})}>
                {content?.text}
            </Text>
        );
    }, [content]);

    const ctaButtonCompWrap = React.useMemo(() => {
        const CTAButtonComp: React.FC = () => (
            <HStack>
                {ctaButton?.primaryButton && (
                    <Button
                        size="xs"
                        colorScheme="brand"
                        _hover={{ bg: "blue.500", color: "white" }}
                        {...(ctaButton?.primaryButton?.buttonProps ?? {})}
                    >
                        {ctaButton?.primaryButton.buttonText}
                    </Button>
                )}
                {ctaButton?.secondaryButton && (
                    <Button
                        as={Link}
                        target="_blank"
                        href={ctaButton.secondaryButton.href}
                        variant="link"
                        textDecor="underline"
                        fontSize="xs"
                        colorScheme="brand"
                        fontWeight="normal"
                        rightIcon={<Icon as={MdOutlineOpenInNew} color="blue.500" />}
                        {...(ctaButton?.secondaryButton?.buttonProps ?? {})}
                    >
                        {ctaButton?.secondaryButton.buttonText}
                    </Button>
                )}
                {ctaButton?.additionalCmptRender}
            </HStack>
        );
        if (ctaButton?.canPermissionCheck) {
            return (
                <ValidateUserAccountPermission>
                    <CTAButtonComp />
                </ValidateUserAccountPermission>
            );
        }
        return <CTAButtonComp />;
    }, [ctaButton]);

    return (
        <HStack w="full" justifyContent={"center"} py={1} maxH="50px" bg="yellow.100" {...containerProps}>
            {backButtonComp}
            <Box flex={1} />
            <HStack flex="auto" justify="center">
                {contentLeftComp}
                {leftIcon}
                {ContentComp}
                {ctaButtonCompWrap}
            </HStack>
            <HStack flex="1" justify="end" pr="3">
                {onClose && (
                    <Button
                        size="xs"
                        colorScheme="yellow"
                        bg="transparent"
                        color="gray.500"
                        fontWeight="normal"
                        _hover={{ bg: "yellow.200" }}
                        px={2}
                        onClick={onClose}
                    >
                        Dismiss
                    </Button>
                )}
                {secondaryComponent}
            </HStack>
        </HStack>
    );
};

export default AlertBanner;
