import { Box, Button, HStack, Image, Link, Skeleton, Spacer, Text, VStack } from "@chakra-ui/react";
import { RootState } from "app/modules";
import SelfServeLayout from "app/screens/Auth/SelfServe/components/SelfServeLayout";
import { useQueryParam } from "app/utils/history";
import GBBotImg from "assets/gb-bot-img.svg";
import SuccessJSON from "assets/lottie/onboarding/success.json";
import MobConvImg from "assets/onboarding/desktop-img.png";
import Lottie from "lottie-react";
import React from "react";
import { useSelector } from "react-redux";

const appStoreURL = "https://apps.apple.com/in/app/gallabox/id1542957177";
const playStoreURL = "https://play.google.com/store/apps/details?id=com.mangoleap.gallabox&utm_source=Landing%20Page";

const MobileAppDownloadView: React.FC = () => {
    const { deviceOS, deviceType } = useSelector((state: RootState) => state.environment);

    const query = useQueryParam();
    const showSuccess = query.get("status") == "success";

    const isDesktop = deviceType == "desktop";
    const showAppStore = isDesktop || deviceOS === "iOS" || deviceOS === "Mac";
    const showPlayStore = isDesktop || !showAppStore;
    const hideBotImage = showSuccess || isDesktop;

    return (
        <SelfServeLayout logout w="full" pos="relative" bgColor="white">
            {hideBotImage ? null : (
                <Image
                    src={GBBotImg}
                    transform="rotate(35deg)"
                    pos="absolute"
                    h="100px"
                    w="50px"
                    left="-3"
                    top="7"
                    fallback={<Skeleton w="full" h="full" />}
                />
            )}
            <VStack align="center" mx="auto" spacing={2} px={5} my={8}>
                {showSuccess && (
                    <HStack>
                        <Box boxSize="8">
                            <Lottie loop={false} animationData={SuccessJSON} />
                        </Box>
                        <Text fontSize={{ md: "2xl" }} color="green.500" fontWeight="medium">
                            Sandbox is successfully created!
                        </Text>
                    </HStack>
                )}
                <Spacer />
                <Image
                    px={5}
                    maxW={{ md: "594px", sm: "90%", base: "full" }}
                    src={MobConvImg}
                    fallback={<Skeleton w="full" h="full" />}
                />
                <Text fontSize={{ base: "sm", sm: "sm", md: "2xl" }} fontWeight="medium" textAlign="center">
                    To get the best Gallabox experience, use either the mobile app or the desktop web browser
                </Text>
                <HStack w="full" justify="center">
                    {showPlayStore && (
                        <Link target="_blank" rel="noreferrer" href={playStoreURL}>
                            <Image
                                w="140px"
                                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            />
                        </Link>
                    )}
                    {showAppStore && (
                        <Link target="_blank" rel="noreferrer" href={appStoreURL}>
                            <Image
                                src="https://assets.gallabox.com/gb-home/appstore-badge.svg"
                                objectFit="fill"
                                w="120px"
                            />
                        </Link>
                    )}
                </HStack>
                <Spacer />
                <Spacer />
                <Spacer />
                {!isDesktop && (
                    <Button
                        w={{ md: "fit-content", base: "full" }}
                        as={Link}
                        px={{ md: 20 }}
                        variant="primary"
                        target="_blank"
                        rel="noreferrer"
                        href={showAppStore ? appStoreURL : playStoreURL}
                    >
                        Install Mobile App
                    </Button>
                )}
            </VStack>
        </SelfServeLayout>
    );
};

export default MobileAppDownloadView;
