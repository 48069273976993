import {
    Grid,
    HStack,
    Icon,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Text,
    VStack,
} from "@chakra-ui/react";
import AnimatedLoadingState from "app/components/GBLoadingState";
import InfoTooltip from "app/components/InfoTooltip";
import Table from "app/components/Tables/SimpleTableV2";
import { useListTransaction, usePostPrePaidExport } from "app/fetchHooks/prepaid-wallet";
import { ListConversation, ListConversationBeforeJun, ListConversationFromJun } from "app/types/prepaid-wallet";
import { formatDateGroup } from "app/utils/date";
import NoConversation from "assets/no-conversation.png";
import dayjs from "dayjs";
import React, { FC, useCallback, useMemo } from "react";
import { AiOutlineCloudDownload, AiOutlineInfoCircle } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { CellProps, Column } from "react-table";
import { formatAmount, MonthValue } from "./TransactionConversationTable";
import CanIReadPII from "app/components/CanIReadPII";

interface ConversationTableProps {
    accountId: string;
    walletId: string;
    currency: string;
    channelId?: string;
    monthValues?: MonthValue;
}

const sum = (prev: number, next: number) => prev + next;

const isBeforeJun = (data?: ListConversation[]): data is ListConversationBeforeJun[] => {
    return (data?.[0] as ListConversationBeforeJun)?.amountBic != null;
};

const isFromJun = (data?: ListConversation[]): data is ListConversationFromJun[] => {
    return (data?.[0] as ListConversationFromJun)?.amountAuthentication != null;
};

const ConversationTable: React.FC<ConversationTableProps> = (props) => {
    const { accountId, walletId, currency, channelId, monthValues } = props;
    const history = useHistory();
    const startDate = monthValues?.from;
    const endDate = monthValues?.to;
    const { mutate: dayWiseExport, isLoading: isExporting } = usePostPrePaidExport({
        accountId,
        walletId,
        onSuccess: () => {
            history.push("/message-credits/export");
        },
    });

    const { data, isLoading, isError, error } = useListTransaction<ListConversation>({
        accountId,
        query: "aggregate",
        type: "debit",
        walletId,
        channelId,
        startDate,
        endDate,
    });

    const handleDayWiseExport = useCallback(
        (value: { startDate: string; endDate: string }) => {
            dayWiseExport({ type: "day", startDate: value.startDate, endDate: value.endDate });
        },
        [dayWiseExport]
    );

    const columns: Column<ListConversation>[] = useMemo(() => {
        const colsBeforeJun: Column<ListConversationBeforeJun>[] = [
            {
                Header: "Total BIC",
                accessor: "bicT",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text title={value as unknown as string} maxW="15ch" noOfLines={1}>
                            {value}
                        </Text>
                    );
                },
            },
            {
                Header: "Total UIC",
                accessor: "uicT",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text title={value as unknown as string} maxW="15ch" noOfLines={1}>
                            {value}
                        </Text>
                    );
                },
            },
            {
                Header: "Free tier",
                id: "freeTier",
                // eslint-disable-next-line react/display-name
                accessor: (originalValue) => {
                    const freeBicT = originalValue.freeBicT;
                    const freeUicT = originalValue.freeUicT;
                    return (
                        <Text maxW="15ch" noOfLines={1}>
                            {freeBicT + freeUicT}
                        </Text>
                    );
                },
            },
            {
                Header: "Free entry point",
                accessor: "freeEntryPointT",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text title={value as unknown as string} maxW="15ch" isTruncated>
                            {value}
                        </Text>
                    );
                },
            },
            {
                Header: "Billable BIC",
                id: "billable bic",
                // eslint-disable-next-line react/display-name
                accessor: (originalValue) => {
                    const totalBic = originalValue.bicT;
                    const freeBicT = originalValue.freeBicT;
                    return (
                        <Text maxW="15ch" noOfLines={1}>
                            {totalBic - freeBicT}
                        </Text>
                    );
                },
            },
            {
                Header: "Billable UIC",
                id: "billable uic",
                // eslint-disable-next-line react/display-name
                accessor: (originalValue) => {
                    const totalUic = originalValue.uicT;
                    const freeUicT = originalValue.freeUicT;
                    return (
                        <Text maxW="15ch" noOfLines={1}>
                            {totalUic - freeUicT}
                        </Text>
                    );
                },
            },
            {
                Header: "amount",
                accessor: "amount",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text maxW="15ch" noOfLines={1}>
                            {currency} {formatAmount(value)}
                        </Text>
                    );
                },
            },
        ];
        const colsFromJun: Column<ListConversationFromJun>[] = [
            {
                Header: "Marketing",
                accessor: "marketingT",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text title={value as unknown as string} maxW="15ch" noOfLines={1}>
                            {value}
                        </Text>
                    );
                },
            },
            {
                Header: "Utility",
                accessor: "utilityT",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text title={value as unknown as string} maxW="15ch" noOfLines={1}>
                            {value}
                        </Text>
                    );
                },
            },
            {
                Header: "Authentication",
                accessor: "authenticationT",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text title={value as unknown as string} maxW="15ch" noOfLines={1}>
                            {value}
                        </Text>
                    );
                },
            },
            {
                Header: "Service",
                accessor: "serviceT",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text title={value as unknown as string} maxW="15ch" noOfLines={1}>
                            {value}
                        </Text>
                    );
                },
            },
            {
                Header: "Free Tier",
                accessor: "freeUicT",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text title={value as unknown as string} maxW="15ch" isTruncated>
                            {value}
                        </Text>
                    );
                },
            },
            {
                Header: "Free entry point",
                accessor: "freeEntryPointT",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    return (
                        <Text title={value as unknown as string} maxW="15ch" isTruncated>
                            {value}
                        </Text>
                    );
                },
            },
            {
                Header: "Total amount",
                accessor: "amount",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }) => {
                    const displayValue = `${currency} ${formatAmount(value)}`;
                    return (
                        <Text title={displayValue} maxW="15ch" noOfLines={1}>
                            {displayValue}
                        </Text>
                    );
                },
            },
        ];
        return [
            {
                Header: "Date",
                accessor: "_id",
                // eslint-disable-next-line react/display-name
                Cell: ({ value }: CellProps<ListConversationFromJun>) => {
                    const { day, month, year } = value;
                    const date = [month, day, year].join("-");
                    return (
                        <Text title={date} maxW="15ch" noOfLines={1}>
                            {formatDateGroup(date)}
                        </Text>
                    );
                },
            },
            ...(isBeforeJun(data) ? colsBeforeJun : colsFromJun),
            {
                id: "actions",
                // eslint-disable-next-line react/display-name
                Cell: ({ row }: CellProps<ListConversation>) => {
                    const { day, month, year } = row.original._id;

                    const date = [year, month, day].join("-");
                    const startDate = dayjs(date).toISOString();
                    const endDate = dayjs(date).add(1, "day").subtract(1, "second").toISOString();

                    return (
                        <>
                            <CanIReadPII>
                                <Menu isLazy>
                                    <MenuButton
                                        minWidth="30px"
                                        as={IconButton}
                                        data-cy={`prepaid-credits-menu-button-${row.index}`}
                                    >
                                        <Icon as={BsThreeDotsVertical} />
                                    </MenuButton>
                                    <MenuList p={2}>
                                        <MenuItem
                                            fontSize="14px"
                                            color="gray.500"
                                            borderRadius="6px"
                                            iconSpacing="1.5"
                                            boxShadow="sm"
                                            icon={<AiOutlineCloudDownload size="20" />}
                                            isDisabled={isExporting}
                                            onClick={() => handleDayWiseExport({ startDate, endDate })}
                                        >
                                            Export
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </CanIReadPII>
                        </>
                    );
                },
            },
        ] as Column<ListConversation>[];
    }, [currency, data, handleDayWiseExport, isExporting]);

    const stats = useMemo(() => {
        if (!data || data.length < 1) return null;
        if (isBeforeJun(data)) return <StatsBeforeJun currency={currency} data={data} />;
        return <StatsAfterJun currency={currency} data={data} />;
    }, [currency, data]);

    if (isLoading) {
        return (
            <Grid placeItems="center" bg="white" w="full" h="full" maxH="full" maxW="full">
                <AnimatedLoadingState spinnerSize={40} title="Loading..." />
            </Grid>
        );
    }

    if (isError || !data) {
        return (
            <VStack w="full" height="full" justify="center" align="center">
                <Text color="red.500">{error?.message || "Unable to load data at the moment!"}</Text>
            </VStack>
        );
    }

    if (data.length < 1) {
        return (
            <Grid py={10} placeItems="center">
                <Image src={NoConversation} />
                <Text fontSize="18px" fontWeight="semibold" color="gray.900">
                    No Conversations started
                </Text>
            </Grid>
        );
    }

    return (
        <>
            {stats}
            <HStack w="full" align="flex-start">
                <HStack spacing={1} flexShrink={0}>
                    <Icon w={6} as={AiOutlineInfoCircle} color="gray.500" />
                    <Text fontSize="13px" color="gray.500">
                        Track the number of WhatsApp conversations per day.
                    </Text>
                </HStack>
                <Text isTruncated color="red.400" fontSize="xs" fontWeight="normal">
                    * All insights data is approximate and may differ from what&apos;s shown on your invoices due to
                    small variations in data processing
                </Text>
            </HStack>
            <Table<ListConversation> data={data} columns={columns} isStickyHeader />
        </>
    );
};

export default ConversationTable;

interface StatsProps {
    data: ListConversation[];
    currency: string;
}
const StatsBeforeJun: FC<StatsProps> = (props) => {
    const { data, currency } = props;
    if (!isBeforeJun(data)) return null;

    const uict = data.map((v) => v.uicT).reduce(sum, 0);
    const bict = data.map((v) => v.bicT).reduce(sum, 0);
    const freeUict = data.map((v) => v.freeUicT).reduce(sum, 0);
    const freeBict = data.map((v) => v.freeBicT).reduce(sum, 0);
    const freeEntryPointt = data.map((v) => v.freeEntryPointT).reduce(sum, 0);
    const amount = data.map((v) => v.amount).reduce(sum, 0);
    const amountBic = data.map((v) => v.amountBic).reduce(sum, 0);
    const amountUic = data.map((v) => v.amountUic).reduce(sum, 0);

    const totalConversation = uict + bict + freeEntryPointt;
    const freeConversation = freeBict + freeUict + freeEntryPointt;
    const billableConversation = totalConversation - freeConversation;
    const billableUict = uict - freeUict;
    const billableBict = bict - freeBict;

    const charges = `${currency} ${formatAmount(amount)}`;
    const bictCharges = `${currency} ${formatAmount(amountBic)}`;
    const uictCharges = `${currency} ${formatAmount(amountUic)}`;

    return (
        <SimpleGrid w="full" minChildWidth="200px" spacing={5}>
            <ConversationDetailsCard
                header="All Conversations"
                headerValues={totalConversation}
                lineItems={[
                    {
                        title: "Business-initiated (BIC)",
                        value: bict,
                        info: "Conversations initiated by the business (Authentication, Marketing, Utility)",
                    },
                    {
                        title: "User-initiated (UIC)",
                        value: uict,
                        info: "Conversations initiated by users and responded by the business (Service)",
                    },
                    {
                        title: "Free entry point",
                        value: freeEntryPointt,
                        info: "Conversation from Facebook & Instagram Ads with Whatsapp CTA",
                    },
                ]}
            />
            <ConversationDetailsCard
                header="Free Conversations"
                headerValues={freeConversation}
                lineItems={[
                    { title: "Business-initiated (BIC)", value: freeBict },
                    { title: "User-initiated (UIC)", value: freeUict },
                    { title: "Free entry point", value: freeEntryPointt },
                ]}
            />
            <ConversationDetailsCard
                header="Billable Conversations"
                headerValues={billableConversation}
                lineItems={[
                    { title: "Business-initiated (BIC)", value: billableBict },
                    { title: "User-initiated (UIC)", value: billableUict },
                ]}
            />
            <ConversationDetailsCard
                header="Charges"
                headerValues={charges}
                lineItems={[
                    { title: "Business-initiated (BIC)", value: bictCharges },
                    { title: "User-initiated (UIC)", value: uictCharges },
                ]}
            />
        </SimpleGrid>
    );
};
const StatsAfterJun: FC<StatsProps> = (props) => {
    const { data, currency } = props;
    if (!isFromJun(data)) return null;

    const {
        marketingTotal,
        utilityTotal,
        authenticationTotal,
        serviceTotal,
        freeTierTotal,
        freeEntryPointTotal,
        billableMarketingAmount,
        billableUtilityAmount,
        billableAuthenticationAmount,
        billableServiceAmount,
    } = data.reduce(
        (acc, curr) => {
            acc.marketingTotal += curr.marketingT;
            acc.utilityTotal += curr.utilityT;
            acc.authenticationTotal += curr.authenticationT;
            acc.serviceTotal += curr.serviceT;
            acc.freeTierTotal += curr.freeUicT;
            acc.freeEntryPointTotal += curr.freeEntryPointT;

            acc.billableMarketingAmount += curr.amountMarketing;
            acc.billableUtilityAmount += curr.amountUtility;
            acc.billableAuthenticationAmount += curr.amountAuthentication;
            acc.billableServiceAmount += curr.amountService;
            return acc;
        },
        {
            marketingTotal: 0,
            utilityTotal: 0,
            authenticationTotal: 0,
            serviceTotal: 0,
            freeTierTotal: 0,
            freeEntryPointTotal: 0,

            billableMarketingAmount: 0,
            billableUtilityAmount: 0,
            billableAuthenticationAmount: 0,
            billableServiceAmount: 0,
        }
    );

    const { billableMarketing, billableUtility, billableAuthentication, billableService } = {
        billableMarketing: marketingTotal,
        billableUtility: utilityTotal,
        billableAuthentication: authenticationTotal,
        billableService: serviceTotal - freeTierTotal,
    };

    const totalCharges = `${currency} ${formatAmount(
        billableMarketingAmount + billableUtilityAmount + billableAuthenticationAmount + billableServiceAmount
    )}`;

    return (
        <SimpleGrid w="full" minChildWidth="200px" spacing={5}>
            <ConversationDetailsCard
                header="All Conversations"
                headerValues={marketingTotal + utilityTotal + authenticationTotal + serviceTotal}
                lineItems={[
                    {
                        title: "Marketing",
                        value: marketingTotal,
                        info: "Marketing Conversations initiated by the business",
                    },
                    {
                        title: "Utility",
                        value: utilityTotal,
                        info: "Utility Conversations initiated by the business",
                    },
                    {
                        title: "Authentication",
                        value: authenticationTotal,
                        info: "Authentication Conversations initiated by the business",
                    },
                    {
                        title: "Service",
                        value: serviceTotal,
                        info: "Service initiated by users and responded by the business",
                    },
                ]}
            />
            <ConversationDetailsCard
                header="Free Conversations"
                headerValues={freeTierTotal + freeEntryPointTotal}
                lineItems={[
                    { title: "Free tier", value: freeTierTotal },
                    { title: "Free entry point", value: freeEntryPointTotal },
                ]}
            />
            <ConversationDetailsCard
                header="Billable Conversations"
                headerValues={billableMarketing + billableUtility + billableAuthentication + billableService}
                lineItems={[
                    { title: "Marketing", value: billableMarketing },
                    { title: "Utility", value: billableUtility },
                    { title: "Authentication", value: billableAuthentication },
                    { title: "Service", value: billableService },
                ]}
            />
            <ConversationDetailsCard
                header="Charges"
                headerValues={totalCharges}
                lineItems={[
                    { title: "Marketing", value: `${currency} ${formatAmount(billableMarketingAmount)}` },
                    { title: "Utility", value: `${currency} ${formatAmount(billableUtilityAmount)}` },
                    { title: "Authentication", value: `${currency} ${formatAmount(billableAuthenticationAmount)}` },
                    { title: "Service", value: `${currency} ${formatAmount(billableServiceAmount)}` },
                ]}
            />
        </SimpleGrid>
    );
};

interface ConversationDetailsCardProps {
    header: string;
    headerValues: number | string;
    lineItems: { title: string; value: string | number; info?: string }[];
}
export const ConversationDetailsCard: React.FC<ConversationDetailsCardProps> = (props) => {
    const { header, headerValues, lineItems } = props;
    return (
        <VStack border="1px solid" borderColor="gray.200" p={2} bg="gray.50" borderRadius="sm">
            <HStack justifyContent="space-between" w="full" fontWeight="extrabold" fontSize="13px" color="gray.700">
                <Text>{header}</Text>
                <Text fontSize="12px">{headerValues}</Text>
            </HStack>
            {lineItems.map((item) => {
                return (
                    <HStack
                        key={`${header}-${item.title}`}
                        justifyContent="space-between"
                        w="full"
                        fontSize="13px"
                        fontWeight="normal"
                        color="gray.600"
                    >
                        <HStack spacing={1}>
                            <Text fontSize="13px" fontWeight="normal">
                                {item.title}
                            </Text>
                            {item.info != null ? <InfoTooltip text={item.info} infoIconProps={{ boxSize: 3 }} /> : null}
                        </HStack>
                        <Text fontSize="12px">{item.value}</Text>
                    </HStack>
                );
            })}
        </VStack>
    );
};
