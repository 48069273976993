import { useCheckPermissions } from "app/fetchHooks/catalog-v2";
import { useAccount, useAccountId, useWaChannels } from "app/utils/react-helpers";
import dayjs from "dayjs";
import React from "react";
import { useCheckAbility } from "./useCheckAbility";

const isOFC = (countryISO: string) => {
    //India, Saudi Arabia, United Arab Emirates, Oman ,Yemen, Kuwait, Qatar, Bahrain
    const countriesNotIncludedInOFC = ["IND", "SAU", "ARE", "OMN", "YEM", "KWT", "QAT", "BHR"];
    return !countriesNotIncludedInOFC.includes(countryISO);
};

interface AccountMasterHookProps {
    isSuspended: boolean;
    isSandboxChannel: boolean;
    isNewAccount: boolean;
    isFreeTrial: boolean;
    hasOpenInvoice: boolean;
    needActivation: boolean;
    isManagedByPartner: boolean;
    isSubscribed: boolean;
    sandboxExpireDate?: Date;
    freeTrialExpireDate?: Date;
    isSandboxExpired: boolean;
    isFreeTrialExpired: boolean;
    isOFC: boolean;
    sandboxLifeSpan?: number;
    // isSubscriptionPriceChange: boolean;
}
export const useAccountMasterHook = (): AccountMasterHookProps => {
    const account = useAccount();
    const waChannels = useWaChannels();
    const { canIUpdateAccount: isAdmin } = useCheckAbility();

    const isSuspended = Boolean(account?.status && account?.status !== "active");
    const isSandboxChannel = account?.onboardingState === "sandbox";
    const isNewAccount = account?.onboardingState === "new";
    const isFreeTrial = account?.onboardingState === "trial";
    const isSubscribed = account?.onboardingState === "subscribed";

    const sandboxExpireDate = account?.onboarding?.sandboxDetails?.expiresAt;
    const sandboxLifeSpan = account?.onboarding?.sandboxDetails?.lifeSpan;
    const isSandboxExpired = isSandboxChannel && dayjs(sandboxExpireDate).diff(dayjs(), "seconds") <= 0;

    const freeTrialExpireDate = account?.onboarding?.trialDetails?.expiresAt;
    const isFreeTrialExpired = isFreeTrial && dayjs(freeTrialExpireDate).diff(dayjs(), "seconds") <= 0;

    const hasOpenInvoice = React.useMemo(() => {
        if (!account?.invoice) return false;
        const { status, createdDate } = account.invoice;
        const alertThreshold = isAdmin ? 0 : 5;
        const shouldAlert = dayjs().diff(dayjs(createdDate).tz("UTC"), "day") > alertThreshold;
        return status === "open" && shouldAlert;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account?.invoice, isAdmin]);

    const liveChannels = waChannels?.filter((channel) => channel?.whatsapp?.mode === "live");
    const needActivation = Boolean(account?.onboardingState === "subscribed" && liveChannels && !liveChannels.length);

    // const isSubscriptionPriceChange = React.useMemo(() => {
    //     return (
    //         account?.currency?.iso !== "INR" && account?.currency?.iso !== "IND" && (isFreeTrial || isSandboxChannel)
    //     );
    // }, [account?.currency?.iso, isFreeTrial, isSandboxChannel]);

    return {
        isSuspended,
        isSandboxChannel,
        isNewAccount,
        isFreeTrial,
        hasOpenInvoice,
        needActivation,
        isManagedByPartner: account?.managedBy != null,
        isSubscribed,
        sandboxExpireDate,
        freeTrialExpireDate,
        isSandboxExpired,
        isFreeTrialExpired,
        isOFC: account?.countryIso2 ? isOFC(account.countryIso2?.iso) : false,
        sandboxLifeSpan,
        // isSubscriptionPriceChange,
    };
};

export const useCheckIsCatalogConnected = (
    enabled: boolean = true
): {
    isCatalogConnected: boolean;
    isLoading: boolean;
} => {
    const accountId = useAccountId();
    const { data, isLoading, isError } = useCheckPermissions({ accountId, enabled });

    if (isError || !data) return { isCatalogConnected: false, isLoading };
    const { ungrantedPermissions, ungrantedResources = [] } = data;
    if (ungrantedPermissions.length > 0 || ungrantedResources.length > 0)
        return { isCatalogConnected: false, isLoading };

    return { isCatalogConnected: true, isLoading };
};
