import { AllNameSpacesAndTypes, I18NextNameSpaceType } from "lang/types/common";
import React from "react";
import { Trans, TransProps } from "react-i18next";

interface GBTransProps<T extends I18NextNameSpaceType | "translation", U extends AllNameSpacesAndTypes[T]>
    extends Omit<TransProps<U>, "i18nKey" | "ns"> {
    i18nKey: U;
    ns: T | T[];
}
const GBTrans = <T extends I18NextNameSpaceType | "translation", U extends AllNameSpacesAndTypes[T]>(
    props: GBTransProps<T, U>
) => {
    return <Trans {...props}>{props.children}</Trans>;
};

export default GBTrans;
