import { Checkbox, Table as ChakraTable, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { PropsWithChildren, ReactElement, useEffect } from "react";
import { TableOptions, useTable } from "react-table";

const stickyHeaderStyle = {
    pos: "sticky",
    top: 0,
    zIndex: 1,
};

interface TableProps<D extends Record<keyof D, unknown>> extends TableOptions<D> {
    onSelect?: (data: D) => void;
    small?: boolean;
    isStickyHeader?: boolean;
    enableCheckBox?: boolean;
    emptyComponent?: JSX.Element;
    onSelection?: (selected: string[]) => void;
    unCheckAll?: boolean;
}

type PartialData<D> = Partial<Record<keyof D, unknown>> & { id?: string };

const Table = <D extends PartialData<D>>(props: PropsWithChildren<TableProps<D>>): ReactElement | null => {
    const {
        onSelect,
        isStickyHeader,
        small = false,
        emptyComponent = null,
        enableCheckBox = false,
        onSelection,
        unCheckAll = false,
        ...tableProps
    } = props;

    const [checkBoxDetails, setCheckBoxDetails] = React.useState<string[]>([]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(tableProps);

    const handleRowSelect = React.useCallback(
        (d: D): React.MouseEventHandler<HTMLTableRowElement> => (e) => {
            if (onSelect) {
                e.preventDefault();
                onSelect(d);
            }
        },
        [onSelect]
    );

    useEffect(() => {
        if (unCheckAll) setCheckBoxDetails([]);
    }, [unCheckAll]);
    useEffect(() => {
        onSelection?.(checkBoxDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkBoxDetails]);
    return (
        <>
            <ChakraTable variant="simple" {...getTableProps()} rounded="md">
                <Thead sx={isStickyHeader ? { ...stickyHeaderStyle } : {}}>
                    {headerGroups.map((headerGroup) => {
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <Tr
                                {...headerGroup.getHeaderGroupProps()}
                                bg="brand.50"
                                roundedTopRight="md"
                                roundedTopLeft="md"
                            >
                                {enableCheckBox ? (
                                    <Th>
                                        <Checkbox
                                            isChecked={
                                                checkBoxDetails.length === rows.map((vendor) => vendor.id).length &&
                                                Boolean(rows.length)
                                            }
                                            onChange={() => {
                                                if (!tableProps) return;
                                                const selected = tableProps.data.map((r) => r.id);
                                                if (checkBoxDetails.length === selected.length) {
                                                    setCheckBoxDetails([]);
                                                } else {
                                                    setCheckBoxDetails(selected as string[]);
                                                }
                                            }}
                                        ></Checkbox>
                                    </Th>
                                ) : null}
                                {headerGroup.headers.map((column, cI) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <Th
                                        {...column.getHeaderProps()}
                                        paddingInlineStart={small && cI === 0 ? 4 : small ? 2 : 4}
                                        paddingInlineEnd={small ? 2 : 4}
                                        py={small ? 3 : 4}
                                        fontSize={small ? "xs" : "sm"}
                                        fontWeight="medium"
                                        color="gray.800"
                                        letterSpacing="normal"
                                    >
                                        {column.render("Header")}
                                    </Th>
                                ))}
                            </Tr>
                        );
                    })}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {rows.map((row, idx) => {
                        prepareRow(row);
                        const id = tableProps?.data?.[idx]?.id ?? "";
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <Tr
                                {...row.getRowProps()}
                                fontSize="sm"
                                onClick={handleRowSelect(row.original)}
                                cursor={!!onSelect ? "pointer" : "initial"}
                                _hover={{ bg: !!onSelect ? "gray.50" : "initial" }}
                            >
                                {enableCheckBox ? (
                                    <Td width="10px" onClick={(e) => e.stopPropagation()}>
                                        <Checkbox
                                            isChecked={checkBoxDetails.includes(id)}
                                            onChange={(event) => {
                                                event.stopPropagation();
                                                const index = checkBoxDetails.indexOf(id);

                                                if (index > -1) {
                                                    setCheckBoxDetails([
                                                        ...checkBoxDetails.slice(0, index),
                                                        ...checkBoxDetails.slice(index + 1),
                                                    ]);
                                                } else {
                                                    setCheckBoxDetails([...checkBoxDetails, id]);
                                                }
                                            }}
                                        ></Checkbox>
                                    </Td>
                                ) : null}
                                {row.cells.map((cell, cI) => {
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <Td
                                            {...cell.getCellProps()}
                                            paddingInlineStart={small && cI === 0 ? 4 : small ? 2 : 4}
                                            paddingInlineEnd={small ? 2 : 4}
                                            py={small ? 2 : 4}
                                            fontSize={small ? "xs" : "sm"}
                                            color="gray.800"
                                        >
                                            {cell.render("Cell")}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </ChakraTable>
            {!rows.length ? emptyComponent : null}
        </>
    );
};

export default Table;
