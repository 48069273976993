import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

export interface PhoneEmailTextProps extends Omit<TextProps, "onClick"> {
    value: string;
    onClick?: (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => void;
}

const PhoneEmailText: React.FC<PhoneEmailTextProps> = (props) => {
    const { onClick, children, ...textProps } = props;

    return (
        <Text onClick={onClick} fontSize="2xs" {...textProps}>
            {children}
        </Text>
    );
};

export default PhoneEmailText;
