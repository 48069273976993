import { ReactSelectRef } from "app/components/CustomizedReactSelect/type";
import ReactSelectStyled, { ReactSelectStyledProps } from "app/components/CustomizedReactSelect/ReactSelectStyled";
import { CountryAndCurrency, CountryAndCurrencyType } from "app/utils/common";
import React from "react";
import cloneDeep from "lodash/cloneDeep";

export type CurrencySelectProps = Exclude<ReactSelectStyledProps<CountryAndCurrencyType>, "options">;

const CurrencySelect: React.FC = React.forwardRef(
    (props: CurrencySelectProps, ref: ReactSelectRef<CountryAndCurrencyType>) => {
        return <ReactSelectStyled options={cloneDeep(CountryAndCurrency)} {...props} ref={ref} />;
    }
);

CurrencySelect.displayName = "CurrencySelect";

export default CurrencySelect as (
    props: CurrencySelectProps & { ref?: ReactSelectRef<CountryAndCurrencyType> }
) => JSX.Element;
