import { User as IUser } from "./user";
import { File as IFile } from "./file";
import { LabelValue } from "./common";

export enum ScopeOptions {
    COMPANY = "COMPANY",
    CONTACT = "CONTACT",
}

export enum FieldTypeEnum {
    TEXT = "TEXT",
    TEXT_AREA = "TEXT_AREA",
    MULTI_TEXT = "MULTI_TEXT",
    NUMBER = "NUMBER",
    SELECT = "SELECT",
    MULTI_SELECT = "MULTI_SELECT",
    DATE = "DATE",
    USER = "USER",
    FILE = "FILE",
    SWITCH = "SWITCH",
    URL = "URL",
    EMAIL = "EMAIL",
    PHONE = "PHONE",
}

export type ITextField = string;
export type INumberField = number;
export type ISelectField = string;
export type IMultiSelectValue = string[];
export type IDateField = string;
export type IUserField = Pick<IUser, "id" | "name" | "email" | "picture"> | string;
export type ISwitchField = boolean;
export type IUrlField = string;
export type IEmailField = string;
export type IPhoneField = string;
export type IFileField = IFile;

export type IFieldType = {
    type: FieldTypeEnum;
    label: string;
    options?: string[] | LabelValue[];
    placeholder?: string;
    hint?: string;
    regex?: string;
};

export type FieldValueType =
    | ITextField
    | INumberField
    | ISelectField
    | IMultiSelectValue
    | IDateField
    | IUserField
    | IFileField
    | ISwitchField
    | IUrlField
    | IEmailField
    | IPhoneField;

export type IFieldValue = {
    id?: string;
    fieldId: string;
    field?: IField;
    type: FieldTypeEnum;
    value?: FieldValueType;
    valueString?: ITextField;
    valueNumber?: INumberField;
    valueArray?: IMultiSelectValue;
    valueUser?: IUserField;
    valueBoolean?: ISwitchField;
    valueDate?: IDateField;
    valueFile?: IFileField;
};
export interface FieldCollection {
    isActive: boolean;
    scope: ScopeOptions;
    fieldName: string;
    config: IFieldType;
    createdAt: string;
    id: string;
}
export type IField = {
    id: string;
    fieldCollectionId?: string;
    scope?: ScopeOptions;
    name: string;
    accountId: string;
    config: IFieldType;
    isPrimary: boolean;
    isInternal?: boolean;
    isMandatory?: boolean;
    isActive?: boolean;
    fieldValue?: IFieldValue;
    isDelete?: boolean;
    sortOrder?: number;
};
export type IFieldWithLabelValue = IField & LabelValue;
