import { useToast } from "@chakra-ui/react";
import config from "app/app.config";
import {
    CreateAutoReAssign,
    CreateAutoReply,
    CreateAutoResolveChat,
    CreateAutoUpdateMsgOptIn,
    CreateAwayMessage,
    CreateFailedMessage,
    CreateReEngagementMessage,
    CreateWelcomeMessage,
    CreateWorkingTime,
} from "app/screens/Channels/Whatsapp/configurations/configurationTypes";
import { BotCompact } from "app/types/bot";
import { Channel, ChannelType, CompactUserTeam, Configurations, WAChannelUpdatableFields } from "app/types/channel";
import { QueryKey, TQueryKey } from "app/types/common";
import { IUserCompact, User, UserCompact } from "app/types/user";
import { deleteJSON, fetcher, mapQueryParams, patchJSON, postJSON, putJSON } from "app/utils/fetchUtils";
import {
    MutateFunction,
    MutationFunction,
    MutationOptions,
    QueryFunction,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from "react-query";

const PAGE_SIZE = 10;

interface ChannelsListProps<TData> extends UseQueryOptions<TData[], Error, TData[], TQueryKey> {
    accountId?: string;
    channelType?: ChannelType;
    channelId?: string;
    search?: string;
    showSuccessMessage?: boolean;
    onSuccess?: () => void;
}
type UseListChannelResult<InputType> = UseMutationResult<Channel[], Error, InputType>;

export const useChannelList = <InputType = void>(
    props: ChannelsListProps<Channel>
): UseListChannelResult<InputType> => {
    const { accountId, channelId, channelType, search, onSuccess } = props;
    const queryClient = useQueryClient();
    const toast = useToast();
    const queryKey: string | unknown[] = [QueryKey.Channel, { accountId, channelId }];

    const fetchChannelList: MutateFunction<Channel[], Error, InputType> = () => {
        const queryParams = [];
        if (channelId) {
            queryParams.push(`channelId=${channelId}`);
        }
        if (search) {
            queryParams.push(`name=${search}`);
        }
        if (channelType) {
            queryParams.push(`channelType=${channelType}`);
        }
        return fetcher(`/api/accounts/${accountId}/channels?${queryParams.join("&")}`);
    };

    const mutationResult = useMutation<Channel[], Error, InputType>(fetchChannelList, {
        onSuccess: () => {
            onSuccess?.();
        },
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

export const useChannelsList = (props: ChannelsListProps<Channel>): UseQueryResult<Channel[], Error> => {
    const { accountId, channelType, ...options } = props;

    const fetchChannels: QueryFunction<Channel[], TQueryKey> = ({ queryKey }) => {
        const [, { accountId, channelType }] = queryKey;

        return fetcher(`/api/accounts/${accountId}/channels${channelType ? `?channelType=${channelType}` : ""}`);
    };

    const queryResult = useQuery<Channel[], Error, Channel[], TQueryKey>(
        [QueryKey.ChannelsList, { accountId, ...(channelType ? { channelType } : {}) }],
        fetchChannels,
        { ...options, enabled: Boolean(accountId) }
    );

    return queryResult;
};

interface UseChannelGetProps<TData> extends UseQueryOptions<TData[], Error, TData[], TQueryKey> {
    accountId: string;
    channelId: string;
}

export const useGetChannel = (props: UseChannelGetProps<Channel>): UseQueryResult<Channel, Error> => {
    const { accountId, channelId } = props;

    const fetchChannels: QueryFunction<Channel, TQueryKey> = ({ queryKey }) => {
        const [, { accountId, channelId }] = queryKey;
        return fetcher(`/api/accounts/${accountId}/channels/${channelId}`);
    };
    const toast = useToast();
    return useQuery<Channel, Error, Channel, TQueryKey>([QueryKey.Channel, { accountId, channelId }], fetchChannels, {
        enabled: Boolean(channelId) && Boolean(accountId),
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error?.message,
            });
        },
    });
};

interface UseChannelConfigGetProps<TData> extends UseQueryOptions<TData[], Error, TData[], TQueryKey> {
    accountId: string;
    channelId: string;
}

export const useGetChannelConfiguration = (
    props: UseChannelConfigGetProps<Configurations>
): UseQueryResult<Configurations, Error> => {
    const { accountId, channelId } = props;

    const fetchChannels: QueryFunction<Configurations, TQueryKey> = ({ queryKey }) => {
        const [, { accountId, channelId }] = queryKey;
        return fetcher(`/api/accounts/${accountId}/channels/${channelId}/configurations`);
    };
    return useQuery<Configurations, Error, Configurations, TQueryKey>(
        [QueryKey.ChannelConfiguration, { accountId, channelId }],
        fetchChannels
    );
};

interface ChannelUsersListProps<TData> extends UseInfiniteQueryOptions<TData[], Error, TData[], TData[], TQueryKey> {
    accountId: string;
    channelId?: string | null;
    search?: string | null;
}

export const useChannelUsersList = (
    props: ChannelUsersListProps<UserCompact>
): UseInfiniteQueryResult<UserCompact[], Error> => {
    const { accountId, channelId, search, ...options } = props;

    const fetchUsers: QueryFunction<UserCompact[], TQueryKey> = ({ pageParam, queryKey }) => {
        const [, { accountId, channelId, search }] = queryKey;

        const queryParams = [`limit=${50}`, `page=${pageParam ?? 1}`];
        if (channelId) {
            queryParams.push(`channelId=${channelId}`);
        }
        if (search) {
            queryParams.push(`name=${search}`);
        }

        return fetcher(`/api/account/${accountId}/users?${queryParams.join("&")}`);
    };

    const infiniteQueryResult = useInfiniteQuery<UserCompact[], Error, UserCompact[], TQueryKey>(
        [QueryKey.ChannelUsersList, { accountId, channelId, search }],
        fetchUsers,
        {
            enabled: Boolean(search) || search === "",
            ...options,
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === PAGE_SIZE ? pages.length + 1 : undefined;
            },
        }
    );

    return infiniteQueryResult;
};

interface ChannelQueryContext {
    previousChannel?: Channel;
}

type UseUpdateChannelResult<InputType> = UseMutationResult<
    WAChannelUpdatableFields,
    Error,
    WAChannelUpdatableFields,
    ChannelQueryContext
>;

interface UseUpdateChannelProps {
    accountId: string;
    channelId?: string;
    onSuccess?: (channel?: Channel) => void;
    showSuccessMessage?: boolean;
}

export const useUpdateChannel = <InputType = WAChannelUpdatableFields>(
    props: UseUpdateChannelProps
): UseUpdateChannelResult<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Channel, { accountId, channelId }];

    const updateChannel: MutationFunction<Channel, WAChannelUpdatableFields> = (channelDetails) => {
        return patchJSON(`/api/accounts/${accountId}/channels/${channelId ?? channelDetails.id}`, channelDetails);
    };

    const mutationResult = useMutation<Channel, Error, WAChannelUpdatableFields, ChannelQueryContext>(updateChannel, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedChannel, context) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: err.message,
            });
            if (context?.previousChannel) {
                queryClient.setQueryData<Channel>(queryKey, context.previousChannel);
            }
        },
        onSuccess: (channelUpdated) => {
            if (showSuccessMessage) {
                toast({
                    title: "Data Updated!",
                    status: "success",
                    description: "Channel updated successfully",
                });
            }
            onSuccess?.(channelUpdated);
        },
        // When mutate is called:
        onMutate: async (updatedChannel) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const previousChannel = queryClient.getQueryData<Channel>(queryKey);

            if (!previousChannel) return {};

            const updated = { ...previousChannel, ...updatedChannel };

            queryClient.setQueryData<WAChannelUpdatableFields>(queryKey, updated);

            // Optionally return a context containing data to use when for example rolling back
            return { previousChannel };
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

export const useRemoveDefaultBot = <InputType = WAChannelUpdatableFields>(
    props: UseUpdateChannelProps
): UseUpdateChannelResult<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Channel, { accountId, channelId }];

    const updateChannel = () => {
        return deleteJSON<WAChannelUpdatableFields>(`/api/accounts/${accountId}/channels/${channelId}/defaultBot`);
    };

    const mutationResult = useMutation<WAChannelUpdatableFields, Error, WAChannelUpdatableFields, ChannelQueryContext>(
        updateChannel,
        {
            // If the mutation fails, use the context returned from onMutate to roll back
            onError: (err) => {
                toast({ title: "Something went wrong!", status: "error", description: err.message });
            },
            onSuccess: () => {
                if (showSuccessMessage) {
                    toast({
                        title: "Default Bot Removed!",
                        status: "success",
                        description: "Default bot removed successfully!",
                    });
                }
                onSuccess?.();
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(queryKey);
            },
        }
    );

    return mutationResult;
};

// WELCOME MESSAGE
type UseWelcomeMessage<InputType> = UseMutationResult<CreateWelcomeMessage, Error, InputType>;
interface UseWelcomeMessageProps {
    accountId: string;
    channelId?: string;
    onSuccess?: (ApiKey: CreateWelcomeMessage) => void;
    showSuccessMessage?: boolean;
}

export const usePostWelcomeMessage = <InputType extends Partial<CreateWelcomeMessage>>(
    props: UseWelcomeMessageProps
): UseWelcomeMessage<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ChannelConfiguration, { accountId, channelId }];

    const createWelcomeMessage = (welcomeMessage: InputType) => {
        return postJSON<InputType>(
            `api/accounts/${accountId}/channels/${channelId}/configurations/addOrUpdateWelcomeMessage`,
            welcomeMessage
        );
    };

    const mutationResult = useMutation<CreateWelcomeMessage, Error, InputType>(createWelcomeMessage, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (WelcomeMessage) => {
            if (showSuccessMessage) {
                toast({
                    title: "Welcome message configured",
                    status: "success",
                    description: "Welcome Message configured successfully!",
                });
            }
            onSuccess?.(WelcomeMessage);
            queryClient.setQueryData(queryKey, WelcomeMessage);
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

//AWAY MESSAGE
type UseAwayMessage<InputType> = UseMutationResult<CreateAwayMessage, Error, InputType>;
interface UseAwayMessageProps {
    accountId: string;
    channelId?: string;
    onSuccess?: (ApiKey: CreateAwayMessage) => void;
    showSuccessMessage?: boolean;
}

export const usePostAwayMessage = <InputType extends Partial<CreateAwayMessage>>(
    props: UseAwayMessageProps
): UseAwayMessage<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.AwayMessage, { accountId, channelId }];

    const createAwayMessage = (awayMessage: InputType) => {
        return postJSON<InputType>(
            `api/accounts/${accountId}/channels/${channelId}/configurations/addOrUpdateAwayMessage`,
            awayMessage
        );
    };

    const mutationResult = useMutation<CreateAwayMessage, Error, InputType>(createAwayMessage, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (AwayMessage) => {
            if (showSuccessMessage) {
                toast({
                    title: "Away message configured",
                    status: "success",
                    description: "Away Message configured successfully!",
                });
            }
            onSuccess?.(AwayMessage);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

//AUTO REPLY
type UseAutoReply<InputType> = UseMutationResult<CreateAutoReply, Error, InputType>;
interface UseAutoReplyProps {
    accountId: string;
    channelId?: string;
    onSuccess?: (ApiKey: CreateAutoReply) => void;
    showSuccessMessage?: boolean;
}

export const usePostAutoReply = <InputType extends Partial<CreateAutoReply>>(
    props: UseAutoReplyProps
): UseAutoReply<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ChannelConfiguration, { accountId, channelId }];

    const CreateAutoReply = (autoReply: InputType) => {
        return postJSON<InputType>(
            `api/accounts/${accountId}/channels/${channelId}/configurations/addOrUpdateAutoReply`,
            autoReply
        );
    };

    const mutationResult = useMutation<CreateAutoReply, Error, InputType>(CreateAutoReply, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (AutoReply) => {
            if (showSuccessMessage) {
                toast({
                    title: "Auto Reply configured",
                    status: "success",
                    description: "Auto Reply configured successfully!",
                });
            }
            onSuccess?.(AutoReply);
            queryClient.setQueryData(queryKey, AutoReply);
        },
    });
    return mutationResult;
};

//RE-ENGAGEMENT MESSAGE
type UseReEngagementMessage<InputType> = UseMutationResult<CreateReEngagementMessage, Error, InputType>;
interface UseReEngagementProps {
    accountId: string;
    channelId?: string;
    onSuccess?: (ApiKey: CreateReEngagementMessage) => void;
    showSuccessMessage?: boolean;
}

export const usePostReEngagementMessage = <InputType extends Partial<CreateReEngagementMessage>>(
    props: UseReEngagementProps
): UseReEngagementMessage<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ChannelConfiguration, { accountId, channelId }];

    const CreateReEngagementMessage = (reEngagementMessage: InputType) => {
        return postJSON<InputType>(
            `api/accounts/${accountId}/channels/${channelId}/configurations/addOrUpdateReEngagement`,
            reEngagementMessage
        );
    };

    const mutationResult = useMutation<CreateReEngagementMessage, Error, InputType>(CreateReEngagementMessage, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (ReEngagementMessage) => {
            if (showSuccessMessage) {
                toast({
                    title: "Re-Engagement message configured",
                    status: "success",
                    description: "Re-Engagement message configured successfully!",
                });
            }
            onSuccess?.(ReEngagementMessage);
            queryClient.setQueryData(queryKey, ReEngagementMessage);
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

//WORKING TIME
type UseWorkingTime<InputType> = UseMutationResult<CreateWorkingTime, Error, InputType>;
interface UseWorkingTmeProps {
    accountId: string;
    channelId?: string;
    onSuccess?: (ApiKey: CreateWorkingTime) => void;
    showSuccessMessage?: boolean;
}

export const usePostWorkingTime = <InputType extends Partial<CreateWorkingTime>>(
    props: UseWorkingTmeProps
): UseWorkingTime<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ChannelConfiguration, { accountId, channelId }];

    const CreateWorkingTime = (workingTime: InputType) => {
        return postJSON<InputType>(
            `api/accounts/${accountId}/channels/${channelId}/configurations/addOrUpdateAwayMessage`,
            workingTime
        );
    };

    const mutationResult = useMutation<CreateWorkingTime, Error, InputType>(CreateWorkingTime, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (WorkingTime) => {
            if (showSuccessMessage) {
                toast({
                    title: "Working time  configured",
                    status: "success",
                    description: "Working time configured successfully!",
                });
            }
            onSuccess?.(WorkingTime);
            queryClient.setQueryData(queryKey, WorkingTime);
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

//AUTO RE-ASSIGN
type UseAutoReAssign<InputType> = UseMutationResult<CreateAutoReAssign, Error, InputType>;
interface UseAutoReAssignProps {
    accountId: string;
    channelId?: string;
    onSuccess?: (ApiKey: CreateAutoReAssign) => void;
    showSuccessMessage?: boolean;
}

export const usePostAutoReAssign = <InputType extends Partial<CreateAutoReAssign>>(
    props: UseAutoReAssignProps
): UseAutoReAssign<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.AutoReAssign, { accountId, channelId }];

    const CreateAutoReAssign = (autoReAssign: InputType) => {
        return postJSON<InputType>(
            `api/accounts/${accountId}/channels/${channelId}/configurations/addOrUpdateAutoReassign`,
            autoReAssign
        );
    };

    const mutationResult = useMutation<CreateAutoReAssign, Error, InputType>(CreateAutoReAssign, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (AutoReAssign) => {
            if (showSuccessMessage) {
                toast({
                    title: "Auto re-assign  configured",
                    status: "success",
                    description: "Auto re-assign configured successfully!",
                });
            }
            onSuccess?.(AutoReAssign);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

//FAILED MESSAGE
type UseFailedMessage<InputType> = UseMutationResult<CreateFailedMessage, Error, InputType>;
interface UseFailedMessageProps {
    accountId: string;
    channelId?: string;
    onSuccess?: (ApiKey: CreateFailedMessage) => void;
    showSuccessMessage?: boolean;
}

export const usePostFailedMessage = <InputType extends Partial<CreateFailedMessage>>(
    props: UseFailedMessageProps
): UseFailedMessage<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ChannelConfiguration, { accountId, channelId }];

    const CreateFailedMessage = (failedMessage: InputType) => {
        return postJSON<InputType>(
            `api/accounts/${accountId}/channels/${channelId}/configurations/addOrUpdateFailedMessageEmail`,
            failedMessage
        );
    };

    const mutationResult = useMutation<CreateFailedMessage, Error, InputType>(CreateFailedMessage, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (FailedMessage) => {
            if (showSuccessMessage) {
                toast({
                    title: "Failed message configured",
                    status: "success",
                    description: "Failed message configured successfully!",
                });
            }
            onSuccess?.(FailedMessage);
            queryClient.setQueryData(queryKey, FailedMessage);
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

//Toggle
type UseCCToggleMessage<InputType> = UseMutationResult<Configurations, Error, InputType>;
interface UseCCToggleProps {
    accountId: string;
    channelId: string;
    url: string;
    queryCode: string;
    onSuccess?: (configurations: Configurations) => void;
    showSuccessMessage?: boolean;
    toastTitle: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCCToggle = <InputType extends Record<string, any>>(
    props: UseCCToggleProps
): UseCCToggleMessage<InputType> => {
    const { url, onSuccess, showSuccessMessage = true, toastTitle } = props;

    const toast = useToast();

    const changeStatus = (toggle: InputType) => {
        return postJSON<InputType>(url, toggle);
    };

    const mutationResult = useMutation<Configurations, Error, InputType>(changeStatus, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: error.message || "Something went wrong!",
                status: "error",
            });
        },
        onSuccess: (Toggle) => {
            if (showSuccessMessage) {
                toast({
                    title: toastTitle,
                    status: "success",
                    description: "Status changed successfully!",
                });
            }
            onSuccess?.(Toggle);
        },
    });
    return mutationResult;
};

//AUTO RESOLVE CHAT
type UseAutoResolveChat<InputType> = UseMutationResult<CreateAutoResolveChat, Error, InputType>;
interface UseAutoResolveChatProps {
    accountId: string;
    channelId?: string;
    onSuccess?: (ApiKey: CreateAutoResolveChat) => void;
    showSuccessMessage?: boolean;
}

export const usePostAutoResolveChat = <InputType extends Partial<CreateAutoResolveChat>>(
    props: UseAutoResolveChatProps
): UseAutoResolveChat<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ChannelConfiguration, { accountId, channelId }];

    const CreateAutoResolveChat = (autoResolveChat: InputType) => {
        return postJSON<InputType>(
            `api/accounts/${accountId}/channels/${channelId}/configurations/addOrUpdateAutoResolve`,
            autoResolveChat
        );
    };

    const mutationResult = useMutation<CreateAutoResolveChat, Error, InputType>(CreateAutoResolveChat, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (AutoResolveChat) => {
            if (showSuccessMessage) {
                toast({
                    title: "Auto resolve chat configured",
                    status: "success",
                    description: "Auto resolve chat configured successfully!",
                });
            }
            onSuccess?.(AutoResolveChat);
            queryClient.setQueryData(queryKey, AutoResolveChat);
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

//---
//AUTO UPDATE MESSAGE OPTIN
type UseAutoUpdateMsgOptIn<InputType> = UseMutationResult<CreateAutoUpdateMsgOptIn, Error, InputType>;
interface UseAutoUpdateMsgOptInProps {
    accountId: string;
    channelId?: string;
    onSuccess?: (ApiKey: CreateAutoUpdateMsgOptIn) => void;
    showSuccessMessage?: boolean;
}

export const usePostAutoUpdateMsgOptIn = <InputType extends Partial<CreateAutoUpdateMsgOptIn>>(
    props: UseAutoUpdateMsgOptInProps
): UseAutoUpdateMsgOptIn<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ChannelConfiguration, { accountId, channelId }];

    const CreateAutoUpdateMsgOptIn = (autoUpdateMessageOptIn: InputType) => {
        return postJSON<InputType>(
            `api/accounts/${accountId}/channels/${channelId}/configurations/addOrUpdatemessageOptin`,
            autoUpdateMessageOptIn
        );
    };

    const mutationResult = useMutation<CreateAutoUpdateMsgOptIn, Error, InputType>(CreateAutoUpdateMsgOptIn, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (AutoUpdateMsgOptIn) => {
            if (showSuccessMessage) {
                toast({
                    title: "Auto update Marketing OptIn configured",
                    status: "success",
                    description: "Auto update Marketing OptIn configured successfully!",
                });
            }
            onSuccess?.(AutoUpdateMsgOptIn);
            queryClient.setQueryData(queryKey, AutoUpdateMsgOptIn);
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

type CreateChannelInput = Partial<Channel>;
type UseCreateWhatsappChannelResult = UseMutationResult<Channel, Error, CreateChannelInput, unknown>;
export interface UseCreateWhatsappChannelProps {
    accountId: string;
    showSuccessMessage?: boolean;
    onSuccess?: (channel: Channel) => void;
}

export const useCreateWhatsappChannel = (props: UseCreateWhatsappChannelProps): UseCreateWhatsappChannelResult => {
    const { accountId, showSuccessMessage = true, onSuccess } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ChannelsList, { accountId, channelType: "whatsapp" }];

    const createChannel: MutationFunction<Channel, CreateChannelInput> = (data) => {
        const url = `/api/accounts/${accountId}/channels`;
        return postJSON<CreateChannelInput>(url, data);
    };

    const mutationResult = useMutation<Channel, Error, Partial<Channel>>(createChannel, {
        onError: (error) => {
            toast({ title: error.message ?? "Something went wrong!", status: "error" });
        },
        onSuccess: (response) => {
            if (showSuccessMessage) {
                toast({ title: "Channel Created!", status: "success" });
            }
            onSuccess?.(response);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface UsePartnerSignupProps {
    accountId: string;
}

interface PartnerSignupResponse {
    url: string;
}

export const usePartnerSignup = (
    props: UsePartnerSignupProps
): UseMutationResult<PartnerSignupResponse, Error, void> => {
    const { accountId } = props;
    const partnerSignup: MutationFunction<PartnerSignupResponse, void> = () => {
        return postJSON(`/api/accounts/${accountId}/channels/partner-signup`, {});
    };
    return useMutation<PartnerSignupResponse, Error, void>(partnerSignup);
};

interface DeleteSandboxChannelInput {
    channelId: string;
}
interface DeleteSandboxChannelResponse {
    isDisabled: boolean;
}
type DeleteSandboxChannelResult = UseMutationResult<
    DeleteSandboxChannelResponse,
    Error,
    DeleteSandboxChannelInput,
    unknown
>;
export interface DeleteSandboxChannelProps {
    accountId: string;
    showSuccessMessage?: boolean;
    onSuccess?: (response: DeleteSandboxChannelResponse) => void;
}

export const useDeleteSandboxChannel = (props: DeleteSandboxChannelProps): DeleteSandboxChannelResult => {
    const { accountId, showSuccessMessage = true, onSuccess } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ChannelsList, { accountId, channelType: "whatsapp" }];

    const deleteSandboxChannel: MutationFunction<DeleteSandboxChannelResponse, DeleteSandboxChannelInput> = (data) => {
        const url = `/api/accounts/${accountId}/channels/sandbox/${data.channelId}/disable`;
        return deleteJSON(url);
    };

    const mutationResult = useMutation<DeleteSandboxChannelResponse, Error, DeleteSandboxChannelInput>(
        deleteSandboxChannel,
        {
            onError: (error) => {
                toast({ title: error.message ?? "Something went wrong!", status: "error" });
            },
            onSuccess: (response) => {
                if (showSuccessMessage) {
                    toast({ title: "Sandbox Channel Deleted!", status: "success" });
                }
                onSuccess?.(response);
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(queryKey);
            },
        }
    );

    return mutationResult;
};

//add or update Assignment rules

interface AssignmentRulesProps {
    accountId: string;
    channelId: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess?: (data: any) => void;
    showSuccessMessage?: boolean;
}

export interface AssignmentResponse {
    isActive?: boolean;
    shouldAutoReassign?: boolean;
    shouldAssignToContactOwner?: boolean;
    shouldAssignToContactOwnerIfOnlyActive?: boolean;
    type?: string;
    assignmentId?: string;
    updatedAt?: string;
    createdAt?: string;
    bot?: BotCompact;
    user?: IUserCompact;
    team?: CompactUserTeam;
}

type UsePostRules<InputType> = UseMutationResult<Configurations, Error, InputType>;

export const useAddAssignmentRules = <InputType extends Partial<AssignmentResponse>>(
    props: AssignmentRulesProps
): UsePostRules<InputType> => {
    const { accountId, channelId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.AddAssignmentRules, { accountId, channelId }];

    const addAssignmentRules = (rules: InputType) => {
        return postJSON<InputType>(
            `api/accounts/${accountId}/channels/${channelId}/configurations/addOrUpdateAssignmentRules`,
            rules
        );
    };

    const mutationResult = useMutation<Configurations, Error, InputType>(addAssignmentRules, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (res) => {
            if (showSuccessMessage) {
                toast({
                    title: "Assignment Rules Configured",
                    status: "success",
                    description: "Assignment Rules Configured successfully!",
                });
            }
            onSuccess?.(res);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

export interface ChannelWAWidget {
    id?: string | undefined;
    channelId: string;
    accountId: string;
    businessName: string;
    availabilityTag: string;
    logoUrl: string;
    welcomeText: string;
    question?: string;
    options?: string[];
    widgetPosition: string;
    waId: string;
}

export type ChannelWAWidgetUpsertPayload = Omit<ChannelWAWidget, "channelId" | "accountId">;
export type ChannelWAWidgetUpsertResponse = ChannelWAWidget;
export interface ChannelWAWidgetProps {
    channelId: string | undefined;
    accountId: string;
    onSuccess?: (response: ChannelWAWidgetUpsertResponse) => void;
    onError?: (error: Error) => void;
}

export type ChannelWAWidgetUpsertResult = UseMutationResult<
    ChannelWAWidget,
    Error,
    ChannelWAWidgetUpsertPayload,
    unknown
>;

export const useUpsertChannelWAWidget = (params: ChannelWAWidgetProps): ChannelWAWidgetUpsertResult => {
    const { accountId, channelId, onSuccess } = params;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.GetChannelWAWidget, { accountId, channelId }];

    const upsertChannelWAWidget: MutationFunction<ChannelWAWidgetUpsertResponse, ChannelWAWidgetUpsertPayload> = (
        widgetDetails
    ) => {
        return putJSON(`/api/accounts/${accountId}/channels/${channelId}/whatsapp-widget`, widgetDetails);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<ChannelWAWidgetUpsertResponse, Error, ChannelWAWidgetUpsertPayload>(
        upsertChannelWAWidget,
        {
            // If the mutation fails, use the context returned from onMutate to roll back
            onError: (err) => {
                toast({
                    title: "Error",
                    status: "error",
                    description: err.message ?? "Error while creating or updating the channel whatsapp widget",
                });
            },
            onMutate: async (updatedContact) => {
                // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
                await queryClient.cancelQueries(queryKey);

                // Snapshot the previous value
                const previousWAWidget = queryClient.getQueryData<ChannelWAWidget>(queryKey);

                if (!previousWAWidget) return {};

                const updated = { ...previousWAWidget, ...updatedContact };

                queryClient.setQueryData<ChannelWAWidget & ChannelWAWidget>(queryKey, updated);

                // Optionally return a context containing data to use when for example rolling back
                return { previousWAWidget };
            },
            onSuccess: (res) => {
                onSuccess?.(res);
            },
            onSettled: () => {
                queryClient.invalidateQueries(queryKey);
            },
        }
    );

    return mutationResult;
};

const fetchChannelWAWidget: QueryFunction<ChannelWAWidget, TQueryKey> = ({ queryKey }) => {
    const [, { accountId, channelId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/channels/${channelId}/whatsapp-widget`);
};

export const useChannelWAWidget = (props: ChannelWAWidgetProps): UseQueryResult<ChannelWAWidget, Error> => {
    const { accountId, channelId } = props;

    const queryResult = useQuery<ChannelWAWidget, Error, ChannelWAWidget, TQueryKey>(
        [QueryKey.GetChannelWAWidget, { accountId, channelId }],
        fetchChannelWAWidget
    );

    return queryResult;
};

export type SendChannelWAWidgetEmailPayload = { widgetCode: string; recipientEmail: string };
export type SendChannelWAWidgetEmailResponse = { status: string };
export interface SendChannelWAWidgetEmailProps {
    channelId: string | undefined;
    accountId: string;
    onSuccess?: (response: SendChannelWAWidgetEmailResponse) => void;
    onError?: (error: Error) => void;
}

export type SendChannelWAWidgetEmailResult = UseMutationResult<
    SendChannelWAWidgetEmailResponse,
    Error,
    SendChannelWAWidgetEmailPayload,
    unknown
>;

export const useSendChannelWAWidgetEmail = (params: SendChannelWAWidgetEmailProps): SendChannelWAWidgetEmailResult => {
    const { accountId, channelId, onSuccess } = params;

    const toast = useToast();

    const sendWAWidgetEmail: MutationFunction<SendChannelWAWidgetEmailResponse, SendChannelWAWidgetEmailPayload> = (
        widgetDetails
    ) => {
        return postJSON(`/api/accounts/${accountId}/channels/${channelId}/whatsapp-widget/sendmail`, widgetDetails);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<SendChannelWAWidgetEmailResponse, Error, SendChannelWAWidgetEmailPayload>(
        sendWAWidgetEmail,
        {
            // If the mutation fails, use the context returned from onMutate to roll back
            onError: (err) => {
                toast({
                    title: "Error",
                    status: "error",
                    description: err.message ?? "Error while sending channel whatsapp widget email",
                });
            },
            onSuccess: (res) => {
                onSuccess?.(res);
            },
        }
    );

    return mutationResult;
};

type UseSyncChannelResult = UseMutationResult<Channel, Error, void>;
type UseSyncChannelOptions = UseMutationOptions<Channel, Error, void>;

interface SyncChannelProps extends UseSyncChannelOptions {
    accountId: string;
    channelId: string;
    onSuccess?: () => void;
}

export const useSyncChannel = (props: SyncChannelProps): UseSyncChannelResult => {
    const { accountId, channelId, onSuccess, ...options } = props;
    const toast = useToast();
    const syncTemplates = () => {
        return postJSON(`/api/accounts/${accountId}/channels/${channelId}/sync`, {});
    };

    const mutationResult = useMutation<Channel, Error, void>(syncTemplates, {
        ...options,
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error?.message,
            });
        },
        onSuccess: () => {
            toast({
                title: "Channel Synced!",
                status: "success",
                description: "Channel synced successfully!",
            });
            onSuccess?.();
        },
    });

    return mutationResult;
};

//Broadcast Limit

interface BroadCastLimitInput {
    limit: number;
}

type UpdateBrodCastLimit = UseMutationResult<Channel, Error, BroadCastLimitInput>;
type UpdateBrodCastLimitMutationOptions = UseMutationOptions<Channel, Error, BroadCastLimitInput>;
interface UseUpdateBroadCastLimitProps extends UpdateBrodCastLimitMutationOptions {
    accountId: string;
    channelId: string;
}

export const useUpdateBroadCastLimit = (props: UseUpdateBroadCastLimitProps): UpdateBrodCastLimit => {
    const { accountId, channelId, ...options } = props;

    const toast = useToast();

    const queryClient = useQueryClient();
    const queryKey = [QueryKey.Channel, { accountId, channelId }];

    const updateBroadLimit: MutationFunction<Channel, BroadCastLimitInput> = (data) => {
        return patchJSON(`/api/accounts/${accountId}/channels/${channelId}/broadcastLimit`, data);
    };

    const mutationResult = useMutation<Channel, Error, BroadCastLimitInput>(updateBroadLimit, {
        ...options,
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
        },

        onSuccess: (data) => {
            toast({
                title: "Updated",
                status: "success",
                description: "BroadCast Limit updated successfully!",
            });
            queryClient.setQueryData<Channel>(queryKey, data);
        },
    });
    return mutationResult;
};

export type CreateChannelShareableLinkPayload = {
    whatsappNumber: string;
    message: string;
    linkExtension: string;
    isRemoveCharactersLimit?: boolean;
};
export type CreateChannelShareableLinkResponse = { dataURL: string; uid: string; link: string; message: string };

export interface CreateChannelShareableLinkProps {
    onSuccess?: (response: CreateChannelShareableLinkResponse) => void;
}

export type CreateChannelShareableLinkResult = UseMutationResult<
    CreateChannelShareableLinkResponse,
    Error,
    CreateChannelShareableLinkPayload,
    unknown
>;
export const useCreateChannelShareableLink = (
    params: CreateChannelShareableLinkProps
): CreateChannelShareableLinkResult => {
    const { onSuccess } = params;
    const toast = useToast();
    const hostUrl = config.branding.links.wa_me_pro;

    const createShareableLink: MutationFunction<
        CreateChannelShareableLinkResponse,
        CreateChannelShareableLinkPayload
    > = (linkDetails) => {
        const { isRemoveCharactersLimit = false, ...restParams } = linkDetails;
        let removeCharactersLimit = {};
        if (isRemoveCharactersLimit) {
            removeCharactersLimit = { context: { source: "gallabox-internal" } };
        }
        return postJSON(`${hostUrl}/api/shorturl/uploadShortUrl`, {
            ...restParams,
            ...removeCharactersLimit,
        });
    };

    const mutationResult = useMutation<CreateChannelShareableLinkResponse, Error, CreateChannelShareableLinkPayload>(
        createShareableLink,
        {
            onError: (err) => {
                toast({
                    title: "Error",
                    status: "error",
                    description: err.message ?? "Error while creating sharable link",
                });
            },
            onSuccess: (res) => {
                onSuccess?.(res);
            },
        }
    );
    return mutationResult;
};

interface ChannelDeleteResponse {
    isDeleted: boolean;
    pendingItemsForDelete?: string[];
    status: number;
}

export interface UseDeleteChannelProps extends MutationOptions<ChannelDeleteResponse, Error, string> {
    accountId: string;
    showSuccessMessage?: boolean;
    onSuccess: (res: ChannelDeleteResponse) => void;
    onError: () => void;
}

interface channelDeletePayload {
    channelId: string;
    deletedReason: string;
}

export const useDeleteChannel = (
    props: UseDeleteChannelProps
): UseMutationResult<ChannelDeleteResponse, Error, channelDeletePayload> => {
    const { accountId, showSuccessMessage, ...mutationOptions } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const deleteChannel: MutationFunction<ChannelDeleteResponse, channelDeletePayload> = ({
        channelId,
        deletedReason,
    }) => {
        return deleteJSON(`/api/accounts/${accountId}/channels/${channelId}?deletedReason=${deletedReason}`);
    };
    return useMutation<ChannelDeleteResponse, Error, channelDeletePayload>(deleteChannel, {
        onError: (err) => {
            toast({ title: "Error deleting the Channel", status: "error", description: err.message });
            mutationOptions.onError();
        },
        onSuccess: (res) => {
            if (res.isDeleted) {
                // To show loading for few seconds added timeout
                setTimeout(async () => {
                    const channelListQueryKey = [QueryKey.ChannelsList, { accountId }];
                    await queryClient.invalidateQueries(channelListQueryKey);
                    if (showSuccessMessage) toast({ title: "Channel deleted!", status: "success" });
                    mutationOptions.onSuccess?.(res);
                }, 3000);
            } else {
                mutationOptions.onSuccess?.(res);
            }
        },
    });
};
interface MigrateDialog360ToDialog360CloudApiResult {
    channel: Channel;
    webhookSubscribeResponse: string;
}

interface MigrateDialog360ToDialog360CloudApiProps {
    accountId: string;
    onSuccess?: (response: MigrateDialog360ToDialog360CloudApiResult) => void;
}

export interface UseMigrateDialog360ToDialog360CloudApi {
    channelId: string;
    migrateChannelProviderTo: "dialog360CloudApi";
}

export type UseMigrateDialog360ToDialog360CloudApiResult = UseMutationResult<
    MigrateDialog360ToDialog360CloudApiResult,
    Error,
    UseMigrateDialog360ToDialog360CloudApi,
    unknown
>;

export const useMigrateDialog360ToDialog360CloudApi = (
    props: MigrateDialog360ToDialog360CloudApiProps
): UseMigrateDialog360ToDialog360CloudApiResult => {
    const { accountId, onSuccess } = props;
    const toast = useToast();

    const migrateProvider: MutationFunction<
        MigrateDialog360ToDialog360CloudApiResult,
        UseMigrateDialog360ToDialog360CloudApi
    > = ({ channelId, migrateChannelProviderTo }) => {
        return patchJSON(`/api/accounts/${accountId}/channels/${channelId}/whatsapp/provider-migration`, {
            migrateChannelProviderTo,
        });
    };

    const mutationResult = useMutation<
        MigrateDialog360ToDialog360CloudApiResult,
        Error,
        UseMigrateDialog360ToDialog360CloudApi,
        unknown
    >(migrateProvider, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while migrating channel provider",
            });
        },
        onSuccess,
    });

    return mutationResult;
};

interface GetSubscribedWebhookProps {
    accountId: string;
    channelId: string;
}

interface GetSubscribedWebhookResult {
    url: string;
}

const fetchSubscribedWebhook: QueryFunction<GetSubscribedWebhookResult, TQueryKey> = ({ queryKey }) => {
    const [, { accountId, channelId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/channels/${channelId}/whatsapp/subscribed-webhook`);
};

export const useGetSubscribedWebhook = (
    props: GetSubscribedWebhookProps
): UseQueryResult<GetSubscribedWebhookResult, Error> => {
    const { accountId, channelId } = props;
    return useQuery<GetSubscribedWebhookResult, Error, GetSubscribedWebhookResult, TQueryKey>(
        [QueryKey.GetSubscribedWebhook, { accountId, channelId }],
        fetchSubscribedWebhook
    );
};

interface SubscribeWebhookResult {
    url: string;
}

interface SubscribeWebhookProps {
    accountId: string;
    onSuccess?: (response: SubscribeWebhookResult) => void;
}

export interface UseSubscribeWebhook {
    channelId: string;
}

export type UseSubscribeWebhookResult = UseMutationResult<SubscribeWebhookResult, Error, UseSubscribeWebhook, unknown>;

export const useSubscribeWebhook = (props: SubscribeWebhookProps): UseSubscribeWebhookResult => {
    const { accountId, onSuccess } = props;
    const toast = useToast();

    const subscribeWebhook: MutationFunction<SubscribeWebhookResult, UseSubscribeWebhook> = ({ channelId }) => {
        return postJSON(`/api/accounts/${accountId}/channels/${channelId}/whatsapp/subscribe-webhook`, {});
    };

    const mutationResult = useMutation<SubscribeWebhookResult, Error, UseSubscribeWebhook, unknown>(subscribeWebhook, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while subscribing webhook for channel",
            });
        },
        onSuccess,
    });

    return mutationResult;
};
interface AddUserToChannelProps {
    accountId: string;
    channelId: string;
    onSuccess?: (user: User) => void;
    onError?: (err: Error) => void;
}

type UseAddUserToChannelProps = AddUserToChannelProps & UseMutationOptions<User, Error, string>;
type AddUserToChannelResult = UseMutationResult<User, Error, string>;

export const useAddUserToChannel = (props: UseAddUserToChannelProps): AddUserToChannelResult => {
    const { accountId, channelId, onSuccess, onError, ...options } = props;
    const toast = useToast();

    const addUser = (userId: string) => {
        return postJSON(`/api/accounts/${accountId}/channels/${channelId}/users/${userId}`, {});
    };

    const mutation = useMutation<User, Error, string>(addUser, {
        onSuccess: async (data) => {
            toast({
                status: "success",
                title: "User Added",
                description: "User added successfully",
            });

            onSuccess?.(data);
        },
        onError: (err: Error) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message ?? "Error while adding user",
            });
            onError?.(err);
        },
        ...options,
    });
    return mutation;
};

type UseRemoveUserFromChannelProps = UseAddUserToChannelProps;
type RemoveUserFromChannelResult = AddUserToChannelResult;

export const useRemoveUserFromChannel = (props: UseRemoveUserFromChannelProps): RemoveUserFromChannelResult => {
    const { accountId, channelId, onSuccess, onError, ...options } = props;
    const toast = useToast();

    const removeUser = (userId: string) => {
        return deleteJSON<User>(`/api/accounts/${accountId}/channels/${channelId}/users/${userId}`);
    };

    const mutation = useMutation<User, Error, string>(removeUser, {
        onSuccess: async (data) => {
            toast({
                status: "success",
                title: "User Removed",
                description: "User removed successfully",
            });

            onSuccess?.(data);
        },
        onError: (err: Error) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message ?? "Error while removing user",
            });
            onError?.(err);
        },
        ...options,
    });
    return mutation;
};

export interface GetChannelUsersResult {
    count: number;
    data: User[];
}
interface GetChannelUsersProps {
    accountId: string;
    channelId: string;
    page: number;
    limit?: number;
}
type UseGetChannelUsersProps = GetChannelUsersProps &
    UseQueryOptions<GetChannelUsersResult, Error, GetChannelUsersResult, TQueryKey>;
type UseGetChannelUsersResult = UseQueryResult<GetChannelUsersResult, Error>;

export const useGetChannelUsers = (props: UseGetChannelUsersProps): UseGetChannelUsersResult => {
    const { accountId, channelId, page, limit, ...options } = props;
    const queryKey: TQueryKey = [QueryKey.GetChannelUsers, { accountId, channelId, page, limit }];

    const toast = useToast();

    const queryResult = useQuery<GetChannelUsersResult, Error, GetChannelUsersResult, TQueryKey>(
        queryKey,
        ({ queryKey }) => {
            const [, { accountId, channelId, page, limit }] = queryKey;
            const queryParams = mapQueryParams({
                limit: limit ?? 20,
                channelId,
                getCount: true,
                page: page ?? 1,
            });
            return fetcher(`/api/account/${accountId}/users?${queryParams}`);
        },
        {
            ...options,
            refetchInterval: 120000,
            cacheTime: 1000 * 60 * 10,
            staleTime: 1000 * 60 * 10,
            enabled: Boolean(accountId) && Boolean(channelId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
            keepPreviousData: true,
        }
    );
    return queryResult;
};

interface GetWaCommerceSettingsProps {
    accountId: string;
    channelId: string;
    isEnable: boolean;
}

interface GetWACommerceSettingsResult {
    is_cart_enabled: boolean;
    is_catalog_visible: boolean;
}

const fetchWACommerceSettings: QueryFunction<GetWACommerceSettingsResult, TQueryKey> = ({ queryKey }) => {
    const [, { accountId, channelId }] = queryKey;
    return fetcher(`/api/account/${accountId}/whatsappProfile/${channelId}/commerce/settings`);
};

export const useGetWACommerceSettings = (
    props: GetWaCommerceSettingsProps
): UseQueryResult<GetWACommerceSettingsResult, Error> => {
    const { accountId, channelId, isEnable } = props;
    return useQuery<GetWACommerceSettingsResult, Error, GetWACommerceSettingsResult, TQueryKey>(
        [QueryKey.CommerceSettings, { accountId, channelId }],
        fetchWACommerceSettings,
        { enabled: isEnable }
    );
};

interface PatchWACommerceSettingsProps {
    accountId: string;
    channelId: string;
}

export interface UsePatchWACommerceSettings {
    is_cart_enabled: boolean;
    is_catalog_visible: boolean;
}

export type UsePatchWACommerceSettingsResult = UseMutationResult<unknown, Error, UsePatchWACommerceSettings, unknown>;

export const usePatchWACommerceSettings = (props: PatchWACommerceSettingsProps): UsePatchWACommerceSettingsResult => {
    const { accountId, channelId } = props;
    const toast = useToast();

    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.CommerceSettings, { accountId, channelId }];

    const patchWACommerceSettings: MutationFunction<unknown, UsePatchWACommerceSettings> = (data) => {
        return patchJSON(`/api/account/${accountId}/whatsappProfile/${channelId}/commerce/settings`, data);
    };

    const mutationResult = useMutation<unknown, Error, UsePatchWACommerceSettings, unknown>(patchWACommerceSettings, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Something went wrong",
            });
        },
        onSuccess: () => {
            toast({
                title: "Data Updated!",
                status: "success",
                description: "Channel updated successfully",
            });
        },
        onMutate: async (commerceData) => {
            await queryClient.cancelQueries(queryKey);

            const previousCommerceData = queryClient.getQueryData<UsePatchWACommerceSettings>(queryKey);

            if (!previousCommerceData) return {};

            const updated = { ...previousCommerceData, ...commerceData };

            queryClient.setQueryData(queryKey, updated);

            return { previousCommerceData };
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

export interface ListPhoneForWABA {
    phone_numbers: {
        data: {
            verified_name: string;
            code_verification_status: string;
            display_phone_number: string;
            quality_rating: string;
            platform_type: "CLOUD_API";
            throughput: {
                level: string;
            };
            last_onboarded_time: string;
            id: string; // phone no id
        }[];
    };
    business_verification_status: string;
    message_template_namespace: string;
    account_review_status: string;
    id: string; // waba_id
}

interface UseGetMetaChannelProps {
    accountId: string;
}
interface GetMetaProps {
    channelId: string;
}

type GetDialog360IndividualChannelProps = UseGetMetaChannelProps &
    UseMutationOptions<ListPhoneForWABA, Error, GetMetaProps>;

export const fetchMetaChannel: QueryFunction<ListPhoneForWABA, TQueryKey> = ({ queryKey }) => {
    const [, { accountId, channelId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/channels/${channelId}/whatsapp/meta`);
};

export const useGetMetaChannel = (props: GetDialog360IndividualChannelProps) => {
    const { accountId } = props;

    const fetchMetaChannel = ({ channelId }: GetMetaProps) => {
        return fetcher(`/api/accounts/${accountId}/channels/${channelId}/whatsapp/meta`);
    };

    const queryResult = useMutation<ListPhoneForWABA, Error, GetMetaProps>(fetchMetaChannel, props);
    return queryResult;
};
