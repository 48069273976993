import { HStack, Icon, Image, Skeleton } from "@chakra-ui/react";
import config from "app/app.config";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { isMacOS } from "app/utils/common";
import React from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
interface NavigationHeaderProps {
    isCollapsed: boolean;
    onToggle: () => void;
}
const NavigationHeader: React.FC<NavigationHeaderProps> = (props) => {
    const { isCollapsed, onToggle } = props;

    const onNavChange = React.useCallback(() => {
        trackEvent({
            event: isCollapsed ? "LNAV_OPEN" : "LNAV_COLLAPSE",
        });
        onToggle();
    }, [isCollapsed, onToggle]);

    return (
        <HStack w="full" bg="white" spacing={2} alignItems="center" p={4}>
            <Image w="full" boxSize={6} src={config.branding.product_logos.icon} fallback={<Skeleton boxSize={6} />} />
            {!isCollapsed && (
                <Image
                    src={config.branding.product_logos.text}
                    w="85"
                    h="25px"
                    fallback={<Skeleton w="85px" h="25px" mt="1" />}
                />
            )}

            <Icon
                as={isCollapsed ? RiArrowRightSLine : RiArrowLeftSLine}
                pos="absolute"
                boxSize={5}
                color="brand.500"
                transition="left 0.2s"
                left={isCollapsed ? "40px" : "180px"}
                bg="gray.100"
                boxShadow="base"
                _hover={{ bg: "brand.500", color: "white" }}
                onClick={onNavChange}
                cursor="pointer"
                rounded="sm"
                title={isMacOS ? "⌘ + \\" : "CTRL + \\"}
                data-cy="navigation-shrink-expand-btn"
            />
        </HStack>
    );
};

export default React.memo(NavigationHeader);
