import { useContext, useEffect, useMemo, useRef } from "react";
import { SocketIOContext } from "./context";

export enum SocketEvents {
    Exception = "exception",
    ReadMessage = "readMessage",
    Connect = "connect",
    Reconnect = "reconnect",
    Disconnect = "disconnect",
    Visit = "visit",
    Visitor = "visitor",
    Message = "message",
    RetryMessage = "retryMessage",
    TriggerBot = "triggerBot",
    AddContact = "addContact",
    UserConnect = "userConnect",
    AccountConnect = "accountConnect",
    Conversation = "Conversation",
    LoadConversations = "loadConversations",
    JoinConversation = "joinConversation",
    LeaveConversation = "leaveConversation",
    LoadMessages = "loadMessages",
    LoadMessageWithContactId = "loadMessagesWithContactId",
    Listen = "listen",
    Assign = "assign",
    Config = "config",
    LoadOnlineVisitors = "loadOnlineVisitors",
    LoadConversationStats = "loadConversationStats",
    ResolveConversation = "resolveConversation",
    ReopenConversation = "reopenConversation",
    AssignConversation = "assignConversation",
    AssignFromUnassignedConversation = "assignFromUnassignedConversation",
    UnAssignConversation = "unassignConversation",
    UpdateMention = "updateMention",
    BackStageConnect = "backStageConnect",
    BackStageEvents = "backStageEvents",
    ConversationBulkAction = "conversationBulkAction",
    RetryMediaUpload = "retryMediaUpload",

    SandboxMemberConnect = "sandboxMemberConnect",

    SandboxMemberAdded = "sandboxMemberAdded",
    SandboxChannelSetup = "sandboxChannelSetup",

    BulkUpdateConnect = "bulkUpdateConnect",
    BulkUpdateEvents = "bulkUpdateEvents",

    BulkActionMemberConnect = "bulkActionMemberConnect",
    ContactBulkAction = "contactBulkAction",
    GenericWebhookConnect = "genericWebhookConnect",
    OnGenericWebhookConnect = "onGenericWebhookConnect",

    GenericWebhookSampleResponse = "GenericWebhookSampleResponse",

    OnWATemplateCreate = "onWATemplateCreate",
    WATemplateSync = "WATemplateSync",

    UserAccountConnect = "userAccountConnect",
    UpdateAccountUsage = "updateAccountUsage",
    UpdateAccountAddons = "updateAccountAddons",
    UpdateAccountEntitlement = "updateAccountEntitlement",

    WARegistrationConnect = "waRegistrationConnect",
    OnWARegistration = "WARegistration",

    EntityImportConnect = "entityImportConnect",
    EntityImportUpdates = "entityImportUpdates",

    WhatsappFlowSyncConnect = "whatsappFlowSyncConnect",
    WhatsappFlowSync = "whatsappFlowSync",

    OnGenerateAIVoice = "generateAIVoice",

    WATemplateStatusChange = "waTemplateStatusChange",
    BuildAIBot = "buildAIBot",
}

export interface SocketErrorResponse {
    statusCode: number;
    title?: string;
    message: string;
    eventName: SocketEvents;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metaData: any;
}

export const useSocket = (
    eventName: SocketEvents,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback?: (...args: any[]) => void
): { socket: SocketIOClient.Socket | null; subscribe: () => void; unsubscribe: () => void } => {
    const socket = useContext<SocketIOClient.Socket | null>(SocketIOContext);
    const callbackRef = useRef(callback);

    callbackRef.current = callback;

    const eventKey = useMemo(() => {
        return `on${eventName.charAt(0).toUpperCase() + eventName.slice(1)}`;
    }, [eventName]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const socketHandlerRef = useRef((...args: any[]) => {
        if (callbackRef.current) {
            callbackRef.current(...args);
        }
    });

    const subscribe = () => {
        if (eventKey && socket) {
            socket.on(eventKey, socketHandlerRef.current);
        }
    };

    const unsubscribe = () => {
        if (eventKey && socket) {
            socket.off(eventKey, socketHandlerRef.current);
        }
    };

    useEffect(() => {
        subscribe();

        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventKey]);

    return { socket, unsubscribe, subscribe };
};
