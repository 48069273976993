import { useToast } from "@chakra-ui/react";
import { SAccountEntitlement, QueryKey, AccountConfig, TQueryKey } from "app/types";
import { postJSON } from "app/utils/fetchUtils";
import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { fetcher } from "app/utils/fetchUtils";

interface GetAccountConfigProps {
    accountId: string;
}

interface UseGetAccountEntitlementProps
    extends GetAccountConfigProps,
        UseQueryOptions<SAccountEntitlement, Error, SAccountEntitlement, TQueryKey> {}
type UseGetAccountEntitlementResult = UseQueryResult<SAccountEntitlement, Error>;

export const useGetAccountEntitlement = (props: UseGetAccountEntitlementProps): UseGetAccountEntitlementResult => {
    const { accountId, ...options } = props;

    const toast = useToast();

    const accountEntitlementFetcher: QueryFunction<SAccountEntitlement, TQueryKey> = ({ queryKey }) => {
        const [, { accountId }] = queryKey;
        return fetcher(`/api/accounts/${accountId}/entitlement`);
    };

    const queryResult = useQuery<SAccountEntitlement, Error, SAccountEntitlement, TQueryKey>(
        [QueryKey.GetAccountEntitlement, { accountId }],
        accountEntitlementFetcher,
        {
            onError: (error) => {
                toast({
                    title: "Error",
                    status: "error",
                    description: error?.message ?? "Something went wrong on fetching account entitlement",
                });
            },
            enabled: Boolean(accountId),
            ...options,
        }
    );

    return queryResult;
};

interface UseGetAccountConfigProps
    extends GetAccountConfigProps,
        UseQueryOptions<AccountConfig, Error, AccountConfig, TQueryKey> {}
type UseGetAccountConfigResult = UseQueryResult<AccountConfig, Error>;

export const useGetAccountConfig = (props: UseGetAccountConfigProps): UseGetAccountConfigResult => {
    const { accountId, ...options } = props;

    const toast = useToast();

    const accountConfigFetcher: QueryFunction<AccountConfig, TQueryKey> = ({ queryKey }) => {
        const [, { accountId }] = queryKey;
        return fetcher(`/api/accounts/${accountId}/configs`);
    };

    const queryResult = useQuery<AccountConfig, Error, AccountConfig, TQueryKey>(
        [QueryKey.AccountConfig, { accountId }],
        accountConfigFetcher,
        {
            onError: (error) => {
                toast({
                    title: "Error",
                    status: "error",
                    description: error?.message ?? "Something went wrong on fetching account config",
                });
            },
            enabled: Boolean(accountId),
            ...options,
        }
    );

    return queryResult;
};
