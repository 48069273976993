import AsyncSelectStyled, { AsyncSelectStyledProps } from "app/components/CustomizedReactSelect/AsyncSelectStyled";
import { useContactSearch } from "app/fetchHooks/contact";
import { Contact, LabelValue } from "app/types";
import { useAccountId } from "app/utils/react-helpers";
import debounce from "debounce-promise";
import React from "react";

type ContactOption = LabelValue & Contact;

export interface ContactSearchSelectProps
    extends Omit<AsyncSelectStyledProps<ContactOption>, "loadOptions" | "isLoading"> {
    contactLimitPerSearch?: number;
}

const ContactSearchSelect: React.FC<ContactSearchSelectProps> = (props) => {
    const { contactLimitPerSearch, ...selectProps } = props;
    const accountId = useAccountId();

    const { mutateAsync: fetchContactList, isLoading } = useContactSearch({
        accountId,
        populatePaths: ["company", "contactOwner"],
    });

    const getContactList = async (inputValue: string) => {
        const data = await fetchContactList({ search: inputValue, limit: contactLimitPerSearch });
        return data?.map((contact) => {
            return {
                label: contact?.name,
                value: contact?.id,
                ...contact,
            };
        });
    };

    return (
        <AsyncSelectStyled<ContactOption>
            cacheOptions
            noOptionsMessage={({ inputValue }) => {
                if (!inputValue) {
                    return "Type to search";
                }
                return `No contact found for ${inputValue}`;
            }}
            loadOptions={debounce(getContactList, 500)}
            isLoading={isLoading}
            loadingMessage={() => "Loading"}
            {...selectProps}
        />
    );
};

export default ContactSearchSelect;
